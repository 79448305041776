export const transformBillingAddress = (billingContact, shippingContact) => {
  return {
    phoneNumber: shippingContact.phoneNumber,
    city: billingContact.locality,
    firstName: billingContact.givenName,
    lastName: billingContact.familyName,
    postalCode: billingContact.postalCode,
    country: billingContact.countryCode,
    countrySubDivision: billingContact.administrativeArea,
    line1: billingContact.addressLines[0],
    line2: billingContact.addressLines[1] ?? '',
    addressType: 'Billing',
    emailAddress: shippingContact.emailAddress,
  };
};
