/* eslint max-statements: 0 */
import { startState, successState, errorState } from './item-state-changers';

export default (opts) => {
  opts = Object.assign(
    {
      startAction: opts.startAction,
      successAction: `${opts.startAction}_SUCCESS`,
      errorAction: `${opts.startAction}_ERROR`,
      idProp: 'id',
    },
    opts
  );

  const initialState = {};

  return {
    initialState,
    reducer: (state = initialState, action) => {
      switch (action.type) {
        case opts.startAction: {
          let payload = action.payload;
          if (!Array.isArray(action.payload)) {
            payload = [action.payload];
          }
          const stateToMerge = {};
          payload.forEach((id) => {
            stateToMerge[id] = startState(state[id]);
          });
          return Object.assign({}, state, stateToMerge);
        }
        case opts.successAction: {
          let payload = action.payload;
          if (!Array.isArray(payload)) {
            payload = [payload];
          }

          const stateToMerge = {};
          payload.forEach((item) => {
            const id = item[opts.idProp];
            stateToMerge[id] = successState(state[id], item);
          });
          return Object.assign({}, state, stateToMerge);
        }
        case opts.errorAction: {
          // payload for the error case should be the idProp of the item
          // which is to be updated at `state[idProp]`.
          // The error should be in an error property in the action.
          let payload = action.payload;
          if (!Array.isArray(action.payload)) {
            payload = [action.payload];
          }
          const stateToMerge = {};
          payload.forEach((id) => {
            stateToMerge[id] = errorState(state[id], action.error);
          });
          return Object.assign({}, state, stateToMerge);
        }
      }

      return state;
    },
  };
};
