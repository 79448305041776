import { createSelector } from 'reselect';
import { findLast, isEmpty, get } from 'lodash';

import { isMobileViewportSelector } from '../../../bundles/viewport';
import {
  currentRouteSelector,
  hashSelector,
} from 'shared/app/state/selectors/routes';

export const appTimeSelector = (state) => state.time;
export const bottomSheetDataSelector = (state) => state.bottomSheet;
export const dialogSelector = (state) => state.dialog;
export const hamburgerNavSelector = (state) => state.hamburgerNav;
export const maskActiveSelector = (state) => state.mask.active;
export const maskClickHandlerSelector = (state) => state.mask.maskClickHandler;
export const modalsStateSelector = (state) => state.modals;

const findCurrentLayer = (layerSet, routerHash) =>
  findLast(layerSet, (layer) => `#${layer.name}` === routerHash) || {};

export const routerHashSelector = createSelector(hashSelector, (hash) =>
  hash ? hash.substring(1) : undefined
);

export const modalToOpenByHashSelector = createSelector(
  modalsStateSelector,
  (modalState) => modalState.active
);

export const modalToBeClosedSelector = createSelector(
  modalsStateSelector,
  (modalState) => modalState.toBeClosed
);

export const bottomSheetSelector = createSelector(
  hashSelector,
  bottomSheetDataSelector,
  (hash, bottomSheetData) => {
    const bottomSheet = findCurrentLayer(
      bottomSheetData.bottomSheetRoutes,
      hash
    );

    if (isEmpty(bottomSheet)) {
      return null;
    }

    return {
      containerProps: bottomSheet.containerProps,
      content: bottomSheet.sheet,
      contentProps: bottomSheet.contentProps,
      props: bottomSheet.props,
      name: bottomSheet.name,
    };
  }
);

export const bottomSheetUrlSelector = createSelector(
  currentRouteSelector,
  (currentRoute) => `${currentRoute.pathname}${currentRoute.hash}`
);

export const bottomSheetReturnUrlSelector = createSelector(
  bottomSheetDataSelector,
  (bottomSheetData) => get(bottomSheetData, 'bottomSheetReturnUrl')
);

export const hamburgerNavIsOpenSelector = createSelector(
  hamburgerNavSelector,
  (hamburgerNav) => hamburgerNav.isOpen
);

/* eslint-disable max-params, complexity */
export const preventScrollingSelector = createSelector(
  hamburgerNavIsOpenSelector,
  bottomSheetSelector,
  dialogSelector,
  (hamburgerNavIsOpen, bottomSheet, dialog) => {
    const { content: BottomSheetContent } = bottomSheet || {};
    const { Content: DialogContent } = dialog || {};
    const hasBottomSheetContent = Boolean(BottomSheetContent);

    return (
      hamburgerNavIsOpen || hasBottomSheetContent || Boolean(DialogContent)
    );
  }
);

export const isAnyModalLayerOpenSelector = createSelector(
  bottomSheetSelector,
  dialogSelector,
  (bottomSheet, dialog) => Boolean(bottomSheet) || Boolean(dialog?.Content)
);

export const preventFocusTargetsSelector = createSelector(
  bottomSheetSelector,
  hamburgerNavIsOpenSelector,
  dialogSelector,
  isMobileViewportSelector,
  (bottomSheet, dialog, isMobileViewport) => {
    const { Content: DialogContent } = dialog || {};

    // In mobile viewports, standard overlays cover the full page,
    // rather than just a single crate. This flag indicates that the full page is
    // covered by an overlay and should be prevented from having focus.
    const mobilePreventFocusToAll = isMobileViewport && Boolean(bottomSheet);

    const preventFocusToAll =
      (isMobileViewport && mobilePreventFocusToAll) || Boolean(DialogContent);

    const preventFocusToContent = preventFocusToAll || Boolean(bottomSheet);

    // This is included in the event we need to add custom focus rules for the header
    const preventFocusToHeader = preventFocusToAll;

    return {
      all: preventFocusToAll,
      header: preventFocusToHeader,
      content: preventFocusToContent,
    };
  }
);
/* eslint-enable max-params, complexity */
