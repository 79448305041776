import React from 'react';
import { useIntl } from 'react-intl';

import Card from '@starbucks-web/pattern-library/lib/components/card/';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Button from '@starbucks-web/pattern-library/lib/components/button';

import {
  trackGuestSrCtaClick,
  UO_GUEST_SIGN_IN,
  UO_GUEST_SIGN_UP,
} from 'shared/app/state/track-event';
import { sharedCallsToAction } from '../../messages/ctas';

import styles from './upsell-card.cssm';
import messages from './messages';

const UpsellCard = ({ containerClasses = '' }) => {
  const { formatMessage } = useIntl();
  const redirectUser = ({ pathName, clickTarget }) => {
    trackGuestSrCtaClick({
      'click_target': clickTarget,
      'cta_placement': 'MOP_POST_ORDER',
    });

    // Adding a delay to allow the track event to succeed
    setTimeout(() => {
      const encodedReturnUrl = encodeURIComponent('/');
      window.location.href = `${pathName}?returnUrl=${encodedReturnUrl}`;
    }, 200);
  };

  const handleJoinNowClick = () => {
    redirectUser({
      pathName: '/account/create',
      clickTarget: UO_GUEST_SIGN_UP,
    });
  };

  const handleSignInClick = () => {
    redirectUser({
      pathName: '/account/signin',
      clickTarget: UO_GUEST_SIGN_IN,
    });
  };

  return (
    <Card
      containerClasses={`${styles.card} ${containerClasses}`}
      contentClasses="flex flex-column items-center text-center"
      disableBoxShadow
    >
      <Heading className={`mt4 mb1 text-bold text-md`} tagName="h2">
        {formatMessage(messages.upsellHeading)}
      </Heading>
      <p className={`mx4 text-xs lg-text-sm`}>
        {formatMessage(messages.upsellBody)}
      </p>
      <div className="my3">
        <Button
          className={`mr3 text-xs lg-text-sm`}
          onClick={handleJoinNowClick}
          visualStyle="positive"
        >
          {formatMessage(sharedCallsToAction.joinNow)}
        </Button>
        <Button
          className={`mr3 text-xs lg-text-sm color-houseGreen }`}
          onClick={handleSignInClick}
        >
          {formatMessage(sharedCallsToAction.signin)}
        </Button>
      </div>
    </Card>
  );
};

export default UpsellCard;
