import { defineMessages } from 'react-intl';

export default defineMessages({
  nonSmsNumberDialogHeading: {
    id: 'shared.mfa.nonSmsNumberDialog.heading',
    defaultMessage: "This number isn't SMS enabled",
    description: "Heading for 'phone is not SMS number' dialog",
  },
  nonSmsNumberDialogBody: {
    defaultMessage:
      "{phoneNumber} isn't set up to receive text messages. Would you like us to call you instead?",
    description: "Body for 'phone is not SMS number' dialog",
    id: 'shared.mfa.nonSmsNumberDialog.body',
  },
  nonSmsNumberDialogConfirm: {
    defaultMessage: 'Call me',
    description: "Confirm button text for 'phone is not SMS number' dialog",
    id: 'shared.mfa.nonSmsNumberDialog.confirm',
  },
  nonSmsNumberDialogCancel: {
    defaultMessage: 'Not now',
    description: "Cancel button text for 'phone is not SMS number' dialog",
    id: 'shared.mfa.nonSmsNumberDialog.cancel',
  },
});
