import { defineMessages } from 'react-intl';

export default defineMessages({
  tipAmountLabel: {
    id: 'shared.tipAmountSelect.tipAmountLabel',
    defaultMessage: 'Tip amount',
    description: 'Label for the amount of the tip',
  },
  noTipLabel: {
    id: 'shared.tipAmountSelect.noTipLabel',
    defaultMessage: 'No tip',
    description: 'Label for no tip',
  },
});
