import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import OverlayWithCloseMessage from 'shared/app/components/overlay-with-close-message';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';

import { sharedCallsToAction, redeemMessages } from 'shared/app/messages';

export const redeemOverlayHeadingId = 'redeemOverlayHeadingId';

const RedeemOverlay = ({ configImagesUrl }) => {
  const imageUrl = `${configImagesUrl}/rewards/redeem-desktop.png`;
  const { closeModal } = useModalContext();

  return (
    <OverlayWithCloseMessage
      imageAlt=""
      imageObjectFit="contain"
      imagePath={imageUrl}
      permitImageToShrink
    >
      <div className="sb-global-gutters pb4">
        <Heading
          className="text-semibold text-md"
          id={redeemOverlayHeadingId}
          tagName="h2"
        >
          <FormattedMessage
            {...redeemMessages.redeemOrderAheadToRedeemPrompt}
          />
        </Heading>
        <p className="mt4">
          <FormattedMessage {...redeemMessages.redeemOrderAheadDetail} />
        </p>
        <p className="mt4">
          <FormattedMessage {...redeemMessages.redeemOrderInPersonCaveat} />
        </p>
        <div className="text-right pt4">
          <Button
            data-e2e="redeemStartAnOrder"
            onClick={() => closeModal()}
            tagName={Link}
            to="/menu"
            visualStyle="positive"
          >
            <FormattedMessage {...sharedCallsToAction.startAnOrder} />
          </Button>
        </div>
      </div>
    </OverlayWithCloseMessage>
  );
};

const select = (state) => ({
  configImagesUrl: configImagesUrlSelector(state),
});

export default connect(select)(RedeemOverlay);
