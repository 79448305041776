'use strict';

const getCaseInsensitiveQueryParam = require('./get-case-insensitive-query-param');

// Gets value of case-insensitive ReturnUrl key from object.
// This value is typically sent through query parameters and
// should be handled with case insensitivity for broader support
// throughout the legacy apps.
const getReturnUrl = (obj) => getCaseInsensitiveQueryParam(obj, 'returnUrl');

module.exports = getReturnUrl;
