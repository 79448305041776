import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ProgressTracker from 'shared/app/components/progress-tracker';
import RewardsStatusMessage from 'shared/app/components/rewards-status-message';
import StarBalance from 'shared/app/components/star-balance';
import { isAccountRewardsPathSelector } from 'shared/app/state/selectors/rewards';

import DisplayRipple from './display-ripple.js';
import messages from './messages';
import styles from './styles.cssm';

const trackerAriaLabelId = 'msrMemberTrackerAriaLabelId';
class LoyaltyStatus extends React.Component {
  render() {
    const {
      intl: { formatMessage },
      isRewardsPath,
      startFadeOut,
      startFadeIn,
    } = this.props;

    return (
      <React.Fragment>
        <div className="relative flex justify-center">
          <DisplayRipple ref={(el) => (this.displayRippleRef = el)} />
          <StarBalance className={`${styles.starBalance}`} starSize="36px" />
          {!isRewardsPath && (
            <Link
              aria-label={formatMessage(messages.viewRewardsLink)}
              className={`${styles.linkOverlay} linkOverlay__primary`}
              to="/account/rewards"
            />
          )}
        </div>
        <div className="relative flex justify-center">
          <RewardsStatusMessage id={trackerAriaLabelId} />
        </div>
        <ProgressTracker
          ariaLabelledbyId={trackerAriaLabelId}
          data-e2e="progressTracker"
          startFadeIn={startFadeIn}
          startFadeOut={startFadeOut}
          startRippleHighlight={
            this.displayRippleRef && this.displayRippleRef.startAnimation
          }
        />
      </React.Fragment>
    );
  }
}

LoyaltyStatus.propTypes = {
  startFadeOut: PropTypes.func,
  startFadeIn: PropTypes.func,
};

const select = (state) => ({
  isRewardsPath: isAccountRewardsPathSelector(state),
});

export default injectIntl(connect(select)(LoyaltyStatus));
