import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'shared.fourOhFour.title',
    defaultMessage: '404: Page not found',
    description: 'Title for 404 page',
  },
  heading: {
    id: 'shared.fourOhFour.heading',
    defaultMessage: "We can't find the page you're looking for.",
    description: 'Heading for 404 page',
  },
  instructions: {
    id: 'shared.fourOhFour.instructions',
    defaultMessage:
      "Don't worry, it's just a little spilled {name}. Let us help you clean that up and find your way!",
    description: 'Instructions to mop up spill',
  },
  imageDescription: {
    id: 'shared.fourOhFour.imageDescriptions',
    defaultMessage: 'Spilled {name} beverage with tipped cup next to it.',
    description: 'A11y description of SpilledCoffeeCanvas component',
  },
  imageInstructions: {
    id: 'shared.fourOhFour.imageInstructions',
    defaultMessage: 'Mop up the spill to reveal something underneath.',
    description: 'A11y instructions to mop up spill',
  },
  linkListHeading: {
    id: 'shared.fourOhFour.linkListHeading',
    defaultMessage: 'Would you like to:',
    description: 'Heading for useful links out of 404 page',
  },
  linkListCta1: {
    id: 'shared.fourOhFour.linkListCta1',
    defaultMessage: 'Place an order',
    description: 'Link to menu',
  },
  linkListCta2: {
    id: 'shared.fourOhFour.linkListCta2',
    defaultMessage: 'Check a gift card',
    description: 'Link to gift',
  },
  linkListCta3: {
    id: 'shared.fourOhFour.linkListCta3',
    defaultMessage: 'Return to home page',
    description: 'Link to home',
  },
});
