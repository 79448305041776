import createActionTypes from '../../state/create-action-types';

export const SET_CARD_IN_MANAGEMENT = 'SET_CARD_IN_MANAGEMENT';

export const {
  FETCH_SVC_CARDS,
  FETCH_SVC_CARDS_SUCCESS,
  FETCH_SVC_CARDS_ERROR,

  SET_PRIMARY_SVC_CARD,
  SET_PRIMARY_SVC_CARD_SUCCESS,
  SET_PRIMARY_SVC_CARD_ERROR,

  ADD_SVC_CARD,
  ADD_SVC_CARD_SUCCESS,
  ADD_SVC_CARD_ERROR,

  REMOVE_SVC_CARD,
  REMOVE_SVC_CARD_SUCCESS,
  REMOVE_SVC_CARD_ERROR,

  // get card balance
  FETCH_SVC_CARD_BALANCE,
  FETCH_SVC_CARD_BALANCE_SUCCESS,
  FETCH_SVC_CARD_BALANCE_ERROR,

  // reload card balance
  RELOAD_SVC_CARD_BALANCE,
  RELOAD_SVC_CARD_BALANCE_SUCCESS,
  RELOAD_SVC_CARD_BALANCE_ERROR,

  // auto reload
  UPDATE_AUTO_RELOAD,
  UPDATE_AUTO_RELOAD_SUCCESS,
  UPDATE_AUTO_RELOAD_ERROR,

  // transfer card balance
  TRANSFER_SVC_CARD_BALANCE,
  TRANSFER_SVC_CARD_BALANCE_SUCCESS,
  TRANSFER_SVC_CARD_BALANCE_ERROR,

  // edit card nickname
  UPDATE_NICKNAME,
  UPDATE_NICKNAME_SUCCESS,
  UPDATE_NICKNAME_ERROR,

  // get card info from eGift token
  GET_CARD_INFO_FROM_TOKEN,
  GET_CARD_INFO_FROM_TOKEN_SUCCESS,
  GET_CARD_INFO_FROM_TOKEN_ERROR,
} = createActionTypes([
  'FETCH_SVC_CARDS',
  'SET_PRIMARY_SVC_CARD',
  'ADD_SVC_CARD',
  'REMOVE_SVC_CARD',
  'FETCH_SVC_CARD_BALANCE',
  'RELOAD_SVC_CARD_BALANCE',
  'UPDATE_AUTO_RELOAD',
  'TRANSFER_SVC_CARD_BALANCE',
  'UPDATE_NICKNAME',
  'GET_CARD_INFO_FROM_TOKEN',
]);

// report lost or stolen card
export const REPORT_LOST_SVC = 'REPORT_LOST_SVC';
export const REPORT_LOST_SVC_SUCCESS = 'REPORT_LOST_SVC_SUCCESS';
export const REPORT_LOST_SVC_INCOMPLETE = 'REPORT_LOST_SVC_INCOMPLETE';
export const REPORT_LOST_SVC_ERROR = 'REPORT_LOST_SVC_ERROR';
export const CLEAR_REPORT_LOST_STOLEN = 'CLEAR_REPORT_LOST_STOLEN';

export const CLEAR_RELOAD_AMOUNT_SELECTED = 'CLEAR_RELOAD_AMOUNT_SELECTED';
export const SET_RELOAD_AMOUNT_SELECTED = 'SET_RELOAD_AMOUNT_SELECTED';

export const CLOSE_PAY_OVERLAY = 'CLOSE_PAY_OVERLAY';
