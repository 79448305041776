import { Link } from 'react-router-dom';

import { sharedCallsToAction } from 'shared/app/messages';

const PERSONAL_INFO_LINK_ID = 'personal-information';

const customizeSecondaryLinks = ({
  coreAppBaseUrl,
  formatMessage,
  isCoreAppUrl,
  links = [],
  signedIn,
  trustArcEnabled,
}) => {
  const linkList = links.map((link) => {
    if (signedIn && link.id === PERSONAL_INFO_LINK_ID) {
      return isCoreAppUrl
        ? {
            tagName: Link,
            text: link.text,
            to: '/account/settings/privacy',
          }
        : {
            text: link.text,
            href: `${coreAppBaseUrl}/account/settings/privacy`,
          };
    }
    return link;
  });

  if (trustArcEnabled) {
    const cookiePreferencesLink = {
      text: formatMessage(sharedCallsToAction.cookiePreferences),
      tagName: 'button',
      // truste is part of the third party TrustArc cookie solution
      onClick: () => truste?.eu?.clickListener?.(1),
    };
    return [...linkList, cookiePreferencesLink];
  }
  return linkList;
};

export default customizeSecondaryLinks;
