import { startsWith } from 'lodash';
import { Link } from 'react-router-dom';

export const setNavLinkProps = (url, isCoreAppUrl) =>
  isCoreAppUrl && !startsWith(url, 'https://')
    ? {
        to: url,
        tagName: Link,
      }
    : {
        href: url,
        tagName: 'a',
      };

export const navItemFormatter = (item, currentRoute, isCoreAppUrl) => {
  const { url, name, ...otherProps } = item;
  const navProps = setNavLinkProps(url, isCoreAppUrl);

  return {
    active: startsWith(currentRoute, url),
    children: name,
    ...navProps,
    ...otherProps,
  };
};

export const navItemsFormatter = ({
  currentRoute,
  isCoreAppUrl,
  logoOnly,
  navItems,
}) => {
  if (logoOnly) {
    return [];
  }
  return navItems.map((item) => {
    const { subNavItems } = item;
    const hasSubNavItems = subNavItems?.length;
    return hasSubNavItems
      ? {
          ...navItemFormatter(item, currentRoute, isCoreAppUrl),
          subNavItems: subNavItems.map((subNavItem) =>
            navItemFormatter(subNavItem, currentRoute, isCoreAppUrl)
          ),
        }
      : navItemFormatter(item, currentRoute, isCoreAppUrl);
  });
};
