// reads a cookie by name
// src: https://github.com/HenrikJoreteg/cookie-getter/blob/master/cookie-getter.js
export default (name, cookieOverride) => {
  if (!cookieOverride && typeof document === 'undefined') {
    return null;
  }
  const cookie = cookieOverride || document.cookie;
  const setPos = cookie.search(new RegExp(`\\b${name}=`));
  const stopPos = cookie.indexOf(';', setPos);
  if (setPos === -1) {
    return null;
  }
  const res = decodeURIComponent(
    cookie.substring(setPos, stopPos !== -1 ? stopPos : undefined).split('=')[1]
  );
  return res;
};
