// returns configured geolocation bundle
import { createSelector } from 'reselect';
import { pick, round } from 'lodash';

export const GEOLOCATION_START = 'GEOLOCATION_START';
export const GEOLOCATION_SUCCESS = 'GEOLOCATION_SUCCESS';
export const GEOLOCATION_ERROR = 'GEOLOCATION_ERROR';

export const geolocationStateSelector = (state) => state.geolocation;
export const geolocationCoordsSelector = createSelector(
  geolocationStateSelector,
  (location) => location.data || null
);
export const hasGeolocationSelector = createSelector(
  geolocationStateSelector,
  (location) => Boolean(location?.data)
);
export const geolocationIsLoadingSelector = createSelector(
  geolocationStateSelector,
  (location) => location.loading
);
export const geolocationDeniedSelector = createSelector(
  geolocationStateSelector,
  (location) => location.error === 1
);
export const geolocationFailedSelector = createSelector(
  geolocationStateSelector,
  (location) => location.error !== null
);
export const geolocationPermanentlyFailedSelector = createSelector(
  geolocationStateSelector,
  (location) => {
    const { error } = location;
    // 0 is not supported, 1 is denied
    return (error !== null && error === 0) || error === 1;
  }
);
export const geolocationTime = createSelector(
  geolocationStateSelector,
  (location) => location.timestamp
);

const defaultOptions = {
  userInitiated: false,
  geoOptions: {},
};
export const requestGeolocation =
  (options) =>
  (dispatch, getState, { getGeolocation }) => {
    const { userInitiated, geoOptions } = Object.assign(
      {},
      defaultOptions,
      options
    );
    dispatch({ type: GEOLOCATION_START, payload: { userInitiated } });
    getGeolocation(geoOptions)
      .then((position) => {
        const cleaned = pick(position.coords, [
          'latitude',
          'longitude',
          'accuracy',
        ]);
        cleaned.latitude = round(cleaned.latitude, 6);
        cleaned.longitude = round(cleaned.longitude, 6);
        dispatch({
          type: GEOLOCATION_SUCCESS,
          payload: {
            coords: cleaned,
            timestamp: position.timestamp,
            userInitiated,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: GEOLOCATION_ERROR,
          error,
          payload: { userInitiated },
        });
      });
  };

const initialState = {
  loading: false,
  error: null,
  data: null,
  timestamp: null,
};

export default {
  name: 'geolocation',
  reducer: (state = initialState, action) => {
    switch (action.type) {
      case GEOLOCATION_START:
        return Object.assign({}, state, {
          error: null,
          loading: true,
        });
      case GEOLOCATION_SUCCESS:
        return Object.assign({}, state, {
          loading: false,
          data: action.payload.coords,
          error: null,
          lastFetch: Date.now(),
          timestamp: action.payload.timestamp,
        });
      case GEOLOCATION_ERROR:
        return Object.assign({}, state, {
          error: action.error.code || 0,
          lastError: Date.now(),
          loading: false,
        });
    }
    return state;
  },
  persistAfter: [GEOLOCATION_SUCCESS],
  mergeCachedAndBootstrapData: (cachedData) => cachedData,
};
