import { SET_HAMBURGER_NAV_OPEN, SET_HAMBURGER_NAV_CLOSED } from '../actions';

export default (state = { isOpen: false }, action) => {
  if (action.type === SET_HAMBURGER_NAV_OPEN) {
    return { isOpen: true };
  }
  if (action.type === SET_HAMBURGER_NAV_CLOSED) {
    return { isOpen: false };
  }
  return state;
};
