import { ADD_NOTIFICATION, DISMISS_NOTIFICATION } from '../actions';

let idCounter = 0;
const AUTO_CLOSE_DEFAULT = 7000;

export default (state = null, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return {
        autoClose: AUTO_CLOSE_DEFAULT,
        body: null,
        callback: () => {},
        cancelTextId: '',
        cancelTextValues: {},
        confirmTextId: '',
        confirmTextValues: {},
        id: ++idCounter,
        messageId: '',
        messageValues: {},
        title: null,
        titleId: '',
        titleValues: {},
        type: 'generic',
        useErrorLoadingIndicator: false,
        ...action.payload,
      };
    }
    case DISMISS_NOTIFICATION: {
      return null;
    }
    default: {
      return state;
    }
  }
};
