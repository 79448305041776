import { defineMessages } from 'react-intl';

const messages = defineMessages({
  city: {
    id: 'shared.cityField.label',
    defaultMessage: 'City',
    description: 'Label for city field',
  },
  cityError: {
    id: 'shared.cityField.missingError',
    defaultMessage: 'Enter your city',
    description: 'Error message displayed when city is missing',
  },
});

export default messages;
