// This reducer keeps track of how many open
// requests there are. It does this by
// incrementing on start and decrementing on
// end (either via error or success).

// This is being done by convention, it assumes
// all fetch-related actions end with `_SUCCESS` or `_ERROR`

// Helper used by `.map` to defensively get and validate
// that corresponding ending action exists.
const getRelatedAction = (suffix) => (action) => action + suffix;

export default (starterActions) => {
  // Generate a list of corresponding SUCCESS actions
  const successActions = starterActions.map(getRelatedAction('_SUCCESS'));

  // Generate a list of corresponding ERROR actions
  const errorActions = starterActions.map(getRelatedAction('_ERROR'));

  // Combine the two list of actions that end async requests
  const enderActions = successActions.concat(errorActions);

  // helpers for checking if given action is starter or ender
  // of an async action
  const isEnd = (type) => enderActions.indexOf(type) !== -1;
  const isStart = (type) => starterActions.indexOf(type) !== -1;

  // the actual reducer is quite simple
  return (state = 0, { type }) => {
    if (isStart(type)) {
      return ++state;
    }

    if (isEnd(type)) {
      return --state;
    }

    return state;
  };
};
