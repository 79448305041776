import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { successNotificationSelector } from '../../shell/state/selectors/notification';

import styles from './styles.cssm';

export const ToastSlider = ({ children, mobileOnly, successNotification }) => {
  const hasToast = Boolean(successNotification);
  const baseClass = hasToast ? styles.sliderActive : styles.slider;

  // The base height of a 1 or 2 line toast. Toasts can be taller
  // in some cases, but this is the default that we optimize for.
  const BASE_TOAST_HEIGHT = 67;
  const translateRule = `translateY(-${BASE_TOAST_HEIGHT}px)`;

  return (
    <div
      className={classNames(baseClass, {
        [styles.mobileOnly]: mobileOnly,
      })}
      data-e2e="toast-slider"
      style={{
        transform: hasToast ? translateRule : 'none',
      }}
    >
      {children}
    </div>
  );
};

ToastSlider.propTypes = {
  /**
   * Flag indicating that the animation should only apply on mobile screens.
   * Useful for elements that sit in the left-hand crate on desktop and don't
   * intersect with the toast.
   */
  mobileOnly: PropTypes.bool,
};

const select = (state) => ({
  successNotification: successNotificationSelector(state),
});

export default connect(select)(ToastSlider);
