import { defineMessages } from 'react-intl';

const messages = defineMessages({
  firstName: {
    id: 'shared.firstName.label',
    defaultMessage: 'First name',
    description: 'Label for first name field',
  },
  lastName: {
    id: 'shared.lastName.label',
    defaultMessage: 'Last name',
    description: 'Label for last name field',
  },
});

export default messages;
