import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import ExternalLink from 'shared/app/components/external-link';

import { termsOfUseUrlSelector } from 'shared/app/state/selectors/privacy-and-terms';
import { accountsMessages } from 'shared/app/messages';

const TermsOfUseLink = ({ className }) => {
  const { formatMessage } = useIntl();
  const termsOfUseUrl = useSelector(termsOfUseUrlSelector);
  return (
    <ExternalLink
      className={className}
      data-e2e="terms-of-use-link"
      href={termsOfUseUrl}
    >
      {formatMessage(accountsMessages.termsOfUse)}
    </ExternalLink>
  );
};

export default TermsOfUseLink;
