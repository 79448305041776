import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Logo from '@starbucks-web/pattern-library/lib/components/logo';

import { commonMessages } from 'shared/app/messages';

const LogoWithA11yName = ({
  href = '/',
  isCoreAppUrl = false,
  ...otherProps
}) => {
  const { formatMessage } = useIntl();
  const logoProps = isCoreAppUrl
    ? { to: href, tagName: Link, ...otherProps }
    : { href, tagName: 'a', ...otherProps };
  return (
    <Logo ariaLabel={formatMessage(commonMessages.logoLabel)} {...logoProps} />
  );
};

LogoWithA11yName.propTypes = {
  href: PropTypes.string,
  isCoreAppUrl: PropTypes.bool,
  to: PropTypes.string,
  tagName: PropTypes.string,
};

export default LogoWithA11yName;
