import {
  GET_TERMS_PRIVACY_STATUS,
  GET_TERMS_PRIVACY_STATUS_SUCCESS,
  GET_TERMS_PRIVACY_STATUS_ERROR,
  SET_TERMS_PRIVACY_STATUS,
  SET_TERMS_PRIVACY_STATUS_SUCCESS,
  SET_TERMS_PRIVACY_STATUS_ERROR,
} from './types';

import {
  SET_TERMS_ACKNOWLEDGEMENT,
  GET_TERMS_ACKNOWLEDGEMENT,
} from 'shared/universal/gql-operation-ids';
import { setTermsPrivacyFlag } from 'shared/app/utils/local-storage-privacy-flag';

import {
  rawTermsPrivacy,
  hasTermsAcknowledgementFlag,
  shouldCheckTermsPrivacyStatusSelector,
} from '../selectors/terms-privacy';

export const getTermsPrivacyStatus =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: GET_TERMS_PRIVACY_STATUS });

    return gqlFetch({
      operationId: GET_TERMS_ACKNOWLEDGEMENT,
      allowNonFatalErrors: true,
    })
      .then((result) => {
        dispatch({
          type: GET_TERMS_PRIVACY_STATUS_SUCCESS,
          payload: result?.termsAcknowledgement,
        });
        if (result?.termsAcknowledgement?.acknowledged) {
          setTermsPrivacyFlag(result?.termsAcknowledgement?.version);
        }
      })
      .catch((error) =>
        dispatch({
          type: GET_TERMS_PRIVACY_STATUS_ERROR,
          error,
        })
      );
  };

export const checkTermsPrivacyStatus = () => (dispatch, getState) => {
  const state = getState();
  if (
    shouldCheckTermsPrivacyStatusSelector(state) &&
    !hasTermsAcknowledgementFlag &&
    !rawTermsPrivacy(state)?.lastFetch
  ) {
    dispatch(getTermsPrivacyStatus());
  }
};

export const setTermsPrivacyStatus =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: SET_TERMS_PRIVACY_STATUS });

    return gqlFetch({
      operationId: SET_TERMS_ACKNOWLEDGEMENT,
      allowNonFatalErrors: true,
    })
      .then((result) => {
        dispatch({
          type: SET_TERMS_PRIVACY_STATUS_SUCCESS,
          payload: result?.acknowledgeTerms,
        });
        setTermsPrivacyFlag(result?.termsAcknowledgement?.version);
      })
      .catch((error) => {
        dispatch({ type: SET_TERMS_PRIVACY_STATUS_ERROR, error });
        throw error;
      });
  };
