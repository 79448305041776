import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import Frap from '@starbucks-web/pattern-library/lib/components/frap';
import FrapContainer from '../frap-container';
import { getShortStoreNumber } from '../../bundles/menu/util/get-filter-query';

import messages from './messages';

const PickupImHereFrap = ({
  closeModal,
  isCoreAppUrl,
  isSignedIn,
  selectedPickupOptionPath,
  localMostRecentOrderStore,
  localMostRecentOrderToken,
  mostRecentOrder,
}) => {
  if (!selectedPickupOptionPath) {
    return null;
  }
  const guestFirstName = mostRecentOrder?.orderStatus?.guest?.firstName;
  const guestFirstNameQuery =
    !isSignedIn && guestFirstName ? `&firstName=${guestFirstName}` : '';
  const pickupUrl = `${selectedPickupOptionPath}?store=${getShortStoreNumber(
    localMostRecentOrderStore?.storeNumber
  )}&orderToken=${localMostRecentOrderToken}${guestFirstNameQuery}&platform=web`;

  return (
    <FrapContainer>
      <Frap
        data-e2e="pickupImHereLink"
        href={isCoreAppUrl ? undefined : pickupUrl}
        onClick={() => {
          closeModal();
        }}
        tagName={isCoreAppUrl ? Link : undefined}
        to={isCoreAppUrl ? pickupUrl : undefined}
      >
        <FormattedMessage {...messages.pickupCheckInCta} />
      </Frap>
    </FrapContainer>
  );
};

PickupImHereFrap.propTypes = {
  isCoreAppUrl: PropTypes.bool,
  isSignedIn: PropTypes.bool,
  selectedPickupOptionPath: PropTypes.string,
  localMostRecentOrderStore: PropTypes.object,
  localMostRecentOrderToken: PropTypes.string,
  mostRecentOrder: PropTypes.object,
};

export default PickupImHereFrap;
