'use strict';

const startsWithHttp = /^http:\/\//i;

module.exports = (url) => {
  if (!url) {
    return '';
  }
  return url.replace(startsWithHttp, 'https://');
};
