import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pickupOverlayHeader: {
    id: 'shared.orderPickupOverlay.header',
    defaultMessage: 'See you soon, {name}!',
    description: 'Header for pickup overlay',
  },
  orderItems: {
    id: 'shared.orderPickupOverlay.orderItems',
    defaultMessage:
      '{orderItemsCount} {orderItemsCount, plural, one {item} other {items}} in your order',
    description: 'order items count label',
  },
  howToPickup: {
    id: 'shared.orderPickupOverlay.howToPickup',
    defaultMessage: 'How to pick up',
    description: 'pickup options label',
  },
  pickupInStoreOption: {
    id: 'shared.orderPickupOverlay.pickupInStore.Option',
    defaultMessage:
      'Head to the pickup counter and look for an order for {name}. If you have questions, just ask a barista.',
    description: 'pickup options in-store description',
  },
  pickupDriveThruOption: {
    id: 'shared.orderPickupOverlay.pickupDriveThru.Option',
    defaultMessage:
      'Pull up to the drive-thru and ask the barista for an order for {name}.',
    description: 'pickup options drive-thru description',
  },
  pickupCurbsideOption: {
    id: 'shared.orderPickupOverlay.pickupCurbside.Option',
    defaultMessage:
      'Park in a reserved curbside spot and check in. A barista will bring your order to you.',
    description: 'pickup options curbside check in description',
  },
  pickupOutdoorOption: {
    id: 'shared.orderPickupOverlay.pickupOutdoor.Option',
    defaultMessage:
      'Check in and your order will be handed off on a table outside the store.',
    description: 'pickup options outdoor check in description',
  },
  pickupCheckInCta: {
    id: 'shared.orderPickupOverlay.pickupCheckInCta',
    defaultMessage: "I'm here",
    description: 'CTA for navigating useer to selected check in option',
  },
  pickupMaskupImageAltText: {
    id: 'shared.orderPickupOverlay.maskup.ImageAltText',
    defaultMessage: 'A medical mask',
    description: 'alternate text for missing medical mask image',
  },
  pickupMaskupHeader: {
    id: 'shared.orderPickupOverlay.maskup.Header',
    defaultMessage: 'Facial coverings recommended',
    description: 'facial covering recommendation header',
  },
  pickupMaskupText: {
    id: 'shared.orderPickupOverlay.maskup.Text',
    defaultMessage:
      'Please follow CDC guidance and local mandates for facial coverings when visiting our stores.',
    description: 'facial covering recommendation text',
  },
  pickupOrderReceiptAndTipping: {
    id: 'shared.orderPickupOverlay.orderReceiptAndTipping',
    defaultMessage: 'Receipt & Tipping',
    description: 'Link to the order receipt and tipping option.',
  },
  pickupOrderViewReceipt: {
    id: 'shared.orderPickupOverlay.pickupOrderViewReceipt',
    defaultMessage: 'View receipt',
    description: 'Link to the order receipt.',
  },
  guestEmailMessage: {
    id: 'shared.orderPickupOverlay.guestEmailMessage',
    defaultMessage: 'We’ll send an email receipt to {email}',
    description:
      'Message to inform guest users that a receipt will be emailed to them.',
  },
  orderPickupTimeGeneric: {
    id: 'shared.orderPickupOverlay.orderPickupTimeGeneric',
    defaultMessage: 'Your order will be ready soon.',
    description: 'Generic pickup time message',
  },
  orderPickupTimeLoading: {
    id: 'shared.orderPickupOverlay.orderPickupTimeLoading',
    defaultMessage: 'Getting your order ready time, hold tight.',
    description: 'pickup time message when still fetching data',
  },
  orderPickupTime: {
    id: 'shared.orderPickupOverlay.orderPickupTime',
    defaultMessage:
      'Ready around {orderPickupTime} (in {orderPickupTimeMin}-{orderPickupTimeMax} minutes)',
    description: 'order pickup time message',
  },
});

export default messages;
