import { createSelector } from 'reselect';

export const configSelector = (state) => state.config;

export const localeEmailTopicsSelector = createSelector(
  configSelector,
  (config) => config.communicationPreferences.email
);

export const localeMailTopicsSelector = createSelector(
  configSelector,
  (config) => config.communicationPreferences.mail
);

export const communicationPreferencesSelector = createSelector(
  configSelector,
  (config) => config.universal.communicationPreferences
);

export const topicsListSelector = createSelector(
  communicationPreferencesSelector,
  (commPrefs) => commPrefs.topics
);
