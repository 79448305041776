import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';
import { CLEAR_STORE_MENU } from 'ordering/app/state/actions/types';
import {
  FETCH_MENU,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_ERROR,
} from '../action-types';

const { initialState, reducer } = createSingleResourceReducer({
  startAction: FETCH_MENU,
});

export const completeInitialState = {
  ...initialState,
  currentMenuStoreNumber: null,
  storeNumberBeingFetched: null,
  failedMenuStoreNumber: null,
};

export default (state = completeInitialState, action) => {
  state = reducer(state, action);
  switch (action.type) {
    case FETCH_MENU: {
      return {
        ...state,
        storeNumberBeingFetched: action.payload.storeNumber,
      };
    }

    case FETCH_MENU_SUCCESS: {
      return {
        ...state,
        currentMenuStoreNumber: state.storeNumberBeingFetched,
        storeNumberBeingFetched: null,
        // clear any previous failure
        failedMenuStoreNumber: null,
      };
    }

    case FETCH_MENU_ERROR: {
      return {
        ...state,
        failedMenuStoreNumber: state.storeNumberBeingFetched,
        storeNumberBeingFetched: null,
      };
    }

    case CLEAR_STORE_MENU: {
      return { ...completeInitialState };
    }

    default:
      return state;
  }
};
