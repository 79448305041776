/* eslint-disable max-params */
import { createSelector } from 'reselect';
import { isEmpty, flatMap } from 'lodash';

import shouldUpdate from 'shared/app/stale-reducers/should-update';
import IS_BROWSER from 'shared/app/shell/utils/is-browser';
import { configSelector } from 'shared/app/shell/state/selectors/config';
import { routeSelector } from 'shared/app/state/selectors/routes';
import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';

import { FETCH_MENU_ERROR } from './action-types';
const appTimeSelector = (state) => state?.time;
export const fullMenuStateSelector = (state) => state?.menu?.menu;
const previousActionSelector = (state) => state?.previousAction;

export const menuProductsByNumberAndFormSelector = (state) =>
  state?.menu?.menuProductsByNumberAndForm;

export const ROUTES_THAT_NEED_MENU = ['/'];

export const fullMenuSelector = createSelector(
  fullMenuStateSelector,
  (menuState) => menuState.data ?? []
);

export const fullMenuIsEmptySelector = createSelector(
  fullMenuStateSelector,
  fullMenuSelector,
  (menuState, fullMenu) => Boolean(menuState.lastFetch && isEmpty(fullMenu))
);

export const menuIsLoadingSelector = createSelector(
  fullMenuStateSelector,
  (menuState) => menuState.loading
);

export const menuFetchFailedSelector = createSelector(
  fullMenuStateSelector,
  // if lastFetch is populated, data from previous fetch is
  // still available
  (menuState) => Boolean(menuState.lastError)
);

export const menuLoadingCompletedSelector = createSelector(
  fullMenuStateSelector,
  (menuState) => Boolean(menuState.lastFetch)
);

export const allCategoriesSelector = createSelector(
  fullMenuSelector,
  (fullMenu) => flatMap(fullMenu, (menu) => menu.children)
);

export const currentMenuStoreNumberSelector = createSelector(
  fullMenuStateSelector,
  (menuState) => menuState.currentMenuStoreNumber
);

const storeNumberBeingFetchedSelector = createSelector(
  fullMenuStateSelector,
  (menuState) => menuState.storeNumberBeingFetched
);

const failedMenuStoreNumberSelector = createSelector(
  fullMenuStateSelector,
  (menuState) => menuState.failedMenuStoreNumber
);

export const storeMenusDidFailSelector = createSelector(
  previousActionSelector,
  failedMenuStoreNumberSelector,
  (previousAction, failedMenuStoreNumber) => {
    // reference previousAction so that this selector doesn't stay true indefinitely;
    // this is used to trigger a failure notification.
    return (
      previousAction?.type === FETCH_MENU_ERROR &&
      Boolean(failedMenuStoreNumber)
    );
  }
);

export const isPdpRequestSelector = createSelector(
  routeSelector,
  (route) => route === '/menu/product/:productNumber/:formCode'
);

export const selectedStoreNumberSelector = createSelector(
  selectedStoreSelector,
  (selectedStore) => selectedStore?.store?.storeNumber
);

export const menuMatchesSelectedStoreSelector = createSelector(
  selectedStoreNumberSelector,
  currentMenuStoreNumberSelector,
  (selectedStoreNumber, menuStoreNumber) => {
    // if both are undefined/null, return true, this means the national menu is loaded/loading
    if (!selectedStoreNumber && !menuStoreNumber) {
      return true;
    }
    return selectedStoreNumber === menuStoreNumber;
  }
);

export const storeMenuIsReadySelector = createSelector(
  selectedStoreNumberSelector,
  currentMenuStoreNumberSelector,
  menuIsLoadingSelector,
  failedMenuStoreNumberSelector,
  (selectedStoreNumber, currentStoreNumber, isLoading, failedStoreNumber) => {
    return Boolean(
      selectedStoreNumber &&
        currentStoreNumber === selectedStoreNumber &&
        !isLoading &&
        failedStoreNumber !== selectedStoreNumber
    );
  }
);

export const shouldUpdateMenuSelector = createSelector(
  configSelector,
  fullMenuStateSelector,
  appTimeSelector,
  (config, menuState, time) => {
    const { menuStaleAfter, menuRetryAfter } = config;
    return shouldUpdate(menuState, {
      staleTime: menuStaleAfter,
      retryAfter: menuRetryAfter,
      now: time,
    });
  }
);

const selectedStoreMenuIsLoadingSelector = createSelector(
  selectedStoreNumberSelector,
  storeNumberBeingFetchedSelector,
  (selectedStoreNumber, storeNumberBeingFetched) =>
    selectedStoreNumber === storeNumberBeingFetched
);

const selectedStoreMenuDidFailSelector = createSelector(
  selectedStoreNumberSelector,
  failedMenuStoreNumberSelector,
  (selectedStoreNumber, failedMenuStoreNumber) =>
    selectedStoreNumber === failedMenuStoreNumber
);

export const selectedMenuIsLoadedOrLoadingSelector = createSelector(
  menuMatchesSelectedStoreSelector,
  shouldUpdateMenuSelector,
  selectedStoreMenuIsLoadingSelector,
  selectedStoreMenuDidFailSelector,
  (
    menuMatchesSelectedStore,
    shouldUpdateMenu,
    selectedStoreMenuIsLoading,
    selectedStoreMenuDidFail
  ) => {
    if (menuMatchesSelectedStore && !shouldUpdateMenu) {
      return true;
    }
    if (selectedStoreMenuIsLoading || selectedStoreMenuDidFail) {
      // Even though it failed, it is still considered loaded, which prevents an infinite loop.
      // In the app, a failure triggers a reload of the national menu which will reset state.
      return true;
    }
    return false;
  }
);

export const shouldFetchMenuSelector = createSelector(
  configSelector,
  routeSelector,
  isPdpRequestSelector,
  selectedMenuIsLoadedOrLoadingSelector,
  (config, route, isPdpRequest, selectedMenuIsLoadedOrLoading) => {
    const { enableOrderingFeatures, hasStandaloneMenu } = config;
    if (!enableOrderingFeatures && !hasStandaloneMenu) {
      return false;
    }
    if (!route?.startsWith('/menu') && !ROUTES_THAT_NEED_MENU.includes(route)) {
      return false;
    }
    // we do want to fetch the menu on the client in the PDP, but not on the server.
    if (isPdpRequest && !IS_BROWSER) {
      return false;
    }
    if (selectedMenuIsLoadedOrLoading) {
      return false;
    }
    return true;
  }
);
