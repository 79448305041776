import { createSelector } from 'reselect';

export const fetchCountSelector = (state) => state.asyncCount;

export const isFetchingSelector = createSelector(
  fetchCountSelector,
  (count) => {
    return count > 0;
  }
);
