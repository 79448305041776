import { defineMessages } from 'react-intl';

export default defineMessages({
  hasRewards: {
    id: 'shared.rewardsStatusMessage.hasRewards',
    defaultMessage:
      '{displayNumber} free drink or food {displayNumber, plural, one {Reward} other {Rewards}}',
    description: 'Describes number of earned rewards user has',
  },
  nextReward: {
    id: 'shared.rewardsStatusMessage.nextReward',
    defaultMessage: 'Collect {stars} more Stars for another Reward',
    description: 'Reward banner describing stars needed for next reward',
  },
  starBalance: {
    id: 'shared.rewardsStatusMessage.starBalance',
    defaultMessage: 'Star balance',
    description: 'Label for Star balance.',
  },
});
