import { shouldFetchMenuSelector } from './selectors';

import reducer from './reducers';
import { FETCH_MENU } from './action-types';
import { fetchMenu } from './action-creators';

const effects = [
  {
    selector: shouldFetchMenuSelector,
    actionCreator: fetchMenu,
  },
];

export default {
  name: 'menu',
  reducer,
  effects,
  asyncActions: [FETCH_MENU],
};

export * from './selectors';
export * from './action-types';
export * from './action-creators';
