import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import { isMobileViewportSelector } from 'shared/app/bundles/viewport';

import styles from './styles.cssm';
import messages from './messages';
import sharedMessages from '../messages';
const CUSTOMER_SUPPORT_PHONE_NUM = '1-866-286-2698';
const CUSTOMER_SUPPORT_EMAIL = 'StarbuckseGiftCardSupport@cashstar.com';

const AdditionalHelp = ({ orderNumber }) => {
  const { formatMessage } = useIntl();
  const isMobileViewport = useSelector(isMobileViewportSelector);
  return (
    <React.Fragment>
      <Heading className="text-semibold mb2" size="sm" tagName="h2">
        {formatMessage(messages.additionalHelpHeading)}
      </Heading>
      <p className="color-textBlackSoft text-xxs mb3">
        {formatMessage(messages.additionalHelpBody1)}
      </p>
      <ul
        className={`ml2 my3 color-textBlackSoft text-xxs ${styles.confirmationFooterList}`}
      >
        <li className="mb1">
          {formatMessage(messages.additionalHelpListItem1)}
        </li>
        <li className="mb1">
          {formatMessage(messages.additionalHelpListItem2)}
        </li>
        <li className="mb1">
          {formatMessage(messages.additionalHelpListItem3)}
        </li>
        <li className="mb1">
          {formatMessage(messages.additionalHelpListItem4)}
        </li>
      </ul>
      <p className="color-textBlackSoft text-xxs mb3">
        {formatMessage(messages.additionalHelpBody2)}
      </p>
      <p className="text-upper color-greenStarbucks text-bold text-xxs">
        {formatMessage(sharedMessages.orderNumber, {
          orderNumber: orderNumber || false,
        })}
      </p>
      <p className="color-textBlackSoft text-xxs mt3">
        {formatMessage(messages.additionalHelpBody3)}
      </p>
      <div className="flex flex-column items-start">
        <Button
          className="mt5 mb4"
          color="black"
          href={`tel:${CUSTOMER_SUPPORT_PHONE_NUM}`}
          visualStyle={isMobileViewport ? 'default' : 'textOnly'}
        >
          {`${formatMessage(messages.callSupportCta)} ${CUSTOMER_SUPPORT_PHONE_NUM}`}
        </Button>
        <Button
          color="black"
          href={`mailto: ${CUSTOMER_SUPPORT_EMAIL}?body=${
            orderNumber
              ? formatMessage(sharedMessages.orderNumber, {
                  orderNumber: orderNumber || false,
                })
              : ''
          }`}
        >
          {formatMessage(messages.emailSupportCta)}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AdditionalHelp;
