import { showErrorNotification } from 'shared/app/shell/state/action-creators/notifications';

export const showCodedErrorNotification =
  (errorCode, errorMappingFunc, data = {}) =>
  (dispatch) => {
    const {
      callToAction,
      confirmAction = () => {},
      message,
      messageValues,
      title,
      translatedMessage,
    } = errorMappingFunc(errorCode, data);
    dispatch(
      showErrorNotification({
        callback: () => dispatch(confirmAction),
        confirmTextId: callToAction?.id,
        messageId: message?.id,
        messageValues,
        body: translatedMessage,
        titleId: title?.id,
      })
    );
  };
