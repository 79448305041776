import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const MessageCounter = ({ current, max }) => {
  const { formatMessage } = useIntl();
  return (
    <React.Fragment>
      <span
        aria-hidden
        className="text-right color-black80"
      >{`${current} / ${max}`}</span>
      <span className="hiddenVisually">
        {formatMessage(messages.messageCounterLabel, {
          currentNumber: current,
          maxNumber: max,
        })}
      </span>
    </React.Fragment>
  );
};

export default MessageCounter;
