import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { FormattedMessage, FormattedDate } from 'react-intl';
import Card from '@starbucks-web/pattern-library/lib/components/card';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Button from '@starbucks-web/pattern-library/lib/components/button';

import DefaultCouponImg from './images/coupon-default-img';
import BirthdayCouponImg from './images/coupon-birthday-img';
import MopOnlyCouponImg from './images/coupon-mop-only-img';

import styles from './styles.cssm';
import messages from './messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

import { orderingEnabledSelector } from 'shared/app/state/selectors/config';
import {
  cartOfferAppliedSelector,
  appliedCartOffersSelector,
} from 'ordering/app/state/selectors';

const BIRTHDAY_COUPON_CODE = '593';

export const CouponCard = ({
  cardProps,
  className,
  coupon: { rewardCode, redemptionChannels, description, expirationDate },
  offerAppliedToCart,
  userSelectedOffer,
  onClick,
  orderingEnabled,
  canApplyToCart,
  couponId,
}) => {
  const isMOPOnly =
    redemptionChannels?.includes('MOP') && redemptionChannels?.length === 1;

  if (isMOPOnly && !orderingEnabled) {
    return null;
  }

  const [couponApplied, setCouponApplied] = useState(false);
  useEffect(() => {
    offerAppliedToCart.code === rewardCode &&
    userSelectedOffer[0]?.couponId === couponId
      ? setCouponApplied(true)
      : setCouponApplied(false);
  }, [offerAppliedToCart]);

  const handleApplyClick = () => {
    if (couponApplied) {
      onClick({});
    } else {
      // must be `rewardCode`, not `code`
      onClick({ code: rewardCode, stars: null, couponId });
    }
  };

  const getCouponUiByType = () => {
    if (rewardCode === BIRTHDAY_COUPON_CODE) {
      return {
        headingMessage: messages.birthdayHeading,
        ImgComponent: BirthdayCouponImg,
      };
    }
    if (isMOPOnly) {
      return {
        headingMessage: messages.mopOnlyHeading,
        ImgComponent: MopOnlyCouponImg,
      };
    }
    return { headingMessage: messages.heading, ImgComponent: DefaultCouponImg };
  };

  const { headingMessage, ImgComponent } = getCouponUiByType();
  return (
    <Card
      containerClasses={`mt3 flex-row ${styles.containerCard} ${className}`}
      contentClasses="py3 pr3"
      imageChild={<ImgComponent />}
      imageClasses="m3"
      {...cardProps}
    >
      <Heading className="text-bold color-textBlack" size="sm" tagName="h3">
        <FormattedMessage {...headingMessage} />
      </Heading>
      <p className="color-textBlackSoft text-xs">
        {description} <FormattedMessage {...messages.body} />{' '}
        <FormattedDate value={new Date(expirationDate)} />.
      </p>
      {canApplyToCart ? (
        <Button
          className="mt3"
          data-e2e={couponApplied ? 'removeCoupon' : 'applyCoupon'}
          onClick={handleApplyClick}
          visualStyle={couponApplied ? 'default' : 'positive'}
        >
          {
            <FormattedMessage
              {...(couponApplied
                ? sharedCallsToAction.remove
                : messages.applyCta)}
            />
          }
        </Button>
      ) : null}
    </Card>
  );
};

const select = (state) => ({
  offerAppliedToCart: cartOfferAppliedSelector(state),
  userSelectedOffer: appliedCartOffersSelector(state),
  orderingEnabled: orderingEnabledSelector(state),
});

export default connect(select)(CouponCard);
