import { defineMessages } from 'react-intl';

export const sharedCallsToAction = defineMessages({
  agreeAndContinue: {
    id: 'shared.CTAs.agreeAndContinue',
    defaultMessage: 'Agree & continue',
    description: 'button label for agree and continue',
  },
  back: {
    id: 'shared.CTAs.back',
    defaultMessage: 'Back',
    description: 'CTA to let the user know they can go back',
  },
  cancel: {
    id: 'shared.CTAs.cancel',
    defaultMessage: 'Cancel',
    description:
      'Call to action indicating that user wants to cancel current workflow',
  },
  closeLabel: {
    id: 'shared.CTAs.closeLabel',
    defaultMessage: 'Close',
    description: 'Label for a button which closes or collapes content',
  },
  closeMenuButton: {
    id: 'shared.navigationButton.close',
    defaultMessage: 'Close menu',
    description: 'Describes navigation button for screenreaders',
  },
  confirm: {
    id: 'shared.CTAs.confirm',
    defaultMessage: 'Confirm',
    description:
      'Label for a button user clicks to confirm they want to proceed',
  },
  continue: {
    id: 'shared.CTAs.continue',
    defaultMessage: 'Continue',
    description:
      'Call to action indicating that the user should continue to the next step',
  },
  details: {
    id: 'shared.CTAs.details',
    defaultMessage: 'Details',
    description: 'Call to action allowing drill down into topic details',
  },
  done: {
    id: 'shared.CTAs.done',
    defaultMessage: 'Done',
    description: 'Call to action label for done button',
  },
  earnStars: {
    id: 'shared.CTAs.earnStars',
    defaultMessage: 'Earn Stars',
    description: 'Call to action to start an order',
  },
  gotIt: {
    id: 'shared.CTAs.gotIt',
    defaultMessage: 'Got it',
    description: 'Call to action indicating that user understands',
  },
  goBack: {
    id: 'shared.CTAs.goBack',
    defaultMessage: 'Go back',
    description: 'Call to action indicating that user wants to step backwards',
  },
  joinNow: {
    id: 'shared.CTAs.joinNow',
    description: 'Join Starbucks Rewards program now',
    defaultMessage: 'Join now',
  },
  joinStarbucksRewards: {
    id: 'shared.CTAs.joinStarbucksRewards',
    description: 'Join Starbucks Rewards program',
    defaultMessage: 'Join Starbucks® Rewards',
  },
  keep: {
    id: 'shared.CTAs.keep',
    defaultMessage: 'Keep',
    description: 'Button text confirming retention of a zero-balance card',
  },
  notNow: {
    id: 'shared.CTAs.notNow',
    defaultMessage: 'Not now',
    description: 'Button label to let the user not take any action for now',
  },
  ok: {
    id: 'shared.CTAs.Ok',
    defaultMessage: 'Ok',
    description: 'Indicates that this button confirms an action',
  },
  openMenuButton: {
    id: 'shared.navigationButton.open',
    defaultMessage: 'Open menu',
    description: 'Describes navigation button for screenreaders',
  },
  order: {
    id: 'shared.CTAs.order',
    defaultMessage: 'Order',
    description: 'Yet another button label to initiate an order',
  },
  profile: {
    id: 'shared.CTAs.profile',
    defaultMessage: 'Profile',
    description: 'Link to user profile page',
  },
  redeem: {
    id: 'shared.CTAs.redeem',
    defaultMessage: 'Redeem',
    description: 'Button label to navigate to rewards redemption bottomsheet',
  },
  remove: {
    id: 'shared.CTAs.remove',
    defaultMessage: 'Remove',
    description: 'Button text allow/confirm removal of a card',
  },
  restrictionsApply: {
    id: 'shared.CTAs.restrictionsApply',
    defaultMessage: 'Some restrictions apply',
    description: 'Label for restrictions button',
  },
  seeMore: {
    id: 'shared.CTAs.seeMore',
    defaultMessage: 'See more',
    description: 'Call to action for a link to see more details',
  },
  send: {
    id: 'shared.CTAs.send',
    defaultMessage: 'Send',
    description: 'Call to action to send',
  },
  signin: {
    id: 'shared.CTAs.signIn',
    defaultMessage: 'Sign in',
    description: 'Call to action that takes user to sign in page/signs them in',
  },
  signOut: {
    id: 'shared.CTAs.signOut',
    defaultMessage: 'Sign out',
    description: 'Call to action that lets user sign out of their account',
  },
  manage: {
    id: 'shared.CTAs.manage',
    defaultMessage: 'Manage',
    description: 'Call to action allowing drill down into an item',
  },
  startAnOrder: {
    id: 'shared.CTAs.startAnOrder',
    defaultMessage: 'Start an order',
    description: 'Button label to initiate an order',
  },
  tryAgain: {
    id: 'shared.CTAs.tryAgain',
    defaultMessage: 'Try again',
    description:
      'Call to action indicating that user wants to try again current workflow',
  },
  findAStore: {
    id: 'shared.CTAs.findAStore',
    defaultMessage: 'Find a store',
    description: 'Find a store button label',
  },
  noThanks: {
    id: 'shared.CTAs.noThanks',
    defaultMessage: 'No thanks',
    description:
      'A shared action to indicate the user does not want to move forward with the action',
  },
  save: {
    id: 'shared.CTAs.save',
    defaultMessage: 'Save',
    description: 'Button allowing user to save some setting',
  },
  edit: {
    id: 'shared.CTAs.edit',
    defaultMessage: 'Edit {a11yMessage}',
    description: 'Button to edit some feature',
  },
  cookiePreferences: {
    id: 'shared.CTAs.cookiePreferences',
    defaultMessage: 'Cookie Preferences',
    description: 'Button to change cookie preferences',
  },
  startOrderCTA: {
    id: 'shared.CTAs.startOrderCTA',
    defaultMessage: 'Order Now',
    description: 'button text to start an order',
  },
  learnMore: {
    id: 'shared.CTAs.learnMore',
    defaultMessage: 'Learn more',
    description: 'Button to learn more about a Starbucks feature',
  },
  submit: {
    id: 'shared.CTAs.submit',
    description: 'Submit button',
    defaultMessage: 'Submit',
  },
  nevermind: {
    id: 'shared.CTAs.nevermind',
    defaultMessage: 'Nevermind',
    description: 'Nevermind button',
  },
  checkoutAsGuest: {
    id: 'shared.CTAs.checkoutAsGuest',
    defaultMessage: 'Checkout as guest',
    description: 'Checkout as guest button',
  },
});
