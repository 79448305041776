import getThumbnailUri from 'shared/app/utils/get-thumbnail-uri';
import { menuProductsByNumberAndFormSelector } from '../selectors';

export const getProductDetailsFromMenu =
  ({ productNumber, formCode }) =>
  (dispatch, getState) => {
    const productFormId = `${productNumber}/${formCode?.toLowerCase()}`;
    const productsByNumberAndForm =
      menuProductsByNumberAndFormSelector(getState());
    const product = productsByNumberAndForm?.[productFormId];

    if (product) {
      const { name, productType, categoryName } = product;
      const image = getThumbnailUri(product);
      return { productCategory: categoryName, name, productType, image };
    }
    return null;
  };
