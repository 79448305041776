import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Field from '@starbucks-web/pattern-library/lib/components/field';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';

import messages from './messages';
import { formMessages } from 'shared/app/messages';

const AddressLineFields = ({
  addressLine1 = {},
  addressLine2 = {},
  fieldProps = {},
}) => {
  const { formatMessage } = useIntl();
  const [addressLine2WasFocused, setAddressLine2WasFocused] = useState(false);

  const addressL1Message = formatMessage(
    addressLine1.errorMessage || messages.addressError
  );
  const addressL2Message = addressLine2.errorMessage
    ? formatMessage(addressLine2.errorMessage)
    : null;

  return (
    <div>
      <Field
        {...addressLine1.input}
        autoComplete="address-line1"
        className="mb2"
        data-e2e="addressLine1"
        error={shouldDisplayError(addressLine1)}
        errorMessage={addressL1Message}
        maxLength={60}
        maxLengthAriaMessage={formatMessage(formMessages.maxLengthAriaMessage)}
        {...fieldProps.addressLine1}
      >
        {formatMessage(messages.addressLine1Label)}
      </Field>

      <Field
        {...addressLine2.input}
        autoComplete="address-line2"
        className="mb2"
        customFieldStatus
        data-e2e="addressLine2"
        displayStatus={addressLine2WasFocused}
        error={shouldDisplayError(addressLine2)}
        errorMessage={addressL2Message}
        hintText={formatMessage(messages.addressLine2Instruction)}
        maxLength={60}
        onFocus={() => {
          setAddressLine2WasFocused(true);
        }}
        {...fieldProps.addressLine2}
      >
        {formatMessage(messages.addressLine2Label)}
      </Field>
    </div>
  );
};

AddressLineFields.propTypes = {
  addressLine1: PropTypes.object,
  addressLine2: PropTypes.object,
  fieldProps: PropTypes.object,
};

export default AddressLineFields;
