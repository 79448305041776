// loading helpers
import React from 'react';
import loadable from '@loadable/component';

import ContentCrateLoading from '../../components/content-crate-loading';
import HeaderCrate from '@starbucks-web/pattern-library/lib/components/header-crate';

const LoadingContentCrate = () => (
  <div className="flex">
    <ContentCrateLoading />
  </div>
);

/*
  These lazy* functions accept a function to dynamically import a component (loadFn),
  and provide placeholder components to render while the component is lazily loaded.
  https://loadable-components.com/docs/api-loadable-component/#loadablecomponent
  https://webpack.js.org/guides/code-splitting/#dynamic-imports
*/
export const lazyContentCrate = (loadFn) =>
  loadable(loadFn, {
    fallback: <LoadingContentCrate />,
  });

export const lazyHeaderCrate = (loadFn) =>
  loadable(loadFn, {
    fallback: <HeaderCrate.Inner />,
  });

export const lazyFullPage = (loadFn) =>
  loadable(loadFn, {
    fallback: <div />,
  });
