import React from 'react';
import Button from '@starbucks-web/pattern-library/lib/components/button/';

import messages from './messages';
import { FormattedMessage } from 'react-intl';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/* error */) {
    // Update state so the next render will show the fallback UI.
    // The value returned here should be the updated state, rather than using a setState call
    // https://reactjs.org/docs/react-component.html#static-getderivedstatefromerror
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // newrelic being available is dependent on the browser agent having been loaded,
    // but `noticeError` is available once it is.
    if (window.newrelic) {
      // We want the errorInfo, since it contains a useful componentStack.
      // newrelic's noticeError allows for a custom_attribute object to be passed as a 2nd param
      // https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/notice-error
      window.newrelic.noticeError(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      const { fallbackContent: Fallback, className } = this.props;
      if (Fallback) {
        return <Fallback />;
      }

      const reload = () => window.location.reload(true);

      return (
        <div className={`flex ${className}`}>
          <div className="p4 mx-auto">
            <h1>
              <FormattedMessage {...messages.somethingWentWrong} />
            </h1>
            <p>
              <FormattedMessage {...messages.couldNotRecover} />
            </p>
            <Button className="mt2" onClick={reload}>
              <FormattedMessage {...messages.refresh} />
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children || null;
  }
}

export default ErrorBoundary;
