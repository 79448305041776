import reducer from './reducer';

import { FETCH_ACCRUAL_EARN_RATES_SUCCESS } from './actions';
import { shouldFetchAccrualEarnRates } from './selectors';
import { fetchAccrualEarnRates } from './action-creators';

export default {
  name: 'accrualEarnRates',
  reducer,
  effects: [
    {
      selector: shouldFetchAccrualEarnRates,
      actionCreator: fetchAccrualEarnRates,
    },
  ],
  persistAfter: [
    {
      action: FETCH_ACCRUAL_EARN_RATES_SUCCESS,
    },
  ],
};
