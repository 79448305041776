import { defineMessages } from 'react-intl';

export default defineMessages({
  enterMfaCodeHeading: {
    id: 'shared.mfa.enterCodeExitDialog.heading',
    defaultMessage: "The number isn't verified yet",
    description: "Heading for 'enter code' exit confirmation dialog",
  },
  enterMfaCodeBody: {
    id: 'shared.mfa.enterCodeExitDialog.body',
    defaultMessage:
      'We sent a code to {phoneNumber}. Would you like to verify now, or later?',
    description: "Body for 'enter code' exit confirmation dialog",
  },
  enterMfaCodeConfirm: {
    defaultMessage: 'Verify now',
    description:
      "Confirm button text for 'enter code' exit confirmation dialog",
    id: 'shared.mfa.enterCodeExitDialog.confirm',
  },
  enterMfaCodeCancel: {
    defaultMessage: 'Not now',
    description: "Cancel button text for 'enter code' exit confirmation dialog",
    id: 'shared.mfa.enterCodeExitDialog.cancel',
  },
});
