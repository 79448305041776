import PropTypes from 'prop-types';
import React, { useId } from 'react';
import Select from '@starbucks-web/pattern-library/lib/components/select';
import { injectIntl } from 'react-intl';

export const MonetaryAmountSelect = ({
  amounts,
  intl: { formatNumber, formats },
  ...rest
}) => {
  const id = useId();

  return (
    <Select id={id} {...rest}>
      {amounts
        .map((amount) => amount.toString())
        .map((amount) => {
          return (
            <option key={amount} value={amount}>
              {formatNumber(amount, formats.number.money)}
            </option>
          );
        })}
    </Select>
  );
};

MonetaryAmountSelect.propTypes = {
  amounts: PropTypes.array.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(MonetaryAmountSelect);
