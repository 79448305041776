import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import PreventFocus from '@starbucks-web/pattern-library/lib/components/prevent-focus';

import {
  isAnyModalLayerOpenSelector,
  preventFocusTargetsSelector,
} from 'shared/app/shell/state/selectors/app-ui';
import SuccessNotification from './success-notification';
import styles from './styles.cssm';

const GlobalBottomDrawer = ({
  isAnyModalLayerOpen,
  preventFocusTargets,
  routeOptions,
}) => {
  const { hasOpenModal } = useModalContext();
  const Crust = routeOptions?.crustComponent;
  const useFullWidthBottomDrawer = Boolean(routeOptions?.fullWidthBottomDrawer);
  return (
    <PreventFocus
      enabled={preventFocusTargets.content || preventFocusTargets.all}
      wrapWithDiv
    >
      <div
        className={classNames(styles.bottomDrawer, {
          [styles.contentCrateDrawer]: Boolean(!useFullWidthBottomDrawer),
        })}
      >
        <SuccessNotification />
        {/*
          For pages that use a Crust, if a modal window is open we want to make sure
          that Toasts are visible, but it's awkward to see the Crust as well, so
          we don't render it when a modal is open.
          `isAnyModalLayerOpen` is for the original modal system,
          `hasOpenModal` is for the newer ModalProvider modals.
        */}
        {Crust && !hasOpenModal && !isAnyModalLayerOpen ? <Crust /> : null}
      </div>
    </PreventFocus>
  );
};

const select = (state) => ({
  isAnyModalLayerOpen: isAnyModalLayerOpenSelector(state),
  preventFocusTargets: preventFocusTargetsSelector(state),
});

export default connect(select)(GlobalBottomDrawer);
