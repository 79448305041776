import { defineMessages } from 'react-intl';

export default defineMessages({
  privacyPermissionsBrowserSettingsHeading: {
    id: 'shared.privacyPermissions.browserSettingsHeading',
    description: 'Terms Privacy browser settings heading',
    defaultMessage: 'Setting updated',
  },
  privacyPermissionsBrowserSettingsText: {
    id: 'shared.privacyPermissions.browserSettingsText',
    description: 'Terms Privacy browser settings text',
    defaultMessage:
      'We updated your privacy setting to honor your browser setting. If you would like to allow the sharing of personal information for targeted advertising, you can opt back in.',
  },
  privacyPermissionsDismiss: {
    id: 'shared.privacyPermissions.dismiss',
    description: 'Button label dismiss',
    defaultMessage: 'Dismiss',
  },
  privacyPermissionsOptBackIn: {
    id: 'shared.privacyPermissions.optBackIn',
    description: 'Button label for opting back in',
    defaultMessage: 'Opt back in',
  },
  privacyPermissionsConfirmChangeHeading: {
    id: 'shared.privacyPermissions.confirmChangeHeading',
    description: 'Confirm change heading',
    defaultMessage: 'Confirm change',
  },
  privacyPermissionsConfirmChangeText: {
    id: 'shared.privacyPermissions.confirmChangeText',
    description: 'Confirm change description',
    defaultMessage:
      'By confirming, your account’s personal information will be shared with Starbucks and advertising partners for ad targeting and measurement.',
  },
});
