import React from 'react';
import PropTypes from 'prop-types';
import { OptimizelyExperiment } from '@optimizely/react-sdk';
import { isFunction } from 'lodash';
import Provider from './provider';

const Experiment = ({ children, name }) => {
  return (
    <Provider>
      <OptimizelyExperiment autoUpdate experiment={name}>
        {isFunction(children) ? (variation) => children(variation) : children}
      </OptimizelyExperiment>
    </Provider>
  );
};

Experiment.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  name: PropTypes.string.isRequired,
};

export default Experiment;
