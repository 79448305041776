import { defineMessages } from 'react-intl';
/* eslint-disable max-len */
export const carouselControlAriaMessages = defineMessages({
  ariaLabel: {
    id: 'shared.carousel.carouselLabel',
    description: 'aria label for carousel',
    defaultMessage: '{carouselLabel} carousel',
  },
  mainDescription: {
    id: 'shared.carousel.mainDescription',
    description: 'Instructions for interacting with the carousel',
    // eslint-disable-next-line max-len
    defaultMessage:
      'Carousel content with {slideCount} slides. Use the previous and next buttons or swipe right or left to change the currently displayed slides.',
  },
  pageButtons: {
    id: 'shared.carousel.pageButtons',
    defaultMessage: 'go to page { pageNumber }',
    description: 'aria label for carousel paging buttons',
  },
  prevButton: {
    id: 'shared.carousel.prevButton',
    description: 'Button to go the previous slide in the carousel',
    defaultMessage: 'Previous slide',
  },
  nextButton: {
    id: 'shared.carousel.nextButton',
    description: 'Button to go the next slide in the carousel',
    defaultMessage: 'Next slide',
  },
});
