/* eslint no-console: 0*/
let hasDebugFlag = false;

// Wrap localStorage access in try...catch to prevent iOS from breaking
// when Cookies and Website Data are blocked
try {
  hasDebugFlag = typeof localStorage !== 'undefined' && localStorage.debug;
} catch (e) {
  // localStorage is disabled due to browser settings, do nothing.
}

const noOp = () => {};

export const IS_DEBUG = hasDebugFlag;

export const getDebugLogger = (color = 'black') => {
  return IS_DEBUG
    ? (...args) => {
        const logArgs = [`%c${args[0]}`, `color:${color};`].concat(
          args.slice(1)
        );
        console.log(...logArgs);
      }
    : noOp;
};
