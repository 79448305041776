import { createSelector } from 'reselect';

const mfaSelector = (state) => state.mfa;

export const mfaChallengeRefSelector = createSelector(
  mfaSelector,
  ({ challengeRef }) => challengeRef || ''
);

export const isSendingMfaCodeSelector = createSelector(
  mfaSelector,
  ({ sendingCode }) => Boolean(sendingCode)
);

export const isVerifyingMfaCodeSelector = createSelector(
  mfaSelector,
  ({ verifyingCode }) => Boolean(verifyingCode)
);

export const mfaCodeDeliveryMethodSelector = createSelector(
  mfaSelector,
  ({ deliveryMethod }) => deliveryMethod
);
