import { replace } from 'redux-first-history';

import EnterCodeExitDialog, {
  ENTER_CODE_EXIT_DIALOG_ID,
} from 'shared/app/bundles/mfa/components/enter-code-exit-dialog';

import MfaChallengeEnterCodeOverlay, {
  MFA_CHALLENGE_ENTER_CODE_OVERLAY_ID,
} from '../components/mfa-challenge-enter-code-overlay';
import MfaChallengeOtherOptionsOverlay, {
  MFA_CHALLENGE_OTHER_OPTIONS_OVERLAY_ID,
} from '../components/mfa-challenge-other-options-overlay';

export const showEnterCodeOverlayForLogin =
  ({
    phoneId,
    phoneNumber,
    phoneSmsState,
    username,
    password,
    onCodeSuccess,
    onCodeError,
    openModal,
  }) =>
  () => {
    openModal({
      component: MfaChallengeEnterCodeOverlay,
      ariaLabelledBy: MFA_CHALLENGE_ENTER_CODE_OVERLAY_ID,
      id: 'mfa-overlay-login',
      componentProps: {
        onCodeError,
        verificationType: 'login',
        phoneId,
        phoneNumber,
        phoneSmsState,
        username,
        password,
        onCodeSuccess,
      },
    });
  };

export const showEnterCodeOverlayForSecureSession =
  ({ onCodeSuccess, onCodeError, openModal }) =>
  (dispatch) => {
    const closeCallback = () => {
      dispatch(replace('/account/settings'));
    };

    openModal({
      component: MfaChallengeEnterCodeOverlay,
      ariaLabelledBy: MFA_CHALLENGE_ENTER_CODE_OVERLAY_ID,
      onEscape: closeCallback,
      id: 'mfa-overlay-secure-session',
      componentProps: {
        closeCallback,
        onCodeSuccess,
        onCodeError,
        verificationType: 'secureSession',
      },
    });
  };

export const showEnterCodeOverlayForPhoneVerification =
  ({
    phoneId,
    phoneNumber,
    phoneSmsState,
    onCodeSuccess,
    onCodeError,
    openModal,
  }) =>
  () => {
    const closeCallback = () => {
      openModal({
        component: EnterCodeExitDialog,
        ariaLabelledBy: ENTER_CODE_EXIT_DIALOG_ID,
        componentProps: {
          phoneNumber,
          parentOverlayId: 'showEnterCodeOverlayForPhoneVerification',
        },
      });
    };

    openModal({
      component: MfaChallengeEnterCodeOverlay,
      ariaLabelledBy: MFA_CHALLENGE_ENTER_CODE_OVERLAY_ID,
      id: 'mfa-overlay-phone-verification',
      // Adding a delay to avoid a racing condition
      onEscape: () => setTimeout(closeCallback, 200),
      componentProps: {
        onCodeSuccess,
        onCodeError,
        phoneId,
        phoneNumber,
        phoneSmsState,
        verificationType: 'phoneVerification',
        closeCallback,
      },
    });
  };

export const showOtherOptionsOverlay =
  ({ phoneId, phoneNumber, verificationType, onCodeSendSuccess, openModal }) =>
  (dispatch) => {
    const closeCallback = () => {
      dispatch(replace('/account/settings'));
    };

    openModal({
      component: MfaChallengeOtherOptionsOverlay,
      ariaLabelledBy: MFA_CHALLENGE_OTHER_OPTIONS_OVERLAY_ID,
      onEscape: closeCallback,
      id: 'mfa-overlay-other-options',
      componentProps: {
        closeCallback,
        onCodeSendSuccess,
        phoneId,
        phoneNumber,
        verificationType,
      },
    });
  };
