import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import Field from '@starbucks-web/pattern-library/lib/components/field';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';

import { formMessages } from 'shared/app/messages';
import messages from './messages';

const PhoneNumberField = ({ phoneNumber, ...rest }) => {
  const { formatMessage } = useIntl();
  const phoneNumberError = formatMessage(
    phoneNumber.errorMessage || messages.phoneNumberExistenceError
  );

  return (
    <Field
      {...phoneNumber.input}
      autoComplete="tel"
      data-e2e="phoneNumber"
      error={shouldDisplayError(phoneNumber)}
      errorMessage={phoneNumberError}
      maxLength={60}
      maxLengthAriaMessage={formatMessage(formMessages.maxLengthAriaMessage)}
      {...rest}
    >
      {formatMessage(messages.phoneNumber)}
    </Field>
  );
};

PhoneNumberField.propTypes = {
  phoneNumber: PropTypes.object,
};

PhoneNumberField.defaultProps = {
  phoneNumber: {
    input: {
      id: 'phoneNumber',
      value: '',
    },
  },
};

export default PhoneNumberField;
