// Loads a tracking pixel (img) for provided url.
// The upcoming UO may provide a centralized mechanism for
// managing these additional tracking pixels.
export default (url) => {
  return new Promise((resolve) => {
    // Image not available during test-app (on the server).
    if (typeof Image === 'undefined') {
      resolve();
      return;
    }
    const img = new Image();
    img.onerror = img.onload = resolve;
    img.src = url;
  });
};
