import React from 'react';
import { useIntl } from 'react-intl';
import DialogWithMessages from 'shared/app/components/dialog-with-messages';

import messages from './messages';

const SelectStoreDialog = ({ goToStoreLocator }) => {
  const { formatMessage } = useIntl();
  return (
    <DialogWithMessages
      confirmText={formatMessage(messages.cta)}
      onConfirm={goToStoreLocator}
    >
      {formatMessage(messages.body)}
    </DialogWithMessages>
  );
};

export default SelectStoreDialog;
