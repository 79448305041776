import { defineMessages } from 'react-intl';

export default defineMessages({
  successHeader: {
    id: 'shared.giftReceipt.statusBanner.successHeader',
    description: 'Title for success status',
    defaultMessage:
      "We've sent your {numberOfRecipients, plural, one {eGift} other {eGifts}}!",
  },
  successBody: {
    id: 'shared.giftReceipt.statusBanner.successBody',
    description: 'Body copy for success status',
    defaultMessage:
      "{numberOfRecipients, plural, one {Your egift was sent to {recipientEmail}!} other {}} We'll {numberOfRecipients, plural, one {also} other {}} notify you when your {numberOfRecipients, plural, one {eGift has} other {eGifts have}} been opened.",
  },
  pendingHeader: {
    id: 'shared.giftReceipt.statusBanner.pendingHeader',
    description: 'Title for pending status',
    defaultMessage:
      'Preparing to send your {numberOfRecipients, plural, one {eGift} other {eGifts}}',
  },
  pendingBody: {
    id: 'shared.giftReceipt.statusBanner.pendingBody',
    description: 'Body copy for auth pending status',
    defaultMessage:
      '{isEmail, select, true {This shouldn’t take long! We’ll notify you when your {numberOfRecipients, plural, one {eGift has} other {eGifts have}} been sent} other {You’ll still need to send your eGift once it’s done processing}}{isGiftHistoryRoute, select, true {. It may take a moment to update your information. Feel free to check back later.} other {{isEmail, select, true { — or, c} other {. C}}heck its status below{isAuthenticated, select, true { or in eGift History} other {}}.}}',
  },
  errorHeader: {
    id: 'shared.giftReceipt.statusBanner.errorHeader',
    description: 'Title for error status',
    defaultMessage: 'Something went wrong!',
  },
  errorBody: {
    id: 'shared.giftReceipt.statusBanner.errorBody',
    description: 'Body copy for error status',
    defaultMessage:
      'If you were charged and do not see a refund, please contact customer support.',
  },
  tryAgainButton: {
    id: 'shared.giftReceipt.statusBanner.errorStatus.tryAgainButton',
    description: 'Button Label for creating another eGift',
    defaultMessage: 'Try creating again',
  },
  linkSuccessHeader: {
    id: 'shared.giftReceipt.statusBanner.linkSuccessHeader',
    description: 'Header for success shareable link banner',
    defaultMessage:
      '{isGiftHistoryRoute, select, true {Your eGift is ready to send} other {Send your eGift now}}',
  },
  linkSuccessBody: {
    id: 'shared.giftReceipt.statusBanner.linkSuccessBody',
    description: 'Body message for shareable link success banner',
    defaultMessage:
      'Make sure to send your eGift and confirm your recipient got it, as we cannot verify its delivery status.',
  },
  linkPendingHeader: {
    id: 'shared.giftReceipt.statusBanner.linkPendingHeader',
    description: 'Header for pending shareable link banner',
    defaultMessage: 'Wait — your eGift is processing',
  },
});
