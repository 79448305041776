// Helper module for tracking things that are specific
// to the platform, such as whether or not a notification
// has been shown to the user.
// It's also in a wrapper in order to wrap localStorage access
// in a try/catch.
const KEY_NAME = 'flags';

const ls = (obj) => {
  let result = {};
  try {
    if (obj) {
      result = Object.assign(ls(), obj);
      localStorage[KEY_NAME] = JSON.stringify(result);
    } else {
      result = JSON.parse(localStorage[KEY_NAME]) || {};
    }
  } catch (e) {
    return {};
  }
  return result;
};

export const setFlag = (name, value) => ls({ [name]: value });
export const getFlag = (name) => ls()[name] || null;
export const removeAllFlags = () => localStorage.removeItem(KEY_NAME);
