import {
  ADDRESS_LINES,
  CITY,
  COUNTRY_SUBDIVISION,
  POSTAL_CODE,
} from './field-names';

const componentMap = {
  addressLine1: ADDRESS_LINES,
  addressLine2: ADDRESS_LINES,
  city: CITY,
  countrySubdivision: COUNTRY_SUBDIVISION,
  postalCode: POSTAL_CODE,
};

export default (fieldConfigs, fieldNames = []) => {
  return Object.keys(fieldConfigs)
    .filter((key) => fieldNames.indexOf(componentMap[key]) !== -1)
    .reduce((accum, val) => {
      accum[val] = fieldConfigs[val];
      return accum;
    }, {});
};
