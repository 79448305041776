import { createSelector } from 'reselect';
import { get } from 'lodash';

const configSelector = (state) => state.config;

export const trustArcConfigSelector = createSelector(
  configSelector,
  (config) => get(config, 'trustArc') || {}
);

export const trustArcEnabledSelector = createSelector(
  configSelector,
  trustArcConfigSelector,
  (config, trustArcConfig) =>
    get(config, 'universal.trustArcEnabled') && trustArcConfig.enabled
);
