export const fileReaderSupported = typeof FileReader !== 'undefined';

export default (blob) =>
  new Promise((resolve, reject) => {
    if (!fileReaderSupported) {
      return reject(new Error('not supported'));
    }
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const { result } = reader;
      if (!result) {
        return reject(Error('failed to read base64 data'));
      }
      return resolve(result);
    };
  });
