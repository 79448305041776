// TODO: migrate to redux toolkit
// https://redux.js.org/introduction/why-rtk-is-redux-today
import {
  legacy_createStore as createStore,
  compose,
  applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import { createReduxHistoryContext } from 'redux-first-history';
import { isFunction } from 'lodash';

import { SESSION_EXPIRED } from 'shared/app/bundles/user/actions.js';
import {
  userEmailSelector,
  hasGuestSessionSelector,
} from 'shared/app/bundles/user/state/selectors/profile';
import { doLogoutAndForget } from 'shared/app/shell/state/action-creators/app-lifecycle';

import { extractAsArray } from '../../utils/sub-app-extractors';
import IS_BROWSER from '../../utils/is-browser';

import debugLogger from '../../state/middleware/debug-logger';
import { getGeolocation } from '../../utils/geolocation';
import createApiFetcher from '../../../utils/create-api-fetcher';
import createGQLFetcher from '../../../utils/create-gql-fetcher';
import createPersistMiddleware from '../create-persist-middleware';
import uoTracking from '../../state/middleware/uo-tracking';

import addSessionChecker from './add-session-checker';
import { validateSubApp } from './sub-app-validator';
import createRunLoop from './init-runloop';
import { getStoreReducer } from './store-reducers';

const isRequestForbidden = (err) => {
  if (!err) return false;
  return err.httpStatus === 403 || err.httpStatus === 401;
};

export class StoreManager {
  constructor() {
    this.store = null;
  }

  getReduxThunk() {
    return thunk.withExtraArgument({
      fetch,
      getGeolocation,
      // passing in an extra `catch` handler
      // here lets the apiHelper dispatch a
      // `doLogout` when api requests fail because
      // of missing or expired sessions. This way it
      // can be checked and handled in a single place
      // instead of having to remember to do that each
      // time it's used.
      apiFetch: createApiFetcher(
        async (err, requestPath) => {
          const state = this.store.getState();
          const hasGuestSession = hasGuestSessionSelector(state);

          // don't mark re-auth eligible if they just failed re-auth!
          const reAuthEligible = requestPath !== '/bff/account/reauth';

          if (isRequestForbidden(err) && !hasGuestSession) {
            this.store.dispatch({
              type: SESSION_EXPIRED,
              payload: { reAuthEligible },
            });
          }

          if (isRequestForbidden(err) && hasGuestSession) {
            err.guestSessionExpired = true;
          }
          throw err;
        },
        (res) => {
          this.store.checkSession();
          return res;
        }
      ),
      gqlFetch: createGQLFetcher(
        async (err) => {
          const state = this.store.getState();
          const hasGuestSession = hasGuestSessionSelector(state);

          // since permissions for the graphQL stuff is handled
          // per operation, we can't use a http status code for this.
          // So we look for errors of a certain type (generated by "boom"
          // on the server-side).
          const isAuthorizeOperationError =
            err?.data?.type?.toLowerCase() === 'authorize-operation';

          if (isRequestForbidden(err) && !hasGuestSession) {
            const email = userEmailSelector(state);

            if (isAuthorizeOperationError && email) {
              this.store.dispatch({
                type: SESSION_EXPIRED,
                payload: { reAuthEligible: true },
              });
            } else {
              this.store.dispatch(doLogoutAndForget());
            }
          }

          if (hasGuestSession && isRequestForbidden(err)) {
            err.guestSessionExpired = true;
          }
          throw err;
        },
        (res) => {
          this.store.checkSession();
          return res;
        }
      ),
    });
  }

  // eslint-disable-next-line max-statements
  createRootStore({ data, apps, env }) {
    const runLoop = createRunLoop();

    // ensure apps are structured as needed
    apps.forEach(validateSubApp);

    const routes = apps.reduce((acc, app) => {
      const appRoutes = isFunction(app.routes)
        ? app.routes(data.config || {})
        : app.routes;
      for (const route in appRoutes) {
        acc[route] = appRoutes[route];
      }
      return acc;
    }, {});

    // Create history specific for run-time environment.
    // i.e.  `createBrowserHistory`, or `createMemoryHistory`
    const history = env.createHistory();
    const storeReducer = getStoreReducer({ data, apps, routes, history });

    // https://github.com/salvoravida/redux-first-history/tree/master#options
    const { createReduxHistory, routerMiddleware } = createReduxHistoryContext({
      history,
    });

    // use redux devtools browser extension if installed
    const composeEnhancers =
      IS_BROWSER && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;
    const persistMiddleware = createPersistMiddleware();

    const storeEnhancer = composeEnhancers(
      applyMiddleware(
        routerMiddleware,
        this.getReduxThunk(),
        debugLogger,
        persistMiddleware.getMiddleware(apps),
        uoTracking
      )
    );

    // generate the actual redux store
    this.store = createStore(storeReducer, data, storeEnhancer);

    extractAsArray(apps, 'initialize').forEach((init) => init(this.store));

    if (IS_BROWSER) {
      // the session checker keeps the app informed of session status
      // by reading the s_check cookie
      addSessionChecker(this.store);
      this.store.checkSession();

      // the run loop subscribes to store and efficiently dispatches if needed
      runLoop.start(this.store, extractAsArray(apps, 'effects'));
    }

    return Object.assign(
      {},
      { ...this.store },
      { routes, history: createReduxHistory(this.store) }
    );
  }
}

// main export to create a store
const storeManager = new StoreManager();
export default storeManager;
