// A client-side shim for [`config`](https://github.com/lorenwest/node-config).
//
// It mirrors a very small subset of the API of the server-side `node-config` such that shared
// client/server React code can just do `require('config')`, basically:
//
// - `get(objPath)`
// - `has(objPath)`
import { get as lodashGet, isUndefined } from 'lodash';

import { getBootstrapData } from './bootstrap-data';

export const get = (objPath) => {
  const data = getBootstrapData() || {};

  // Throw same errors that `node-config` does.
  if (objPath === null || isUndefined(objPath)) {
    throw new Error('Calling config.get with null or undefined argument');
  }

  const val = lodashGet(data.config, objPath);
  if (isUndefined(val)) {
    throw new Error(`Configuration property "${objPath}" is not defined`);
  }

  return val;
};

export const has = (objPath) => {
  const data = getBootstrapData() || {};

  const val = lodashGet(data.config, objPath);
  return !isUndefined(val);
};

// Wrapper to allow any kind of import usage.
export default {
  get,
  has,
};
