import { headers, messages } from './messages';
import {
  sharedCallsToAction,
  sharedNotificationMessages,
} from 'shared/app/messages';

const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';

// Card, Payment, and Barcode Error Codes:
// JIRA/wiki/spaces/WNW/pages/137322543/Card%2C+Payment+and+Barcode+Error+Codes
//
// Transfer Balance Error Codes:
// JIRA/wiki/spaces/WNW/pages/139672819
export const getErrorDialogForErrorCode = (errorCode) => {
  const errorDialogMap = {
    121001: {
      header: headers.checkCardInformation,
      message: messages.checkCardInformation,
      callToAction: sharedCallsToAction.gotIt,
    },
    121002: {
      header: headers.checkCardInformation,
      message: messages.checkCardInformation,
      callToAction: sharedCallsToAction.gotIt,
    },
    121017: {
      header: headers.checkCardInformation,
      message: messages.checkCardInformation,
      callToAction: sharedCallsToAction.gotIt,
    },
    121030: {
      header: headers.cardInactive,
      message: messages.startDigitalCard,
      callToAction: sharedCallsToAction.gotIt,
    },
    121032: {
      header: headers.lostStolenCard,
      message: messages.tryDifferentCard,
      callToAction: sharedCallsToAction.gotIt,
    },
    121037: {
      header: headers.cardClosed,
      message: messages.tryDifferentCard,
      callToAction: sharedCallsToAction.gotIt,
    },
    122000: {
      header: headers.cardRegistered,
      message: messages.tryDifferentCard,
      callToAction: sharedCallsToAction.gotIt,
    },
    [UNEXPECTED_ERROR]: {
      header: sharedNotificationMessages.unexpectedError,
      message: messages.internalServerError,
      callToAction: sharedCallsToAction.gotIt,
    },
  };

  return errorDialogMap[errorCode] || errorDialogMap[UNEXPECTED_ERROR];
};
