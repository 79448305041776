import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    id: 'shared.couponCard.heading',
    defaultMessage: 'Coupon available',
    description: 'Heading for coupon card to display available coupons',
  },
  body: {
    id: 'shared.couponCard.body',
    defaultMessage: 'Expires',
    description: 'Body copy to indicate coupon expiration date',
  },
  applyCta: {
    id: 'shared.couponCard.applyCta',
    defaultMessage: 'Apply',
    description: 'CTA text to apply coupon',
  },
  birthdayHeading: {
    id: 'shared.couponCard.birthdayHeading',
    defaultMessage: 'Birthday Reward available',
    description: 'Heading for informational birthday coupon',
  },
  mopOnlyHeading: {
    id: 'shared.couponCard.mopOnlyHeading',
    defaultMessage: 'Coupon available (order ahead only)',
    description:
      'Heading for coupon that can only be redeemed with Mobile Order & Pay',
  },
});
