import React from 'react';
import classNames from 'classnames';

import Footer from 'shared/app/components/footer';

const GlobalFooter = ({
  alwaysUseMobileLayout,
  nav,
  routeOptions,
  useCrateLayout,
}) => {
  // Footer can be suppressed per route by supplying an option on
  // the route called hideGlobalFooter.
  const useFooter = !routeOptions?.hideGlobalFooter;
  const footerProps = nav?.footerNav?.props ?? {};
  const { className: footerClassName, ...otherFooterProps } = footerProps;

  return useFooter ? (
    <Footer
      alwaysUseMobileLayout={alwaysUseMobileLayout}
      className={classNames('frapPaddingDouble', footerClassName)}
      useCrateLayout={useCrateLayout}
      {...otherFooterProps}
    />
  ) : null;
};

export default GlobalFooter;
