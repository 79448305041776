import ms from 'milliseconds';
import { createInstance } from '@optimizely/react-sdk';
import config from 'config';

export class OptimizelyManager {
  _optimizelyInstance = null;

  getInstance() {
    if (!this._optimizelyInstance) {
      const { sdkKey } = config.get('universal.optimizely');

      this._optimizelyInstance = createInstance({
        sdkKey,
        datafileOptions: {
          autoUpdate: true,
          updateInterval: ms.minutes(5),
        },
      });
    }

    return this._optimizelyInstance;
  }
}

export default new OptimizelyManager();
