import exEnv from 'exenv';
import { createSelector } from 'reselect';
import { debounce, mapKeys } from 'lodash';

import { canonicalBreakpoints } from '@starbucks-web/pattern-library/lib/shared-variables';

const IS_BROWSER = exEnv.canUseDOM;
const RESIZE_ACTION = 'WINDOW_RESIZED';
const DEBOUNCE_THRESHOLD = 300;
const getWindowSize = () => {
  if (!IS_BROWSER) {
    return null;
  }
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

// convert breakpoints to a js-optimized shape:
// {
//   sm: 480,
//   md: 768,
//   lg: 1024,
//   xl: 1280,
//   xxl: 1600
// }
export const breakpoints = mapKeys(canonicalBreakpoints, (value, key) => {
  return key.toLowerCase().replace('breakpoint', '');
});

export const viewportSelector = (state) => state.viewport;
export const viewportWidthSelector = createSelector(
  viewportSelector,
  (viewport) => viewport?.width || 0
);
export const viewportHeightSelector = createSelector(
  viewportSelector,
  (viewport) => viewport?.height || 0
);
export const isMobileViewportSelector = createSelector(
  viewportWidthSelector,
  (width) => (width && width < breakpoints.lg) || false
);
// selector for viewports <768
export const isSmMaxViewportSelector = createSelector(
  viewportWidthSelector,
  (width) => (width && width < breakpoints.md) || false
);

export const currentBreakpointSelector = createSelector(
  viewportWidthSelector,
  (width) => {
    let current = null;
    for (const size in breakpoints) {
      const val = breakpoints[size];
      if (width >= val) {
        current = size;
      }
    }
    return current;
  }
);
export const activeBreakpointsSelector = createSelector(
  viewportWidthSelector,
  (width) => {
    const activeBreakpoints = {};

    for (const size in breakpoints) {
      const val = breakpoints[size];
      activeBreakpoints[size] = width >= val;
      activeBreakpoints[`${size}Max`] = width < val;
    }

    return activeBreakpoints;
  }
);

const initialState = getWindowSize();

export default {
  name: 'viewport',
  reducer: (state = initialState, { type, payload }) => {
    if (!state && IS_BROWSER) {
      return Object.assign({}, initialState);
    }
    if (type === RESIZE_ACTION) {
      return Object.assign({}, payload);
    }
    return state;
  },
  initialize: (store) => {
    if (IS_BROWSER) {
      const debouncedResizeHandler = debounce(() => {
        store.dispatch({ type: RESIZE_ACTION, payload: getWindowSize() });
      }, DEBOUNCE_THRESHOLD);
      window.addEventListener('resize', debouncedResizeHandler);
    }
  },
};
