import React from 'react';
import PropTypes from 'prop-types';

const Repeat = ({ children = null, times = 0 }) => {
  if (!children || !times) {
    return null;
  }
  const items = [];
  for (let i = 0; i < times; i++) {
    items.push(children(i));
  }
  return <React.Fragment>{items}</React.Fragment>;
};

Repeat.propTypes = {
  children: PropTypes.any,
  times: PropTypes.number,
};

export default Repeat;
