import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({
  text,
  link,
  isLastCrumb,
  crumbSeparator = '/',
  ...otherProps
}) => {
  return (
    <li data-e2e="breadcrumb">
      {!isLastCrumb ? (
        <React.Fragment>
          {link ? (
            <Link className="text-noUnderline" {...otherProps} to={link}>
              {text}
            </Link>
          ) : (
            <span>{text}</span>
          )}
          <span aria-hidden>&nbsp;{crumbSeparator}&nbsp;</span>
        </React.Fragment>
      ) : (
        <span aria-current="page" className="text-semibold">
          {text}
        </span>
      )}
    </li>
  );
};

export default Breadcrumb;
