import { createSelector } from 'reselect';
import shouldUpdate from 'shared/app/stale-reducers/should-update';

export const programPrefix = 'MSR';

export const configSelector = (state) => state.config;
export const appTimeSelector = (state) => state.time;

export const userStateSelector = (state) => state.user;

export const profileSelector = createSelector(
  userStateSelector,
  (user) => user?.accountProfile
);

export const hasFetchedProfileSelector = createSelector(
  profileSelector,
  (profile) => profile && Boolean(profile.lastFetch)
);

export const profileStatusSelector = createSelector(
  profileSelector,
  (profile) => {
    if (!profile || !profile.data) return null;
    if (profile.loading) return 'loading';
    if (profile.error) return 'error';
    if (profile.permanentFail) return 'failed';
    return 'success';
  }
);

export const profileDataSelector = createSelector(
  profileSelector,
  (profile) => profile?.data
);

export const firstNameSelector = createSelector(
  profileDataSelector,
  (profile) => profile?.firstName
);

export const lastNameSelector = createSelector(
  profileDataSelector,
  (profile) => profile?.lastName
);

export const userEmailSelector = createSelector(
  profileDataSelector,
  (profile) => profile?.email
);

export const partnerNumberSelector = createSelector(
  profileDataSelector,
  (profile) => profile?.partnerNumber
);

export const isPartnerSelector = createSelector(
  partnerNumberSelector,
  (partnerNumber) => Boolean(partnerNumber)
);

export const birthDaySelector = createSelector(
  profileDataSelector,
  (profile) => profile?.birthDay
);

export const birthMonthSelector = createSelector(
  profileDataSelector,
  (profile) => profile?.birthMonth
);

export const birthDateSelector = createSelector(
  birthMonthSelector,
  birthDaySelector,
  (birthMonth, birthDay) => {
    if (!birthDay || !birthMonth) {
      return null;
    }
    // recall that the month index is zero-based
    return new Date(0, birthMonth - 1, birthDay);
  }
);

export const exIdSelector = createSelector(
  profileDataSelector,
  (profile) => profile?.exId || null
);

export const sessionExpiredSelector = createSelector(
  userStateSelector,
  (user) => user.sessionExpired && !user.loggedOut
);

export const requiresMfaChallengeSelector = createSelector(
  userStateSelector,
  (user) => user.requiresMfaChallenge
);

export const sessionMetadataSelector = createSelector(
  userStateSelector,
  (user) => user && user.sessionMeta
);

// Possible session names: [short, extended or guest]
const createHasSessionSelector = (sessionName) =>
  createSelector(
    sessionMetadataSelector,
    appTimeSelector,
    (sessionMeta, appTime) => {
      if (!sessionMeta || !sessionMeta[sessionName]) {
        return false;
      }
      const timeLeft = sessionMeta[sessionName] - appTime;
      if (timeLeft < 0) {
        return false;
      }
      return true;
    }
  );
export const hasShortSessionSelector = createHasSessionSelector('short');

export const hasExtendedSessionSelector = createHasSessionSelector('extended');

export const hasGuestSessionSelector = createHasSessionSelector('guest');

export const isCreatingGuestSessionSelector = createSelector(
  userStateSelector,
  (user) => user.guestLoading === true
);

export const signedInSelector = createSelector(
  hasShortSessionSelector,
  hasExtendedSessionSelector,
  (hasShortSession, hasExtendedSession) => hasShortSession || hasExtendedSession
);

export const allSessionsExpiredSelector = createSelector(
  userStateSelector,
  profileDataSelector,
  hasShortSessionSelector,
  hasExtendedSessionSelector,
  // eslint-disable-next-line max-params
  (user, profileData, hasShort, hasExtended) =>
    Boolean(!hasShort && profileData && !hasExtended && !user.loggedOut)
);

export const onlyExtendedSessionFoundSelector = createSelector(
  hasShortSessionSelector,
  hasExtendedSessionSelector,
  (hasShort, hasExtended) => Boolean(hasExtended && !hasShort)
);

export const shouldFetchProfileSelector = createSelector(
  profileSelector,
  signedInSelector,
  appTimeSelector,
  (profile, signedIn, time) => {
    return signedIn && shouldUpdate(profile, { now: time });
  }
);

export const loyaltyStatusSelector = createSelector(
  profileDataSelector,
  (profileData) => profileData?.loyaltyProgram
);

export const programNameSelector = createSelector(
  loyaltyStatusSelector,
  (loyaltyStatus) => loyaltyStatus?.programName
);

export const tenuredStatusSelector = createSelector(
  loyaltyStatusSelector,
  (loyaltyStatus) => loyaltyStatus?.tenuredGold ?? null
);

export const isTenuredSelector = createSelector(
  tenuredStatusSelector,
  (tenuredStatus) => tenuredStatus && Boolean(tenuredStatus.tenured)
);

export const rewardLevelsSelector = createSelector(
  loyaltyStatusSelector,
  (loyaltyStatus) => loyaltyStatus?.rewards?.filter(Boolean)
);

export const starBalanceSelector = createSelector(
  loyaltyStatusSelector,
  (loyaltyStatus) => loyaltyStatus?.progress?.starBalance ?? null
);

export const hasRewardAvailableSelector = createSelector(
  loyaltyStatusSelector,
  (loyaltyStatus) => {
    const { rewards } = loyaltyStatus || {};
    return rewards?.[0]?.available ?? false;
  }
);

export const joinDateSelector = createSelector(
  profileStatusSelector,
  loyaltyStatusSelector,
  (profileStatus, loyaltyStatus) => {
    if (profileStatus !== 'success') return null;
    // Return today's date in case the rewards account isn't provisioned yet
    return loyaltyStatus?.cardHolderSince
      ? new Date(loyaltyStatus.cardHolderSince)
      : new Date();
  }
);

export const yearGoldStatusObtainedSelector = createSelector(
  joinDateSelector,
  (joinDate) => joinDate?.getFullYear().toString()
);

export const tenuredGoldMedalYearSelector = createSelector(
  yearGoldStatusObtainedSelector,
  (yearGoldStatusObtained) => `’${yearGoldStatusObtained?.slice(-2)}`
);

const checkNudge = (nudge) => {
  if (!nudge || !nudge.emoji || !nudge.text) {
    return null;
  }

  return nudge;
};

export const progressNudgeSelector = createSelector(
  profileDataSelector,
  (profile) => profile && checkNudge(profile?.loyaltyProgram?.progressNudge)
);

export const expiringStarsNudgeSelector = createSelector(
  profileDataSelector,
  (profile) =>
    profile && checkNudge(profile?.loyaltyProgram?.expiringStarsNudge)
);

export const userSubMarketSelector = createSelector(
  profileDataSelector,
  (profileData) => profileData?.subMarket
);

export const promoUserDataSelector = createSelector(
  profileStatusSelector,
  loyaltyStatusSelector,
  userSubMarketSelector,
  joinDateSelector,
  // eslint-disable-next-line max-params
  (profileStatus, loyaltyStatus, userSubMarket, joinDate) => {
    if (
      profileStatus === 'success' &&
      Boolean(userSubMarket) &&
      Boolean(joinDate) &&
      Boolean(loyaltyStatus)
    ) {
      return { userSubMarket, joinDate };
    }
    if (
      profileStatus === 'error' ||
      profileStatus === 'failed' ||
      loyaltyStatus === null
    ) {
      return { error: true };
    }
    return { loading: true };
  }
);

export const allCouponsSelector = createSelector(
  profileDataSelector,
  (profile) => (profile?.coupons?.length ? profile.coupons : [])
);

export const userHasCouponsSelector = createSelector(
  allCouponsSelector,
  (coupons) => Boolean(coupons?.length)
);

export const filteredCouponsSelector = createSelector(
  allCouponsSelector,
  (allCoupons) => {
    // returned from pricing fetch in GQL;
    // already appears as button in rewards-cell
    const birthdayCouponCode = '593';
    return (
      allCoupons?.filter(
        (coupon) => coupon.rewardCode !== birthdayCouponCode
      ) ?? []
    );
  }
);
