import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import skipLinkMap from '../../../app/utils/skip-link-map';
import styles from './styles.cssm';

export const MainElement = ({
  children,
  className,
  role = 'main',
  tagName: TagName = 'main',
  ...other
}) => (
  <TagName
    className={classNames({
      [styles.main]: true,
      [className]: Boolean(className),
    })}
    id={skipLinkMap.mainContent}
    role={role}
    {...other}
  >
    {children}
  </TagName>
);

MainElement.propTypes = {
  children: PropTypes.node,
  role: PropTypes.string,
  tagName: PropTypes.string,
};

export default MainElement;
