import React from 'react';
import { connect } from 'react-redux';
import GlobalNav from '../global-nav';

import { globalNavMainNavLinksSelector } from 'shared/app/state/selectors/global-nav';

const DotComGlobalNav = ({
  mainNavLinks,
  useMaxWidthLayout,
  useFixedDesktopNav,
}) => (
  <GlobalNav
    mainNavLinksConfig={mainNavLinks}
    useFixedDesktopNav={useFixedDesktopNav}
    useMaxWidthLayout={useMaxWidthLayout}
  />
);

const select = (state) => ({
  mainNavLinks: globalNavMainNavLinksSelector(state),
});

export default connect(select)(DotComGlobalNav);
