import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import { shouldShowTermsPrivacyOverlaySelector } from 'shared/app/state/selectors/terms-privacy';
import { shouldShowPrivacyBrowserDialogSelector } from 'shared/app/state/selectors/privacy-permissions';
import { checkTermsPrivacyStatus } from 'shared/app/state/action-creators/terms-privacy';

import TermsPrivacyOverlay, {
  TERMS_PRIVACY_OVERLAY_ID,
} from 'shared/app/components/terms-privacy/terms-privacy-overlay';
import PrivacyPermissionsBrowserSettingsDialog, {
  TERMS_PRIVACY_BROWSER_SETTINGS_DIALOG_ID,
} from 'shared/app/components/privacy-permissions/privacy-permissions-browser-settings-dialog';

const useTermsAndPrivacy = () => {
  const dispatch = useDispatch();
  const { openModal } = useModalContext();

  const shouldShowTermsPrivacyOverlay = useSelector(
    shouldShowTermsPrivacyOverlaySelector
  );
  const shouldShowPrivacyBrowserDialog = useSelector(
    shouldShowPrivacyBrowserDialogSelector
  );

  useEffect(() => {
    dispatch(checkTermsPrivacyStatus());
  }, []);

  useEffect(() => {
    if (shouldShowTermsPrivacyOverlay) {
      openModal({
        component: TermsPrivacyOverlay,
        ariaLabelledBy: TERMS_PRIVACY_OVERLAY_ID,
        id: TERMS_PRIVACY_OVERLAY_ID,
      });
    }
  }, [shouldShowTermsPrivacyOverlay]);

  useEffect(() => {
    if (shouldShowPrivacyBrowserDialog) {
      openModal({
        component: PrivacyPermissionsBrowserSettingsDialog,
        ariaLabelledBy: TERMS_PRIVACY_BROWSER_SETTINGS_DIALOG_ID,
      });
    }
  }, [shouldShowPrivacyBrowserDialog]);
};

export default useTermsAndPrivacy;
