import { defineMessages } from 'react-intl';

export default defineMessages({
  verifyNumber: {
    id: 'shared.mfa.verifyNumber',
    defaultMessage: 'Verify number',
    description:
      'Text for multi factor authentication (MFA) when verifying number',
  },
  verifyNumberSubHeading: {
    id: 'shared.mfa.verifyNumber.subHeading',
    defaultMessage:
      'Enter the verification code we sent to the phone number ending in {phoneNumber}',
    description:
      'Sub heading for the verify number multi factor authentication (MFA) overlay',
  },
  mfaCodeSentLabel: {
    id: 'shared.mfa.codeSent.label',
    defaultMessage: 'Verification code sent',
    description:
      'Label for verification code sent for multifactor authentication (MFA)',
  },
  twoFactorAuthenticationHeading: {
    id: 'shared.mfa.twoFactorAuthentication.heading',
    defaultMessage: '2-Factor Authentication',
    description:
      'Heading for the other delivery options multi factor authentication (MFA) overlay',
  },
  sendCodeOptionsSubHeading: {
    id: 'shared.mfa.enterCode.subHeading',
    defaultMessage:
      'Choose how to verify the phone number ending in {phoneNumber} belongs to you. Message and data rates may apply.',
    description:
      'Sub heading for the other delivery options multi factor authentication (MFA) overlay',
  },
  sendCodeOptionsRadioGroupLegend: {
    id: 'shared.mfa.sendCodeOptionsRadioGroupLegend',
    defaultMessage: 'How would you like us to send you a code?',
    description: 'legend for the "send code" radio group',
  },
  sendCodeOptionsTextRadioLabel: {
    id: 'shared.mfa.sendCodeOptionsTextRadio.label',
    defaultMessage: 'One-time code sent by text',
    description: 'label for the text message overlay option',
  },
  sendCodeOptionsCallRadioLabel: {
    id: 'shared.mfa.sendCodeOptionsCallRadio.label',
    defaultMessage: 'One-time code voice call',
    description: 'label for the phone call overlay option',
  },
  sendCodeButton: {
    id: 'shared.mfa.sendCodebutton',
    defaultMessage: 'Send code',
    description: 'label for the "Send code" button',
  },
  // Verify Number & Enter Mfa Code Overlays
  enterMfaCodeHeading: {
    id: 'shared.mfa.enterMfaCode.heading',
    defaultMessage: 'Enter Code',
    description:
      'Heading for the multi factor authentication (MFA) enter code overlay',
  },
  enterMfaCode: {
    id: 'shared.mfa.enterMfaCode',
    defaultMessage:
      'We sent a verification code to {phoneNumber}. Enter it here.',
    description: 'Label for MFA enter code overlay',
  },
  enterMfaCodeError: {
    id: 'shared.mfa.enterMfaCode.error',
    defaultMessage: 'Enter a code',
    description: 'Label for verificaion code field when empty',
  },
  mfaCodeLabel: {
    id: 'shared.mfa.enterMfaCode.yourMfaCode.label',
    defaultMessage: 'Verification code',
    description: 'Label for multi factor authentication (MFA) enter code here',
  },
  resendCodeLabel: {
    id: 'shared.mfa.enterMfaCode.resendCode.label',
    defaultMessage: 'Resend Code',
    description: 'Label for multi factor authentication (MFA) resend code',
  },
  getCodeAnotherWayButton: {
    id: 'shared.mfa.enterMfaCode.getCodeAnotherWay.button',
    defaultMessage: 'Need another option?',
    description:
      'Text for button/link to get an multi factor authentication (MFA) code another way',
  },
  verifyCodeLabel: {
    id: 'shared.mfa.enterMfaCode.verifyCode.label',
    defaultMessage: 'Verify',
    description: 'Label to verify multi factor authentication (MFA) code',
  },
  phoneVerificationSuccessMessage: {
    id: 'shared.mfa.enterMfaCode.phoneVerificationSuccessMessage',
    defaultMessage: 'Phone number verified 👍',
    description: 'notification message when verification is successful',
  },
});
