import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './styles.cssm';

const FaderContainer = (props) => {
  const { children } = props || null;
  if (!children) {
    return null;
  }
  const [state, setFade] = useState({
    fadeOut: false,
    fadeIn: false,
    onFadeOutComplete: null,
  });

  const startFadeOut = (callback) => {
    setFade({
      onFadeOutComplete: callback,
      fadeOut: true,
      fadeIn: false,
    });
  };

  const startFadeIn = () => {
    setFade({
      fadeOut: false,
      fadeIn: true,
    });
  };

  const onAnimationEnd = () => {
    setFade({
      fadeOut: false,
      fadeIn: false,
    });
    if (
      state.onFadeOutComplete &&
      typeof state.onFadeOutComplete === 'function'
    ) {
      state.onFadeOutComplete(startFadeIn);
    }
  };

  const styleFading = state.fadeOut || state.fadeIn ? 'overflow-hidden' : '';

  return (
    <div className={`${styles.parent} ${styleFading}`}>
      <div
        className={classNames(styles.child, {
          [styles.fadeOut]: state.fadeOut,
          [styles.fadeInAndSlideUp]: state.fadeIn,
        })}
        onAnimationEnd={onAnimationEnd}
      >
        {React.Children.map(
          children,
          (child) =>
            child && React.cloneElement(child, { startFadeOut, startFadeIn })
        )}
      </div>
    </div>
  );
};

export default FaderContainer;
