import * as idbKeyval from 'idb-keyval';

const isSupported = Boolean(typeof crypto !== 'undefined' && crypto.subtle);

// Edge does not currently support the Encoding API, but it also doesn't support the Crypto API,
// so these functions are never used by Edge. This usage may need to be polyfilled if Edge adds support
// for Crypto before it supports Encoding.
export const stringToArrayBuffer = (string) =>
  new TextEncoder('utf-8').encode(string);

export const arrayBufferToString = (buffer) =>
  new TextDecoder('utf-8').decode(buffer);

export const decryptedToString = (decrypted) =>
  arrayBufferToString(new Uint8Array(decrypted));

export const getOrCreateVector = () =>
  idbKeyval.get('vector').then((res) => {
    if (res) {
      return res;
    }
    const vector = window.crypto.getRandomValues(new Uint8Array(16));
    idbKeyval.set('vector', vector);
    return vector;
  });

export const getEncryptionKey = (seed) =>
  Promise.resolve()
    .then(() =>
      // First, create a PBKDF2 "key" containing the seed
      window.crypto.subtle.importKey(
        'raw',
        stringToArrayBuffer(seed),
        { name: 'PBKDF2' },
        false,
        ['deriveKey']
      )
    )
    .then((baseKey) =>
      Promise.all([
        // Derive a key from the other key. This seems odd to me too, more info here:
        // https://blog.engelke.com/2015/02/14/deriving-keys-from-passwords-with-webcrypto/
        window.crypto.subtle.deriveKey(
          {
            name: 'PBKDF2',
            salt: stringToArrayBuffer('this is not a secret, dont worry'),
            iterations: 1000,
            hash: 'SHA-512',
          },
          baseKey,
          { name: 'AES-CBC', length: 128 },
          // This means the key contents is opaque to JS
          // we can use and store the key object but can't access
          // its secret.
          false,
          // what we expect to be able to use it for
          ['encrypt', 'decrypt']
        ),
        getOrCreateVector(),
      ])
    )
    .catch(() => null);

export const encryptData = (keyPromise, data) =>
  keyPromise.then(([key, iv]) => {
    if (isSupported) {
      return crypto.subtle.encrypt(
        { name: 'AES-CBC', iv },
        key,
        stringToArrayBuffer(data)
      );
    }

    throw Error('CryptoNotSupported');
  });

export const decryptData = (keyPromise, encrypted) =>
  keyPromise
    .then(([key, iv]) => {
      if (isSupported) {
        return crypto.subtle.decrypt({ name: 'AES-CBC', iv }, key, encrypted);
      }
      throw Error('CryptoNotSupported');
    })
    .then(decryptedToString);
