import React from 'react';
import { connect } from 'react-redux';

import LoadingIndicator from '@starbucks-web/pattern-library/lib/components/loading-indicator';

import { isFetchingSelector } from 'shared/app/shell/state/selectors/fetch-status';
import { useErrorLoadingIndicatorSelector } from 'shared/app/shell/state/selectors/notification';

// This is the green "laser" which streaks across the top of the UI when async requests
// are processing. It turns red if an error status is returned.

const AsyncLoadingIndicator = (props) => {
  return <LoadingIndicator {...props} />;
};

const select = (state) => ({
  active: isFetchingSelector(state),
  error: useErrorLoadingIndicatorSelector(state),
});

export default connect(select)(AsyncLoadingIndicator);
