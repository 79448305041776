'use strict';

// Google Geocode Api communicates errors in response body rather than http status codes.
// Possible response status matches client-side values at `window.google.maps.GeocoderStatus`
// See https://developers.google.com/maps/documentation/geocoding/intro#StatusCodes
const GeocoderStatus = {
  OK: 'OK',
  ZERO_RESULTS: 'ZERO_RESULTS',
  INVALID_REQUEST: 'INVALID_REQUEST',
  OVER_QUERY_LIMIT: 'OVER_QUERY_LIMIT',
  REQUEST_DENIED: 'REQUEST_DENIED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

// Whether or not the status returned from geocoding
// should be considered an invalid place name.
const isPlaceNotFound = (status) => {
  return (
    [GeocoderStatus.INVALID_REQUEST, GeocoderStatus.ZERO_RESULTS].indexOf(
      status
    ) > -1
  );
};

module.exports = {
  GeocoderStatus,
  isPlaceNotFound,
};
