import {
  FETCH_MODIFIER_AVAILABILITY,
  FETCH_MODIFIER_AVAILABILITY_ERROR,
  FETCH_MODIFIER_AVAILABILITY_SUCCESS,
} from './types';

import { getBffBaseUrl } from 'shared/app/utils/config-helper';
import { selectedStoreShortNumberSelector } from 'shared/app/state/selectors/ordering';
import { signedInSelector } from 'shared/app/bundles/user';

import { OptimizelyManager } from 'shared/app/components/optimizely';
import { FEATURES } from 'shared/universal/optimizely-keys';
import { optimizelyUserIdSelector } from 'shared/app/state/selectors/optimizely';

export const fetchModifierAvailability =
  () =>
  (dispatch, getState, { apiFetch }) => {
    const state = getState();
    if (!signedInSelector(state)) return;

    const optimizelyUserId = optimizelyUserIdSelector(state);
    const optimizely = OptimizelyManager.getInstance();

    optimizely.onReady().then(() => {
      if (
        optimizely.isFeatureEnabled(
          FEATURES.MODIFIER_AVAILABILITY,
          optimizelyUserId
        )
      ) {
        const storeNumber = selectedStoreShortNumberSelector(state);
        const url = `${getBffBaseUrl()}/bff/ordering/modifier-availability?storeNumber=${storeNumber}`;

        dispatch({
          type: FETCH_MODIFIER_AVAILABILITY,
          payload: { storeNumber },
        });
        return apiFetch(url)
          .then((data) => {
            dispatch({
              type: FETCH_MODIFIER_AVAILABILITY_SUCCESS,
              payload: data,
            });
          })
          .catch((error) => {
            dispatch({ type: FETCH_MODIFIER_AVAILABILITY_ERROR, error });
          });
      }
    });
  };
