import { defineMessages } from 'react-intl';

const messages = defineMessages({
  requiredFieldIndicator: {
    id: 'shared.formWithMessaging.requiredFieldIndicator',
    defaultMessage: 'indicates required field',
    description: 'Message for shared form to convey that fields are required',
  },
});

export default messages;
