import { debounce } from 'lodash';

import runDataChecks from '../run-data-checks';
import { appIdle } from '../../state/action-creators/app-lifecycle';

export default function RunLoop() {
  const actionPairs = [];

  const start = function (store, pairs) {
    pairs.forEach((effect) => {
      actionPairs.push(effect);
    });
    const idleDispatcher = debounce(() => {
      requestAnimationFrame(() => store.dispatch(appIdle()));
    }, 30000);

    const callback = () => {
      runDataChecks({ store, actionPairs });
      idleDispatcher();
    };

    store.subscribe(callback);
    // kick things off even without events getting fired
    callback();
  };

  const addEffects = function (effects) {
    effects.forEach((effect) => {
      actionPairs.push(effect);
    });
  };

  return {
    start,
    addEffects,
  };
}
