import { createSelector } from 'reselect';

import {
  signedInSelector,
  hasFetchedProfileSelector,
  programNameSelector,
} from 'shared/app/bundles/user';

import { svcCardsFetchedSelector } from 'shared/app/bundles/svc-cards';

export const hasFetchedCardsAndProfileSelector = createSelector(
  svcCardsFetchedSelector,
  hasFetchedProfileSelector,
  (cardsFetched, profileFetched) => cardsFetched && profileFetched
);

export const showRewardsEmptyStateSelector = createSelector(
  signedInSelector,
  programNameSelector,
  (isSignedIn, programName) =>
    // When true, selects for the condition where we know the user is a loyalty member because
    // they have a card, but the loyalty data is not available. This would most commonly be
    // the case for a brand new user whose loyalty account has not made it to UCP yet.
    isSignedIn && !programName
);
