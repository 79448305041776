import { isString, omit, pick } from 'lodash';
import ric from 'ric-shim';

import { cacheItem, getCachedItem } from '../../utils/cache-helper';
import { getDebugLogger } from '../utils/debug';
import { cacheOptionsSelector } from '../state/selectors/config';

const log = getDebugLogger('orange');

export default function PersistMiddleware() {
  const actionMap = {};
  // extract actions that should cause caching as an object
  // where the action is the 'key' and the app name is the
  // value
  const createPersistActionMap = function (subApps) {
    return subApps.reduce((obj, app) => {
      if (!app.persistAfter) {
        return obj;
      }
      const actionObj = app.persistAfter.reduce((accum, actionType) => {
        if (isString(actionType)) {
          accum[actionType] = { appName: app.name };
          return accum;
        }
        const { action, include, exclude } = actionType;
        accum[action] = { appName: app.name, include, exclude };
        return accum;
      }, {});
      return Object.assign(obj, actionObj);
    }, {});
  };

  const registerCacheActions = function (app) {
    Object.assign(actionMap, createPersistActionMap([app]));
  };

  const getSlices = function ({ state, include, exclude }) {
    // if both include/exclude are present, it was an error setting up.
    if (include && exclude) {
      throw Error(
        'Having both include/exclude is not allowed. Just use one or omit.'
      );
    }
    // if both  are omitted, it was a string only setup
    // return the full subApp state.
    if (!include && !exclude) {
      return state;
    }
    if (exclude) {
      return omit(state, exclude);
    }
    if (include) {
      return pick(state, include);
    }
  };

  const getMiddleware = function (subApps) {
    Object.assign(actionMap, createPersistActionMap(subApps));

    // now we can return middleware that has the info it needs
    return (store) => (next) => (action) => {
      const result = next(action);
      const state = store.getState();
      const cacheOptions = cacheOptionsSelector(state);
      const matchedAppInfo = actionMap[action.type];

      if (matchedAppInfo) {
        const { appName, include, exclude } = matchedAppInfo;
        const cacheState = getSlices({
          state: state[appName],
          include,
          exclude,
        });
        // we schedule this as async work since it's non-critical
        // to the UI
        ric(() => {
          // since we extract keys first, we're safe to mutate the object we're
          // iterating through inside the `.map` function.
          // note there is no retry mechanism here, we assume success
          // at the point where we get this far
          getCachedItem(appName, cacheOptions)
            .then((prevCache) => {
              const cacheKeys = Object.keys(prevCache?.data ?? {});
              const currentReduxStore = getSlices({
                state: state[appName],
                include: cacheKeys,
              });
              const toCache = {
                ...currentReduxStore,
                ...cacheState,
              };
              return cacheItem(appName, toCache, cacheOptions).then(
                (success) => {
                  if (success) {
                    log(`cached '${appName}':`, toCache);
                  }
                }
              );
            })
            // not matter what... don't throw errors. This is all add-on functionality
            // not crucial to the app.
            .catch(() => null);
        });
      }

      return result;
    };
  };

  return {
    registerCacheActions,
    getMiddleware,
  };
}
