import { defineMessages } from 'react-intl';

export default defineMessages({
  passwordCriterionValid: {
    id: 'shared.passwordField.passwordCriterionValid',
    defaultMessage: 'Satisfied:',
    description: 'Text on password field indicating it has been satisfied',
  },
  passwordCriterionInvalid: {
    id: 'shared.passwordField.passwordCriterionInvalid',
    defaultMessage: 'Not satisfied:',
    description: 'Text on password field indicating it has not been satisfied',
  },
  passwordHintCharacterLength: {
    defaultMessage: 'From 8 to 25 characters',
    description:
      'Hint for account create password field character length requirement',
    id: 'shared.passwordField.passwordHintCharacterLength',
  },
  passwordHintNumber: {
    defaultMessage: 'At least one number',
    description: 'Hint for account create password field number requirement',
    id: 'shared.passwordField.passwordHintNumber',
  },
  passwordHintCapital: {
    defaultMessage: 'At least one capital letter',
    description:
      'Hint for account create password field capital letter requirement',
    id: 'shared.passwordField.passwordHintCapital',
  },
  passwordHintLowercase: {
    defaultMessage: 'At least one lowercase letter',
    description:
      'Hint for account create password field lowercase letter requirement',
    id: 'shared.passwordField.passwordHintLowercase',
  },
  passwordHintSpecialCharacter: {
    defaultMessage:
      'At least one special character such as exclamation point or comma',
    description:
      'Hint for account create password field special character requirement',
    id: 'shared.passwordField.passwordHintSpecialCharacter',
  },
  passwordAriaHint: {
    defaultMessage:
      'Create a password 8 to 25 characters long that includes at least 1 uppercase and 1 lowercase letter, 1 number and 1 special character like an exclamation point or asterisk.',
    description: 'Aria hint for account create password field requirements',
    id: 'shared.passwordField.passwordAriaHint',
  },
});
