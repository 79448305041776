import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
  passwordComplexityError: {
    id: 'shared.messages.userFormInput.passwordComplexityError',
    defaultMessage: 'Password does not meet complexity requirements',
    description: 'Error when password is too simple',
  },
  invalidPassword: {
    id: 'shared.messages.userFormInput.passwordInvalidError',
    defaultMessage: 'Password is invalid',
    description: 'Error when current password is invalid',
  },
  newPasswordInvalidReuse: {
    id: 'shared.messages.userFormInput.passwordReuseError',
    defaultMessage: 'You’ve used this one before. Try a different password.',
    description:
      'Text on password input when previously used password was specified',
  },
  maxLengthAriaMessage: {
    id: 'shared.messages.userFormInput.maxLength',
    defaultMessage: 'You have reached the maximum length',
    description: 'Aria message for maximum reached length',
  },
});
