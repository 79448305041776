import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import {
  pathnameSelector,
  previousRouteSelector,
} from 'shared/app/state/selectors/routes';

const H1 = forwardRef(({ children, ...otherProps }, ref) => {
  const previousRoute = useSelector(previousRouteSelector);
  const pathname = useSelector(pathnameSelector);
  /* 
  We want to autofocus the h1 on the page when the user has done a 
  single-page-app style page transition, as opposed to a server loaded page.
  We do this so that the user immediately has context of the new page. This 
  is especially important and useful for keyboard and screen reader users 
  so they don't have to navigate through the site header again and again.
  To accomplish this we check if the current pathname is different than 
  the previous pathname; we can ignore changes to the hash or search because 
  those should not trigger the h1 to be focused.
  */
  const shouldAutoFocus =
    Boolean(pathname) && pathname !== previousRoute?.pathname;

  return (
    <Heading autoFocus={shouldAutoFocus} ref={ref} tagName="h1" {...otherProps}>
      {children}
    </Heading>
  );
});

export default H1;
