import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import tween from '@starbucks-web/pattern-library/lib/helpers/tween';

import {
  isTrackerCompletedSelector,
  trackerPercentProgressSelector,
  trackerGoalsWithUiPropertiesSelector,
} from 'shared/app/state/selectors/rewards-tracker';

import AnimatedStarsBar from './animated-stars-bar';
import GoalMarkers from './goal-markers-container';
import TrackerPointer from './tracker-pointer';
import styles from './index.cssm';

// delay should match the animation-duration set in CSS for the goal marker animation
export const CALLBACK_ANIMATION_DELAY = 1500;
// duration should match the animation-delay set in CSS for the goal marker animation
export const PROGRESS_BAR_ANIMATION_DURATION = 500;

export class ProgressTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animatedStarsBarVisible: this.props.isTrackerCompleted,
      goalAchievedOnUpdateIndex: -1, // set to -1 to indicate no goals achieved as a default
      percentProgress: this.props.percentProgress,
      trackerPointerVisible: !this.props.isTrackerCompleted,
    };
    this.animateProgressBar = this.animateProgressBar.bind(this);
    this.calculateWhichGoalsHaveBeenPassed =
      this.calculateWhichGoalsHaveBeenPassed.bind(this);
    this.handleStarsBarAnimationEnd =
      this.handleStarsBarAnimationEnd.bind(this);
    this.hideMarkersShowStars = this.hideMarkersShowStars.bind(this);
    this.startRippleHighlightBackground =
      this.startRippleHighlightBackground.bind(this);
    this.moveProgressToEnd = this.moveProgressToEnd.bind(this);
    this.resetProgressTrackerCallback =
      this.resetProgressTrackerCallback.bind(this);
    this.showProgressBarWhileFading =
      this.showProgressBarWhileFading.bind(this);

    this.progressBar = React.createRef();
  }

  componentDidMount() {
    this.setInitialProgress(this.props.percentProgress);

    // Create snapshot - so the AnimatedStarsBar is only rendered once, otherwise the component will re-render and cause star jank
    // whenever this component renders.  Even though the AnimatedStarsBar is a pure component it will re-render every chance it gets.
    // This is because AnimatedStarsBar's children are randomly located.
    // Add to state - to force re-render after intial did mount.

    /* eslint-disable-next-line react/no-did-mount-set-state */
    this.setState({
      AnimatedStarsBarSnapshot: React.memo(AnimatedStarsBar),
    });
  }

  calculateWhichGoalsHaveBeenPassed(trackerSegments, prevPercent, newPercent) {
    // calculate which goals have been passed. For the last one:
    // 1. Animate the progress bar to that goal marker
    // 2. Let the goal marker animation happen
    // 3. Resume progress bar animation to newPercent
    let percentAcrossTracker = 0;
    let goalAchievedOnUpdateIndex;
    const lastGoalPassed =
      trackerSegments &&
      trackerSegments.reduce((animationOptions, segment, index) => {
        percentAcrossTracker += segment.segmentPercentWidth;

        if (
          prevPercent < percentAcrossTracker &&
          newPercent >= percentAcrossTracker
        ) {
          // user passed this goal, save this one and keep iterating to find the last one
          animationOptions.animateTo = percentAcrossTracker;
          goalAchievedOnUpdateIndex = index;
        }
        return animationOptions;
      }, {});

    return {
      lastGoalPassed,
      goalAchievedOnUpdateIndex,
    };
  }

  startRippleHighlightBackground() {
    this.props.startRippleHighlight && this.props.startRippleHighlight();
  }

  handleStarsBarAnimationEnd() {
    this.setState({
      animatedStarsBarAnimate: false,
      trackerPointerVisible: false,
      goalMarkersStyles: null,
      trackerPointerStyles: null,
    });
  }

  hideMarkersShowStars() {
    // Add styles animation to both shrink+fade the markers and expand starts simultaniously
    this.startRippleHighlightBackground();
    this.setState({
      animatedStarsBarAnimate: true,
      goalMarkersStyles: styles.shrinkAndFadeMarkers,
      trackerPointerStyles: styles.shrinkAndFadePointer,
    });
  }

  moveProgressToEnd(prevPercent) {
    this.setState({
      animatedStarsBarAnimate: true,
      animatedStarsBarVisible: true,
    });
    // nav to the 100%
    this.animateProgressBar({
      animateFrom: prevPercent,
      animateTo: 100,
      callback: this.hideMarkersShowStars,
    });
  }

  resetProgressTrackerCallback() {
    const isTrackerCompleted = this.props.isTrackerCompleted;
    this.setState({
      trackerPointerVisible: !isTrackerCompleted,
      animatedStarsBarVisible: isTrackerCompleted,
    });
    this.setInitialProgress(this.props.percentProgress);
    if (this.props.startFadeIn) {
      this.props.startFadeIn();
    }
  }

  showProgressBarWhileFading() {
    if (this.props.startFadeOut) {
      this.props.startFadeOut(this.resetProgressTrackerCallback);
    }
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(
    { percentProgress: prevPercent },
    { animatedStarsBarVisible: prevAnimatedStarsBarVisible }
  ) {
    const { percentProgress: newPercent, isTrackerCompleted } = this.props;

    if (prevPercent === newPercent) {
      return;
    }

    const { lastGoalPassed, goalAchievedOnUpdateIndex } =
      this.calculateWhichGoalsHaveBeenPassed(
        this.props.trackerSegments,
        prevPercent,
        newPercent
      );

    if (prevAnimatedStarsBarVisible !== isTrackerCompleted) {
      if (isTrackerCompleted) {
        // After the progress bar has moved to the end, the markers faded out,
        // then the animated stars bar and background highlight are displayed
        this.moveProgressToEnd(prevPercent);
      } else {
        this.showProgressBarWhileFading();
      }
    } else if (lastGoalPassed?.animateTo) {
      // initiate the animation of the goal marker which has been passed
      this.animateToGoalMarker({
        animateFrom: prevPercent,
        animateTo: lastGoalPassed.animateTo,
      });
    } else {
      // user didn't pass any goals, just move the bar
      this.animateProgressBar({
        animateFrom: prevPercent,
        animateTo: newPercent,
      });
    }
    if (
      !isTrackerCompleted &&
      this.state.goalAchievedOnUpdateIndex !== goalAchievedOnUpdateIndex
    ) {
      // This will tell the GoalMarkersContainer which goal (if any) needs to be
      // animated as a result of changing progress
      this.setState({ goalAchievedOnUpdateIndex });
    }
  }
  /* eslint-enable react/no-did-update-set-state */

  setInitialProgress(percent) {
    // for the initial render, don't animate the progress bar, just set its width
    const el = this.progressBar;
    if (el && el.current) {
      el.current.style.width = `${percent}%`;
    }
  }

  animateProgressBar({ animateFrom, animateTo, callback }) {
    const el = this.progressBar;
    if (el && el.current) {
      tween(
        {
          startValue: animateFrom,
          endValue: animateTo,
          duration: PROGRESS_BAR_ANIMATION_DURATION,
          springFactor: 2,
          easingFunction: 'easeInOutQuart',
          endCallback: callback,
        },
        (tweenValue) => {
          if (el && el.current) {
            el.current.style.width = `${tweenValue}%`;
          }
        }
      );
    }
  }

  animateToGoalMarker(options) {
    // User has passed a goal. We call animateToProgressBar twice.
    // First, animate to this goal's point on the tracker.
    // We pass a callback that is called by tween when the first animation is finished.
    // The callback allows time for marker animation to happen via setTimeout,
    // then calls animateProgressBar again to continue animation to final percentProgress.
    const callback = (endValue) => {
      setTimeout(
        () =>
          this.animateProgressBar({
            animateFrom: endValue,
            animateTo: this.props.percentProgress,
          }),
        CALLBACK_ANIMATION_DELAY
      );
    };

    this.animateProgressBar({
      animateFrom: options.animateFrom,
      animateTo: options.animateTo,
      callback,
    });
  }

  render() {
    const { ariaLabelledbyId } = this.props;
    const {
      animatedStarsBarAnimate,
      animatedStarsBarVisible,
      AnimatedStarsBarSnapshot,
      goalAchievedOnUpdateIndex,
      goalMarkersStyles,
      trackerPointerVisible,
      trackerPointerStyles,
    } = this.state;

    return (
      <div
        aria-labelledby={ariaLabelledbyId}
        className={`${styles.maxTrackerWidth} mx-auto relative mt6 mb8`}
        role="img"
      >
        <div className={`relative size12of12 ${styles.barBackground}`}>
          <div
            className={`${styles.progressBar} bg-gold`}
            ref={this.progressBar}
          >
            {trackerPointerVisible && (
              <TrackerPointer className={trackerPointerStyles} />
            )}
          </div>
          <GoalMarkers
            animationDelay={PROGRESS_BAR_ANIMATION_DURATION}
            animationDuration={CALLBACK_ANIMATION_DELAY}
            goalAchievedOnUpdateIndex={goalAchievedOnUpdateIndex}
            otherClasses={goalMarkersStyles}
          />

          <div
            className={`${animatedStarsBarVisible ? '' : 'hidden'} ${
              animatedStarsBarAnimate ? styles.active : ''
            }`}
            onAnimationEnd={this.handleStarsBarAnimationEnd}
          >
            {AnimatedStarsBarSnapshot && (
              <AnimatedStarsBarSnapshot className={styles.expandStarsBar} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ProgressTracker.propTypes = {
  /*
   * ID of the element in the DOM to use for a11y label
   */
  ariaLabelledbyId: PropTypes.string.isRequired,
  startRippleHighlight: PropTypes.func,
  startFadeOut: PropTypes.func,
  startFadeIn: PropTypes.func,
};

const select = (state) => ({
  isTrackerCompleted: isTrackerCompletedSelector(state),
  percentProgress: trackerPercentProgressSelector(state),
  trackerSegments: trackerGoalsWithUiPropertiesSelector(state),
});

export default connect(select)(ProgressTracker);
