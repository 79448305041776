import { defineMessages } from 'react-intl';

export const paymentMessages = defineMessages({
  addPayment: {
    id: 'shared.payments.addPayment',
    defaultMessage: 'Add payment',
    description: 'Label for payment method select option to add a credit card',
  },
  addPaymentMethod: {
    id: 'shared.payments.addPaymentMethod',
    description:
      'Button to add payment method if insufficient funds and no payment methods',
    defaultMessage: 'Add payment method',
  },
  noPaymentMethodSelected: {
    id: 'shared.payments.noPaymentMethodSelected',
    defaultMessage: 'To add money, select a payment method.',
    description: 'Label for payment method missing field error',
  },
  payment: {
    id: 'shared.payments.payment',
    defaultMessage: 'Payment',
    description: 'Float label for payment select component',
  },
  earnText: {
    id: 'shared.payments.earnText',
    defaultMessage: 'Earns',
    description: 'Verb before earn rate of selected order payment',
  },
  payPalPaymentMethodAdded: {
    id: 'shared.payments.payPalPaymentMethodAdded',
    description: 'PayPal added as a payment method',
    defaultMessage: 'PayPal added',
  },
  reloadSource: {
    id: 'shared.payments.reloadSource',
    description: 'Float label for reload source',
    defaultMessage: 'Reload source',
  },
  reloadSourceA11yLabel: {
    id: 'shared.payments.reloadSourceA11yLabel',
    description: 'Aria label for reload source',
    defaultMessage: 'Reload source',
  },
  selectPaymentInstrument: {
    id: 'shared.payments.selectPaymentInstrument',
    defaultMessage: 'Select payment method',
    description: 'Label describing the payment method dropdown',
  },
});
