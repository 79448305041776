import { defineMessages } from 'react-intl';

export default defineMessages({
  storeClosedHeader: {
    id: 'shared.orderingUnavailableDialog.storeClosedHeader',
    description: 'Header for dialog that shows when a store is closed',
    defaultMessage: 'This store is closed',
  },
  mopUnavailableHeader: {
    id: 'shared.orderingUnavailableDialog.mopUnavailableHeader',
    description:
      'Header for dialog that shows when mobile ordering is unavailable',
    defaultMessage: 'Choose another store to order ahead',
  },
  body: {
    id: 'shared.orderingUnavailableDialog.body',
    description: 'Body for dialog that shows when a store is closed',
    defaultMessage:
      'Ordering ahead is currently unavailable at {hasStoreName, select, true {{storeNameSpan}} other {this store}}.',
  },
  seeTheMenu: {
    id: 'shared.orderingUnavailableDialog.seeTheMenu',
    description: 'Call to action to see the menu',
    defaultMessage: 'See the menu',
  },
  accountRequiredHeader: {
    id: 'shared.orderingUnavailableDialog.accountRequiredHeader',
    defaultMessage: 'Account required to order ahead at this store',
    description:
      'Header for dialog that shows when an account is required for mobile ordering',
  },
  accountRequiredBody: {
    id: 'shared.orderingUnavailableDialog.accountRequiredBody',
    defaultMessage:
      'You’ll need to sign in or sign up if you want to order ahead to this store. Please choose another store if you want to order ahead as a guest.',
    description:
      'Body for dialog that shows when an account is required for mobile ordering',
  },
});
