import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

/* Documentation:
 * Application Insights JS SDK (RUM) - https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-sdk?tabs=npmpackage
 * React plugin - https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-framework-extensions?tabs=react
 */
export const initializeAppInsights = function (connectionString) {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
    },
  });
  appInsights.loadAppInsights();
};
