import { SET_ACTIVE_DIALOG, UPDATE_ACTIVE_DIALOG } from '../actions';

export default (state = null, action) => {
  if (action.type === SET_ACTIVE_DIALOG) {
    return action.payload || null;
  }
  if (action.type === UPDATE_ACTIVE_DIALOG) {
    const {
      Content,
      props: currentProps,
      contentProps: currentContentProps,
    } = state || {};
    const { props = {}, contentProps = {} } = action.payload || {};
    return {
      Content,
      props: { ...currentProps, ...props },
      contentProps: { ...currentContentProps, ...contentProps },
    };
  }
  return state;
};
