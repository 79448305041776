'use strict';

const TRAILING_SLASHES = /\/*$/g;
const SURROUND_SLASHES = /^\/*|\/*$/g;

// Formats and normalizes a URL from host / path parts.
//
// See unit tests for full semantics.
//
// - `host`: Hostname (everything up to first path slash -- '/', '//foo.com', 'https://foo.com')
// - `[path1, path2, ...]`: Remaining arguments are path parts to join.
const formatUrl = function (host /* path1, path2, ... */) {
  const normalizedHost = (host || '').replace(TRAILING_SLASHES, '');
  const parts = Array.prototype.slice.call(arguments, 1);

  // Normalize host + parts into array, that we join.
  const url = []
    .concat(
      normalizedHost,
      parts
        // Remove slashes
        .map((part) => (part || '').replace(SURROUND_SLASHES, ''))
        // Remove empties
        .filter(Boolean)
    )
    .join('/');

  // Corner case: Single slash becomes empty for easier string concatentation.
  return url === '/' ? '' : url;
};

module.exports = formatUrl;
