import { createSelector } from 'reselect';
import { configSelector } from '../../shell/state/selectors/config';
import { pathnameSelector } from 'shared/app/state/selectors/routes';
import { getBaseUrl } from '../../utils/redirect-to-signin-with-return-url';

export const supportedLocalesSelector = createSelector(
  configSelector,
  (config) => config?.supportedLocales
);

// The locale string. e.g. `en-US`
export const localeTagSelector = (state) => state.locale;

// The locale object from config. eg { name: 'English', legacyBaseUrl: 'https://www.starbucks.com' }
export const currentLocaleSelector = createSelector(
  localeTagSelector,
  supportedLocalesSelector,
  (locale, supportedLocales) => supportedLocales?.[locale]
);

export const alternativeAppLanguageSelector = createSelector(
  configSelector,
  (config) => config.alternativeAppLanguage || {}
);

export const languagesInWebAppSelector = createSelector(
  alternativeAppLanguageSelector,
  localeTagSelector,
  currentLocaleSelector,
  supportedLocalesSelector,
  // eslint-disable-next-line max-params
  (altLang, localeTag, currentLocale, supportedLocales) => {
    if (!altLang.enabled) {
      return null;
    }
    return [
      {
        language: localeTag,
        name: supportedLocales[localeTag].name,
        isCurrent: true,
      },
      {
        language: altLang.code,
        name: supportedLocales[altLang.code].name,
        isCurrent: false,
      },
    ];
  }
);

export const coreAppBaseUrlSelector = createSelector(
  currentLocaleSelector,
  (currentLocale) => currentLocale?.coreAppBaseUrl
);

export const legacyBaseUrlSelector = createSelector(
  currentLocaleSelector,
  (currentLocale) => currentLocale.legacyBaseUrl
);

export const isCoreAppUrlSelector = createSelector(
  coreAppBaseUrlSelector,
  (coreAppBaseUrl) => coreAppBaseUrl === getBaseUrl()
);

export const currentBaseUrlSelector = createSelector(
  isCoreAppUrlSelector,
  coreAppBaseUrlSelector,
  legacyBaseUrlSelector,
  (isCoreAppUrl, coreAppBaseUrl, legacyBaseUrl) =>
    isCoreAppUrl ? coreAppBaseUrl : legacyBaseUrl
);

export const isCoreAppSinglePageAppSelector = createSelector(
  isCoreAppUrlSelector,
  pathnameSelector,
  (isCoreAppUrl, pathname) => {
    return (
      isCoreAppUrl &&
      !pathname.startsWith('/account/create') &&
      !pathname.startsWith('/account/signin')
    );
  }
);

export const defaultSigninRedirectSelector = createSelector(
  currentLocaleSelector,
  (currentLocale) => currentLocale.coreAppBaseUrl
);

export const currencyCodeSelector = createSelector(
  currentLocaleSelector,
  (currentLocale) => currentLocale.currency
);

export const hasAltLangSelector = createSelector(
  currentLocaleSelector,
  (currentLocale) => Boolean(currentLocale.altLang)
);

export const altLocaleSelector = createSelector(
  currentLocaleSelector,
  hasAltLangSelector,
  supportedLocalesSelector,
  // eslint-disable-next-line max-params
  (currentLocale, hasAltLang, supportedLocales) => {
    return hasAltLang ? supportedLocales[currentLocale.altLang] : null;
  }
);

export const altLangSelector = createSelector(
  currentLocaleSelector,
  hasAltLangSelector,
  (currentLocal, hasAltLang) => {
    return hasAltLang ? currentLocal.altLang : null;
  }
);

export const altLangUrlSelector = createSelector(
  hasAltLangSelector,
  altLocaleSelector,
  pathnameSelector,
  (hasAltLang, altLocale, pathName) => {
    if (!hasAltLang) {
      return '';
    }
    return `${altLocale.legacyBaseUrl}${pathName}`;
  }
);

export const currentCountryCodeSelector = createSelector(
  localeTagSelector,
  (locale) => locale.split('-')[1]
);

export const securePaymentFormUrlSelector = createSelector(
  currentLocaleSelector,
  (currentLocale) => currentLocale.securePaymentFormUrl
);
