import React from 'react';

const Form = (props) => <form {...props} />;

Form.defaultProps = {
  method: 'post',
  noValidate: true,
};

export default Form;
