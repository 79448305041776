import { defineMessages } from 'react-intl';

export const preferencesMessages = defineMessages({
  accountHoldersLink: {
    id: 'shared.communicationPreferences.accountHoldersLink',
    description: 'Offers account holders a link to communication preferences',
    defaultMessage:
      'If you have an account, you can { communicationPreferencesLink }',
  },
  communicationPreferencesLink: {
    id: 'shared.communicationPreferences.linkText',
    defaultMessage: 'change your preferences',
    description: 'text of link to communication preferences settings',
  },
});
