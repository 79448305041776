import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import {
  currentCountryCodeSelector,
  currencyCodeSelector,
} from 'shared/app/state/selectors/locales';

import { showErrorNotification } from 'shared/app/shell';
import { sharedNotificationMessages } from 'shared/app/messages/notifications';

import { useLoadApplePayScript } from './use-load-apple-pay-script';
import NotificationErrorDialog, {
  NOTIFICATION_ERROR_DIALOG_ID,
} from '../error-notification/notification-error-dialog';
import styles from './styles.cssm';
import { useIntl } from 'react-intl';

const ApplePaySession = window.ApplePaySession;

const ApplePayButton = ({
  totalAmount,
  transactionAction,
  optionalPreAction = null,
}) => {
  useLoadApplePayScript();

  const { formatMessage, locale } = useIntl();
  const { openModal } = useModalContext();
  const dispatch = useDispatch();

  const currencyCode = useSelector(currencyCodeSelector);
  const currentCountryCode = useSelector(currentCountryCodeSelector);

  const onApplePayButtonClicked = async () => {
    if (optionalPreAction) {
      try {
        await dispatch(
          optionalPreAction({
            openModal,
          })
        );
      } catch (err) {
        dispatch(
          showErrorNotification({
            messageId: sharedNotificationMessages.somethingWentWrong.id,
          })
        );
        return;
      }
    }

    if (!window.ApplePaySession) {
      // Apple Pay is not supported in this browser
      return;
    }

    // Define ApplePayPaymentRequest
    // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/
    const request = {
      countryCode: currentCountryCode,
      currencyCode,
      merchantCapabilities: ['supports3DS'],
      // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaycontactfield
      // https://forums.developer.apple.com/forums/thread/61802
      requiredBillingContactFields: ['postalAddress', 'name'],
      requiredShippingContactFields: ['email', 'phone'],
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      total: {
        label: 'Starbucks',
        type: 'final',
        amount: totalAmount,
      },
    };

    // Create ApplePaySession
    // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/
    const session = new window.ApplePaySession(3, request);

    session.onvalidatemerchant = async () => {
      try {
        const getMerchantSession = await fetch(
          '/bff/account/payment/apple-pay/merchant-session',
          {
            method: 'post',
            body: {},
          }
        );
        const merchantSession = await getMerchantSession.json();
        session.completeMerchantValidation(merchantSession);
      } catch (error) {
        dispatch(
          showErrorNotification({
            messageId: sharedNotificationMessages.somethingWentWrong.id,
          })
        );
        session.abort();
      }
    };

    session.onpaymentmethodselected = () => {
      const update = {
        newTotal: {
          label: 'Starbucks',
          amount: totalAmount,
        },
      };
      session.completePaymentMethodSelection(update);
    };

    session.onpaymentauthorized = async (event) => {
      try {
        await dispatch(
          transactionAction({
            applePayPaymentInfo: event.payment,
            formatMessage,
            openModal,
          })
        );
      } catch ({ errorNotificationPayload }) {
        openModal({
          component: NotificationErrorDialog,
          ariaLabelledBy: NOTIFICATION_ERROR_DIALOG_ID,
          componentProps: errorNotificationPayload,
        });
        return session.abort();
      }
      session.completePayment(ApplePaySession.STATUS_SUCCESS);
    };

    session.begin();
  };

  if (!window.ApplePaySession) {
    // Apple Pay is not supported in this browser
    return null;
  }
  return (
    <button
      aria-label="Apple Pay Button"
      className={styles.applePayButton}
      id="applePayButton"
      lang={locale.split('-')[0]}
      onClick={onApplePayButtonClicked}
    />
  );
};

export default ApplePayButton;
