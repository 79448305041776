import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  ThemeContext,
  themes,
} from '@starbucks-web/pattern-library/lib/components/theme';

import ExternalLink from 'shared/app/components/external-link';
import PrivacyNoticeLink from 'shared/app/components/privacy-notice-link';
import TermsOfUseLink from 'shared/app/components/terms-of-use-link';
import { signedInSelector } from 'shared/app/bundles/user';
import {
  coreAppBaseUrlSelector,
  legacyBaseUrlSelector,
} from 'shared/app/state/selectors/locales';
import { accountsMessages } from 'shared/app/messages';

const PrivacyAndTerms = () => {
  const { formatMessage } = useIntl();

  const signedIn = useSelector(signedInSelector);
  const coreAppBaseUrl = useSelector(coreAppBaseUrlSelector);
  const legacyBaseUrl = useSelector(legacyBaseUrlSelector);
  const hrefValue = signedIn
    ? `${coreAppBaseUrl}/account/settings/privacy`
    : `${legacyBaseUrl}/personal-information`;

  return (
    <ThemeContext.Consumer>
      {(theme) => {
        return (
          <ul
            className={
              theme === themes.dark
                ? 'bg-greenStarbucks color-textWhiteSoft'
                : 'color-textBlackSoft'
            }
          >
            <li>
              <PrivacyNoticeLink className="mb3 text-noUnderline" />
            </li>
            <li>
              <TermsOfUseLink className="mb3 text-noUnderline" />
            </li>
            <li>
              <ExternalLink className="mb3 text-noUnderline" href={hrefValue}>
                {formatMessage(
                  accountsMessages.doNotShareMyPersonalInformation
                )}
              </ExternalLink>
            </li>
          </ul>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default PrivacyAndTerms;
