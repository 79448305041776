/* eslint-disable no-invalid-this */
'use strict';

// Utilities to create custom cross-browser errors
// that maintain stack traces.

// Adapted from divmain/drydock with permission.
// (https://github.com/divmain/drydock/blob/master/src/node/errors.js)

const inherit = function inherit(Child, Parent) {
  const hasOwn = Object.prototype.hasOwnProperty;

  const Intermediate = function () {
    this.constructor = Child;
    this.constructor$ = Parent;
    // eslint-disable-next-line no-unused-vars
    for (const prop in Parent.prototype) {
      if (hasOwn.call(Parent.prototype, prop) && prop.slice(-1) !== '$') {
        this[`${prop}$`] = Parent.prototype[prop];
      }
    }
  };

  Intermediate.prototype = Parent.prototype;
  Child.prototype = new Intermediate();
  return Child.prototype;
};

const errorFactory = function errorFactory(Parent, name) {
  const ErrorType = function (message) {
    this.name = name;
    this.message = message;
    this.cause = message;

    if (message instanceof Parent) {
      this.message = message.message;
      this.stack = message.stack;
    } else if (Parent.captureStackTrace) {
      Parent.captureStackTrace(this, this.constructor);
    }
  };
  inherit(ErrorType, Parent);
  return ErrorType;
};

const BaseError = errorFactory(Error, 'BaseError');

const PseudoHttpError = function (providedOptions) {
  const options = providedOptions || {};

  this.name = 'HttpError';
  this.code = options.code;

  this.message = options.message || options.code;
  this.cause = options.code;

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, this.constructor);
  }
};
inherit(PseudoHttpError, BaseError);

module.exports = {
  errorFactory,
  BaseError,
  PseudoHttpError,
};
