import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import {
  sharedCallsToAction,
  sharedNotificationMessages,
} from 'shared/app/messages';

import NotificationErrorDialog, {
  NOTIFICATION_ERROR_DIALOG_ID,
} from './notification-error-dialog';

export const somethingWentWrongPayload = {
  title: sharedNotificationMessages.somethingWentWrong,
  body: sharedNotificationMessages.beBackToNormalSoon,
  confirmText: sharedCallsToAction.gotIt,
};

const useErrorNotification = () => {
  const { openModal } = useModalContext();

  const showErrorDialog = (options = {}) => {
    openModal({
      component: NotificationErrorDialog,
      ariaLabelledBy: NOTIFICATION_ERROR_DIALOG_ID,
      componentProps: { ...somethingWentWrongPayload, ...options },
    });
  };

  return {
    showErrorDialog,
    openModal,
  };
};

export default useErrorNotification;
