import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import Frap from '@starbucks-web/pattern-library/lib/components/frap';

import ExternalLink from 'shared/app/components/external-link';
import {
  privacyStatementUrlSelector,
  termsOfUseUrlSelector,
} from 'shared/app/state/selectors/privacy-and-terms';
import { commonMessages, sharedCallsToAction } from 'shared/app/messages';

import { setTermsPrivacyStatus } from '../../state/action-creators/terms-privacy';

import TermsPrivacyErrorDialog, {
  TERMS_PRIVACY_ERROR_DIALOG_ID,
} from './terms-privacy-error-dialog';

const TermsPrivacyLinksButton = ({ overlayId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();
  const { openModal, closeModal } = useModalContext();
  const dispatch = useDispatch();

  const onAgreeAndContinue = () => {
    setIsLoading(true);

    dispatch(setTermsPrivacyStatus())
      .then(() => {
        setIsLoading(false);
        closeModal(overlayId);
      })
      .catch(() => {
        openModal({
          component: TermsPrivacyErrorDialog,
          ariaLabelledBy: TERMS_PRIVACY_ERROR_DIALOG_ID,
        });
        setIsLoading(false);
      });
  };

  const privacyStatementUrl = useSelector(privacyStatementUrlSelector);
  const termsOfUseUrl = useSelector(termsOfUseUrlSelector);

  return (
    <Fragment>
      <p>
        <ExternalLink className="mb3" href={termsOfUseUrl}>
          {formatMessage(commonMessages.termsOfUse)}
        </ExternalLink>
      </p>
      <p>
        <ExternalLink
          className="mb3"
          href={`${privacyStatementUrl}#notice-of-financial-incentive`}
        >
          {formatMessage(commonMessages.privacyNotice)}
        </ExternalLink>
      </p>
      <div className="pt2 pb5 flex justify-center">
        <Frap
          className="mt3"
          data-e2e="agree-and-continue"
          loading={isLoading}
          onClick={onAgreeAndContinue}
        >
          {formatMessage(sharedCallsToAction.agreeAndContinue)}
        </Frap>
      </div>
    </Fragment>
  );
};

export default TermsPrivacyLinksButton;
