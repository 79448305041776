import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  phoneNotSmsCapable: {
    id: 'shared.mfa.phoneNotSmsCapable.error',
    defaultMessage: 'Phone is not SMS capable',
    description: 'Error message when the phone is not SMS capable',
  },
  wrongMfaCode: {
    id: 'shared.mfa.wrongMfaCode.error',
    defaultMessage: 'Check code and try again.',
    description: 'Error message for wrong MFA code',
  },
  noMoreRetries: {
    id: 'shared.mfa.noMoreRetries.error',
    defaultMessage: 'No more tries left. Try again later.',
    description:
      'Error message when no more retries left for entering the MFA code',
  },
});

const mapCodeToErrorMessage = (code) => {
  switch (code) {
    case '501002':
      return messages.phoneNotSmsCapable;
    case '501004':
    case '691092':
      return messages.noMoreRetries;
    case '691225':
    case 'challenge_failed':
      return messages.wrongMfaCode;
    default:
      return null;
  }
};

export default mapCodeToErrorMessage;
