import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Card from '@starbucks-web/pattern-library/lib/components/card';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';
import messages from './messages';

const AddCardContent = ({ configImagesUrl }) => {
  const imageUrl = `${configImagesUrl}/feed/card_and_stars.png`;

  return (
    <Card containerClasses="p3">
      <Heading className="pb3 text-bold" size="lg" tagName="h2">
        <FormattedMessage {...messages.addDigitalCardTitle} />
      </Heading>
      <div className="flex">
        <div className="size6of12 pr3">
          <p className="text-xxs">
            <FormattedMessage {...messages.addDigitalCardMessage} />
          </p>
          <Button className="mt3" tagName={Link} to="/account/cards/add">
            <FormattedMessage {...messages.addDigitalCardBtn} />
          </Button>
        </div>
        <ImageLoader
          includeFallback={{ enabled: true }}
          role="presentation"
          src={imageUrl}
          wrapInFlex={{
            enabled: true,
            height: 73,
            width: 100,
            flexEmbedWrapperClassName: 'size6of12',
          }}
        />
      </div>
    </Card>
  );
};

const select = (state) => ({
  configImagesUrl: configImagesUrlSelector(state),
});

export default connect(select)(AddCardContent);
