import { find } from 'lodash';
import ms from 'milliseconds';
import { createSelector } from 'reselect';

import shouldUpdate from 'shared/app/stale-reducers/should-update';
import { appTimeSelector } from 'shared/app/shell';
import { signedInSelector } from 'shared/app/bundles/user';

const svcCardsSelector = (state) => state.svcCards;

export const cardInManagementIdSelector = createSelector(
  svcCardsSelector,
  (svcCards) => svcCards.cardInManagement || null
);

export const shouldFetchSvcCardsSelector = createSelector(
  signedInSelector,
  svcCardsSelector,
  appTimeSelector,
  (signedIn, cards, time) => {
    if (
      !signedIn ||
      !shouldUpdate(cards, {
        staleTime: ms.minutes(15),
        now: time,
      })
    ) {
      return null;
    }
    return true;
  }
);

export const svcCardsFetchedSelector = createSelector(
  svcCardsSelector,
  ({ lastFetch }) => Boolean(lastFetch)
);

const cardCompare = (a, b) => {
  if (a.isPrimary) {
    return -1;
  }
  if (b.isPrimary) {
    return 1;
  }
  if (a.balance.amount > b.balance.amount) {
    return -1;
  }
  if (a.balance.amount < b.balance.amount) {
    return 1;
  }
  return 0;
};

export const svcCardsDataSelector = createSelector(
  svcCardsSelector,
  (svcCards) => {
    if (!svcCards?.data || !svcCards?.data.length) {
      return null;
    }
    // clone it, sort it
    return svcCards?.data?.slice()?.sort(cardCompare) ?? [];
  }
);

export const svcCardsLastReloadedSelector = createSelector(
  svcCardsDataSelector,
  (svcCardsData) => (svcCardsData ? svcCardsData.lastReloadResponse : null)
);

export const hasSvcCardSelector = createSelector(
  svcCardsDataSelector,
  (cardsData) => Boolean(cardsData)
);

export const primarySvcCardSelector = createSelector(
  svcCardsDataSelector,
  (cards) => find(cards, (card) => card.isPrimary) || null
);

export const isPrimarySvcCardDigitalSelector = createSelector(
  primarySvcCardSelector,
  (card) => card?.isDigital ?? false
);

export const expectingAddCardResponseSelector = createSelector(
  svcCardsSelector,
  (cards) => Boolean(cards.expectingAddCardResponse)
);
export const expectingReloadResponseSelector = createSelector(
  svcCardsSelector,
  (cards) => Boolean(cards.expectingReloadResponse)
);
export const expectingAutoReloadResponseSelector = createSelector(
  svcCardsSelector,
  (cards) => Boolean(cards.expectingAutoReloadResponse)
);
export const expectingTransferResponseSelector = createSelector(
  svcCardsSelector,
  (cards) => Boolean(cards.expectingTransferResponse)
);
export const expectingBalanceResponseSelector = createSelector(
  svcCardsSelector,
  (cards) => Boolean(cards.expectingBalanceResponse)
);

export const svcCardsLoadingSelector = createSelector(
  svcCardsSelector,
  (svcCards) => !svcCards.data && (svcCards.loading || !svcCards.lastError)
);

export const cardInManagementSelector = createSelector(
  cardInManagementIdSelector,
  svcCardsDataSelector,
  (cardId, cardsData) => (cardsData ? find(cardsData, { cardId }) : null)
);

export const reloadAmountSelectedSelector = createSelector(
  svcCardsSelector,
  (svcCards) => svcCards.reloadAmountSelected || null
);

export const autoReloadTriggerAmountSelectedSelector = createSelector(
  svcCardsSelector,
  (svcCards) => svcCards.autoReloadTriggerAmountSelected || null
);

export const lostStolenCompleteSelector = createSelector(
  svcCardsSelector,
  (svcCards) => svcCards?.lostStolenComplete
);

export const hasSvcWithLowBalanceArgsSelector = createSelector(
  svcCardsDataSelector,
  (cardsData) =>
    ({ cardId, minAmount }) => {
      if (!cardId || !cardsData || !minAmount) {
        return false;
      }
      const foundSvc = cardsData.find((svcInfo) => svcInfo.cardId === cardId);
      const svcBalance = foundSvc?.balance?.amount;

      if (!foundSvc || !svcBalance) {
        return false;
      }

      if (parseFloat(minAmount) > svcBalance) {
        return true;
      }
    }
);
