import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';

import curbsideIcon from '@starbucks-web/pattern-library/lib/icons/pickup-curbside';
import inStoreIcon from '@starbucks-web/pattern-library/lib/icons/pickup-in-store';
import outdoorIcon from '@starbucks-web/pattern-library/lib/icons/pickup-outdoor';
import driveThruIcon from '@starbucks-web/pattern-library/lib/icons/pickup-drive-thru';

import {
  IN_STORE_FEATURE_CODE,
  DRIVE_THRU_FEATURE_CODE,
  CURBSIDE_FEATURE_CODE,
  OUTDOOR_FEATURE_CODE,
} from '../../../universal/pickup-feature-codes';

import pickupHeaderMessages from 'shared/app/components/order-pickup-card/messages';
import messages from './messages';
import styles from './styles.cssm';

const HowToPickup = ({ option, name }) => {
  const options = {
    [IN_STORE_FEATURE_CODE]: {
      icon: inStoreIcon,
      header: pickupHeaderMessages.orderPickupTypeInStore,
      text: messages.pickupInStoreOption,
      name,
    },
    [DRIVE_THRU_FEATURE_CODE]: {
      icon: driveThruIcon,
      header: pickupHeaderMessages.orderPickupTypeDriveThru,
      text: messages.pickupDriveThruOption,
      name,
    },
    [CURBSIDE_FEATURE_CODE]: {
      icon: curbsideIcon,
      header: pickupHeaderMessages.orderPickupTypeCurbside,
      text: messages.pickupCurbsideOption,
    },
    [OUTDOOR_FEATURE_CODE]: {
      icon: outdoorIcon,
      header: pickupHeaderMessages.orderPickupTypeOutdoor,
      text: messages.pickupOutdoorOption,
    },
  };

  const opt = options[option] || options[IN_STORE_FEATURE_CODE];

  return (
    <div
      className={`${styles.overlayPickupOption} mt2 px1 py3 flex items-center`}
    >
      <div className={`${styles.iconWrapper}`}>
        <Icon className="color-black90" path={opt.icon} />
      </div>
      <div className="pl2 pr3 flex-grow">
        <Heading className="pb1 text-semibold text-sm" tagName="h3">
          <FormattedMessage {...opt.header} />
        </Heading>
        <div className="text-xxs">
          <FormattedMessage {...opt.text} values={{ name: opt.name }} />
        </div>
      </div>
    </div>
  );
};

HowToPickup.propTypes = {
  option: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default HowToPickup;
