import qs from 'query-string';

export const getUrlWithQuery = (url, query) =>
  `${url}${query ? `?${query}` : ''}`;

export const getShortStoreNumber = (storeNumber) => {
  if (!storeNumber) {
    return;
  }

  const asString = storeNumber.toString();
  const hyphen = '-';
  if (asString.includes(hyphen)) {
    return asString.slice(0, asString.indexOf(hyphen));
  }

  return asString;
};

export const getStoreNumberQuery = (storeNumber) => {
  const shortStoreNumber = getShortStoreNumber(storeNumber);
  return shortStoreNumber
    ? qs.stringify({ storeNumber: shortStoreNumber })
    : null;
};
