import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import FormContainer from '@starbucks-web/pattern-library/lib/components/form-container';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import FormWithMessaging from 'shared/app/components/form-with-messaging';
import {
  addNotification,
  showUnexpectedErrorNotification,
} from 'shared/app/shell/state/action-creators/notifications';
import { doLogin } from 'shared/app/shell/state/action-creators/app-lifecycle';

import MfaChallengeOtherOptionsOverlay, {
  MFA_CHALLENGE_OTHER_OPTIONS_OVERLAY_ID,
} from '../mfa-challenge-other-options-overlay';
import MfaChallengeEnterCodeOverlayForm from './form';
import {
  verifyMfaCode,
  verifyMfaCodeForLogin,
  verifyMfaCodeForSecureSession,
} from '../../state/action-creators';
import { mfaChallengeRefSelector } from '../../state/selectors';

import mapCodeToErrorMessage from '../error-messages';
import sharedMessages from '../shared-messages';

const MfaChallengeEnterCodeOverlayContainer = ({
  username,
  onCodeSuccess,
  onCodeError,
  password,
  phone,
  verificationType,
}) => {
  const challengeRef = useSelector(mfaChallengeRefSelector);

  const [disableButtons, setDisableButtons] = useState(false);
  const { openModal, closeModal } = useModalContext();
  const { formatMessage } = useIntl();
  const formContainerRef = useRef(null);
  const dispatch = useDispatch();

  const setServerFieldError = (errorMessage) => {
    const fieldName = 'verificationCode';
    formContainerRef.current.updateField({
      errorMessage,
      hasServerSideError: true,
      error: true,
      input: {
        name: fieldName,
      },
    });
    formContainerRef.current.focusOnFirstInvalid([fieldName]);
  };

  const handleSubmitError = (error) => {
    // We have three different error data shapes, depending on the error
    if (error?.data?.error === 'too_many_challenge_attempts') {
      // for secureSession challenges, user is to be logged out
      dispatch(showUnexpectedErrorNotification());
      return dispatch(doLogin());
    }
    // graphql and bff responses provide different shape to access code
    const code = error?.data?.code || error?.code;

    const errorMessage = mapCodeToErrorMessage(code);

    if (errorMessage) {
      // Disable CTA buttons for "no more retries" errors
      const shouldDisableButtons = ['501004', '691092'].indexOf(code) > -1;
      setDisableButtons(shouldDisableButtons);
      setServerFieldError(errorMessage);
    } else {
      dispatch(showUnexpectedErrorNotification());
    }
  };

  const handleOnOtherOptions = () => {
    openModal({
      component: MfaChallengeOtherOptionsOverlay,
      ariaLabelledBy: MFA_CHALLENGE_OTHER_OPTIONS_OVERLAY_ID,
      componentProps: {
        phoneId: phone?.id,
        phoneNumber: phone?.phoneNumber,
        verificationType,
      },
    });
  };

  const handleSubmit = (fieldsState) => {
    const { verificationCode } = fieldsState;
    const phoneId = phone?.id;

    const verifyActions = {
      phoneVerification: verifyMfaCode,
      login: verifyMfaCodeForLogin,
      secureSession: verifyMfaCodeForSecureSession,
    };

    const verifyAction = verifyActions[verificationType];

    dispatch(
      verifyAction({
        phoneId,
        verificationCode,
        username,
        password,
        challengeRef,
        challengeCode: verificationCode,
      })
    )
      .then((result) => {
        // literal Boolean result if the code is verified
        // phoneVerification response comes from graphql
        // login and secureSession are handled in bff layer
        if (result === true) {
          onCodeSuccess?.(result);
          if (verificationType === 'phoneVerification') {
            dispatch(
              addNotification(
                formatMessage(sharedMessages.phoneVerificationSuccessMessage)
              )
            );
          }
          closeModal();
        }

        // eslint-disable-next-line no-throw-literal
        else throw { data: { code: 'challenge_failed' } };
      })
      .catch((err) => {
        onCodeError?.(err);
        handleSubmitError(err);
      });
  };

  const setDidSubmit = () => {
    formContainerRef.current.updateField({
      input: {
        name: 'verificationCode',
      },
      wasSubmitted: true,
    });
  };

  return (
    <FormContainer
      fields={{
        verificationCode: {
          value: '',
        },
      }}
      focusOnInvalidAfterAnimation
      focusOnSubmitError
      onError={setDidSubmit}
      onSubmit={handleSubmit}
      ref={formContainerRef}
    >
      <FormWithMessaging requiredIndicatorTextClassName="pb3">
        <MfaChallengeEnterCodeOverlayForm
          disableButtons={disableButtons}
          onOtherOptions={handleOnOtherOptions}
          verificationType={verificationType}
        />
      </FormWithMessaging>
    </FormContainer>
  );
};

export default MfaChallengeEnterCodeOverlayContainer;
