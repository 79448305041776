import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import showPassword from '@starbucks-web/pattern-library/lib/icons/eye-open';
import hidePassword from '@starbucks-web/pattern-library/lib/icons/eye-closed';

const messages = defineMessages({
  textToggleLabelLong: {
    id: 'shared.passwordField.textToggleLong',
    description:
      'Long label for text visibility toggle on account create password field',
    defaultMessage:
      '{toggleActive, select, true {Hide} other {Show}} password text',
  },
});

const PasswordVisibilityToggle = ({ intl, onClick, textVisible }) => {
  const { formatMessage } = intl;

  const ariaLabel = formatMessage(messages.textToggleLabelLong, {
    toggleActive: textVisible,
  });

  return (
    <button
      aria-label={ariaLabel}
      className="mb1 color-textBlackSoft text-sm text-bold self-end px1"
      onClick={onClick}
      type="button"
    >
      <Icon path={textVisible ? showPassword : hidePassword} />
    </button>
  );
};

export default injectIntl(PasswordVisibilityToggle);
