import { doLogin } from 'shared/app/shell/state/action-creators/app-lifecycle';
import { userEmailSelector } from '../selectors/profile';

export const reAuthenticateRequest =
  (password, reputation) =>
  (dispatch, getState, { apiFetch }) => {
    const state = getState();
    const email = userEmailSelector(state);
    if (!email) {
      dispatch(doLogin());
    }
    return apiFetch('/bff/account/reauth', {
      method: 'post',
      body: { email, password, reputation },
    });
  };
