import { defineMessages } from 'react-intl';

const messages = defineMessages({
  characterLimitLabel: {
    id: 'textArea.characterLimitLabel',
    defaultMessage: '{maxCharacterCount} character limit',
    description:
      'Label to indicate maximum allowed number of characters in text area',
  },
  messageCounterLabel: {
    id: 'textArea.numberCharactersUsed',
    defaultMessage:
      '{currentNumber} characters used out of {maxNumber} available',
    description: 'Accessible label for Message Counter',
  },
  numberCharactersRemaining: {
    id: 'textArea.numberCharactersRemaining',
    defaultMessage: '{remainingCharacterCount} characters left',
    description: 'Label indicating number of characters left',
  },
  noCharactersRemaining: {
    id: 'textArea.noCharactersRemaining',
    defaultMessage: 'No characters left',
    description: 'Label indicating no characters remaining',
  },
});

export default messages;
