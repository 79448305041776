import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Frap from '@starbucks-web/pattern-library/lib/components/frap';
import FrapContainer from 'shared/app/components/frap-container';
import Radio from '@starbucks-web/pattern-library/lib/components/radio';
import RadioGroup from '@starbucks-web/pattern-library/lib/components/radio-group';

import Form from 'shared/app/components/form';

import messages from '../shared-messages';

const MfaChallengeOtherOptionsOverlayForm = ({
  fields: { radioGroup },
  onSubmit,
  isSendingCode,
  disableFrap,
}) => {
  const { formatMessage } = useIntl();
  const [radioValue, setRadioValue] = useState(radioGroup.input.value);

  return (
    <Form onSubmit={onSubmit}>
      <RadioGroup
        {...radioGroup.input}
        legend={formatMessage(messages.sendCodeOptionsRadioGroupLegend)}
        onChange={(event) => {
          radioGroup.input.value = event.target.value;
          setRadioValue(event.target.value);
        }}
        value={radioValue}
      >
        <Radio value="phoneSMS">
          <FormattedMessage {...messages.sendCodeOptionsTextRadioLabel} />
        </Radio>

        <Radio value="phoneVoice">
          <FormattedMessage {...messages.sendCodeOptionsCallRadioLabel} />
        </Radio>
      </RadioGroup>

      <FrapContainer className="md-mt6" relativeAbove="md">
        <Frap
          data-e2e="submitCode"
          disabled={disableFrap}
          loading={isSendingCode}
          type="submit"
        >
          <FormattedMessage {...messages.sendCodeButton} />
        </Frap>
      </FrapContainer>
    </Form>
  );
};

export default MfaChallengeOtherOptionsOverlayForm;
