import { defineMessages } from 'react-intl';

export default defineMessages({
  addDigitalCardTitle: {
    id: 'shared.addCard.title',
    defaultMessage: 'Almost there!',
    description: 'Heading call-to-action asking the user to add a digital card',
  },
  addDigitalCardMessage: {
    id: 'shared.addCard.message',
    defaultMessage:
      'Add a Starbucks card so you can order ahead and earn Rewards.',
    description:
      'message text of call-to-action asking the user to add a digital card',
  },
  addDigitalCardBtn: {
    id: 'shared.addCard.button',
    defaultMessage: 'Add a card',
    description:
      'button of call-to-action asking the user to add a digital card',
  },
});
