import dialog from './dialog';
import hamburgerNav from './hamburger-nav';
import notifications from './notifications';
import time from './time';
import termsPrivacy from 'shared/app/state/reducers/terms-privacy';

export default {
  dialog,
  hamburgerNav,
  notifications,
  time,
  termsPrivacy,
};
