import ms from 'milliseconds';
import { createSelector } from 'reselect';

import {
  ADD_SVC_CARD_SUCCESS,
  REMOVE_SVC_CARD_SUCCESS,
  UPDATE_AUTO_RELOAD_SUCCESS,
} from 'shared/app/bundles/svc-cards/actions';
import { signedInSelector, userEmailSelector } from 'shared/app/bundles/user';

import {
  appTimeSelector,
  showUnexpectedErrorNotification,
} from 'shared/app/shell';
import { routeParamsSelector } from 'shared/app/state/selectors/routes';

import { getBffBaseUrl } from 'shared/app/utils/config-helper';
import runSequentially from 'shared/app/utils/run-sequentially';

import {
  CREATE_PAYMENT_INSTRUMENT,
  DELETE_PAYMENT_INSTRUMENT,
  CREATE_SINGLE_USE_APPLE_PAY_PAYMENT,
  GET_PAYPAL_SETUP_TOKEN,
  GET_STARPAY_WALLET,
} from 'shared/universal/gql-operation-ids';

import shouldUpdate from '../../stale-reducers/should-update';
import createSingleResourceReducer from '../../stale-reducers/single-resource-reducer';
import {
  firstNameSelector,
  hasShortSessionSelector,
  onlyExtendedSessionFoundSelector,
  lastNameSelector,
} from '../user';

import { transformBillingAddress } from './transform-billing-address';

export const PAYMENT_TYPE_GENERIC = 'GENERIC';
export const PAYMENT_TYPE_SVC = 'SVC';

export const ADD_PAYPAL = 'paypal';
export const ADD_PAYMENT = 'add-payment';
export const ADD_PAYMENT_INSTRUMENT = 'ADD_PAYMENT_INSTRUMENT';
export const ADD_PAYMENT_INSTRUMENT_SUCCESS = 'ADD_PAYMENT_INSTRUMENT_SUCCESS';
export const ADD_PAYMENT_INSTRUMENT_ERROR = 'ADD_PAYMENT_INSTRUMENT_ERROR';

export const REMOVE_PAYMENT_INSTRUMENT = 'REMOVE_PAYMENT_INSTRUMENT';
export const REMOVE_PAYMENT_INSTRUMENT_SUCCESS =
  'REMOVE_PAYMENT_INSTRUMENT_SUCCESS';
export const REMOVE_PAYMENT_INSTRUMENT_ERROR =
  'REMOVE_PAYMENT_INSTRUMENT_ERROR';

export const PAYPAL_TOKEN_SETUP = 'PAYPAL_TOKEN_SETUP';
export const PAYPAL_TOKEN_SETUP_SUCCESS = 'PAYPAL_TOKEN_SETUP_SUCCESS';
export const PAYPAL_TOKEN_SETUP_ERROR = 'PAYPAL_TOKEN_SETUP_ERROR';

export const ADD_BILLING_ADDRESS = 'ADD_BILLING_ADDRESS';
export const ADD_BILLING_ADDRESS_SUCCESS = 'ADD_BILLING_ADDRESS_SUCCESS';
export const ADD_BILLING_ADDRESS_ERROR = 'ADD_BILLING_ADDRESS_ERROR';

export const EDIT_BILLING_ADDRESS = 'EDIT_BILLING_ADDRESS';
export const EDIT_BILLING_ADDRESS_ERROR = 'EDIT_BILLING_ADDRESS_ERROR';
export const EDIT_BILLING_ADDRESS_SUCCESS = 'EDIT_BILLING_ADDRESS_SUCCESS';

export const FETCH_WALLET = 'FETCH_WALLET';
export const FETCH_WALLET_SUCCESS = 'FETCH_WALLET_SUCCESS';
export const FETCH_WALLET_ERROR = 'FETCH_WALLET_ERROR';

export const PAY_PAL_LOADING_OFF = 'PAY_PAL_LOADING_OFF';
export const PAY_PAL_LOADING_ON = 'PAY_PAL_LOADING_ON';

export const CREATE_SINGLE_USE_AP_PAYMENT = 'CREATE_SINGLE_USE_AP_PAYMENT';
export const CREATE_SINGLE_USE_AP_PAYMENT_SUCCESS =
  'CREATE_SINGLE_USE_AP_PAYMENT_SUCCESS';
export const CREATE_SINGLE_USE_AP_PAYMENT_ERROR =
  'CREATE_SINGLE_USE_AP_PAYMENT_ERROR';

export const SET_NEW_PAYMENT_INSTRUMENT = 'SET_NEW_PAYMENT_INSTRUMENT';

// these are defined in the svc-cards bundle but would cause a circular dependency to import here
// this will ultimately be solved by removing the svc-cards bundle and building all the functionality
// into the wallet bundle to prevent having two sources of truth for SVC data in the redux store
const RELOAD_SVC_CARD_BALANCE_SUCCESS = 'RELOAD_SVC_CARD_BALANCE_SUCCESS';
const TRANSFER_SVC_CARD_BALANCE_SUCCESS = 'TRANSFER_SVC_CARD_BALANCE_SUCCESS';

export const configSelector = (state) => state.config;

export const walletSelector = (state) => state.wallet;

export const paymentInstrumentsSelector = createSelector(
  walletSelector,
  (wallet) => wallet?.data?.paymentInstruments ?? []
);

export const walletSVCsSelector = createSelector(
  walletSelector,
  (wallet) => wallet?.data?.walletSVCs ?? []
);

export const primaryWalletSVCSelector = createSelector(
  walletSVCsSelector,
  (cards) => cards.find((card) => card.isPrimary)
);

export const selectedOrPrimaryWalletSVCSelector = createSelector(
  walletSVCsSelector,
  routeParamsSelector,
  primaryWalletSVCSelector,
  (cards, routeParams, primaryCard) => {
    if (!cards) {
      return null;
    }
    return (
      cards.find((card) => card.cardId === routeParams.cardId) ||
      primaryCard ||
      null
    );
  }
);

export const morePaymentTypes = ['paypal', 'venmo'];
export const morePaymentTypesHash = {
  'paypal': 'PayPal',
  'venmo': 'Venmo',
};

export const filterValidPaymentInstruments = (paymentInstruments) => {
  // https://docs.starbucks.com/display/OAD/StarPayPaymentInstrument
  // VISA, Discover, Mastercard, AMEX, PayPal, Bakkt
  const validPaymentInstrumentsList = [
    'amex',
    'discover',
    'mastercard',
    'visa',
    'paypal',
    'venmo',
  ];

  return paymentInstruments.filter((instrument) =>
    validPaymentInstrumentsList.includes(instrument?.paymentType?.toLowerCase())
  );
};

export const validPaymentInstrumentsSelector = createSelector(
  paymentInstrumentsSelector,
  (paymentInstruments) =>
    // only return payment instrument types from known list
    filterValidPaymentInstruments(paymentInstruments)
);

export const creditDebitPaymentInstrumentsSelector = createSelector(
  validPaymentInstrumentsSelector,
  (paymentInstruments) =>
    paymentInstruments.filter(
      (instrument) =>
        !morePaymentTypes.includes(instrument?.paymentType?.toLowerCase())
    )
);

export const morePaymentInstrumentsSelector = createSelector(
  validPaymentInstrumentsSelector,
  (paymentInstruments) =>
    paymentInstruments.filter((instrument) =>
      morePaymentTypes.includes(instrument?.paymentType?.toLowerCase())
    )
);

export const hasPaymentInstrumentsSelector = createSelector(
  validPaymentInstrumentsSelector,
  (paymentInstruments) => paymentInstruments.length > 0
);

export const hasPayPalPaymentInstrumentSelector = createSelector(
  morePaymentInstrumentsSelector,
  (morePaymentInstruments) => {
    const payPalPaymentInstrument = morePaymentInstruments.find(
      (paymentInstrument) =>
        paymentInstrument?.paymentType?.toLowerCase() === 'paypal'
    );

    return Boolean(payPalPaymentInstrument);
  }
);

export const paymentInstrumentsLoadingSelector = createSelector(
  walletSelector,
  (wallet) => wallet?.loading
);

export const fetchingForPayPalSelector = createSelector(
  walletSelector,
  (wallet) => wallet?.fetchingForPayPal
);

export const paymentServicesPendingSelector = createSelector(
  fetchingForPayPalSelector,
  paymentInstrumentsLoadingSelector,
  (fetchingForPayPal, paymentInstrumentsLoading) =>
    fetchingForPayPal || paymentInstrumentsLoading
);

/* eslint-disable max-params */
export const shouldFetchWalletSelector = createSelector(
  hasShortSessionSelector,
  walletSelector,
  appTimeSelector,
  (hasShortSession, wallet, time) => {
    if (
      !hasShortSession ||
      !shouldUpdate(wallet, {
        staleTime: ms.minutes(15),
        now: time,
      })
    ) {
      return null;
    }
    return true;
  }
);
/* eslint-enable max-params */

export const allOrderPaymentInstrumentsSelector = createSelector(
  validPaymentInstrumentsSelector,
  (validPaymentInstruments) => {
    const addPaymentInstrument = {
      paymentType: ADD_PAYMENT,
      paymentInstrumentId: ADD_PAYMENT,
    };
    return [...validPaymentInstruments, addPaymentInstrument].filter(Boolean);
  }
);

export const allPaymentInstrumentsSelector = createSelector(
  validPaymentInstrumentsSelector,
  hasPayPalPaymentInstrumentSelector,
  (validPaymentInstruments, hasPayPal) => {
    const addPaymentInstrument = {
      paymentType: ADD_PAYMENT,
      paymentInstrumentId: ADD_PAYMENT,
    };
    // if user already has PayPal in their validPaymentInstruments remove
    // the "add" version of it from the list by setting payPalCheckoutPaymentInstrument
    // to null which will then be removed from the list using a Boolean filter
    const payPalCheckoutPaymentInstrument = hasPayPal
      ? null
      : {
          paymentType: ADD_PAYPAL,
          paymentInstrumentId: ADD_PAYPAL,
        };

    return [
      ...validPaymentInstruments,
      payPalCheckoutPaymentInstrument,
      addPaymentInstrument,
    ].filter(Boolean);
  }
);

export const defaultOrFirstPaymentInstrumentSelector = createSelector(
  validPaymentInstrumentsSelector,
  (paymentInstruments) => {
    const defaultPayment = paymentInstruments.find((pi) => pi.default);

    if (defaultPayment) {
      return defaultPayment;
    }

    return paymentInstruments?.[0] ?? {};
  }
);

// SFE
export const sfePaymentEnabledSelector = createSelector(
  configSelector,
  (config) => {
    const isEnabled = config?.universal?.sfePaymentEnabled ?? false;
    return Boolean(isEnabled.toString().toLowerCase() === 'true');
  }
);

const orderTotalIsZeroSelector = (state) => {
  return state?.ordering?.order?.pricing?.summary?.price === 0;
};

export const userHasFundingSourceSelector = createSelector(
  validPaymentInstrumentsSelector,
  walletSVCsSelector,
  (paymentInstruments, SVCs) => {
    if (paymentInstruments.length > 0) return true;
    return SVCs.some((SVC) => {
      return SVC?.balance?.amount > 0;
    });
  }
);

/* eslint-disable max-params */
export const shouldShowPaymentIntentSheetSelector = createSelector(
  orderTotalIsZeroSelector,
  sfePaymentEnabledSelector,
  signedInSelector,
  onlyExtendedSessionFoundSelector,
  userHasFundingSourceSelector,
  (
    orderTotalIsZero,
    sfePaymentEnabled,
    signedIn,
    onlyExtendedSessionFound,
    userHasFundingSource
  ) => {
    if (onlyExtendedSessionFound && !userHasFundingSource) {
      return false;
    }
    return (
      sfePaymentEnabled &&
      signedIn &&
      !userHasFundingSource &&
      !orderTotalIsZero
    );
  }
);
/* eslint-enable max-params */

// *** REDUCERS ***

const { reducer, initialState } = createSingleResourceReducer({
  startAction: FETCH_WALLET,
  markStaleWhen: [
    ADD_PAYMENT_INSTRUMENT_SUCCESS,
    ADD_SVC_CARD_SUCCESS,
    REMOVE_PAYMENT_INSTRUMENT_SUCCESS,
    REMOVE_SVC_CARD_SUCCESS,
    EDIT_BILLING_ADDRESS_SUCCESS,
    UPDATE_AUTO_RELOAD_SUCCESS,
    RELOAD_SVC_CARD_BALANCE_SUCCESS,
    TRANSFER_SVC_CARD_BALANCE_SUCCESS,
  ],
});

const extendedState = {
  ...initialState,
  fetchingForPayPal: false,
};

const extendedReducer = (state = extendedState, action) => {
  state = reducer(state, action);

  switch (action.type) {
    case ADD_PAYMENT_INSTRUMENT_SUCCESS:
    case ADD_PAYMENT_INSTRUMENT_ERROR:
    case FETCH_WALLET_SUCCESS:
    case FETCH_WALLET_ERROR:
    case PAY_PAL_LOADING_OFF:
      return { ...state, fetchingForPayPal: false };
    case PAY_PAL_LOADING_ON:
      return { ...state, fetchingForPayPal: true };
  }
  return state;
};

// *** ACTION CREATORS ***

// orderPaymentTypes to distinguish between SVCs and payment instruments when they are combined
const GENERIC = 'GENERIC';

const transformSVC = (svc) => {
  return {
    ...svc,
    cardImageUrl: svc?.imageUrls?.iosLargeHighRes,
    orderPaymentType: PAYMENT_TYPE_SVC,
    paySheetCardImageUrl: svc?.imageUrls?.iosImageStripMedium,
    thumbImageUrl: svc?.imageUrls?.imageIcon,
  };
};

const transformPaymentInstruments = (paymentInstrument) => {
  return {
    ...paymentInstrument,
    orderPaymentType: GENERIC,
  };
};

// testable
export const transformWallet = (wallet) => {
  const payload = {
    paymentInstruments:
      wallet?.starPayWallet?.paymentInstruments?.map(
        transformPaymentInstruments
      ) ?? [],
    walletSVCs:
      wallet?.starPayWallet?.storedValueCards?.map(transformSVC) ?? [],
  };
  return payload;
};

export const setPayPalLoadingState = (loadingStateAction) => (dispatch) => {
  dispatch({ type: loadingStateAction });
};

export const getPaymentInstrumentDiff = ({
  oldPaymentInstruments,
  newPaymentInstruments,
}) => {
  const validNewPaymentInstruments = filterValidPaymentInstruments(
    newPaymentInstruments
  );
  const validOldPaymentInstruments = filterValidPaymentInstruments(
    oldPaymentInstruments
  );
  const diff = validNewPaymentInstruments.filter((paymentInstrument) => {
    return !validOldPaymentInstruments.some((payment) => {
      return (
        paymentInstrument.paymentInstrumentId === payment.paymentInstrumentId
      );
    });
  });
  // diff from previous fetchWallet should only ever be 1 (add or removal)
  return diff[0];
};

export const fetchWallet =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    const setRiskProp = ({ variables, risk }) => {
      // set specific properties to avoid including the reputation property
      variables.starPayWalletInput.riskInput = {
        platform: risk.platform,
        market: risk.market,
        ccAgentName: risk.ccAgentName,
        deviceFingerprint: risk.deviceFingerprint,
      };
    };

    dispatch({ type: FETCH_WALLET });
    return gqlFetch({
      operationId: GET_STARPAY_WALLET,
      variables: {
        // add the risk object in setRiskProp to match required schema shape
        starPayWalletInput: {},
      },
      includeRisk: true,
      setRiskProp,
    })
      .then((data) => {
        const payload = transformWallet(data);
        const paymentInstruments = paymentInstrumentsSelector(getState());
        const paymentInstrumentDiff = getPaymentInstrumentDiff({
          oldPaymentInstruments: paymentInstruments,
          newPaymentInstruments: payload.paymentInstruments,
        });

        if (paymentInstrumentDiff) {
          dispatch({
            type: SET_NEW_PAYMENT_INSTRUMENT,
            payload: paymentInstrumentDiff,
          });
        }
        dispatch({ type: FETCH_WALLET_SUCCESS, payload });
      })
      .catch((error) => {
        dispatch({ type: FETCH_WALLET_ERROR, error });
        throw error;
      });
  };

// https://docs.starbucks.com/display/OAD/PayPalTokenSetup
// https://docs.starbucks.com/display/OAD/Paypal+Gateway+API#PaypalGatewayAPI-AgreementTokensRequest
export const getPayPalBillingAgreementToken = () => {
  return (dispatch, getState, { apiFetch }) => {
    const userEmail = userEmailSelector(getState());

    dispatch({ type: PAYPAL_TOKEN_SETUP });
    const operationId = GET_PAYPAL_SETUP_TOKEN;
    const url = `${getBffBaseUrl()}/bff/account/payment/wallet/${operationId}`;

    return apiFetch(url, {
      method: 'post',
      operationId,
      body: {
        variables: {
          payPalTokenSetupInput: {
            email: `${userEmail}`,
            description: 'token setup',
            redirectUrls: {
              returnUrl: 'https://www.paypal.com/checkoutnow/error',
              cancelUrl: 'https://www.paypal.com/checkoutnow/error',
            },
          },
          userId: 'me',
        },
      },
      includeRisk: false,
    })
      .then((response) => {
        const { billingAgreementSetupToken, transactionId } =
          response?.data?.payPalTokenSetup ?? {};
        const payload = {
          billingToken: billingAgreementSetupToken,
          transactionId,
        };
        dispatch({ type: PAYPAL_TOKEN_SETUP_SUCCESS });
        return payload;
      })
      .catch((error) => {
        dispatch({ type: PAYPAL_TOKEN_SETUP_ERROR, error });
        dispatch(showUnexpectedErrorNotification());
        throw error;
      });
  };
};

// This use of AddPaymentInstrument is specific to PayPal (not CCs)
// https://docs.starbucks.com/display/OAD/AddPaymentInstrument
export const addPayPalPaymentInstrument = (billingToken, transactionId) => {
  return (dispatch, getState, { apiFetch }) => {
    const firstName = firstNameSelector(getState());
    const lastName = lastNameSelector(getState());

    dispatch({ type: ADD_PAYMENT_INSTRUMENT });
    const operationId = CREATE_PAYMENT_INSTRUMENT;
    const url = `${getBffBaseUrl()}/bff/account/payment/wallet/${operationId}`;

    return apiFetch(url, {
      method: 'post',
      operationId,
      body: {
        variables: {
          paymentInstrumentInput: {
            accountNumber: billingToken,
            fullname: `${firstName} ${lastName}`,
            nickname: `${firstName} PayPal`,
            isOneTimeUse: false,
            isTemporary: false,
            merchantTransactionId: transactionId,
            paymentType: 'PAY_PAL',
          },
          userId: 'me',
        },
      },
      includeRisk: false,
    })
      .then((response) => {
        const newPaymentInstrument = response?.data?.addPaymentInstrument;
        dispatch({ type: ADD_PAYMENT_INSTRUMENT_SUCCESS });
        return newPaymentInstrument;
      })
      .catch((error) => {
        dispatch({ type: ADD_PAYMENT_INSTRUMENT_ERROR, error });
        dispatch(showUnexpectedErrorNotification());
        throw error;
      });
  };
};

// https://docs.starbucks.com/display/OAD/DeletePaymentInstrument
export const removePaymentInstrument =
  (paymentInstrumentId) =>
  (dispatch, getState, { apiFetch }) => {
    dispatch({ type: REMOVE_PAYMENT_INSTRUMENT, payload: paymentInstrumentId });
    const operationId = DELETE_PAYMENT_INSTRUMENT;
    const url = `${getBffBaseUrl()}/bff/account/payment/wallet/${operationId}`;

    return apiFetch(url, {
      method: 'post',
      operationId,
      body: {
        variables: {
          paymentId: paymentInstrumentId,
          userId: 'me',
        },
      },
      includeRisk: true,
    })
      .then((response) => {
        const payload = response?.data;
        dispatch({ type: REMOVE_PAYMENT_INSTRUMENT_SUCCESS, payload });
      })
      .catch((error) => {
        runSequentially(
          () => dispatch({ type: REMOVE_PAYMENT_INSTRUMENT_ERROR, error }),
          () => dispatch(showUnexpectedErrorNotification())
        );
      });
  };

export const createApplePaySingleUsePayment =
  ({ applePayPaymentInfo }) =>
  (dispatch, getState, { apiFetch }) => {
    dispatch({ type: CREATE_SINGLE_USE_AP_PAYMENT });

    const {
      billingContact,
      shippingContact,
      token: apToken,
    } = applePayPaymentInfo;

    const applePayToken = JSON.stringify(apToken.paymentData);
    const billingAddress = transformBillingAddress(
      billingContact,
      shippingContact
    );
    const { network: cardNetwork } = apToken.paymentMethod;

    const fullname = `${billingAddress.firstName} ${billingAddress.lastName}`;

    const body = {
      variables: {
        paymentInstrumentInput: {
          isTemporary: false,
          isOneTimeUse: true,
          fullname,
          source: 'APPLE_PAY',
          cardNetwork,
          token: applePayToken,
          billingAddress,
        },
      },
    };

    const operationId = CREATE_SINGLE_USE_APPLE_PAY_PAYMENT;

    const url = `${getBffBaseUrl()}/bff/account/payment/guest/${operationId}`;

    return apiFetch(url, {
      method: 'post',
      operationId,
      body,
      includeRisk: false,
    })
      .then((paymentResponse) => {
        const { paymentInstrumentId, paymentMethodId } =
          paymentResponse.data.addPaymentInstrument;
        dispatch({
          type: CREATE_SINGLE_USE_AP_PAYMENT_SUCCESS,
          payload: { paymentInstrumentId, paymentMethodId },
        });
        return { paymentInstrumentId, paymentMethodId };
      })
      .catch((error) => {
        const singlePaymentError = new Error(
          'Error while creating Apple Pay single use payment'
        );
        singlePaymentError.guestSessionExpired = error?.guestSessionExpired;
        singlePaymentError.httpStatus = error?.httpStatus;
        dispatch({
          type: CREATE_SINGLE_USE_AP_PAYMENT_ERROR,
          singlePaymentError,
        });
        throw singlePaymentError;
      });
  };

// *** utilities ***

export const getPaymentInstrumentById = ({
  paymentInstrumentId,
  paymentInstruments,
}) => {
  return paymentInstruments.find(
    (paymentInstrument) =>
      paymentInstrument.paymentInstrumentId === paymentInstrumentId
  );
};

// *** APP ***

export default {
  name: 'wallet',
  reducer: extendedReducer,
  effects: [
    {
      selector: shouldFetchWalletSelector,
      actionCreator: fetchWallet,
    },
  ],
  asyncActions: [
    FETCH_WALLET,
    ADD_PAYMENT_INSTRUMENT,
    REMOVE_PAYMENT_INSTRUMENT,
  ],
  persistAfter: [
    FETCH_WALLET_SUCCESS,
    ADD_PAYMENT_INSTRUMENT_SUCCESS,
    REMOVE_PAYMENT_INSTRUMENT_SUCCESS,
  ],
};
