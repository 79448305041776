const createActionTypes = (typeArray) => {
  const types = {};
  typeArray.forEach((type) => {
    const success = `${type}_SUCCESS`;
    const error = `${type}_ERROR`;
    types[type] = type;
    types[success] = success;
    types[error] = error;
  });
  return types;
};

export default createActionTypes;
