import { isArray, isPlainObject } from 'lodash';

import {
  startState,
  successState,
  errorState,
  mutationErrorState,
  staleState,
} from './item-state-changers';

const START = 'START';
const ERROR = 'ERROR';
const SUCCESS = 'SUCCESS';
const MUTATE_ERROR = 'MUTATE_ERROR';
const STALE = 'STALE';

const getMapType = ({
  startAction,
  mutateAction,
  markStaleWhen,
  alternativeSuccessActions,
}) => {
  const map = {
    [startAction]: START,
    [`${startAction}_SUCCESS`]: SUCCESS,
    [`${startAction}_ERROR`]: ERROR,
  };
  if (mutateAction) {
    Object.assign(map, {
      [`${mutateAction}`]: START,
      [`${mutateAction}_SUCCESS`]: SUCCESS,
      [`${mutateAction}_ERROR`]: MUTATE_ERROR,
    });
  }

  const currentType = (type) => {
    if (map.hasOwnProperty(type)) {
      return map[type];
    }
    if (markStaleWhen?.indexOf(type) > -1) {
      return STALE;
    }

    if (alternativeSuccessActions?.indexOf(type) > -1) {
      return SUCCESS;
    }

    return type;
  };

  return currentType;
};

const createSingleResourceReducer = (opts) => {
  opts = Object.assign(
    {
      idProp: 'id',
    },
    opts
  );

  const mapType = getMapType(opts);

  const initialState = {
    data: null,
    loading: false,
    lastFetch: null,
    lastError: null,
    mutationError: null,
  };

  return {
    initialState,
    // eslint-disable-next-line max-statements, complexity
    reducer: (state = initialState, action) => {
      switch (mapType(action.type)) {
        case START: {
          return startState(state);
        }
        case SUCCESS: {
          const { payload } = action;
          let resultData = payload;

          // At times you want to merge in data instead of entirely
          // replacing it. Whether you're building an "additive cache",
          // aggregating requests when paging through results, or in cases
          // where a partial failure led to only getting a partial response
          // from the API.
          if (action.merge) {
            if (isArray(state.data) || isArray(resultData)) {
              resultData = state.data ? state.data.slice() : [];

              // build a litle lookup table so we can replace objects by their
              // index without excessive looping
              const idPropMap = resultData.reduce((acc, item, index) => {
                const id = item[opts.idProp];
                acc[id] = index;
                return acc;
              }, {});
              const newData = payload || [];

              newData.forEach((item) => {
                const id = item[opts.idProp];
                const existingIndex = idPropMap[id];
                // we have it so replace it
                if (existingIndex || existingIndex === 0) {
                  resultData[existingIndex] = item;
                } else {
                  resultData.push(item);
                }
              });
            } else if (isPlainObject(state.data) || isPlainObject(resultData)) {
              resultData = { ...state.data, ...resultData };
            }
          }

          // If we get a partial success response we leave staleness numbers unchanged
          // but we update the last error to avoid a loop
          if (action.partialSuccess) {
            return {
              ...state,
              data: resultData,
              loading: false,
              lastError: Date.now(),
            };
          }

          return successState(state, resultData);
        }
        case ERROR: {
          return errorState(state, action.error);
        }
        case MUTATE_ERROR: {
          return mutationErrorState(state, action.error);
        }
        case STALE: {
          return staleState(state);
        }
      }
      return state;
    },
  };
};

export default createSingleResourceReducer;
