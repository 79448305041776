import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import { setPrivacyPermissions } from 'shared/app/state/action-creators/privacy-permissions';

import { sharedCallsToAction } from 'shared/app/messages/ctas';
import messages from './messages';

export const TERMS_PRIVACY_CONFIRM_CHANGE_DIALOG_ID =
  'PrivacyPermissionsConfirmChangeDialogId';

const PrivacyPermissionsConfirmChangeDialog = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  return (
    <DialogWithMessages
      confirmText={formatMessage(sharedCallsToAction.confirm)}
      onConfirm={() => {
        dispatch(
          setPrivacyPermissions({
            optIn: true,
          })
        ).catch(() => {
          // do nothing ?
        });
      }}
    >
      <Heading
        className="text-semibold mb3"
        id={TERMS_PRIVACY_CONFIRM_CHANGE_DIALOG_ID}
        size="lg"
        tagName="h2"
      >
        {formatMessage(messages.privacyPermissionsConfirmChangeHeading)}
      </Heading>
      <p>{formatMessage(messages.privacyPermissionsConfirmChangeText)}</p>
    </DialogWithMessages>
  );
};

export default PrivacyPermissionsConfirmChangeDialog;
