import { createSelector } from 'reselect';
import { configSelector } from '../../shell/state/selectors/config';

export const addressFieldNamesSelector = createSelector(
  configSelector,
  (config) => config.addressFieldNames
);

export const countrySubdivisionsSelector = createSelector(
  configSelector,
  (config) => config.countrySubdivisions
);

export const numericPostalCodeSelector = createSelector(
  configSelector,
  (config) => config.numericPostalCode
);
