import qs from 'query-string';
import { getWindow } from 'shared/app/utils/window.js';
import { IS_BROWSER } from 'shared/app/shell';

export const getQueryFromWindow = () => {
  const win = IS_BROWSER ? getWindow() : null;
  const query = win ? qs.parse(win.location.search) : null;
  return query;
};

export default getQueryFromWindow;
