import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addNotification } from 'shared/app/shell';

import { commonMessages } from 'shared/app/messages';
import messages from './messages';

const CopyLinkCta = ({
  color,
  TagName,
  className,
  copyLink,
  isEmail,
  recipientName,
}) => {
  const dispatch = useDispatch();
  const originalMessage = (
    <FormattedMessage {...messages.copyLinkCta} values={{ isEmail }} />
  );
  const messageOnClick = <FormattedMessage {...commonMessages.copied} />;

  const [ctaMessage, setCtaMessage] = useState(originalMessage);

  const changeMessage = () => {
    setCtaMessage(messageOnClick);

    setTimeout(() => {
      setCtaMessage(originalMessage);
    }, 3000);
  };

  return (
    <TagName
      className={className}
      color={color}
      onClick={() => {
        navigator.clipboard.writeText(copyLink);
        changeMessage();
        dispatch(
          addNotification(null, {
            messageId: commonMessages.linkCopied.id,
            messageValues: {
              isEmail,
              recipientName,
            },
            autoClose: 3000,
          })
        );
      }}
    >
      {ctaMessage}
    </TagName>
  );
};

export default CopyLinkCta;
