import React from 'react';
import Rule from '@starbucks-web/pattern-library/lib/components/rule';

import SummarySuccess from './success';
import SummaryPending from './pending';

import {
  SUCCESS_PURCHASE_STATUS,
  PENDING_PURCHASE_STATUS,
} from '../../constants';

const PurchaseStatus = ({
  purchaseStatus,
  numberOfRecipients,
  message,
  largeImageUrl,
  altText,
  checkAgainOnClick,
  deliveryMethod,
}) => {
  if (purchaseStatus === SUCCESS_PURCHASE_STATUS) {
    return (
      <React.Fragment>
        <SummarySuccess
          altText={altText}
          largeImageUrl={largeImageUrl}
          message={message}
          numberOfRecipients={numberOfRecipients}
        />
        <Rule className="my3" visualStyle="lightGreen" />
      </React.Fragment>
    );
  } else if (purchaseStatus === PENDING_PURCHASE_STATUS) {
    return (
      <React.Fragment>
        <SummaryPending
          checkAgainOnClick={checkAgainOnClick}
          deliveryMethod={deliveryMethod}
          numberOfRecipients={numberOfRecipients}
        />
        <Rule className="my3" visualStyle="lightGreen" />
      </React.Fragment>
    );
  }
  return null;
};

export default PurchaseStatus;
