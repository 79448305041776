import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';
import { getStaticMapUrl } from 'shared/app/utils/map-utils';

import { CONFIRM_STORE_MENU } from 'store-locator/app/state/actions/types';

import messages from './messages';

const MAP_IMAGE_WIDTH = 343;
const MAP_IMAGE_HEIGHT = 152;

export const confirmStoreDialogHeadingId = 'confirmStoreDialogHeadingId';

const ConfirmStoreDialog = ({
  confirmStore,
  goToCart,
  goToStoreLocator,
  selectedStore,
}) => {
  const { formatMessage } = useIntl();
  const staticMapImageUrl = getStaticMapUrl({
    width: MAP_IMAGE_WIDTH,
    height: MAP_IMAGE_HEIGHT,
    selectedStore,
  });

  return (
    <DialogWithMessages
      cancelText={formatMessage(messages.changeStore)}
      confirmText={formatMessage(sharedCallsToAction.confirm)}
      imageAltText=""
      imageUrl={staticMapImageUrl}
      onCancel={goToStoreLocator}
      onConfirm={() => {
        confirmStore();
        goToCart();
      }}
    >
      <Heading
        className="pb3"
        id={confirmStoreDialogHeadingId}
        size="md"
        tagName="h2"
      >
        {formatMessage(messages.title)}
      </Heading>
      <p className="text-bold">{selectedStore.name}</p>
      {(selectedStore.address.lines || []).map((line) => (
        <p key={line}>{line}</p>
      ))}
    </DialogWithMessages>
  );
};

const mapDispatch = (dispatch) => ({
  confirmStore: () => dispatch({ type: CONFIRM_STORE_MENU }),
});

export default connect(null, mapDispatch)(ConfirmStoreDialog);
