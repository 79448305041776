import createActionTypes from 'shared/app/state/create-action-types';

export const {
  REQUEST_LOCATION_BY_NUMBER,
  REQUEST_LOCATION_BY_NUMBER_SUCCESS,
  REQUEST_LOCATION_BY_NUMBER_ERROR,
} = createActionTypes(['REQUEST_LOCATION_BY_NUMBER']);

// terms and privacy status
export const {
  GET_TERMS_PRIVACY_STATUS,
  GET_TERMS_PRIVACY_STATUS_SUCCESS,
  GET_TERMS_PRIVACY_STATUS_ERROR,
} = createActionTypes(['GET_TERMS_PRIVACY_STATUS']);

export const {
  SET_TERMS_PRIVACY_STATUS,
  SET_TERMS_PRIVACY_STATUS_SUCCESS,
  SET_TERMS_PRIVACY_STATUS_ERROR,
} = createActionTypes(['SET_TERMS_PRIVACY_STATUS']);

export const {
  GET_PRIVACY_PERMISSIONS_STATUS,
  GET_PRIVACY_PERMISSIONS_STATUS_SUCCESS,
  GET_PRIVACY_PERMISSIONS_STATUS_ERROR,
} = createActionTypes(['GET_PRIVACY_PERMISSIONS_STATUS']);

export const {
  SET_PRIVACY_PERMISSIONS_STATUS,
  SET_PRIVACY_PERMISSIONS_STATUS_SUCCESS,
  SET_PRIVACY_PERMISSIONS_STATUS_ERROR,
} = createActionTypes(['SET_PRIVACY_PERMISSIONS_STATUS']);

export const { GUEST_SIGN_IN, GUEST_SIGN_IN_SUCCESS, GUEST_SIGN_IN_ERROR } =
  createActionTypes(['GUEST_SIGN_IN']);

export const {
  CREATE_GUEST_PROFILE,
  CREATE_GUEST_PROFILE_SUCCESS,
  CREATE_GUEST_PROFILE_ERROR,
} = createActionTypes(['CREATE_GUEST_PROFILE']);

export const {
  FETCH_MODIFIER_AVAILABILITY,
  FETCH_MODIFIER_AVAILABILITY_SUCCESS,
  FETCH_MODIFIER_AVAILABILITY_ERROR,
} = createActionTypes(['FETCH_MODIFIER_AVAILABILITY']);

export const {
  DELETE_GUEST_COOKIES,
  DELETE_GUEST_COOKIES_SUCCESS,
  DELETE_GUEST_COOKIES_ERROR,
} = createActionTypes(['DELETE_GUEST_COOKIES']);

export const CONTINUE_AS_GUEST = 'CONTINUE_AS_GUEST';
export const PICKUP_OVERLAY_SEEN = 'PICKUP_OVERLAY_SEEN';
export const CONFIRM_ORDERING_UNAVAILABLE = 'CONFIRM_ORDERING_UNAVAILABLE';
