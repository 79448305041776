import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { privacyStatementUrlSelector } from 'shared/app/state/selectors/privacy-and-terms.js';
import { isExternalLink } from 'shared/app/utils/is-external-link';

const PrivacyStatementLink = ({
  baseUrl,
  className,
  privacyStatementOnClick,
  privacyStatementUrl,
}) => (
  <a
    className={className}
    data-e2e="privacyStatementLink"
    href={
      !isExternalLink(privacyStatementUrl)
        ? `${baseUrl}${privacyStatementUrl}`
        : privacyStatementUrl
    }
    onClick={privacyStatementOnClick}
  >
    <FormattedMessage
      defaultMessage="Privacy Statement"
      description="Label for link to privacy statement"
      id="shared.privacyStatementLink"
    />
  </a>
);

PrivacyStatementLink.propTypes = {
  baseUrl: PropTypes.string,
  privacyStatementOnClick: PropTypes.func,
};

PrivacyStatementLink.defaultProps = {
  baseUrl: '',
};

const select = (state) => ({
  privacyStatementUrl: privacyStatementUrlSelector(state),
});

export default connect(select)(PrivacyStatementLink);
