import { createSelector } from 'reselect';

import getReturnUrlFromWindow from 'shared/app/utils/get-return-url-from-window';
import getReturnUrl from 'shared/universal/get-return-url';

import { queryParamsSelector } from './routes';

export const includesBasePath = ({ urlBasePath, returnUrl }) => {
  if (!returnUrl || !urlBasePath) {
    return false;
  }
  return returnUrl.includes(urlBasePath);
};

const returnUrl = getReturnUrlFromWindow();

export const configSelector = (state) => state.config;

export const promoRedeemBasePathSelector = createSelector(
  configSelector,
  (config) => config.promoRedeemBasePath
);

export const isPromoRedeemRequestSelector = createSelector(
  promoRedeemBasePathSelector,
  (promoRedeemBasePath) =>
    includesBasePath({ urlBasePath: promoRedeemBasePath, returnUrl })
);

export const getPartnershipSelector = createSelector(
  configSelector,
  queryParamsSelector,
  (config, queryParams) => {
    const caseInsensitiveReturnUrl = getReturnUrl(queryParams);
    const partnership = config?.signinPartnerships?.find((item) => {
      if (caseInsensitiveReturnUrl?.indexOf(item.url) > -1 && item.enabled) {
        return item;
      }
      return null;
    });
    return partnership;
  }
);
