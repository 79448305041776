import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import ExternalLink from 'shared/app/components/external-link';

import { privacyStatementUrlSelector } from 'shared/app/state/selectors/privacy-and-terms';
import { commonMessages } from 'shared/app/messages';

const PrivacyNoticeLink = ({ className }) => {
  const { formatMessage } = useIntl();
  const privacyStatementUrl = useSelector(privacyStatementUrlSelector);
  return (
    <ExternalLink
      className={className}
      data-e2e="privacy-notice-link"
      href={privacyStatementUrl}
    >
      {formatMessage(commonMessages.privacyNotice)}
    </ExternalLink>
  );
};

export default PrivacyNoticeLink;
