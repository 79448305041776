import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import Heading from '@starbucks-web/pattern-library/lib/components/heading/';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import { sharedCallsToAction } from 'shared/app/messages/ctas';
import DialogWithMessages from '../dialog-with-messages';
import {
  currentRouteSelector,
  previousRouteSelector,
} from '../../state/selectors/routes';

import { CONFIRM_ORDERING_UNAVAILABLE } from '../../state/action-creators/types';

import { inStandaloneStoreLocatorSelector } from 'store-locator/app/state/selectors';
import { confirmStore } from 'store-locator/app/state/actions/confirm-store';

import messages from './messages';
export const ORDERING_UNAVAILABLE_DIALOG_ID = 'OrderingUnavailableDialogId';

const OrderingUnavailableDialog = ({
  selectedStore,
  isStoreOpen,
  showAccountRequiredMessage,
  rendersOnMenu = false,
}) => {
  const { formatMessage } = useIntl();
  const { closeModal } = useModalContext();

  const dispatch = useDispatch();
  const inStandaloneStoreLocator = useSelector(
    inStandaloneStoreLocatorSelector
  );
  const currentRoute = useSelector(currentRouteSelector);
  const previousRoute = useSelector(previousRouteSelector);
  const { storeNumber } = selectedStore?.store;

  const headerMessage = () => {
    if (!isStoreOpen) return messages.storeClosedHeader;
    if (showAccountRequiredMessage) return messages.accountRequiredHeader;
    return messages.mopUnavailableHeader;
  };

  const bodyMessage = () => {
    const storeName = selectedStore?.store?.name;
    const bodyCopy = formatMessage(messages.body, {
      hasStoreName: Boolean(storeName),
      storeNameSpan: <span className="text-semibold">{storeName}</span>,
    });
    return showAccountRequiredMessage
      ? formatMessage(messages.accountRequiredBody)
      : bodyCopy;
  };

  const cancelMessage = () => {
    if (rendersOnMenu && !showAccountRequiredMessage)
      return messages.seeTheMenu;
    if (rendersOnMenu && showAccountRequiredMessage)
      return sharedCallsToAction.continue;
    if (showAccountRequiredMessage) return sharedCallsToAction.nevermind;
    return sharedCallsToAction.gotIt;
  };

  const confirmMessage = () => {
    if (rendersOnMenu && !showAccountRequiredMessage)
      return sharedCallsToAction.gotIt;
    if (rendersOnMenu && showAccountRequiredMessage)
      return sharedCallsToAction.nevermind;
    if (showAccountRequiredMessage) return sharedCallsToAction.continue;
    return messages.seeTheMenu;
  };

  const confirmOrderingUnavailable = () => {
    dispatch({ type: CONFIRM_ORDERING_UNAVAILABLE, payload: { storeNumber } });
  };

  const onConfirm = () => {
    if (rendersOnMenu) {
      confirmOrderingUnavailable();
      return dispatch(push('/menu/store-locator'));
    }
    if (inStandaloneStoreLocator) {
      return (window.location.href = `/menu?storeNumber=${storeNumber}&distance=${selectedStore.distance}&confirmedOrderingUnavailable=${storeNumber}`);
    }
    confirmOrderingUnavailable();
    return dispatch(confirmStore(selectedStore, currentRoute, previousRoute));
  };

  return (
    <DialogWithMessages
      cancelText={formatMessage(cancelMessage())}
      confirmText={formatMessage(confirmMessage())}
      onCancel={() => {
        confirmOrderingUnavailable();
        closeModal();
      }}
      onConfirm={onConfirm}
    >
      <Heading
        className="text-lg text-semibold mb3"
        id={ORDERING_UNAVAILABLE_DIALOG_ID}
        tagName="h2"
      >
        {formatMessage(headerMessage())}
      </Heading>
      <p className="text-sm">{bodyMessage()}</p>
    </DialogWithMessages>
  );
};

export default OrderingUnavailableDialog;
