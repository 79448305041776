import { getWindow } from 'shared/app/utils/window';
import { startsWith } from 'lodash';
import IS_BROWSER from './is-browser';

/**
 * Creates a domain relative redirect (via window.location.href)
 * to the signin page, ie. requests from `starbucks.com` will go to
 * `starbucks.com/account/signin`, and requests from `app.starbucks.com` will go to
 * `app.starbucks.com/account/signin`. If a return url is not provided,
 * the current page will be used as the return url.
 */

export const getBaseUrl = () => {
  if (!IS_BROWSER) {
    return '';
  }
  const baseUrl = `${getWindow().location.protocol}//${
    getWindow().location.host
  }`;
  const LOCAL_CORE_APP_PATH = '/app';
  const isLocalCoreApp = startsWith(
    getWindow().location.pathname,
    LOCAL_CORE_APP_PATH
  );
  if (isLocalCoreApp) {
    return `${baseUrl}${LOCAL_CORE_APP_PATH}`;
  }
  return baseUrl;
};

export const getSigninUrlWithReturnUrl = (returnUrl) => {
  // we could simply redirect to a relative url, ie. `/account/signin?returnUrl=foo`
  // but when running locally or running PR func tests we'd have a slight inconsistency
  // in the core app.
  // getBaseUrl() creates a base url that works everywhere.
  const baseUrl = getBaseUrl();

  if (typeof returnUrl !== 'string') {
    returnUrl = getWindow().location.href;
  }
  const encodedReturnUrl = encodeURIComponent(returnUrl);
  return `${baseUrl}/account/signin?returnUrl=${encodedReturnUrl}`;
};

export default (opts = { returnUrl: null }) => {
  getWindow().location.href = getSigninUrlWithReturnUrl(opts.returnUrl);
};
