import { defineMessages } from 'react-intl';

export const sharedNotificationMessages = defineMessages({
  errorNotification: {
    id: 'shared.notifications.errorNotification',
    defaultMessage: 'Error notification',
    description: 'Accessible label for error notifications shown to the user',
  },
  notification: {
    id: 'shared.notifications.notification',
    defaultMessage: 'Notification',
    description: 'Accessible label for notifications shown to the user',
  },
  unexpectedError: {
    id: 'shared.notifications.unexpectedError',
    defaultMessage: 'Something went wrong on our end. 😳',
    description: 'Notification message displayed on unexpected errors',
  },
  somethingWentWrong: {
    id: 'shared.notifications.somethingWentWrong',
    defaultMessage: 'Something went wrong on our end',
    description: 'Title of error notification on some unexpected errors',
  },
  tryAgainLater: {
    id: 'shared.notifications.tryAgainLater',
    defaultMessage: 'Try again or check back later.',
    description: 'Message of error notification on some unexpected errors',
  },
  offlineIndicator: {
    defaultMessage: 'You are offline',
    description: "message alerting the user they're offline",
    id: 'shared.notifications.offlineIndicator',
  },
  beBackToNormalSoon: {
    id: 'shared.notifications.beBackToNormalSoon',
    description: 'Message when there is an error',
    defaultMessage:
      'Things should be back to normal soon. Try again or check back later.',
  },
});
