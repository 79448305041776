// Helpers, wrappers around config.
import config from 'config';
import exEnv from 'exenv';

const IS_BROWSER = exEnv.canUseDOM;

// Determine which `bffBaseUrl` value to return based on server vs. client.
export const getBffBaseUrl = () =>
  config.get(`${IS_BROWSER ? 'universal' : 'app'}.bffBaseUrl`);

export const getClientLoggingBit = () =>
  config.get('universal.clientErrorLogging');
