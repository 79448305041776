import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './tracker-goal-marker.cssm';

const TrackerGoalMarker = ({
  animationDelay,
  animationDuration,
  complete,
  goalAchievedOnUpdate,
  isRewardsPath,
  left,
  otherClasses,
  totalStarsToEarn,
}) => {
  const [animation, setAnimationState] = useState({
    start: false,
    isComplete: false,
  });

  const defaultBgColor = isRewardsPath ? 'bg-neutralCool' : 'bg-white';
  const useColoredBgColor =
    (complete && animation.isComplete) || (complete && !animation.start);

  useEffect(() => {
    if (!animation.isComplete && !animation.start && goalAchievedOnUpdate) {
      setAnimationState({ start: true, isComplete: false });
    }
    if (animation.start) {
      // allow colored bg color to show again once animation is completed
      setTimeout(
        () => setAnimationState({ start: false, isComplete: true }),
        animationDelay + animationDuration
      );
    }
    // clean up so animation will run again if user goes below a level and then back up
    if (animation.isComplete && !goalAchievedOnUpdate) {
      setAnimationState({ start: false, isComplete: false });
    }
  });

  return (
    <div
      className={classnames(styles.goalMarker, {
        [styles.sparkle]: animation.start,
      })}
      style={{ left: `${left}%` }}
    >
      <div
        className={classnames(
          'absoluteSpread',
          styles.goalMarkerBubble,
          otherClasses,
          {
            [styles.animate]: animation.start,
            [styles.goldBubble]: useColoredBgColor,
            [defaultBgColor]: !complete || animation.start,
          }
        )}
      />
      <div
        aria-hidden="true"
        className={classnames('text-sm text-bold', styles.goalMarkerText, {
          [styles.animateText]: animation.start,
        })}
      >
        {totalStarsToEarn}
      </div>
    </div>
  );
};

TrackerGoalMarker.propTypes = {
  animationDelay: PropTypes.number.isRequired,
  animationDuration: PropTypes.number.isRequired,
  complete: PropTypes.bool.isRequired,
  goalAchievedOnUpdate: PropTypes.bool.isRequired,
  /*
   * Color scheme is slightly different on the rewards page vs the stream page
   */
  isRewardsPath: PropTypes.bool.isRequired,
  left: PropTypes.number.isRequired,
  totalStarsToEarn: PropTypes.number.isRequired,
};

export default TrackerGoalMarker;
