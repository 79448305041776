import { defineMessages } from 'react-intl';

export default defineMessages({
  changeStore: {
    defaultMessage: 'Change store',
    description: 'Indicates that the user wants to change the selected store',
    id: 'shared.confirmStoreDialog.cancel',
  },
  title: {
    defaultMessage: 'Confirm pickup store',
    description: 'Tells the user to confirm the preselected store.',
    id: 'shared.confirmStoreDialog.title',
  },
});
