import {
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_ERROR,
} from '../../actions';
import { GET_USER } from 'shared/universal/gql-operation-ids';
import { setUserProfileCanonicalAttributes } from 'shared/app/utils/uo-tracking';

export const fetchProfile =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: FETCH_PROFILE });
    return gqlFetch({
      operationId: GET_USER,
      allowNonFatalErrors: true,
    })
      .then((userResult) => {
        setUserProfileCanonicalAttributes(userResult.user);
        dispatch({
          type: FETCH_PROFILE_SUCCESS,
          payload: userResult.user,
        });
      })
      .catch((error) =>
        dispatch({
          type: FETCH_PROFILE_ERROR,
          error,
        })
      );
  };
