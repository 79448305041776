import { REWARD_REDEEMED } from 'ordering/app/state/actions/types';

import {
  shouldFetchProfileSelector,
  shouldFetchOnboardingFlagsSelector,
  allSessionsExpiredSelector,
  shouldFetchPrivacyPermissionsSelector,
} from './state/selectors';

import reducer from './state/reducers';

import {
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_ONBOARDING_FLAGS,
  FETCH_ONBOARDING_FLAGS_SUCCESS,
  FETCH_USER_MFA_FACTORS,
  FETCH_USER_MFA_FACTORS_SUCCESS,
} from './actions';

import { fetchProfile, fetchOnboardingFlags } from './state/action-creators';

import {
  SET_PRIVACY_PERMISSIONS_STATUS_SUCCESS,
  GET_PRIVACY_PERMISSIONS_STATUS,
  GET_PRIVACY_PERMISSIONS_STATUS_SUCCESS,
} from 'shared/app/state/action-creators/types';
import { getPrivacyPermissions } from 'shared/app/state/action-creators/privacy-permissions';

import { doLogout } from 'shared/app/shell/state/action-creators/app-lifecycle';

const effects = [
  {
    selector: shouldFetchProfileSelector,
    actionCreator: fetchProfile,
  },
  {
    selector: shouldFetchOnboardingFlagsSelector,
    actionCreator: fetchOnboardingFlags,
  },
  {
    selector: allSessionsExpiredSelector,
    actionCreator: doLogout,
  },
  {
    selector: shouldFetchPrivacyPermissionsSelector,
    actionCreator: getPrivacyPermissions,
  },
];

export default {
  name: 'user',
  reducer,
  effects,
  asyncActions: [
    FETCH_PROFILE,
    FETCH_ONBOARDING_FLAGS,
    FETCH_USER_MFA_FACTORS,
    GET_PRIVACY_PERMISSIONS_STATUS,
  ],
  persistAfter: [
    FETCH_PROFILE_SUCCESS,
    FETCH_ONBOARDING_FLAGS_SUCCESS,
    REWARD_REDEEMED,
    FETCH_USER_MFA_FACTORS_SUCCESS,
    GET_PRIVACY_PERMISSIONS_STATUS_SUCCESS,
    SET_PRIVACY_PERMISSIONS_STATUS_SUCCESS,
  ],
};

export * from './state/selectors';

export * from './actions';

export * from './state/action-creators';
