import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';
import { FETCH_PROFILE, FETCH_PROFILE_SUCCESS } from '../../actions';

import {
  MUTATE_PERSONAL_SUCCESS,
  MUTATE_PARTNER_SUCCESS,
} from 'account-personal/app/actions';
import {
  ADD_MFA_PHONE_SUCCESS,
  CONFIRM_EMAIL_CODE_SUCCESS,
} from 'account-settings/app/actions';

import { REWARD_REDEEMED } from 'ordering/app/state/actions/types';
import { ADD_SVC_CARD_SUCCESS } from 'shared/app/bundles/svc-cards/actions';

const { reducer: partialProfileReducer, initialState: profileInitialState } =
  createSingleResourceReducer({
    startAction: FETCH_PROFILE,
    markStaleWhen: [
      MUTATE_PERSONAL_SUCCESS,
      MUTATE_PARTNER_SUCCESS,
      ADD_SVC_CARD_SUCCESS,
      ADD_MFA_PHONE_SUCCESS,
      CONFIRM_EMAIL_CODE_SUCCESS,
    ],
  });

export const reducer = (state, action) => {
  // cache starBalance before updating with new payload
  const previousStarBalance =
    state?.data?.loyaltyProgram?.progress?.starBalance;

  state = partialProfileReducer(state, action);
  // previousStarBalance could be undefined
  if (!isNaN(previousStarBalance) && action.type === FETCH_PROFILE_SUCCESS) {
    const newStarBalance = state?.data?.loyaltyProgram?.progress?.starBalance;
    // when starBalance changes, reset redeemedRewards to 0
    if (previousStarBalance !== newStarBalance) {
      return Object.assign({}, state, { redeemedRewards: 0 });
    }
  }

  if (action.type === REWARD_REDEEMED) {
    return Object.assign({}, state, {
      redeemedRewards: state.redeemedRewards + 1,
    });
  }

  return state;
};

// export the initialState createSingleResourceReducer returns for external consumption
export const initialState = Object.assign({}, profileInitialState, {
  redeemedRewards: 0,
});
