'use strict';

// returns the value of a given query parameter ignoring the case sensitivity

module.exports = (query, paramName) => {
  if (!query || !paramName) {
    return null;
  }
  const paramNameLowerCase = paramName.toLowerCase();
  const paramKey = Object.keys(query).find(
    (key) => key.toLowerCase() === paramNameLowerCase
  );

  if (!paramKey) {
    return null;
  }

  return query[paramKey];
};
