import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useKeyPress from '@starbucks-web/pattern-library/lib/utils/use-key-press';

import { sharedNotificationMessages } from 'shared/app/messages';
import { dismissNotification as dismissAction } from 'shared/app/shell/state/action-creators/notifications';
import DeliTicket from './deli-ticket';
import Toast from './toast';
import { getLocalizedText } from './utils';

const ENTER_TIMEOUT = 200;
const EXIT_TIMEOUT = 100;

/* eslint-disable-next-line max-statements */
export const Notification = ({ dismissNotification, notification }) => {
  const intl = useIntl();
  const { body, cancelText, confirmText, title } = getLocalizedText(
    notification,
    intl.formatMessage
  );
  const notificationId = notification?.id;
  const isError = notification?.type === 'error';
  const isSuccess = ['confirm', 'generic', 'success'].includes(
    notification?.type
  );

  // autoClose timer uses a ref not because it refers
  // to a DOM element, but because the current value of a ref is persisted
  // from render to render.
  const autoCloseTimerRef = useRef(null);
  const [returnFocusTarget, setReturnFocusTarget] = useState(null);
  const [currentMessageId, setCurrentMessageId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [wasFocused, setWasFocused] = useState(false);

  useKeyPress({ targetKeyValue: 'Escape', onKeyUp: () => setIsOpen(false) });

  const setTimers = () => {
    // clear autoClose timer in case previous notification had started one
    clearTimeout(autoCloseTimerRef.current);
    if (notification?.autoClose) {
      // start a new timer
      autoCloseTimerRef.current = setTimeout(
        () => setIsOpen(false),
        notification.autoClose
      );
    }
  };

  useEffect(() => {
    if (notificationId && !isOpen && !wasFocused) {
      // this is a new notification
      // capture currently focused element so we can return focus to it on close
      setReturnFocusTarget(document.activeElement);
      setCurrentMessageId(notificationId);
      setIsOpen(true);
      setTimers();
    }
    if (isOpen && !wasFocused) {
      // setting this state allows us to distinguish when the user has closed
      // a notification in the next condition.
      setWasFocused(true);
    }
    if (wasFocused && !isOpen) {
      // this state happens when closed by clicking close button or cta or escape key
      dismissNotification();
      setWasFocused(false);
      returnFocusTarget.focus();
    }
    if (!isOpen && !notificationId) {
      // cleanup phase
      setWasFocused(false);
      setCurrentMessageId(null);
      setReturnFocusTarget(null);
    }
  }, [notificationId, isOpen, wasFocused]);

  useEffect(() => {
    // Handle the edge case of a new notification being set in redux state
    // while there is already one showing.
    if (currentMessageId && currentMessageId !== notificationId) {
      setCurrentMessageId(notificationId);
      setTimers();
    }
  }, [currentMessageId, notificationId]);

  return (
    <TransitionGroup>
      {isOpen && isError && (
        <CSSTransition
          classNames="notification"
          key={notificationId}
          timeout={{
            enter: ENTER_TIMEOUT,
            exit: EXIT_TIMEOUT,
          }}
        >
          <DeliTicket
            ariaLabel={intl.formatMessage(
              sharedNotificationMessages.errorNotification
            )}
            body={body}
            callback={notification?.callback}
            confirmText={confirmText}
            onClose={() => setIsOpen(false)}
            title={title}
          />
        </CSSTransition>
      )}
      {isOpen && isSuccess && (
        <CSSTransition
          classNames="notification"
          key={notificationId}
          timeout={{
            enter: ENTER_TIMEOUT,
            exit: EXIT_TIMEOUT,
          }}
        >
          <Toast
            ariaLabel={intl.formatMessage(
              sharedNotificationMessages.notification
            )}
            body={body}
            callback={notification?.callback}
            cancelText={cancelText}
            confirmText={confirmText}
            onClose={() => setIsOpen(false)}
            title={title}
            type={notification?.type}
          />
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    body: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.elementType,
      PropTypes.object,
    ]),
    callback: PropTypes.func,
    cancelTextId: PropTypes.string,
    cancelTextValues: PropTypes.object,
    confirmTextId: PropTypes.string,
    confirmTextValues: PropTypes.object,
    id: PropTypes.number,
    messageId: PropTypes.string,
    messageValues: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    titleId: PropTypes.string,
    titleValues: PropTypes.object,
    type: PropTypes.oneOf(['confirm', 'error', 'generic', 'success']),
  }),
};

export default connect(null, { dismissNotification: dismissAction })(
  Notification
);
