const getUpdatedCardsState = (state, update) => {
  if (!state.data) {
    return null;
  }

  return state.data.map((card) => {
    if (Array.isArray(update)) {
      for (let i = 0; i < update.length; i++) {
        if (card.cardId === update[i].cardId) {
          return Object.assign({}, card, update[i]);
        }
      }

      return card;
    } else if (card.cardId !== update.cardId) {
      return card;
    }

    return Object.assign({}, card, update);
  });
};

export default getUpdatedCardsState;
