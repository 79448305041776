import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { push as pushAction } from 'redux-first-history';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import { redeemMessages } from '../../messages';

const RedeemBottomSheet = ({ push }) => (
  <div className="ml8 md-ml0 mb4">
    <Heading className="text-md" tagName="h2">
      <FormattedMessage {...redeemMessages.redeemOrderModePrompt} />
    </Heading>
    <button
      className="block mt4 p1 text-left"
      data-e2e="redeemPayInStoreOption"
      onClick={() => push('#pay')}
    >
      <span className="text-md text-semibold">
        <FormattedMessage {...redeemMessages.redeemOrderInPersonPrompt} />
      </span>
      <p className="pt2 text-sm">
        <FormattedMessage {...redeemMessages.redeemOrderInPersonDetail} />
      </p>
    </button>
    <div className="mt4 p1 cursor-pointer">
      <Link
        className={'block text-noUnderline'}
        data-e2e="redeemOrderAheadOption"
        to={`/menu`}
      >
        <span className="text-md text-semibold">
          <FormattedMessage {...redeemMessages.redeemOrderAheadPrompt} />
        </span>
        <p className="pt2 text-sm">
          <FormattedMessage {...redeemMessages.redeemOrderAheadDetail} />
        </p>
      </Link>
    </div>
  </div>
);

export default connect(null, { push: pushAction })(RedeemBottomSheet);
