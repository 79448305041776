'use strict';

const isIpAddress = (segments) => {
  if (
    segments.every((s) => {
      return s >= 0 && s <= 255;
    })
  ) {
    return true;
  }
  return false;
};

module.exports = (hostname) => {
  if (!hostname) {
    return '';
  }
  const segments = hostname.split('.');
  if (isIpAddress(segments) || hostname === 'localhost') {
    return hostname;
  }

  const domainStart = segments.indexOf('starbucks');
  const topLevel = segments.slice(domainStart).join('.');
  return `.${topLevel}`;
};
