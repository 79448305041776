import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import Button from '@starbucks-web/pattern-library/lib/components/button';

import { signedInSelector } from 'shared/app/bundles/user';
import { doLogoutAndForget, doLogin } from 'shared/app/shell';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

const SignInLink = ({ className, showSignOut = false, onClick }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const signedIn = useSelector(signedInSelector);

  const onClickHandler = () => {
    if (onClick) onClick();

    // Adding a delay to allow the track event to succeed
    setTimeout(() => {
      if (signedIn) {
        return dispatch(doLogoutAndForget());
      }
      return dispatch(doLogin());
    }, 200);
  };

  if (signedIn && !showSignOut) {
    return null;
  }
  return (
    <Button
      className={`${className || ''}`}
      color="black"
      onClick={onClickHandler}
    >
      {signedIn
        ? formatMessage(sharedCallsToAction.signOut)
        : formatMessage(sharedCallsToAction.signin)}
    </Button>
  );
};

export default SignInLink;
