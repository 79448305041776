import React from 'react';
import Field from '@starbucks-web/pattern-library/lib/components/field';

import PasswordVisibilityToggle from './password-visibility-toggle';

const PasswordField = (props) => {
  const [isTextVisible, setTextVisible] = React.useState(false);

  const toggleTextVisibility = () => {
    setTextVisible(!isTextVisible);
  };

  const inputType = isTextVisible ? 'text' : 'password';

  return (
    <Field
      {...props}
      data-e2e="password"
      inputAddon={
        <PasswordVisibilityToggle
          onClick={toggleTextVisibility}
          textVisible={isTextVisible}
        />
      }
      type={inputType}
    />
  );
};

export default PasswordField;
