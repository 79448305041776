import React from 'react';
import { connect } from 'react-redux';
import { errorNotificationSelector } from 'shared/app/shell/state/selectors/notification';
import Notification from 'shared/app/components/notification';

const ErrorNotification = ({ errorNotification }) => (
  <Notification notification={errorNotification} />
);

const select = (state) => ({
  errorNotification: errorNotificationSelector(state),
});

export default connect(select)(ErrorNotification);
