import {
  VERIFY_MFA_CODE,
  VERIFY_MFA_CODE_SUCCESS,
  VERIFY_MFA_CODE_ERROR,
  SEND_MFA_CODE,
  SEND_MFA_CODE_SUCCESS,
  SEND_MFA_CODE_ERROR,
  VERIFY_MFA_CODE_FOR_LOGIN,
  VERIFY_MFA_CODE_FOR_LOGIN_SUCCESS,
  VERIFY_MFA_CODE_FOR_LOGIN_ERROR,
  VERIFY_MFA_CODE_SECURE_SESSION,
  VERIFY_MFA_CODE_SECURE_SESSION_SUCCESS,
  VERIFY_MFA_CODE_SECURE_SESSION_ERROR,
  SET_MFA_CODE_DELIVERY_METHOD,
} from '../actions';

// eslint-disable-next-line complexity
export default (state = {}, action) => {
  switch (action.type) {
    case VERIFY_MFA_CODE:
    case VERIFY_MFA_CODE_FOR_LOGIN:
    case VERIFY_MFA_CODE_SECURE_SESSION:
      return Object.assign({}, state, { verifyingCode: true });
    case VERIFY_MFA_CODE_SUCCESS:
    case VERIFY_MFA_CODE_FOR_LOGIN_SUCCESS:
    case VERIFY_MFA_CODE_SECURE_SESSION_SUCCESS:
      return Object.assign({}, state, { verifyingCode: false });
    case VERIFY_MFA_CODE_ERROR:
    case VERIFY_MFA_CODE_FOR_LOGIN_ERROR:
    case VERIFY_MFA_CODE_SECURE_SESSION_ERROR:
      return Object.assign({}, state, {
        verifyCodeError: action.payload,
        verifyingCode: false,
      });
    case SEND_MFA_CODE:
      return Object.assign({}, state, { sendingCode: true });
    case SEND_MFA_CODE_SUCCESS:
      return Object.assign({}, state, {
        ...action.payload,
        sendingCode: false,
      });
    case SEND_MFA_CODE_ERROR:
      return Object.assign({}, state, { sendingCode: false });
    case SET_MFA_CODE_DELIVERY_METHOD:
      return Object.assign({}, state, {
        deliveryMethod: action.deliveryMethod,
      });
    default:
      return state;
  }
};
