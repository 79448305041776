import { createSelector } from 'reselect';
import readCookie from '../../../utils/read-cookie';
import { getEncryptionKey } from '../../../utils/encryption';
import formatUrl from '../../../../universal/format-url';

const getSessionCheckValue = () => {
  const val = readCookie('s_check');
  if (val) {
    try {
      const parsed = JSON.parse(val);
      return parsed.value;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const configSelector = (state) => state.config;
export const getLocaleSelector = (state) => state.locale;

export const configImagesUrlSelector = createSelector(
  configSelector,
  (config) => {
    const { host, path } = config?.universal?.staticUrls?.images ?? {};

    return formatUrl(host, path);
  }
);

export const cacheOptionsSelector = createSelector(
  configSelector,
  (config = {}) => {
    const { browserCache } = config;
    if (!browserCache) {
      return {};
    }
    const sessionCheckValue = getSessionCheckValue();
    return Object.assign({}, browserCache, {
      keyPromise: getEncryptionKey(sessionCheckValue),
      sessionCheckValue,
    });
  }
);

export const localeCurrencySelector = createSelector(
  configSelector,
  getLocaleSelector,
  (config, locale) => config?.supportedLocales?.[locale]?.currency
);

export const limitSignificantDigitsSelector = createSelector(
  localeCurrencySelector,
  (currency) => {
    return {
      style: 'currency',
      currency,
      maximumSignificantDigits: 3,
    };
  }
);

export const languageCookieNameSelector = createSelector(
  configSelector,
  (config) => config?.universal?.userSelectedLanguageCookieName
);
