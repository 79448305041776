import { findLastIndex } from 'lodash';
import {
  SET_ACTIVE_BOTTOM_SHEET,
  SET_BOTTOM_SHEET_URL,
} from '../state/actions';

export default (initialState) => {
  const defaultState = Object.assign(
    {},
    {
      bottomSheetRoutes: initialState,
      bottomSheetReturnUrl: null,
    }
  );
  return (state = defaultState, action) => {
    switch (action.type) {
      case SET_ACTIVE_BOTTOM_SHEET:
        if (action.payload) {
          // if the bottomsheet is already in state, update its instance in case
          // props have changed.
          const layerIndex = findLastIndex(
            state.bottomSheetRoutes,
            (layerInfo) => layerInfo.name === action.payload.name
          );
          if (layerIndex > -1) {
            return Object.assign({}, state, {
              bottomSheetRoutes: [
                ...state.bottomSheetRoutes.slice(0, layerIndex),
                action.payload,
                ...state.bottomSheetRoutes.slice(layerIndex + 1),
              ],
            });
          }
          return Object.assign({}, state, {
            bottomSheetRoutes: [...state.bottomSheetRoutes, action.payload],
          });
        }
        // clear the bottomsheet url when sheet is closed
        return Object.assign({}, state, { bottomSheetReturnUrl: null });
      case SET_BOTTOM_SHEET_URL:
        return Object.assign({}, state, {
          bottomSheetReturnUrl: action.payload,
        });
      default:
        return state;
    }
  };
};
