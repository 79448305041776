import { createSelector } from 'reselect';
import ms from 'milliseconds';

import shouldUpdate from 'shared/app/stale-reducers/should-update';

import { signedInSelector } from './profile';

const appTimeSelector = (state) => state.time;

export const rawPrivacyPermissionsSelector = (state) =>
  state?.user?.privacyPermissions;

export const privacyPermissionsSelector = createSelector(
  rawPrivacyPermissionsSelector,
  (rawPrivacyPermissions) => rawPrivacyPermissions?.data?.permissions
);

export const privacyTargetedAdsSelector = createSelector(
  privacyPermissionsSelector,
  (privacyPermissions) => {
    return privacyPermissions?.find(
      (permission) => permission?.type === 'PRIVACY_TARGETED_ADS'
    );
  }
);

export const isOptedInTargetedAdsSelector = createSelector(
  privacyTargetedAdsSelector,
  (privacyTargetedAds) => privacyTargetedAds?.optedIn
);

export const hasExplicitlyUpdatedTargetedAdsSelector = createSelector(
  privacyTargetedAdsSelector,
  (privacyTargetedAds) => privacyTargetedAds?.explicit
);

export const shouldFetchPrivacyPermissionsSelector = createSelector(
  signedInSelector,
  appTimeSelector,
  rawPrivacyPermissionsSelector,
  (signedIn, time, rawPrivacyPermissions) => {
    if (
      signedIn &&
      shouldUpdate(rawPrivacyPermissions, {
        staleTime: ms.hours(24),
        now: time,
      })
    ) {
      return true;
    }
    return null;
  }
);
