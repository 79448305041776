import React from 'react';
import HeaderCrate from '@starbucks-web/pattern-library/lib/components/header-crate';
import PreventFocus from '@starbucks-web/pattern-library/lib/components/prevent-focus';

import ErrorBoundary from 'shared/app/components/error-boundary';

const GlobalHeaderCrate = ({
  authSatisfied = true,
  hasGlobalNav,
  preventFocusTargets = {},
  routeOptions,
}) => {
  if (!routeOptions?.headerCrate) {
    return null;
  }
  const HeaderCrateContent = routeOptions.headerCrate;

  return (
    <PreventFocus enabled={preventFocusTargets.header} wrapWithDiv={false}>
      <HeaderCrate className="flex-shrink-none" hasGlobalNav={hasGlobalNav}>
        <ErrorBoundary>{authSatisfied && <HeaderCrateContent />}</ErrorBoundary>
      </HeaderCrate>
    </PreventFocus>
  );
};

export default GlobalHeaderCrate;
