import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { replace } from 'redux-first-history';

import Heading from '@starbucks-web/pattern-library/lib/components/heading/';

import DialogWithMessages from 'shared/app/components/dialog-with-messages';

import messages from './messages';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

export const ENTER_CODE_EXIT_DIALOG_ID = 'EnterCodeExitDialogId';

const EnterCodeExitDialog = ({ phoneNumber, parentOverlayId }) => {
  const { formatMessage } = useIntl();
  const { closeModal } = useModalContext();
  const dispatch = useDispatch();

  return (
    <DialogWithMessages
      cancelText={formatMessage(messages.enterMfaCodeCancel)}
      confirmText={formatMessage(messages.enterMfaCodeConfirm)}
      onCancel={() => {
        dispatch(replace('/account/settings'));
        closeModal(parentOverlayId);
        // Setting a delay to avoid a racing condition
        setTimeout(closeModal, 200);
      }}
    >
      <Heading
        className="text-bold mb3"
        id={ENTER_CODE_EXIT_DIALOG_ID}
        size="md"
        tagName="h2"
      >
        {formatMessage(messages.enterMfaCodeHeading)}
      </Heading>
      <p>{formatMessage(messages.enterMfaCodeBody, { phoneNumber })}</p>
    </DialogWithMessages>
  );
};

export default EnterCodeExitDialog;
