import React from 'react';
import { useSelector } from 'react-redux';

import { rewardLevelsSelector } from 'shared/app/bundles/user';
import AccessibleStar from 'shared/app/components/accessible-star';
import styles from './index.cssm';

const RewardsLevelsDescriptions = () => {
  const rewardLevels = useSelector(rewardLevelsSelector);

  if (!rewardLevels) {
    return null;
  }

  return (
    <div
      className={`text-sm px3 pb2 pt1 lg-pb3 lg-pt3 bg-ceramic ${styles.container}`}
    >
      {rewardLevels.map((level, index) => (
        <div key={index}>
          <div className="flex">
            <div className={`py1 lg-py2 ${styles.firstColumn}`}>
              <div className="flex items-center">
                <div className={`text-bold ${styles.starCount}`}>
                  {level.totalStarsToEarn}
                  <AccessibleStar
                    className="text-xxs"
                    starCount={level.totalStarsToEarn}
                  />
                </div>
              </div>
            </div>
            <div className="py1 lg-py2">{level.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RewardsLevelsDescriptions;
