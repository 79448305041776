import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';

import Overlay from '@starbucks-web/pattern-library/lib/components/overlay';

import { configImagesUrlSelector } from 'shared/app/state/selectors/config';
import TermsPrivacyLinksButton from './terms-privacy-links-button';

import messages from './messages.js';

export const TERMS_PRIVACY_OVERLAY_ID = 'termsPrivacyOverlayId';

const TermsPrivacyOverlay = () => {
  const imgUrl = useSelector(configImagesUrlSelector);
  const { formatMessage } = useIntl();

  return (
    <Overlay
      alignment="fullscreen"
      containerProps={{ 'data-e2e': 'terms-privacy-overlay' }}
      renderCloseButton={false}
    >
      <ContentColumn className="pt8" size="md">
        <Heading
          className="text-bold text-left mb3"
          id={TERMS_PRIVACY_OVERLAY_ID}
          size="lg"
          tagName="h2"
        >
          {formatMessage(messages.termsPrivacyTitle)}
        </Heading>
        <ImageLoader
          alt=""
          className="mb4 size12of12"
          role="presentation"
          src={`${imgUrl}/terms-and-privacy.svg`}
        />
        <p className="text-left mb4 text-sm">
          {formatMessage(messages.termsPrivacyText)}
        </p>
        <p className="text-left mb4 text-sm">
          {formatMessage(messages.termsPrivacyAgreeContinueText)}
        </p>
        <TermsPrivacyLinksButton overlayId={TERMS_PRIVACY_OVERLAY_ID} />
      </ContentColumn>
    </Overlay>
  );
};

export default TermsPrivacyOverlay;
