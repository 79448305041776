// This polyfill helper is "smart" in that it will only load polyfills that are needed.
// We use feature detection and the rather odd `require.ensure` syntax to tell webpack
// to split our app bundle and only load polyfills if they're not available in the browser.
const ensureFetch = (cb) => {
  if (!window.fetch) {
    require.ensure([], (require) => {
      require('cross-fetch/polyfill');
      cb();
    });
  } else {
    cb();
  }
};

export default (cb) => {
  ensureFetch(cb);
};
