import { defineMessages } from 'react-intl';

export default defineMessages({
  navAriaLabel: {
    id: 'shared.globalNav.navAriaLabel',
    defaultMessage: 'Global',
    description: 'Accessible label for nav element in the global nav',
  },
  pushViewButtonLabelLeft: {
    id: 'shared.globalNav.pushViewButtonLabelLeft',
    defaultMessage: 'Back to Main Menu',
    description:
      'A11y label for the secondary hamburger panel push view button',
  },
  pushViewButtonLabelRight: {
    id: 'shared.globalNav.pushViewButtonLabelRight',
    defaultMessage: 'Expand {navItemText} sub-menu',
    description: 'A11y label for the primary hamburger panel push view button',
  },
});
