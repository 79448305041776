/* eslint-disable no-console */
import { IS_DEBUG } from '../../utils/debug';

const actionQueue = [];
let nested = false;

export default (store) => (next) => (action) => {
  nested = false;
  if (IS_DEBUG) {
    actionQueue.push(action.type);
    console.group(action.type);
    console.info('action:', action);
  }
  const result = next(action);
  if (IS_DEBUG) {
    console.log('state:', store.getState());
    console.groupEnd(action.type);
    actionQueue.pop();
    if (actionQueue.length) {
      nested = true;
    }
    if (nested && actionQueue.length === 0) {
      console.warn('^ nested action');
    }
  }
  return result;
};
