import { fromPairs, isEmpty, flatMap } from 'lodash';
import { FETCH_MENU_SUCCESS } from '../action-types';

import getFormId from 'shared/app/bundles/menu/util/get-menu-product-form-id';

/* eslint-disable max-params */
export const extractProductsFromMenuCategory = (
  state,
  category,
  parentCategoryName,
  menuName
) => {
  const children = category.children;
  if (!isEmpty(children)) {
    return flatMap(children, (child) =>
      // as we recurse through the menu structure, we can hold onto the name of the current category
      // and send as optional third and eventually fourth args. These will represent the category and
      // top level menu names which we can attach to the product below. This will make it easier to
      // display breadcrubms and other links that need the product's ancestry.
      extractProductsFromMenuCategory(
        state,
        child,
        category.name,
        parentCategoryName
      )
    );
  }

  const products = category.products;
  if (!isEmpty(products)) {
    return category.products.map((product) => {
      const { productNumber, formCode } = product;
      const id = getFormId({ productNumber, formCode });

      return [
        id,
        {
          ...product,
          breadcrumbUri: category.uri,
          categoryName:
            menuName && parentCategoryName ? parentCategoryName : category.name,
          menuName: menuName || parentCategoryName,
          subcategoryName: menuName ? category.name : null,
        },
      ];
    });
  }
};

export const extractProductsFromMenu = (state, fullMenu) => {
  const topLevelMenus = fullMenu ?? [];
  return flatMap(topLevelMenus, (menu) =>
    extractProductsFromMenuCategory(state, menu)
  ).filter(Boolean);
};

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_MENU_SUCCESS: {
      return fromPairs(extractProductsFromMenu(state, action.payload));
    }

    default:
      return state;
  }
};
