import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isAccountRewardsPathSelector } from 'shared/app/state/selectors/rewards';
import { trackerGoalsWithUiPropertiesSelector } from 'shared/app/state/selectors/rewards-tracker';

import TrackerGoalMarker from './tracker-goal-marker';

const GoalMarkers = ({
  animationDelay,
  animationDuration,
  isRewardsPath,
  goalAchievedOnUpdateIndex,
  otherClasses,
  trackerSegments,
}) => {
  if (!trackerSegments) {
    return null;
  }
  return (
    <React.Fragment>
      {trackerSegments.map((segment, index) => {
        const { complete, totalStarsToEarn, markerPercentLeft } = segment;
        const goalAchievedOnUpdate = index === goalAchievedOnUpdateIndex;
        return (
          <TrackerGoalMarker
            animationDelay={animationDelay}
            animationDuration={animationDuration}
            complete={complete}
            goalAchievedOnUpdate={goalAchievedOnUpdate}
            isRewardsPath={isRewardsPath}
            key={markerPercentLeft}
            left={markerPercentLeft}
            otherClasses={otherClasses}
            totalStarsToEarn={totalStarsToEarn}
          />
        );
      })}
    </React.Fragment>
  );
};

GoalMarkers.propTypes = {
  animationDelay: PropTypes.number.isRequired,
  animationDuration: PropTypes.number.isRequired,
  goalAchievedOnUpdateIndex: PropTypes.number,
};

const select = (state) => ({
  isRewardsPath: isAccountRewardsPathSelector(state),
  trackerSegments: trackerGoalsWithUiPropertiesSelector(state),
});

export default connect(select)(GoalMarkers);
