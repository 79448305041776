import { createSelector } from 'reselect';

const notificationsSelector = (state) => state.notifications;

export const errorNotificationSelector = createSelector(
  notificationsSelector,
  (notifications) => (notifications?.type === 'error' ? notifications : null)
);

const successNotificationTypes = ['confirm', 'generic', 'success'];

export const successNotificationSelector = createSelector(
  notificationsSelector,
  (notifications) =>
    successNotificationTypes.includes(notifications?.type)
      ? notifications
      : null
);

export const useErrorLoadingIndicatorSelector = createSelector(
  notificationsSelector,
  (notifications) => notifications?.useErrorLoadingIndicator ?? false
);
