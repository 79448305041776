import ExEnv from 'exenv';
import { isPlaceNotFound } from '../../universal/geocode-utils';

const handleGeocodedLocation = (callbacks, results, status) => {
  const { GeocoderStatus } = window.google.maps;

  if (status === GeocoderStatus.OK) {
    const coordinates = results[0].geometry.location;

    callbacks.success({
      lat: coordinates.lat(),
      lng: coordinates.lng(),
    });
  }

  // track geocoding failures
  if (isPlaceNotFound(status)) {
    callbacks.error({
      placeNameNotFound: true,
      event: 'store-locator-geocode',
      error: 'errBadAddressSearch',
    });
  } else if (status === GeocoderStatus.UNKNOWN_ERROR) {
    callbacks.error({
      status,
      event: 'store-locator-geocode',
      error: 'errLocationService',
    });
  }
};

export const geocodeLocation = ({ location, success, error, fallback }) => {
  if (ExEnv.canUseDOM && window.google && window.google.maps) {
    const geocoder = new window.google.maps.Geocoder();
    const callback = handleGeocodedLocation.bind(null, { success, error });

    geocoder.geocode({ address: location }, callback);

    return;
  }

  fallback();
};
