/* eslint-disable max-params */
import { createSelector } from 'reselect';

import {
  signedInSelector,
  privacyTargetedAdsSelector,
  hasExplicitlyUpdatedTargetedAdsSelector,
  isOptedInTargetedAdsSelector,
} from 'shared/app/bundles/user';
import { pathnameSelector } from 'shared/app/state/selectors/routes';

import { shouldShowTermsPrivacyOverlaySelector } from './terms-privacy';

export const shouldShowPrivacyBrowserDialogSelector = createSelector(
  pathnameSelector,
  signedInSelector,
  shouldShowTermsPrivacyOverlaySelector,
  privacyTargetedAdsSelector,
  isOptedInTargetedAdsSelector,
  hasExplicitlyUpdatedTargetedAdsSelector,
  (
    pathname,
    signedIn,
    shouldShowTermsPrivacyOverlay,
    privacyTargetedAds,
    isOptedInTargetedAds,
    hasExplicitlyUpdatedTargetedAds
  ) => {
    if (['/account/signin', '/account/create'].includes(pathname)) {
      return false;
    }

    const userRequestingPrivacy = navigator?.globalPrivacyControl;

    return (
      signedIn &&
      !shouldShowTermsPrivacyOverlay &&
      userRequestingPrivacy &&
      // checking "privacyTargetedAds" guarantees we received a response back from the API
      privacyTargetedAds &&
      isOptedInTargetedAds &&
      !hasExplicitlyUpdatedTargetedAds
    );
  }
);
