import React from 'react';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

const BottomSheetHeading = ({ children, ...otherProps }) => {
  return (
    <Heading
      className="color-textBlackSoft mb4 pl8 sm-pl0"
      size="sm"
      tagName="h2"
      {...otherProps}
    >
      {children}
    </Heading>
  );
};

export default BottomSheetHeading;
