import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import styles from './styles.cssm';

const RewardsStatusMessage = ({ id }) => {
  const { formatMessage } = useIntl();

  return (
    <div
      className={`text-upper letterSpacing-loose color-textBlackSoft ${styles.messageText}`}
    >
      <p
        className="mr2 mt2 text-xs text-bold text-center"
        data-e2e="nextRewardMessage"
        id={id}
      >
        {formatMessage(messages.starBalance)}
      </p>
    </div>
  );
};

RewardsStatusMessage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default RewardsStatusMessage;
