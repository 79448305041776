import { defineMessages } from 'react-intl';

export default defineMessages({
  rewardsEmptyStateMessage: {
    id: 'shared.rewardsEmptyState.message',
    defaultMessage:
      'We’re still brewing your {starbucksRewardsTrademark} info.',
    description:
      'Message displayed when user loyalty data has not been retrieved yet.',
  },
  checkBackSoon: {
    id: 'shared.rewardsEmptyState.checkBackSoon',
    defaultMessage: 'Check back soon.',
    description: 'displays check back soon message.',
  },
});
