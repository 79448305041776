import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  addressFieldNamesSelector,
  numericPostalCodeSelector,
  countrySubdivisionsSelector,
} from '../../state/selectors/address-field-names';
import {
  ADDRESS_LINES,
  CITY,
  COUNTRY_SUBDIVISION,
  POSTAL_CODE,
} from './field-names';
import PostalCodeField from '../postal-code-field';
import AddressLineFields from '../address-line-fields';
import CityField from '../city-field';
import CountrySubdivisionSelect from '../country-subdivision-select';

const getRenderFieldMap = (props) => (field, index) => {
  const {
    addressLine1,
    addressLine2,
    city,
    countrySubdivision,
    countrySubdivisions,
    numericPostalCode,
    postalCode,
    fieldProps,
  } = props;

  const map = {
    [ADDRESS_LINES]: () => (
      <AddressLineFields
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        fieldProps={{
          addressLine1: fieldProps.addressLine1,
          addressLine2: fieldProps.addressLine2,
        }}
        key={index}
      />
    ),
    [CITY]: () => <CityField city={city} key={index} {...fieldProps.city} />,
    [COUNTRY_SUBDIVISION]: () => (
      <CountrySubdivisionSelect
        countrySubdivision={countrySubdivision}
        countrySubdivisions={countrySubdivisions}
        key={index}
        {...fieldProps.countrySubdivision}
      />
    ),
    [POSTAL_CODE]: () => (
      <PostalCodeField
        key={index}
        numeric={numericPostalCode}
        postalCode={postalCode}
        {...fieldProps.postalCode}
      />
    ),
  };

  return map[field]();
};

const AddressFields = (props) => {
  const { addressFieldNames } = props;
  const renderMap = getRenderFieldMap(props);

  return <div>{addressFieldNames.map(renderMap)}</div>;
};

AddressFields.propTypes = {
  addressLine1: PropTypes.object,
  addressLine2: PropTypes.object,
  city: PropTypes.object,
  countrySubdivision: PropTypes.object,
  countrySubdivisions: PropTypes.object,
  fieldProps: PropTypes.object,
  numericPostalCode: PropTypes.bool,
  postalCode: PropTypes.object,
  addressFieldNames: PropTypes.arrayOf(
    PropTypes.oneOf([ADDRESS_LINES, CITY, COUNTRY_SUBDIVISION, POSTAL_CODE])
  ).isRequired,
};

AddressFields.defaultProps = {
  fieldProps: {},
};

const select = (state) => ({
  addressFieldNames: addressFieldNamesSelector(state),
  countrySubdivisions: countrySubdivisionsSelector(state),
  numericPostalCode: numericPostalCodeSelector(state),
});

export default connect(select)(AddressFields);
