import { defineMessages } from 'react-intl';

export default defineMessages({
  passwordError: {
    id: 'shared.reAuthForm.passwordError',
    defaultMessage: 'Wrong password',
    description: 'Error message when incorrect password',
  },
  unsuccessfulError: {
    id: 'shared.reAuthForm.unsuccessfulError',
    defaultMessage: 'Sign in unsuccessful',
    description: 'Error message when unsuccessful sign in',
  },
  forgotPassword: {
    id: 'shared.reAuthForm.forgotPasswordLink',
    defaultMessage: 'Forgot your password?',
    description: 'Forgot password link in re-auth modal',
  },
  submitLabel: {
    id: 'shared.reAuthForm.submitLabel',
    defaultMessage: 'Submit',
    description: 'Button text submitting re-auth form',
  },
  reAuthDialogTitle: {
    id: 'shared.userBundle.reAuthDialogTitle',
    defaultMessage: 'Re-enter password',
    description:
      'title for modal dialog prompting user to re-enter their password',
  },
  reAuthDialogBody: {
    id: 'shared.userBundle.reAuthDialogBody',
    defaultMessage: `Hey {name}, we need your password to be sure it's you.`,
    description:
      'body of modal dialog informing user to re-enter their password',
  },
  reAuthorizeBody: {
    id: 'shared.userBundle.reAuthorizeBody',
    defaultMessage: 'Something went wrong. Try again',
    description:
      'body of modal dialog when an operation requires a user re-authenticate',
  },
});
