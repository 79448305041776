import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Field from '@starbucks-web/pattern-library/lib/components/field';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';
import messages from './messages';
import { prefixErrorMessage } from 'shared/app/components/a11y-error-message-prefix';
import { formMessages } from 'shared/app/messages';

export const EmailField = ({
  className,
  field,
  label,
  hasHint = true,
  ...otherProps
}) => {
  const { formatMessage } = useIntl();
  const { errorDetails, errorMessage, input } = field;

  const hasError = shouldDisplayError(field);

  const detailsMessage = errorDetails.existence
    ? messages.existenceError
    : messages.invalidError;

  const message = errorMessage || detailsMessage;

  return (
    <Field
      {...input}
      autoComplete="email"
      className={className}
      data-e2e="emailAddressField"
      error={hasError}
      errorMessage={prefixErrorMessage(message)}
      floatLabelId={`${input.id}Label`}
      hintText={hasHint && formatMessage(messages.usernameHint)}
      maxLength={50}
      maxLengthAriaMessage={formatMessage(formMessages.maxLengthAriaMessage)}
      type="email"
      {...otherProps}
    >
      {label || formatMessage(messages.emailFieldLabel)}
    </Field>
  );
};

EmailField.propTypes = {
  label: PropTypes.object,
};

export default EmailField;
