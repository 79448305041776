import { defineMessages } from 'react-intl';

const messages = defineMessages({
  upsellHeading: {
    id: 'shared.upsellHeading',
    defaultMessage: 'All your faves. Our best offers.',
    description: 'Heading for the upsell card',
  },
  upsellBody: {
    id: 'shared.upsellBody',
    defaultMessage:
      'Get the best offers on treats you love, tailored just for you. Join Starbucks Rewards.',
    description: 'Body for the upsell card',
  },
});

export default messages;
