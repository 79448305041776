import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import { doLogin } from 'shared/app/shell';
import { hideBottomSheet } from 'shared/app/shell/state/action-creators/app-lifecycle';
import { sharedCallsToAction } from 'shared/app/messages/ctas';
import isApplePaySupported from 'shared/app/utils/is-apple-pay-supported';
import { CONTINUE_AS_GUEST } from 'shared/app/state/action-creators/types';
import { hasGuestSessionSelector } from 'shared/app/bundles/user';
import {
  trackCheckoutAsGuestClick,
  trackGuestSrCtaClick,
  UO_GUEST_SIGN_IN,
  UO_GUEST_SIGN_UP,
} from 'shared/app/state/track-event';
import { withOptimizely } from 'shared/app/components/optimizely';
import useGcoV2Flag from 'shared/app/hooks/use-gco-v2-flag';

const JoinRewardsSigninSheet = ({
  targetUrl,
  headingContent,
  preJoinPromise,
  preSignInPromise,
  storeSupportsGuestCheckout,
  optimizely,
}) => {
  const dispatch = useDispatch();
  const hasGuestSession = useSelector(hasGuestSessionSelector);
  const { isGcoV2Enabled } = useGcoV2Flag(optimizely);

  const hash = window.location.hash;
  const returnUrl = targetUrl || window.location.href.replace(hash, '');
  const encodedReturnUrl = encodeURIComponent(returnUrl);

  const onJoinClickHandler = () => {
    trackGuestSrCtaClick({
      'click_target': UO_GUEST_SIGN_UP,
      'cta_placement': 'MOP_CART_VIEW',
    });

    // Adding a delay to allow the track event to succeed
    setTimeout(() => {
      const preAction = preJoinPromise
        ? preJoinPromise
        : () => Promise.resolve();

      preAction().then(() => {
        window.location.href = `/account/create?returnUrl=${encodedReturnUrl}`;
      });
    }, 200);
  };

  const onSigninClickHandler = () => {
    trackGuestSrCtaClick({
      'click_target': UO_GUEST_SIGN_IN,
      'cta_placement': 'MOP_CART_VIEW',
    });

    // Adding a delay to allow the track event to succeed
    setTimeout(() => {
      const preAction = preSignInPromise
        ? preSignInPromise
        : () => Promise.resolve();

      preAction().then(() => {
        dispatch(doLogin({ returnUrl }));
      });
    }, 200);
  };

  const onCheckoutAsGuest = () => {
    trackCheckoutAsGuestClick();
    dispatch({ type: CONTINUE_AS_GUEST });
    dispatch(hideBottomSheet());
  };

  return (
    <div className="mt6">
      <Heading className="mb4" size="xs" tagName="h2">
        {headingContent}
      </Heading>
      {hasGuestSession &&
        storeSupportsGuestCheckout &&
        (isApplePaySupported || isGcoV2Enabled) && (
          <p className="mb4">
            <Button
              className="mxn3 text-lg"
              data-e2e="checkoutAsGuestButton"
              onClick={onCheckoutAsGuest}
              visualStyle="textOnly"
            >
              <FormattedMessage {...sharedCallsToAction.checkoutAsGuest} />
            </Button>
          </p>
        )}

      <p className="mb4">
        <Button
          className="mxn3 text-lg"
          data-e2e="joinNowButton"
          onClick={onJoinClickHandler}
          visualStyle="textOnly"
        >
          <FormattedMessage {...sharedCallsToAction.joinNow} />
        </Button>
      </p>
      <p className="mb4">
        <Button
          className="mxn3 text-lg"
          data-e2e="signInButton"
          onClick={onSigninClickHandler}
          visualStyle="textOnly"
        >
          <FormattedMessage {...sharedCallsToAction.signin} />
        </Button>
      </p>
    </div>
  );
};

export default withOptimizely(JoinRewardsSigninSheet);
