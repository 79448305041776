export const ACCERTIFY_GLOBAL_NAME = '_bcn';

export const getAccertifyUab = () => {
  if (window.hasOwnProperty(ACCERTIFY_GLOBAL_NAME)) {
    const ubaId = window._bcn.getToken();
    const ubaPayload = window._bcn.getEvents();
    return {
      ubaId,
      ubaPayload,
    };
  }
};
