import React from 'react';
import { injectIntl } from 'react-intl';
import Form from '@starbucks-web/pattern-library/lib/components/form';

import messages from './messages';

const FormWithMessaging = ({ intl: { formatMessage }, ...props }) => {
  return (
    <Form
      {...props}
      requiredIndicatorText={formatMessage(messages.requiredFieldIndicator)}
    />
  );
};

export default injectIntl(FormWithMessaging);
