import { createSelector } from 'reselect';

import {
  selectedStoreSelector,
  storeIsLoadingSelector,
} from 'shared/app/state/selectors/ordering';

import { hasLocationsSelector } from 'store-locator/app/state/selectors';
import { googleMapsAPIIsReadySelector } from 'shared/app/bundles/scripts';
import { queryParamsSelector } from 'shared/app/state/selectors/routes';
import { orderingSelector } from 'shared/app/state/selectors/ordering';

export const shouldFetchMapsOrderingLocationsSelector = createSelector(
  googleMapsAPIIsReadySelector,
  storeIsLoadingSelector,
  selectedStoreSelector,
  hasLocationsSelector,
  // eslint-disable-next-line max-params
  (isGoogleAPIReady, storeIsLoading, selectedStore, hasLocations) => {
    return (
      isGoogleAPIReady &&
      !storeIsLoading &&
      selectedStore?.store?.address?.postalCode &&
      !hasLocations
    );
  }
);

export const isStoreConfirmedUnavailableSelector = createSelector(
  orderingSelector,
  queryParamsSelector,
  (ordering, queryParams) => {
    return (
      ordering?.notification?.storeConfirmedUnavailable ||
      queryParams?.confirmedOrderingUnavailable
    );
  }
);

export const shouldDisplayNotAvailableStoreDialogSelector = createSelector(
  hasLocationsSelector,
  selectedStoreSelector,
  isStoreConfirmedUnavailableSelector,
  (hasLocations, selectedStore = {}, isStoreConfirmedUnavailable) => {
    const { store } = selectedStore;
    const storeNotConfirmed =
      !isStoreConfirmedUnavailable &&
      isStoreConfirmedUnavailable !== store?.storeNumber;

    if (hasLocations && store?.storeNumber) {
      const mopUnavailable = store?.mobileOrdering?.availability !== 'READY';
      const storeClosed = !store?.open;
      return (mopUnavailable || storeClosed) && storeNotConfirmed;
    }
    return false;
  }
);
