import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Form from 'shared/app/components/form';
import PasswordField from 'shared/app/components/password-field';

import { sharedCallsToAction, accountsMessages } from 'shared/app/messages';
import messages from './messages';

export default ({ fields, onSubmit, onCancel, legacyUrl, loading }) => (
  <Form noValidate onSubmit={onSubmit}>
    <PasswordField
      {...fields.password.input}
      autoComplete="current-password"
      error={Boolean(fields.password.errorMessage)}
      errorMessage={fields.password.errorMessage}
    >
      <FormattedMessage {...accountsMessages.password} />
    </PasswordField>
    <div className="mt2">
      <Button
        className="mr3"
        data-e2e="forgotPassword"
        href={`${legacyUrl}/account/forgot-password`}
        visualStyle="textLink"
      >
        <FormattedMessage {...messages.forgotPassword} />
      </Button>
    </div>

    <div className="text-right mt4">
      <Button className="mr3" data-e2e="reauthCancel" onClick={onCancel}>
        <FormattedMessage {...sharedCallsToAction.cancel} />
      </Button>
      <Button
        data-e2e="reauthSubmit"
        loading={loading}
        type="submit"
        visualStyle="positive"
      >
        <FormattedMessage {...messages.submitLabel} />
      </Button>
    </div>
  </Form>
);
