import createActionTypes from '../../state/create-action-types';

export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const UPDATE_SESSION_INFO = 'UPDATE_SESSION_INFO';

export const DO_LOGIN = 'DO_LOGIN';
export const DO_LOGOUT = 'DO_LOGOUT';

export const {
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_ERROR,

  FETCH_ONBOARDING_FLAGS,
  FETCH_ONBOARDING_FLAGS_SUCCESS,
  FETCH_ONBOARDING_FLAGS_ERROR,

  UPDATE_ONBOARDING_FLAG,
  UPDATE_ONBOARDING_FLAG_SUCCESS,
  UPDATE_ONBOARDING_FLAG_ERROR,

  FETCH_USER_MFA_FACTORS,
  FETCH_USER_MFA_FACTORS_SUCCESS,
  FETCH_USER_MFA_FACTORS_ERROR,
} = createActionTypes([
  'FETCH_PROFILE',
  'FETCH_ONBOARDING_FLAGS',
  'UPDATE_ONBOARDING_FLAG',
  'FETCH_USER_MFA_FACTORS',
]);
