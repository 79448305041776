import React from 'react';
import optimizelyManager from './optimizely-manager';
import config from 'config';
import isBrowser from 'shared/app/shell/utils/is-browser';

const withOptimizely = (Component) => {
  return (props) => {
    const { dataFileTimeout } = config.get('universal.optimizely');

    return (
      <Component
        {...props}
        isServerSide={!isBrowser}
        optimizely={optimizelyManager.getInstance()}
        optimizelyReadyTimeout={dataFileTimeout}
      />
    );
  };
};

export default withOptimizely;
