import { createSelector } from 'reselect';
import { onlinePolicyUrlsSelector } from './config';

export const cardTermsAndConditionsUrlSelector = createSelector(
  onlinePolicyUrlsSelector,
  (config) => config.cardTermsAndConditions
);

export const customerServiceUrlSelector = createSelector(
  onlinePolicyUrlsSelector,
  (config) => config.customerServiceUrl
);

export const customerServiceMfaHelpUrlSelector = createSelector(
  onlinePolicyUrlsSelector,
  customerServiceUrlSelector,
  (config, customerServiceUrl) =>
    config.customerServiceMfaHelpUrl || customerServiceUrl
);

export const privacyStatementContactUsUrlSelector = createSelector(
  onlinePolicyUrlsSelector,
  (config) => config.privacyStatementContactUsUrl
);

export const privacyStatementUrlSelector = createSelector(
  onlinePolicyUrlsSelector,
  (config) => config.privacyStatementUrl
);

export const rewardsTermsOfUseUrlSelector = createSelector(
  onlinePolicyUrlsSelector,
  (config) => config.rewardsTermsOfUse
);

export const termsOfUseUrlSelector = createSelector(
  onlinePolicyUrlsSelector,
  (config) => config.termsOfUseUrl
);
