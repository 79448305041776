import React from 'react';
import styles from './tracker-pointer.cssm';

const Pointer = ({ className }) => {
  return (
    <svg
      className={`absolute ${styles.offset} color-greenAccent`}
      height="17"
      viewBox="0 0 12 17"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`${styles.pathOrigin} ${className || ''}`}
        d="M6.204.037C8.903.035 11.6 2.732 11.598 5.431c-.002 1.799-1.803 5.45-5.406 10.954C2.598 10.933.802 7.284.802 5.438.806 2.67 3.506.04 6.205.038z"
      />
    </svg>
  );
};

export default Pointer;
