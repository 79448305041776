import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import Select from '@starbucks-web/pattern-library/lib/components/select';
import { svcMessages } from 'shared/app/messages';

import InputSvcImage from './input-svc-image';

export const StoredValueCardsSelect = ({
  field,
  svcCards,
  hideImage,
  className = '',
  inputProps,
}) => {
  const { formatMessage, formatNumber, formats } = useIntl();

  const getSvcSelectData = () => {
    const currentCard = svcCards?.find(
      (card) => card.cardId === field.input.value
    );

    return {
      imageUrl: currentCard?.thumbImageUrl || '',
    };
  };

  const getSelectedCardBalance = () => {
    const currentCard = svcCards?.find(
      (card) => card.cardId === field.input.value
    );

    return formatNumber(currentCard?.balance?.amount, formats.number.money);
  };

  const { imageUrl } = getSvcSelectData();

  return (
    <div className={`flex ${className}`}>
      {!hideImage && <InputSvcImage imageUrl={imageUrl} />}
      <div className="flex-grow">
        <Select
          selectedOptionFormatter={getSelectedCardBalance}
          {...field.input}
          label={formatMessage(svcMessages.starbucksCard)}
          {...inputProps}
        >
          {svcCards.map((svcCard) => {
            const { cardId } = svcCard;
            return (
              <option key={cardId} value={cardId}>
                {`${formatNumber(svcCard.balance.amount, formats.number.money)},
                  ${svcCard.nickname}`}
              </option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

StoredValueCardsSelect.propTypes = {
  field: PropTypes.object.isRequired,
  hideImage: PropTypes.bool,
  svcCards: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default StoredValueCardsSelect;
