import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import LoadingCoffeeCup from '@starbucks-web/pattern-library/lib/components/loading-coffee-cup';

const messages = defineMessages({
  title: {
    id: 'shared.contentCrateLoading.title',
    defaultMessage: 'Loading content... (steaming coffee cup)',
    description:
      'Label describing a loading animation for the primary page content',
  },
});

const ContentCrateLoading = ({ intl: { formatMessage } }) => (
  <LoadingCoffeeCup
    className="my9"
    data-e2e="coffee-loader"
    title={formatMessage(messages.title)}
  />
);

export default injectIntl(ContentCrateLoading);
