import React from 'react';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import starPath from '@starbucks-web/pattern-library/lib/icons/star';

import styles from './styles.cssm';

const BarcodeRewardIcon = () => (
  <Button
    className={`items-center mr3 p1 bg-white ${styles.pill}`}
    color="white"
    tagName="div"
  >
    <Icon className="color-gold" path={starPath} size="26px" />
  </Button>
);

export default BarcodeRewardIcon;
