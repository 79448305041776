import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import OverlayWithCloseMessage from 'shared/app/components/overlay-with-close-message';
import { userMfaVerifiedPhoneSelector } from 'shared/app/bundles/user/state/selectors/mfa';

import MfaChallengeOtherOptionsOverlayContainer from './container';
import messages from '../shared-messages';

export const MFA_CHALLENGE_OTHER_OPTIONS_OVERLAY_ID =
  'MfaChallengeOtherOptionsOverlayId';

const MfaChallengeOtherOptionsOverlay = ({
  closeCallback,
  onCodeSendSuccess,
  phoneId,
  phoneNumber,
  verificationType = 'secureSession',
}) => {
  const { formatMessage } = useIntl();

  const isSecureSessionVerificationType = verificationType === 'secureSession';
  const isPhoneVerificationType = verificationType === 'phoneVerification';
  const isSecureSessionOrPhoneVerificationType =
    isSecureSessionVerificationType || isPhoneVerificationType;

  const userMfaVerifiedPhone = useSelector(userMfaVerifiedPhoneSelector);

  const phoneDetails = isSecureSessionVerificationType
    ? {
        id: userMfaVerifiedPhone?.id,
        phoneNumber: userMfaVerifiedPhone?.phoneNumber,
      }
    : {
        id: phoneId,
        phoneNumber,
      };

  return (
    <OverlayWithCloseMessage
      alignment="rightCrate"
      closeCallback={closeCallback}
    >
      <ContentColumn className="frapPadding size12of12" size="sm">
        <Heading
          className="text-bold pb3 md-pt9"
          id={MFA_CHALLENGE_OTHER_OPTIONS_OVERLAY_ID}
          size="lg"
          tagName="h2"
        >
          {formatMessage(
            isSecureSessionOrPhoneVerificationType
              ? messages.verifyNumber
              : messages.twoFactorAuthenticationHeading
          )}
        </Heading>
        <p className="pb4">
          {formatMessage(
            isSecureSessionOrPhoneVerificationType
              ? messages.sendCodeOptionsSubHeading
              : messages.sendCodeOptionsRadioGroupLegend,
            {
              phoneNumber: isSecureSessionOrPhoneVerificationType
                ? userMfaVerifiedPhone?.phoneNumber?.slice(-4) ||
                  phoneNumber?.slice(-4)
                : phoneNumber,
            }
          )}
        </p>
        <MfaChallengeOtherOptionsOverlayContainer
          onCodeSendSuccess={onCodeSendSuccess}
          phone={phoneDetails}
          verificationType={verificationType}
        />
      </ContentColumn>
    </OverlayWithCloseMessage>
  );
};

MfaChallengeOtherOptionsOverlay.propTypes = {
  onCodeSendSuccess: PropTypes.func,
  phoneId: PropTypes.string,
  phoneNumber: PropTypes.string,
  verificationType: PropTypes.string,
};

export default MfaChallengeOtherOptionsOverlay;
