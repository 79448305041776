import exEnv from 'exenv';

if (exEnv.canUseDOM) {
  const uoModule = require('@starbucks-web/universal-object');
  // window.uo is augmented by tags in GTM, so we have to assign
  // the module to the namespace in case the GTM modules load first.
  window.uo = Object.assign({}, window.uo, uoModule);
}

export const uoAvailable = () => Boolean(exEnv.canUseDOM && window.uo);

const trackingQueue = [];

const fireQueue = () => {
  trackingQueue.forEach((uoCall) => uoCall());
  // eslint-disable-next-line no-use-before-define
  removeReadyListener();
};

let readyListenerActive = false;

const addReadyListener = () => {
  readyListenerActive = true;
  window.addEventListener('uo-ready', fireQueue);
};

const removeReadyListener = () => {
  readyListenerActive = false;
  window.removeEventListener('uo-ready', fireQueue);
};

const queueOrFire = (uoCall) => {
  if (!uoAvailable() || !window.uo.loaded) {
    if (!readyListenerActive) {
      addReadyListener();
    }
    trackingQueue.push(uoCall);
    return;
  }

  uoCall();
};

export const trackPageView = (pageURL, eventAttributes = {}) => {
  if (!uoAvailable()) {
    return;
  }

  queueOrFire(() => {
    window.uo.trackPageView(pageURL, eventAttributes);
  });
};

export const trackEvent = (eventName, eventAttributes = {}) => {
  return new Promise((resolve) => {
    if (!uoAvailable() || !window.uo.loaded) {
      // No early return, resolve promise and fall through to the queue
      resolve();
    }

    queueOrFire(() => {
      window.uo.trackEvent(eventName, eventAttributes).finally(resolve);
    });
  });
};

export const uoLoginUser = (xid) => {
  if (!uoAvailable() || !window.uo.loaded) {
    return Promise.resolve();
  }
  return window.uo.loginUser(xid);
};

export const setUserAttribute = (key, value) => {
  queueOrFire(() => {
    window.uo.setUserAttribute?.(key, value);
  });
};

export const setUserAttributes = (attributes) => {
  queueOrFire(() => {
    window.uo.setUserAttributes(attributes);
  });
};

export const setOneTimeCanonicalAttributes = () => {
  setUserAttribute('browserUserAgent', window.navigator.userAgent);
};

export const setUserProfileCanonicalAttributes = (user) => {
  const userAttributes = {
    /* eslint-disable camelcase */
    user_registration_date: user?.loyaltyProgram?.cardHolderSince,
    beginning_star_balance: user?.loyaltyProgram?.progress?.starBalance,
    beginning_star_till_next_reward:
      user?.loyaltyProgram?.progress?.starsToNextGoal,
    authentication_state: true,
    xid: user?.exId,
    // eslint-enable camelcase
  };
  if (user.exId) {
    window.uo
      .loginUser(user.exId)
      .then(() => setUserAttributes(userAttributes));
  } else {
    setUserAttributes(userAttributes);
  }
};

export const setInitialUserAttributes = (cachedStoreData) => {
  const userProfile = cachedStoreData?.user?.accountProfile?.data;
  if (userProfile) {
    setUserProfileCanonicalAttributes(userProfile);
  } else {
    // eslint-disable-next-line camelcase
    setUserAttribute('authentication_state', false);
  }
};

// Converts a timezone offset in minutes to a formatted GMT string
// expected by UO.
export const convertTimezoneOffsetToGMTString = (offset) => `GMT${offset / 60}`;
