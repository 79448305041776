import ms from 'milliseconds';
import { createSelector } from 'reselect';
import shouldUpdate from 'shared/app/stale-reducers/should-update';
import { isPartnerSelector } from 'shared/app/bundles/user';

const SVC_EARN_RATE_TYPE = 'SVC';
const GENERIC_EARN_RATE_TYPE = 'GENERIC';

export const accrualEarnRatesSelector = (state) =>
  state?.accrualEarnRates ?? null;

export const accrualEarnRatesDataSelector = createSelector(
  accrualEarnRatesSelector,
  (earnRates) => earnRates?.data ?? null
);

export const getSVCEarnRateSelector = createSelector(
  accrualEarnRatesDataSelector,
  isPartnerSelector,
  (earnRates, isPartner) => {
    if (!earnRates) return null;
    return isPartner
      ? earnRates?.[SVC_EARN_RATE_TYPE]?.employeeEarnRate
      : earnRates?.[SVC_EARN_RATE_TYPE]?.standardEarnRate;
  }
);

export const getGenericEarnRateSelector = createSelector(
  accrualEarnRatesDataSelector,
  isPartnerSelector,
  (earnRates, isPartner) => {
    if (!earnRates) return null;
    return isPartner
      ? earnRates?.[GENERIC_EARN_RATE_TYPE]?.employeeEarnRate
      : earnRates?.[GENERIC_EARN_RATE_TYPE]?.standardEarnRate;
  }
);

export const shouldFetchAccrualEarnRates = createSelector(
  accrualEarnRatesSelector,
  (earnRatesList) => {
    if (
      shouldUpdate(earnRatesList, {
        staleTime: ms.weeks(1),
      })
    ) {
      return true;
    }
    return null;
  }
);
