/* eslint-disable no-use-before-define */
import { getBffBaseUrl } from 'shared/app/utils/config-helper';

import NonSmsNumberDialog, {
  NON_SMS_NUMBER_DIALOG_ID,
} from '../components/non-sms-number-dialog';

import {
  SEND_MFA_CODE,
  SEND_MFA_CODE_SUCCESS,
  SEND_MFA_CODE_ERROR,
  VERIFY_MFA_CODE,
  VERIFY_MFA_CODE_SUCCESS,
  VERIFY_MFA_CODE_ERROR,
  VERIFY_MFA_CODE_SECURE_SESSION,
  VERIFY_MFA_CODE_SECURE_SESSION_SUCCESS,
  VERIFY_MFA_CODE_SECURE_SESSION_ERROR,
  VERIFY_MFA_CODE_FOR_LOGIN,
  VERIFY_MFA_CODE_FOR_LOGIN_SUCCESS,
  VERIFY_MFA_CODE_FOR_LOGIN_ERROR,
  SET_MFA_CODE_DELIVERY_METHOD,
} from '../actions';

import {
  SEND_MFA_CODE_MUTATION,
  VERIFY_MFA_CODE_MUTATION,
} from '../../../../universal/gql-operation-ids';

export const signalMfaCodeSuccessSent = () => ({ type: SEND_MFA_CODE_SUCCESS });
export const signalMfaCodeErrorSent = (err) => ({
  type: SEND_MFA_CODE_ERROR,
  err,
});

export const setMfaCodeDeliveryMethod = (deliveryMethod) => ({
  type: SET_MFA_CODE_DELIVERY_METHOD,
  deliveryMethod,
});

const sendMfaCodeForLogin =
  ({
    phoneId,
    phoneNumber,
    deliveryMethod,
    onSendCodeSuccess = () => {},
    onSendCodeError = () => {},
    openModal,
  } = {}) =>
  (dispatch, getState, { apiFetch }) => {
    dispatch({ type: SEND_MFA_CODE });
    return apiFetch(`${getBffBaseUrl()}/bff/account/signin/mfa/send-code`, {
      method: 'post',
      body: {
        phoneId,
        phoneNumber,
        deliveryMethod,
        verificationType: 'login',
      },
      includeRisk: true,
    })
      .then((response) => {
        dispatch({
          type: SEND_MFA_CODE_SUCCESS,
          payload: response,
        });
        onSendCodeSuccess();
      })
      .catch((error) => {
        dispatch({ type: SEND_MFA_CODE_ERROR, error });
        if (error?.data?.message === 'Phone is not SMS capable') {
          dispatch(
            showNonSmsNumberDialog({
              phoneId,
              phoneNumber,
              verificationType: 'login',
              onSendCodeSuccess,
              onSendCodeError,
              openModal,
            })
          );
        } else {
          onSendCodeError(error);
        }
      });
  };

export const sendMfaCode =
  ({
    phoneId,
    phoneNumber,
    verificationType = 'secureSession',
    deliveryMethod = 'preferred',
    onSendCodeSuccess = () => {},
    onSendCodeError = () => {},
    openModal,
  } = {}) =>
  (dispatch, getState, { gqlFetch }) => {
    const variables = {
      sendMfaCodeRequest: {
        phoneId,
        verificationType,
        deliveryMethod,
      },
    };

    if (verificationType === 'login') {
      return dispatch(
        sendMfaCodeForLogin({
          phoneNumber,
          deliveryMethod,
          onSendCodeSuccess,
          onSendCodeError,
          openModal,
        })
      );
    }

    dispatch({ type: SEND_MFA_CODE });
    return gqlFetch({
      operationId: SEND_MFA_CODE_MUTATION,
      variables,
      includeRisk: true,
    }).then(
      (payload) => {
        dispatch({
          type: SEND_MFA_CODE_SUCCESS,
          payload: payload.sendMfaCode,
        });
        onSendCodeSuccess();
      },
      (error) => {
        dispatch({ type: SEND_MFA_CODE_ERROR, error });
        if (error && error.message === 'Phone is not SMS capable') {
          dispatch(
            showNonSmsNumberDialog({
              phoneId,
              phoneNumber,
              verificationType,
              onSendCodeSuccess,
              onSendCodeError,
              openModal,
            })
          );
        } else {
          onSendCodeError(error);
        }
      }
    );
  };

export const showNonSmsNumberDialog =
  ({
    phoneId,
    phoneNumber,
    verificationType,
    onSendCodeSuccess,
    onSendCodeError,
    openModal,
  }) =>
  (dispatch) => {
    openModal({
      component: NonSmsNumberDialog,
      ariaLabelledBy: NON_SMS_NUMBER_DIALOG_ID,
      componentProps: {
        phoneNumber,
        phoneId,
        verificationType,
        onSendCodeSuccess,
        onSendCodeError,
        onConfirm: () =>
          dispatch(
            sendMfaCode({
              phoneId,
              verificationType,
              deliveryMethod: 'phoneVoice',
              onSendCodeSuccess,
              onSendCodeError,
              openModal,
            })
          ),
      },
    });
  };

export const verifyMfaCode =
  (data) =>
  (dispatch, getState, { gqlFetch }) => {
    const variables = {
      verifyMfaCodeRequest: {
        phoneId: data.phoneId,
        code: data.verificationCode,
      },
    };

    dispatch({ type: VERIFY_MFA_CODE });
    return gqlFetch({
      operationId: VERIFY_MFA_CODE_MUTATION,
      variables,
      includeRisk: true,
    }).then(
      (payload) => {
        dispatch({
          type: VERIFY_MFA_CODE_SUCCESS,
          payload: payload.verifyMfaPhoneNumber,
        });
        return payload.verifyMfaPhoneNumber;
      },
      (error) => {
        dispatch({ type: VERIFY_MFA_CODE_ERROR, error });
        throw error;
      }
    );
  };

export const verifyMfaCodeForSecureSession =
  (verifyMfaCodePayload) =>
  (dispatch, getState, { apiFetch }) => {
    dispatch({ type: VERIFY_MFA_CODE_SECURE_SESSION });
    return apiFetch(
      `${getBffBaseUrl()}/bff/account/mfa/verify-challenge-code`,
      {
        method: 'post',
        body: verifyMfaCodePayload,
        includeRisk: true,
      }
    )
      .then((payload) => {
        dispatch({ type: VERIFY_MFA_CODE_SECURE_SESSION_SUCCESS, payload });
        return payload;
      })
      .catch((error) => {
        dispatch({ type: VERIFY_MFA_CODE_SECURE_SESSION_ERROR, error });
        throw error;
      });
  };

export const verifyMfaCodeForLogin =
  ({ challengeCode, username, password }) =>
  (dispatch, getState, { apiFetch }) => {
    dispatch({ type: VERIFY_MFA_CODE_FOR_LOGIN });
    return apiFetch(`${getBffBaseUrl()}/bff/account/signin/mfa`, {
      method: 'post',
      body: {
        username,
        password,
        challengeCode,
      },
      includeRisk: true,
      includeUab: true,
    })
      .then(() => {
        dispatch({ type: VERIFY_MFA_CODE_FOR_LOGIN_SUCCESS });
        return true; // return literal true for code verify successful to match other two verify methods
      })
      .catch((error) => {
        dispatch({ type: VERIFY_MFA_CODE_FOR_LOGIN_ERROR, error });
        throw error;
      });
  };
