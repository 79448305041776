import { defineMessages } from 'react-intl';

const messages = defineMessages({
  phoneNumberExistenceError: {
    id: 'shared.phoneNumberField.existenceError',
    defaultMessage: 'Please enter phone number',
    description: 'Error message requesting to provide a phone number',
  },
  phoneNumber: {
    id: 'shared.phoneNumberField.label',
    defaultMessage: 'Phone number',
    description: 'Label for phone number input',
  },
});

export default messages;
