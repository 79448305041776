import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import { starBalanceSelector } from 'shared/app/bundles/user';

import { Star, generateStarInfo, ANIMATION_TIME_MAX_MS } from './star';
import styles from './styles.cssm';

export const STARS_IN_ANIMATION = 30;
let resetTimeout;

/*
 * Animates stars 'falling into' the star next to the star balance when we've detected the user has accumulated new stars
 */
export class CollectingStars extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showStars: false,
      isAnimating: false,
    };
    this.startAnimation = this.startAnimation.bind(this);
  }

  componentDidUpdate(prevProps) {
    const isIncrementing =
      typeof prevProps.totalStars === 'number' &&
      typeof this.props.totalStars === 'number' &&
      this.props.totalStars > prevProps.totalStars;

    if (isIncrementing) {
      this.startAnimation();
    }
  }

  componentWillUnmount() {
    if (resetTimeout) {
      clearTimeout(resetTimeout);
    }
  }

  startAnimation() {
    // Add stars to dom
    this.setState({
      showStars: true,
    });

    setTimeout(() => {
      // Add animation class
      this.setState({
        isAnimating: true,
      });
    }, 0);

    resetTimeout = setTimeout(() => {
      // Remove stars and animation class
      this.setState({
        showStars: false,
        isAnimating: false,
      });
    }, ANIMATION_TIME_MAX_MS);
  }

  render() {
    const { isMobileViewport } = this.props;
    const starProps = [];

    if (this.state.showStars) {
      for (let i = 0; i < STARS_IN_ANIMATION; i++) {
        starProps.push(generateStarInfo(isMobileViewport));
      }
    }

    return (
      <div className={styles.nucleus}>
        {this.state.showStars &&
          starProps.map((starProp, index) => (
            <Star
              key={index}
              {...starProp}
              isAnimating={this.state.isAnimating}
            />
          ))}
      </div>
    );
  }
}

const select = (state) => {
  return {
    isMobileViewport: isMobileViewportSelector(state),
    totalStars: starBalanceSelector(state),
  };
};

export default connect(select)(CollectingStars);
