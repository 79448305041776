import PropTypes from 'prop-types';
import React from 'react';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Card from '@starbucks-web/pattern-library/lib/components/card';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

const MarketingCard = ({
  className = '',
  title,
  titleHeadingLevel,
  bodyMessage,
  buttonProps,
}) => (
  <ContentColumn className="text-center pb4 md-pb9" size="md">
    <Card
      containerClasses={`${className} bg-colorGreenLightSecondary px2 md-px8 py5`}
      disableBoxShadow
    >
      <Heading
        className="mb2 text-bold text-upper color-greenAccent"
        size="xxs"
        tagName={titleHeadingLevel}
      >
        {title}
      </Heading>
      <p className="mb3 color-textBlack">{bodyMessage}</p>
      <Button {...buttonProps} />
    </Card>
  </ContentColumn>
);

MarketingCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  titleHeadingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  bodyMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  buttonProps: PropTypes.object,
};

export default MarketingCard;
