import React from 'react';
import { useIntl } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading/';
import DialogWithMessages from 'shared/app/components/dialog-with-messages';

import messages from './messages';

export const NON_SMS_NUMBER_DIALOG_ID = 'NonSmsNumberDialogId';

const NonSmsNumberDialog = ({ phoneNumber, onConfirm }) => {
  const { formatMessage } = useIntl();

  return (
    <DialogWithMessages
      cancelText={formatMessage(messages.nonSmsNumberDialogCancel)}
      confirmText={formatMessage(messages.nonSmsNumberDialogConfirm)}
      onConfirm={onConfirm}
    >
      <Heading
        className="text-bold mb3"
        id={NON_SMS_NUMBER_DIALOG_ID}
        size="md"
        tagName="h2"
      >
        {formatMessage(messages.nonSmsNumberDialogHeading)}
      </Heading>
      <p>
        {formatMessage(messages.nonSmsNumberDialogHeading, { phoneNumber })}
      </p>
    </DialogWithMessages>
  );
};

export default NonSmsNumberDialog;
