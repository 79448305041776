import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';

import OfflineIndicator from '@starbucks-web/pattern-library/lib/components/offline-indicator';
import { sharedNotificationMessages } from 'shared/app/messages/notifications';

import AsyncLoadingIndicator from './async-loading-indicator';
import ErrorNotification from './error-notification';
import GlobalBottomDrawer from './global-bottom-drawer';
import GlobalBottomSheet from './global-bottom-sheet';
import GlobalDialog from './global-dialog';

/*
 * #bottom-sheet-target and #notifications-target divs are in the html payload
 from the server. See src/shared/server/lib/stream-to-html/index.js
 * z-indexes are applied in the html to manage layering.
 * For now, it will house all of our older style modal layers until they are converted
 * to the ModalProvider pattern. Eventually, this layer will only have the notifications,
 * which always need to be above any open modal layers.
 */
const notificationsTargetId = 'notifications-target';

const bottomSheetTargetId = 'bottom-sheet-target';

const GlobalLayers = ({ routeOptions }) => {
  const { formatMessage } = useIntl();

  const bottomSheetTargetElement = document.getElementById(bottomSheetTargetId);

  const notificationsTargetElement = document.getElementById(
    notificationsTargetId
  );

  return (
    <Fragment>
      {ReactDOM.createPortal(<GlobalBottomSheet />, bottomSheetTargetElement)}
      {ReactDOM.createPortal(
        <Fragment>
          {/* Notifications should appear above any open modals */}
          <div className="relative" style={{ zIndex: 1 }}>
            {/* GlobalBottomDrawer contains Toast notifications plus Crust */}
            <GlobalBottomDrawer routeOptions={routeOptions} />
            <ErrorNotification />
          </div>
          {/*
          Dialog should layer above other modal-like layers as some other layers will trigger a dialog, or the reauth-modal may open when bottom sheets are open, etc.
        */}
          <div className="relative" style={{ zIndex: 2 }}>
            <GlobalDialog />
          </div>
          {/*
          Make sure this is visible above any open modal layer
        */}
          <OfflineIndicator
            className="relative"
            label={formatMessage(sharedNotificationMessages.offlineIndicator)}
            style={{ zIndex: 3 }}
          />
          {/*
          Loading indicator has a z-index of 10 set directly in the pattern library. Would need to
          updated the pattern library component if we want this to be configurable, but it works as is
          with current layout needs.
        */}
          <AsyncLoadingIndicator />
        </Fragment>,
        notificationsTargetElement
      )}
    </Fragment>
  );
};

export default GlobalLayers;
