import { getWindow } from 'shared/app/utils/window';
import qs from 'query-string';

export default (paramValue = Date.now()) => {
  const win = getWindow();
  const { search, pathname, hash } = win.location;
  const newQuery = qs.parse(search);

  newQuery.bustprecache = paramValue;
  win.location.href = `${pathname}?${qs.stringify(newQuery)}${hash || ''}`;
};
