import { get } from 'lodash';
import { createSelector } from 'reselect';
import { configSelector } from 'shared/app/shell/state/selectors/config';
import readCookie from 'shared/app/utils/read-cookie';

const optimizelyConfigSelector = createSelector(configSelector, (config) =>
  get(config, 'universal.optimizely')
);

const optimizelyCookieNameSelector = createSelector(
  optimizelyConfigSelector,
  (config) => get(config, 'cookieName')
);

export const optimizelyEnvironmentSelector = createSelector(
  optimizelyConfigSelector,
  (config) => get(config, 'environment')
);

export const optimizelyLogLevelSelector = createSelector(
  optimizelyConfigSelector,
  (config) => get(config, 'logLevel')
);

export const optimizelyDataFileTimeoutSelector = createSelector(
  optimizelyConfigSelector,
  (config) => get(config, 'dataFileTimeout')
);

export const optimizelySdkKeySelector = createSelector(
  optimizelyConfigSelector,
  (config) => get(config, 'sdkKey')
);

export const optimizelyUserIdSelector = createSelector(
  optimizelyCookieNameSelector,
  (cookieName) => readCookie(cookieName)
);
