import React from 'react';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import starPath from '@starbucks-web/pattern-library/lib/icons/star';

import Repeat from 'shared/app/components/repeat';

import styles from './animated-stars-bar.cssm';

const BUBBLE_COUNT = 20;

const STARS_COUNT = 20;
const STAR_SIZE_MIN = 5;
const STAR_SIZE_MAX = 20;

const Bubble = ({ index }) => {
  const bubbleIndex = `bubble${index}`;
  return <div className={`absolute ${styles.bubble} ${styles[bubbleIndex]}`} />;
};

const Star = ({ index }) => {
  const starIndex = `star${index}`;
  const getStarSize = () =>
    `${
      STAR_SIZE_MIN + Math.round(Math.random() * STAR_SIZE_MAX - STAR_SIZE_MIN)
    }px`;

  return (
    <div className={`absolute ${styles.star} ${styles[starIndex]}`}>
      <Icon path={starPath} size={getStarSize()} />
    </div>
  );
};

const AnimatedStarsBar = ({ className }) => {
  return (
    <div
      className={`absolute overflow-hidden size12of12 bg-gold ${
        styles.container
      } ${className || ''}`}
    >
      <Repeat times={BUBBLE_COUNT}>
        {(index) => <Bubble index={index} key={`bubble${index}`} />}
      </Repeat>
      <Repeat times={STARS_COUNT}>
        {(index) => <Star index={index} key={`star${index}`} />}
      </Repeat>
    </div>
  );
};

export default AnimatedStarsBar;
