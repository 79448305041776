import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import ExternalLink from 'shared/app/components/external-link';
import { rewardsTermsOfUseUrlSelector } from 'shared/app/state/selectors/privacy-and-terms';

import { sharedCallsToAction } from 'shared/app/messages/ctas';

const SomeRestrictionsApplyButton = ({ otherProps }) => {
  const rewardsTermsOfUseUrl = useSelector(rewardsTermsOfUseUrlSelector);
  const { formatMessage } = useIntl();
  return (
    <ExternalLink
      data-e2e="someRestrictionsApplyButton"
      href={rewardsTermsOfUseUrl}
      visualStyle="button"
      {...otherProps}
    >
      {formatMessage(sharedCallsToAction.restrictionsApply)}
    </ExternalLink>
  );
};

export default SomeRestrictionsApplyButton;
