import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';

import SharedPasswordField from '../password-field';
import PasswordFieldStatusMessages from './status-messages';

import messages from './messages';

const PasswordFieldWithValidation = ({ field, fieldProps, label }) => {
  const { formatMessage } = useIntl();
  const handleBlur = (ev) => {
    // A delay (in ms) before closing the input's validation message on blur.
    // If we close the expanded message immediately, the page shifts, which in some
    // cases can prevent the user's click from hitting the right element (radio buttons
    // in particular). A 100ms delay is fast enough that users won't notice, but slow enough
    // that their click lands where they intend.
    const CLOSE_DELAY = 100;

    if (field?.input?.onBlur) {
      field.input.onBlur(ev);
    }

    window.setTimeout(() => {}, CLOSE_DELAY);
  };

  return (
    <div>
      <SharedPasswordField
        {...field.input}
        autoCapitalize="off"
        autoComplete="new-password"
        autoCorrect="off"
        customFieldStatus
        error={shouldDisplayError(field)}
        errorMessage={<PasswordFieldStatusMessages field={field} />}
        maxLength={25}
        onBlur={handleBlur}
        required
        spellCheck={false}
        {...fieldProps}
        hintText={formatMessage(messages.passwordAriaHint)}
      >
        {label}
      </SharedPasswordField>
    </div>
  );
};

PasswordFieldWithValidation.propTypes = {
  field: PropTypes.object.isRequired,
  fieldProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default PasswordFieldWithValidation;
