import React from 'react';
import SwipeDeck from '@starbucks-web/pattern-library/lib/components/swipe-deck';
import { useIntl } from 'react-intl';

import {
  carouselControlAriaMessages as swipeDeckAriaMessages,
  sharedCallsToAction,
} from 'shared/app/messages';

const SwipeDeckWithMessages = ({ children, ...swipeDeckProps }) => {
  const { formatMessage } = useIntl();
  const swipeDeckSlideCount = children?.length ?? 0;
  const carouselLabel = swipeDeckProps?.carouselProps?.carouselLabel;

  return (
    <SwipeDeck
      carouselProps={{
        messages: {
          ariaDescription: formatMessage(
            swipeDeckAriaMessages.mainDescription,
            {
              slideCount: swipeDeckSlideCount,
            }
          ),
          ariaLabel: formatMessage(swipeDeckAriaMessages.ariaLabel, {
            carouselLabel,
          }),
          ariaLabelNext: formatMessage(swipeDeckAriaMessages.nextButton),
          ariaLabelPrev: formatMessage(swipeDeckAriaMessages.prevButton),
          ariaLabelPages: (pageNumber) =>
            formatMessage(swipeDeckAriaMessages.pageButtons, {
              pageNumber,
            }),
        },
      }}
      closeButtonAriaLabel={formatMessage(sharedCallsToAction.closeLabel)}
      {...swipeDeckProps}
    >
      {children}
    </SwipeDeck>
  );
};

export default SwipeDeckWithMessages;
