import { defineMessages } from 'react-intl';

export default defineMessages({
  addressError: {
    id: 'shared.addressLineFields.missingError',
    defaultMessage: 'Enter your address',
    description: 'Error message displayed when address is missing',
  },
  addressLine2Instruction: {
    id: 'shared.addressLineFields.line2.instruction',
    defaultMessage: 'Optional',
    description: 'Label for address line 2 field instruction message',
  },
  addressLine2Label: {
    defaultMessage: 'Address line 2',
    description: 'Label for address line 2 field',
    id: 'shared.addressLineFields.line2.label',
  },
  addressLine1Label: {
    defaultMessage: 'Address line 1',
    description: 'Label for address line 1 field',
    id: 'shared.addressLineFields.line1.label',
  },
});
