import { useEffect, useState } from 'react';
import config from 'config';
import { useSelector } from 'react-redux';
import { FEATURES } from 'shared/universal/optimizely-keys';
import { optimizelyUserIdSelector } from 'shared/app/state/selectors/optimizely';

const optimizelyEnvironment = config.get('universal.optimizely.environment');

const useGcoV2Flag = (optimizely) => {
  const optimizelyUserId = useSelector(optimizelyUserIdSelector);
  const attributes = {
    environment: optimizelyEnvironment,
    'ux_exp_id': optimizelyUserId,
  };

  const [isGcoV2Enabled, setIsGcoV2Enabled] = useState(false);
  const [isGcoMarketingEnabled, setIsGcoMarketingEnabled] = useState(false);

  useEffect(() => {
    optimizely.onReady().then(() => {
      const isGcoV2FlagEnabled = optimizely.isFeatureEnabled(
        FEATURES.WEB_GCO_V2,
        optimizelyUserId,
        attributes
      );
      setIsGcoV2Enabled(isGcoV2FlagEnabled);

      const isGcoMarketingFlagEnabled = optimizely.isFeatureEnabled(
        FEATURES.WEB_GCO_MARKETING,
        optimizelyUserId,
        attributes
      );
      setIsGcoMarketingEnabled(isGcoMarketingFlagEnabled);
    });
  }, [optimizelyUserId]);

  return { isGcoV2Enabled, isGcoMarketingEnabled };
};

export default useGcoV2Flag;
