import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PreventFocus from '@starbucks-web/pattern-library/lib/components/prevent-focus';

import ErrorBoundary from 'shared/app/components/error-boundary';
import MainElement from 'shared/app/components/main-element';
import PageNotFound from 'shared/app/components/page-not-found';
import SessionCheckProvider from 'shared/app/shell/lib/session-check-provider';

import GlobalContentCrate from './global-content-crate';
import GlobalFooter from './global-footer';
import GlobalHeaderCrate from './global-header-crate';
import useModalsOpenedByUrlHash from './use-modals-by-url';

import { is404Selector } from 'shared/app/state/selectors/routes';

import useTermsAndPrivacy from './use-terms-and-privacy';
import styles from './styles.cssm';
import useMfa from './use-mfa';

const MainContent = ({
  authSatisfied,
  hasGlobalNav,
  nav,
  preventFocusTargets,
  routeOptions,
}) => {
  const is404 = useSelector(is404Selector);

  useTermsAndPrivacy();
  useModalsOpenedByUrlHash();
  const { contentLoading } = useMfa({ routeOptions });

  const usesFullPageComponent = Boolean(routeOptions?.fullPageComponent);
  const FullPageComponent = routeOptions?.fullPageComponent;
  const isCrateLayout = Boolean(
    routeOptions?.headerCrate || routeOptions?.usesCrateLayout
  );
  const useFixedDesktopNav = Boolean(
    isCrateLayout || routeOptions?.useFixedDesktopNav
  );

  if (is404) {
    return <PageNotFound />;
  }

  return (
    <SessionCheckProvider>
      <PreventFocus
        className={classNames({
          'flex-auto': routeOptions?.usesFullPageFlexLayout,
          [styles.globalNavPadding]: hasGlobalNav && useFixedDesktopNav,
        })}
        enabled={preventFocusTargets.all}
      >
        <Fragment>
          <MainElement
            className={classNames({
              'flex height-100': routeOptions?.usesFullPageFlexLayout,
              [styles.pushFooterOutOfView]:
                !routeOptions?.usesFullPageFlexLayout,
            })}
          >
            {usesFullPageComponent ? (
              <ErrorBoundary className="py9">
                <FullPageComponent
                  hasGlobalNav={hasGlobalNav}
                  preventFocusTargets={preventFocusTargets}
                />
              </ErrorBoundary>
            ) : (
              <React.Fragment>
                <GlobalHeaderCrate
                  authSatisfied={authSatisfied}
                  hasGlobalNav={hasGlobalNav}
                  preventFocusTargets={preventFocusTargets}
                  routeOptions={routeOptions}
                />
                <GlobalContentCrate
                  authSatisfied={authSatisfied}
                  contentLoading={contentLoading}
                  nav={nav}
                  preventFocusTargets={preventFocusTargets}
                  routeOptions={routeOptions}
                />
              </React.Fragment>
            )}
          </MainElement>
          {usesFullPageComponent ? (
            // GlobalFooter for content crate pages is in the GlobalContentCrate
            <GlobalFooter nav={nav} routeOptions={routeOptions} />
          ) : null}
        </Fragment>
      </PreventFocus>
    </SessionCheckProvider>
  );
};

export default MainContent;
