import { defineMessages } from 'react-intl';

export default defineMessages({
  redemptionHeading: {
    id: 'shared.giftReceipt.giftReceiptFooter.redemptionHeading',
    defaultMessage: 'About eGift card redemption',
    description: 'Footer heading for eGift redemption',
  },
  redemptionBody: {
    id: 'shared.giftReceipt.giftReceiptFooter.redemptionBody',
    defaultMessage:
      'Once an eGift card is registered to a Starbucks account, it can no longer be re-registered or redeemed by anyone else — so make sure you only send it to the correct recipient.',
    description: 'Footer body for eGift redemption',
  },
  viewOrdersHeading: {
    id: 'shared.giftReceipt.giftReceiptFooter.viewOrdersHeading',
    defaultMessage: 'How to view all eGift orders',
    description: 'Footer heading',
  },
  viewOrdersBody1: {
    id: 'shared.giftReceipt.giftReceiptFooter.viewOrdersBody1',
    defaultMessage:
      'For Senders of an eGift with a Starbucks account, go to eGift History when signed in to view, send, or resend purchased eGifts — or contact Starbucks eGift Support for help with your order.',
    description: 'Footer body for viewing eGift receipts',
  },
  viewOrdersBody2: {
    id: 'shared.giftReceipt.giftReceiptFooter.viewOrdersBody2',
    defaultMessage:
      'We also send order receipts to the email address associated with your eGift order. For orders made with Apple Pay, check the inbox of your Apple Pay email address.',
    description: 'Footer body for viewing eGift receipts',
  },
  questionsHeading: {
    id: 'shared.giftReceipt.giftReceiptFooter.questionsHeading',
    defaultMessage: 'Questions around eGift cards?',
    description: 'Footer heading for eGift questions',
  },
  questionsBody: {
    id: 'shared.giftReceipt.giftReceiptFooter.questionsBody',
    defaultMessage:
      'Check our Frequently Asked Questions — your question might already be answered.',
    description: 'Footer body for eGift questions',
  },
  questionsCTA: {
    id: 'shared.giftReceipt.giftReceiptFooter.questionsCTA',
    defaultMessage: 'Card FAQs',
    description: 'Footer CTA for eGift questions',
  },
  additionalHelpHeading: {
    id: 'shared.giftReceipt.giftReceiptFooter.additionalHelpHeading',
    defaultMessage: 'Need additional help? Contact us.',
    description: 'Footer heading for eGift additional help',
  },
  additionalHelpBody1: {
    id: 'shared.giftReceipt.giftReceiptFooter.additionalHelpBody1',
    defaultMessage:
      'For Senders of an eGift, contact Starbucks eGift Support for any questions regarding:',
    description: 'Footer body before unordered list for eGift additional help',
  },
  additionalHelpListItem1: {
    id: 'shared.giftReceipt.giftReceiptFooter.additionalHelpListItem1',
    defaultMessage: 'Your eGift purchase',
    description:
      'Footer unordered list item number 1 for what items customer call center can provide eGift help',
  },
  additionalHelpListItem2: {
    id: 'shared.giftReceipt.giftReceiptFooter.additionalHelpListItem2',
    defaultMessage: 'eGift card delivery',
    description:
      'Footer unordered list item number 2 for what items customer call center can provide eGift help',
  },
  additionalHelpListItem3: {
    id: 'shared.giftReceipt.giftReceiptFooter.additionalHelpListItem3',
    defaultMessage: 'Status of an eGift card',
    description:
      'Footer unordered list item number 3 for what items customer call center can provide eGift help',
  },
  additionalHelpListItem4: {
    id: 'shared.giftReceipt.giftReceiptFooter.additionalHelpListItem4',
    defaultMessage: 'And more',
    description:
      'Footer unordered list item number 4 for what items customer call center can provide eGift help',
  },
  additionalHelpBody2: {
    id: 'shared.giftReceipt.giftReceiptFooter.additionalHelpBody2',
    defaultMessage: 'Please have your order number below handy:',
    description: 'Footer body after unordered list for eGift additional help',
  },
  additionalHelpBody3: {
    id: 'shared.giftReceipt.giftReceiptFooter.additionalHelpBody3',
    defaultMessage:
      'If the order number is unavailable, it’s likely your order didn’t process.',
    description: 'Footer body 2 after order number for eGift additional help',
  },
  callSupportCta: {
    id: 'shared.giftReceipt.giftReceiptFooter.callSupportCta',
    defaultMessage: 'Call Support:',
    description: 'CTA text to call customer support',
  },
  emailSupportCta: {
    id: 'shared.giftReceipt.giftReceiptFooter.emailSupportCta',
    defaultMessage: 'Email Support',
    description: 'CTA text to email customer support',
  },
});
