import { ADD_NOTIFICATION, DISMISS_NOTIFICATION } from '../actions';
import { sharedNotificationMessages } from 'shared/app/messages';

export const addNotification = (body, options) => ({
  type: ADD_NOTIFICATION,
  payload: {
    body,
    ...options,
  },
});

export const dismissNotification = () => {
  return { type: DISMISS_NOTIFICATION };
};

export const showErrorNotification = (opts) =>
  addNotification(null, {
    ...opts,
    autoClose: false,
    type: 'error',
  });

export const showUnexpectedErrorNotification = (opts) =>
  showErrorNotification({
    messageId: sharedNotificationMessages.unexpectedError.id,
    ...opts,
  });
