// Thin layer on top of idb-keyval with support for versioning and max age
import * as idbKeyval from 'idb-keyval';

import { omit } from 'lodash';

import {
  encryptData as defaultEncrypt,
  decryptData as defaultDecrypt,
} from './encryption';
import { getDebugLogger } from '../shell/utils/debug';
const log = getDebugLogger('orange');

const defaultOpts = {
  // Per-record metadata
  maxAge: Infinity,
  version: 0,
  sessionCheckValue: null,

  // Options
  throwErrors: false,
  unencryptedKeys: [],
  anonymousKeys: [],
  contentLanguageSpecificKeys: [],

  // Default implementations
  lib: idbKeyval,
  keyPromise: Promise.resolve(null),
  encryptData: defaultEncrypt,
  decryptData: defaultDecrypt,
};

const getOpts = (opts) => Object.assign({}, defaultOpts, opts);

const isValidKey = (key) => key !== 'vector';

const isUserSpecificKey = (anonymousKeys) => (key) =>
  isValidKey(key) && anonymousKeys.indexOf(key) === -1;

const shouldEncryptKey = (key, { unencryptedKeys = [], anonymousKeys = [] }) =>
  unencryptedKeys.indexOf(key) === -1 && anonymousKeys.indexOf(key) === -1;

export const clearUserSpecifiedCaches = (opts) => {
  const { anonymousKeys, lib, throwErrors } = getOpts(opts);

  return lib
    .keys()
    .then((retrievedKeys) => {
      const validKeys = retrievedKeys
        .filter(isUserSpecificKey(anonymousKeys))
        .map((key) => lib.del(key));
      return Promise.all(validKeys);
    })
    .catch((err) => {
      if (throwErrors) {
        throw err;
      }
      return null;
    });
};

export const clearContentLanguageSpecificCaches = (opts) => {
  const { contentLanguageSpecificKeys, lib, throwErrors } = getOpts(opts);
  return Promise.all(
    contentLanguageSpecificKeys.map((key) => lib.del(key))
  ).catch((err) => {
    if (throwErrors) {
      throw err;
    }
    return null;
  });
};

export const clearAllCached = (opts) => {
  const { lib } = getOpts(opts);
  return lib
    .clear()
    .then(() => log('IndexedDB cache cleared'))
    .catch((err) => log('Error in clearing all on signout: ', err));
};

export const clearCachedItem = (name, opts) => {
  const { lib, throwErrors } = getOpts(opts);
  return lib.del(name).catch((err) => {
    if (throwErrors) {
      throw err;
    }
    return null;
  });
};

export const getCachedItem = (name, opts) => {
  const {
    unencryptedKeys,
    anonymousKeys,
    maxAge,
    version,
    sessionCheckValue,
    throwErrors,
    lib,
    keyPromise,
    decryptData,
  } = getOpts(opts);

  const shouldEncrypt = shouldEncryptKey(name, {
    unencryptedKeys,
    anonymousKeys,
  });

  return (
    lib
      .get(name)
      .then((data) =>
        data && shouldEncrypt ? decryptData(keyPromise, data) : data
      )
      .then(JSON.parse)
      // if it fails here we failed to decrypt or JSON.parse
      .catch((err) => {
        if (shouldEncrypt) {
          // We need to catch both decryption and parse exceptions in the same place becasue
          // some browsers don't throw errors when there's an issue trying to decrypt something.
          // In the case of when the data isn't decrypted properly a SyntaxError should expected
          // to be thrown because garbage returned from the decryption shouldn't be valid JSON.
          throw Error('DecryptionFailed');
        } else if (err.name === 'SyntaxError') {
          const parseJsonError = Error('ParseJsonFailed');
          parseJsonError.cacheName = name;
          throw parseJsonError;
        }
      })
      .then((parsed) => {
        const age = Date.now() - parsed.time;
        if (age > maxAge) {
          lib.del(name);
          return null;
        }

        if (version !== parsed.version) {
          throw Error('CacheVersionFailed');
        }

        if (
          isUserSpecificKey(anonymousKeys)(name) &&
          sessionCheckValue !== parsed.sessionCheckValue
        ) {
          throw Error('SessionCheckMismatch');
        }

        return {
          age,
          data: parsed.data,
        };
      })
      .catch((err) => {
        if (throwErrors) {
          throw err;
        }
        return null;
      })
  );
};

export const getAllCached = (customOpts) => {
  const opts = getOpts(customOpts);
  const childOpts = Object.assign({}, opts, { throwErrors: true });

  let validKeys;

  return opts.lib
    .keys()
    .then((retrievedKeys) => {
      validKeys = retrievedKeys.filter(isValidKey);
      return Promise.all(validKeys.map((key) => getCachedItem(key, childOpts)));
    })
    .then((cachedItems = []) =>
      cachedItems
        .map((item) => item?.data)
        .reduce((acc, bundleData, index) => {
          if (bundleData) {
            acc[validKeys[index]] = bundleData;
          }
          return acc;
        }, {})
    )
    .then((data) => data)
    .catch((err) => {
      switch (err.message) {
        // Clear everything on cache version mismatch...
        case 'CacheVersionFailed':
          log('clearing cache: version mismatch');
          return clearUserSpecifiedCaches(
            omit(childOpts, 'anonymousKeys')
          ).then(() => getAllCached(opts));

        // ...but allow anonymous keys to remain on decryption failure
        case 'DecryptionFailed':
        case 'SessionCheckMismatch':
          log(`clearing user specified cache: ${err.message}`);
          return clearUserSpecifiedCaches(childOpts).then(() =>
            getAllCached(opts)
          );

        case 'ParseJsonFailed':
          log(`clearing cache: ${err.cacheName}`);
          // Only clear the cache that had the error in JSON parse
          return clearCachedItem(err.cacheName, childOpts);

        default:
          log('getAllCached failed:', err);
          return {};
      }
    });
};

export const cacheItem = (name, data, opts) => {
  const {
    unencryptedKeys,
    anonymousKeys,
    version,
    sessionCheckValue,
    throwErrors,
    lib,
    keyPromise,
    encryptData,
  } = getOpts(opts);

  const jsonString = JSON.stringify({
    time: Date.now(),
    sessionCheckValue,
    version,
    data,
  });

  return (
    Promise.resolve()
      .then(() => {
        if (shouldEncryptKey(name, { unencryptedKeys, anonymousKeys })) {
          return encryptData(keyPromise, jsonString);
        }
        return jsonString;
      })
      .then((toSave) => lib.set(name, toSave))
      // return true to signal success
      .then(() => true)
      // but never throw, this functionality is additive
      .catch((err) => {
        if (throwErrors) {
          return { error: err };
        }
        return null;
      })
  );
};
