const hasJsonContent = (response) => {
  const contentType = response.headers.get('content-type');
  if (!contentType) {
    return false;
  }
  return contentType.indexOf('application/json') > -1;
};

// Use this in fetch `.then` callbacks to redirect erroneous responses to the `.catch` callback.
const parseResponseOrThrow = function (response) {
  const status = response.status;
  if (status >= 200 && status < 400) {
    if (hasJsonContent(response)) {
      return response.json();
    }

    return response;
  }

  const httpError = new Error(response.statusText);
  httpError.status = response.status;
  httpError.response = response;
  throw httpError;
};

// Handling of the same behavior as `parseResponseOrThrow` for use with XHR requests.
const parseXhrResponseOrThrow = function (response) {
  const status = response.statusCode;

  if (status >= 200 && status < 400) {
    return response;
  }

  const httpError = new Error(response.statusText);
  httpError.status = status;
  httpError.response = response;
  throw httpError;
};

const parseResponseBody = (response) => {
  return hasJsonContent(response)
    ? response
        .json()
        .then((body) => ({ response, body }))
        // Catch errors where response JSON has a syntax error,
        // or there was no JSON in the response body.
        .catch(() => ({ response, body: null }))
    : { response, body: null };
};

module.exports = {
  parseResponseBody,
  parseResponseOrThrow,
  parseXhrResponseOrThrow,
};
