import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import PrivacyPermissionsConfirmChangeDialog, {
  TERMS_PRIVACY_CONFIRM_CHANGE_DIALOG_ID,
} from './privacy-permissions-confirm-change-dialog';
import { setPrivacyPermissions } from 'shared/app/state/action-creators/privacy-permissions';

import messages from './messages';

export const TERMS_PRIVACY_BROWSER_SETTINGS_DIALOG_ID =
  'PrivacyPermissionsBrowserSettingsDialogId';

const PrivacyPermissionsBrowserSettingsDialog = () => {
  const { formatMessage } = useIntl();
  const { openModal, closeModal } = useModalContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPrivacyPermissions({
        optIn: false,
      })
    );
  }, []);

  return (
    <DialogWithMessages
      cancelText={formatMessage(messages.privacyPermissionsOptBackIn)}
      confirmText={formatMessage(messages.privacyPermissionsDismiss)}
      onCancel={() => {
        closeModal();

        // Adding a delay to avoid a racing condition
        setTimeout(
          () =>
            openModal({
              component: PrivacyPermissionsConfirmChangeDialog,
              ariaLabelledBy: TERMS_PRIVACY_CONFIRM_CHANGE_DIALOG_ID,
            }),
          0
        );
      }}
    >
      <Heading
        className="text-semibold mb3"
        id={TERMS_PRIVACY_BROWSER_SETTINGS_DIALOG_ID}
        size="lg"
        tagName="h2"
      >
        {formatMessage(messages.privacyPermissionsBrowserSettingsHeading)}
      </Heading>
      <p>{formatMessage(messages.privacyPermissionsBrowserSettingsText)}</p>
    </DialogWithMessages>
  );
};

export default PrivacyPermissionsBrowserSettingsDialog;
