import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Card from '@starbucks-web/pattern-library/lib/components/card';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import { commonMessages } from 'shared/app/messages/common-words';
import FormattedMoney from '../../formatted-money';

import PurchaseStatus from './purchase-status';
import SummaryRecipients from './recipients';

import receiptMessages from '../messages';
import messages from './messages';
import { SUCCESS_PURCHASE_STATUS } from '../constants';
import { svcMessages } from 'shared/app/messages/svc-cards';

const GiftSummary = ({
  giftPurchaseDetails: {
    amount,
    altText,
    message,
    numberOfRecipients,
    orderNumber,
    purchaseStatus,
    recipients,
    totalAmount,
    largeImageUrl,
    deliveryMethod,
  },
  onClick,
  GiftLinkInfo,
}) => {
  const { formatMessage } = useIntl();
  const cardArtAlt = `${formatMessage(svcMessages.giftAltContext)} ${altText}`;
  return (
    <React.Fragment>
      <Heading
        className="text-semibold text-center flex flex-column m3 md-mb5"
        tagName="h2"
      >
        <span className="color-textBlackSoft mb1 text-xs">
          <FormattedMessage {...messages.summaryHeading} />
        </span>
        <span className="text-lg">
          <FormattedMessage {...commonMessages.total} />{' '}
          <FormattedMoney value={totalAmount} />
        </span>
      </Heading>
      <Card containerClasses="md-mt2 mb5 lg-mb6 py2 px3 lg-px4">
        <PurchaseStatus
          altText={cardArtAlt}
          amount={amount}
          checkAgainOnClick={onClick}
          deliveryMethod={deliveryMethod}
          largeImageUrl={largeImageUrl}
          message={message}
          numberOfRecipients={numberOfRecipients}
          purchaseStatus={purchaseStatus}
          recipients={recipients}
        />
        {GiftLinkInfo && purchaseStatus === SUCCESS_PURCHASE_STATUS ? (
          <GiftLinkInfo
            deliveryMethod={deliveryMethod}
            numberOfRecipients={numberOfRecipients}
          />
        ) : null}
        <SummaryRecipients
          amount={amount}
          deliveryMethod={deliveryMethod}
          numberOfRecipients={numberOfRecipients}
          purchaseStatus={purchaseStatus}
          recipients={recipients}
        />
        <p className="mt3 text-right text-upper text-bold color-textBlackSoft">
          <FormattedMessage
            {...receiptMessages.orderNumber}
            values={{ orderNumber: orderNumber || false }}
          />
        </p>
      </Card>
    </React.Fragment>
  );
};

export default GiftSummary;
