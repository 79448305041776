'use strict';

// Parses optimizely variation overrides from querystring params.
// e.g. `?force_optly_${EXPERIMENT_KEY}=${variation}`
// https://docs.developers.optimizely.com/full-stack/docs/use-forced-bucketing
const parseForcedVariations = (params) => {
  const prefix = 'force_optly_';

  return Object.keys(params)
    .filter((key) => key.startsWith(prefix))
    .map((key) => {
      return [key.replace(prefix, ''), params[key]];
    });
};

const forceOptimizelyVariations = (
  optimizelyInstance,
  queryParams,
  optimizelyUserId
) => {
  parseForcedVariations(queryParams).forEach(([experiment, variation]) => {
    optimizelyInstance.setForcedVariation(
      experiment,
      optimizelyUserId,
      variation
    );
  });
};

module.exports = {
  forceOptimizelyVariations,
  parseForcedVariations,
};
