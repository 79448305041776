import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '@starbucks-web/pattern-library/lib/components/select';
import { injectIntl } from 'react-intl';
import messages from './messages';
import HeartsAnimation, { heartsAnimationDuration } from './hearts-animation';

export const NO_TIP_VALUE = '0';
export const tipAmountOptionsList = ['0.5', '1', '2', '5'];

export const TipAmountSelect = ({
  intl: { formatMessage, formatNumber, formats },
  tipAmount: currentTipAmount,
  setTipAmount,
  className = '',
  ...otherProps
}) => {
  const tipAmount =
    parseFloat(currentTipAmount) > 0 &&
    tipAmountOptionsList.includes(currentTipAmount.toString())
      ? currentTipAmount?.toString()
      : NO_TIP_VALUE;
  const [showHeartsAnimation, setShowHeartsAnimation] = useState(false);

  // Prevents memory leaks in React and gets rid of the console error below:
  // "Can't perform a React state update on an unmounted component.
  //  This is a no-op, but it indicates a memory leak in your application"
  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const onChangeSelection = (ev) => {
    const selectedTipValue = ev?.target?.value;
    if (!selectedTipValue) {
      return null;
    }
    setTipAmount(selectedTipValue);
    if (selectedTipValue !== NO_TIP_VALUE) {
      setShowHeartsAnimation(true);
      setTimeout(() => {
        if (isMounted) {
          setShowHeartsAnimation(false);
        }
      }, heartsAnimationDuration);
    }
  };

  return (
    <span className="relative flex-grow">
      <Select
        className={`${className}`}
        id="tipAmount"
        label={formatMessage(messages.tipAmountLabel)}
        name="tipAmount"
        onChange={onChangeSelection}
        value={tipAmount}
        {...otherProps}
      >
        <option value={NO_TIP_VALUE}>
          {formatMessage(messages.noTipLabel)}
        </option>
        {tipAmountOptionsList.map((tipAmountOption, index) => (
          <option key={`tipAmountOption-${index}`} value={tipAmountOption}>
            {formatNumber(tipAmountOption, formats.number.money)}
          </option>
        ))}
      </Select>
      <HeartsAnimation showHeartsAnimation={showHeartsAnimation} />
    </span>
  );
};

TipAmountSelect.propTypes = {
  tipAmount: PropTypes.string,
  setTipAmount: PropTypes.func,
  className: PropTypes.string,
};

export default injectIntl(TipAmountSelect);
