import { Link } from 'react-router-dom';

import { sharedCallsToAction } from 'shared/app/messages';

export default ({ doLogin, doLogout, formatMessage, isCoreAppUrl, route }) => {
  const findAStoreProps = isCoreAppUrl
    ? {
        to: '/store-locator',
        tagName: Link,
      }
    : {
        href: '/store-locator',
      };
  return {
    findAStorePin: {
      // findAStore props are formatted differently than the rest of the buttons
      // because of the way the FindAStore component is set up in the pattern library
      active: Boolean(route && route.startsWith('/store-locator')),
      className: 'pr2',
      otherProps: { ...findAStoreProps },
      text: formatMessage(sharedCallsToAction.findAStore),
    },
    joinNowButton: {
      'data-e2e': 'joinRewardsLink',
      href: '/account/create',
      children: formatMessage(sharedCallsToAction.joinNow),
    },
    signInButton: {
      'data-e2e': 'signInButton',
      onClick: doLogin,
      children: formatMessage(sharedCallsToAction.signin),
    },
    signOutButton: {
      'data-e2e': 'signOutHamburgerNav',
      onClick: doLogout,
      children: formatMessage(sharedCallsToAction.signOut),
      tagName: 'button',
    },
  };
};
