export const extractAsArray = (apps, appName) =>
  apps.reduce((arr, app) => arr.concat(app[appName] || []), []);

export const extractByAppName = (apps, appName) =>
  apps.reduce((obj, app) => {
    if (!app[appName]) {
      return obj;
    }
    return Object.assign(obj, { [app.name]: app[appName] });
  }, {});
