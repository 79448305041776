import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Rule from '@starbucks-web/pattern-library/lib/components/rule';
import Button from '@starbucks-web/pattern-library/lib/components/button';

import { isGiftHistoryRouteSelector } from 'shared/app/state/selectors/routes';

import FormattedMoney from '../../formatted-money';
import CopyLinkCta from '../copy-link-cta';
import sharedStyles from '../styles.cssm';
import messages from './messages';

const SummaryRecipients = ({
  numberOfRecipients,
  recipients,
  amount,
  children,
  purchaseStatus,
  deliveryMethod,
}) => {
  const isGiftHistoryRoute = useSelector(isGiftHistoryRouteSelector);
  return (
    <React.Fragment>
      <Heading className="text-bold text-upper text-xs mt4" tagName="h3">
        <FormattedMessage
          {...messages.recipientHeading}
          values={{ numberOfRecipients }}
        />
      </Heading>
      <ul>
        {recipients?.map((recipient, i) => {
          const copyLink = recipient.copyLink;
          const LinkStatus = () => {
            if (purchaseStatus === 'success' && copyLink) {
              return (
                <CopyLinkCta
                  TagName={Button}
                  color="black"
                  copyLink={copyLink}
                  isEmail
                  recipientName={recipient.recipientName}
                />
              );
            } else if (purchaseStatus === 'pending') {
              return <FormattedMessage {...messages.linkProcessing} />;
            }
            return (
              <span className="color-red">
                <FormattedMessage {...messages.linkUnavailable} />
              </span>
            );
          };
          return (
            <React.Fragment key={`email-summary-recipient${i}`}>
              <li className="py3">
                <p className="flex justify-between">
                  <span className="mr2 text-semibold">
                    <FormattedMessage {...messages.to} />{' '}
                    {recipient.recipientName ?? (
                      <FormattedMessage {...messages.iMessageRecipient} />
                    )}
                  </span>
                  <FormattedMoney className="text-semibold" value={amount} />
                </p>
                <span className={sharedStyles.wrapWord}>
                  {recipient.recipientEmail}
                </span>
                {children || null}
                {deliveryMethod?.toLowerCase() === 'email' &&
                isGiftHistoryRoute ? (
                  <div className="text-right mt2">
                    <LinkStatus />
                  </div>
                ) : null}
              </li>
              {i === recipients.length - 1 ? null : <Rule weight="thin" />}
            </React.Fragment>
          );
        })}
      </ul>
    </React.Fragment>
  );
};

export default SummaryRecipients;
