import {
  GET_TERMS_PRIVACY_STATUS,
  SET_TERMS_PRIVACY_STATUS_SUCCESS,
} from '../action-creators/types';

import createSingleResourceReducer from '../../stale-reducers/single-resource-reducer';

export default createSingleResourceReducer({
  startAction: GET_TERMS_PRIVACY_STATUS,
  alternativeSuccessActions: [SET_TERMS_PRIVACY_STATUS_SUCCESS],
}).reducer;
