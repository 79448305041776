import React from 'react';
import { useIntl } from 'react-intl';
import Overlay from '@starbucks-web/pattern-library/lib/components/overlay';

import { sharedCallsToAction } from 'shared/app/messages/ctas';

const OverlayWithCloseMessage = ({
  closeProps = {},
  containerProps = {},
  ...otherProps
}) => {
  const intl = useIntl();

  return (
    <Overlay
      closeProps={{
        ariaLabel: intl.formatMessage(sharedCallsToAction.closeLabel),
        'data-e2e': 'overlay-close-button',
        ...closeProps,
      }}
      containerProps={{
        'data-e2e': 'overlay-container',
        ...containerProps,
      }}
      {...otherProps}
    />
  );
};

export default OverlayWithCloseMessage;
