import { get } from 'lodash';
import replaceHttpWithHttps from 'shared/universal/replace-http-with-https';

// A version of the cards transform tuned to the GraphQL response.
// Use this instead of `cards-transform` for all GraphQL queries and
// mutations. Once everything is converted to GraphQL, we can replace the
// default transform with this one, or, even better, remove the transforms
// entirely.
export default (card) =>
  Object.assign({}, card, {
    cardImageUrl: replaceHttpWithHttps(get(card, 'imageUrls.iosLargeHighRes')),
    paySheetCardImageUrl: replaceHttpWithHttps(
      get(card, 'imageUrls.iosImageStripMedium')
    ),
    thumbImageUrl: replaceHttpWithHttps(get(card, 'imageUrls.imageIcon')),
  });
