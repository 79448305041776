import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadScript,
  applePayScriptUrl,
  applePayScriptLoadedSelector,
} from 'shared/app/bundles/scripts';

export const useLoadApplePayScript = () => {
  const applePayScriptLoaded = useSelector(applePayScriptLoadedSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!applePayScriptLoaded) {
      dispatch(loadScript(applePayScriptUrl));
    }
  }, [applePayScriptLoaded]);
};
