import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import CloseButton from '@starbucks-web/pattern-library/lib/components/close-button';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

const CloseButtonWithMessages = ({ ariaLabel, intl, ...otherProps }) => (
  <CloseButton
    ariaLabel={ariaLabel || intl.formatMessage(sharedCallsToAction.closeLabel)}
    {...otherProps}
  />
);

CloseButtonWithMessages.propTypes = {
  ariaLabel: PropTypes.string,
};

export default injectIntl(CloseButtonWithMessages);
