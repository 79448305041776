import { clearAllCached, clearUserSpecifiedCaches } from './cache-helper';

export default ({
  cacheOptions,
  clearLocalStorage = true,
  explicitSignOut = false,
} = {}) => {
  if (clearLocalStorage) {
    // localStorage access in a try-catch because even if supported,
    // this will throw errors when cookies are disabled.
    try {
      // store and re-configure debug flag if set. This is just for developer convenience.
      const debug = window.localStorage.debug;
      window.localStorage.clear();
      if (debug) {
        window.localStorage.debug = debug;
      }
    } catch (e) {} // eslint-disable-line no-empty
  }

  return explicitSignOut
    ? clearAllCached(cacheOptions)
    : clearUserSpecifiedCaches(cacheOptions);
};
