import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import { enums, OptimizelyProvider } from '@optimizely/react-sdk';

import optimizelyManager from './optimizely-manager';
import {
  exIdSelector,
  hasFetchedProfileSelector,
  signedInSelector,
} from 'shared/app/bundles/user/state/selectors';
import { localeTagSelector } from 'shared/app/state/selectors/locales';
import {
  optimizelyUserIdSelector,
  optimizelyEnvironmentSelector,
  optimizelyDataFileTimeoutSelector,
} from 'shared/app/state/selectors/optimizely';
import { trackEvent } from 'shared/app/utils/uo-tracking';
import { forceOptimizelyVariations } from 'shared/universal/force-optly-variations';
import isBrowser from 'shared/app/shell/utils/is-browser';

// Report experiment impression event to UO for `mParticle` integration. `type` can be
// `ab-test` or `feature`.
const trackImpressionEvent = ({ userId, attributes, decisionInfo, type }) => {
  const decisionAttributes =
    type === 'feature'
      ? {
          'decisionInfo.featureKey': decisionInfo.featureKey,
          'decisionInfo.featureEnabled': decisionInfo.featureEnabled,
        }
      : {
          'decisionInfo.experimentKey': decisionInfo.experimentKey,
          'decisionInfo.variationKey': decisionInfo.variationKey,
        };

  const optimizelyAttributes = {
    optimizelyUserId: userId,
    optimizelyType: type,
    ...attributes,
    ...decisionAttributes,
  };

  trackEvent('OPTIMIZELY_RUN', optimizelyAttributes);
};

const Provider = ({
  blockRendering,
  children,
  dataFileTimeout,
  optimizelyUserId,
  userAttributes,
}) => {
  const optimizelyInstance = optimizelyManager.getInstance();

  useEffect(() => {
    const { DECISION } = enums.NOTIFICATION_TYPES;

    optimizelyInstance.onReady({ timeout: dataFileTimeout }).then(() => {
      optimizelyInstance.notificationCenter.addNotificationListener(
        DECISION,
        trackImpressionEvent
      );

      forceOptimizelyVariations(
        optimizelyInstance,
        qs.parse(window?.location?.search),
        optimizelyUserId
      );
    });

    return function cleanup() {
      optimizelyInstance.notificationCenter.clearNotificationListeners(
        DECISION
      );
    };
  }, []);

  return blockRendering ? null : (
    <OptimizelyProvider
      isServerSide={!isBrowser}
      optimizely={optimizelyInstance}
      timeout={dataFileTimeout}
      user={{
        id: optimizelyUserId,
        attributes: userAttributes,
      }}
    >
      {children}
    </OptimizelyProvider>
  );
};

Provider.propTypes = {
  blockRendering: PropTypes.bool,
  dataFileTimeout: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  optimizelyUserId: PropTypes.string.isRequired,
  userAttributes: PropTypes.shape({
    environment: PropTypes.string.isRequired,
    exId: PropTypes.string,
    'is_user_authenticated': PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

const select = (state) => {
  const isSignedIn = signedInSelector(state);
  const optimizelyUserId = optimizelyUserIdSelector(state);

  return {
    blockRendering: isSignedIn && !hasFetchedProfileSelector(state),
    dataFileTimeout: optimizelyDataFileTimeoutSelector(state),
    optimizelyUserId,
    userAttributes: {
      environment: optimizelyEnvironmentSelector(state),
      exId: exIdSelector(state),
      'is_user_authenticated': isSignedIn,
      locale: localeTagSelector(state),
      'ux_exp_id': optimizelyUserId,
    },
  };
};

export default connect(select)(Provider);
