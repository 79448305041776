import { defineMessages } from 'react-intl';

export default defineMessages({
  navLabel: {
    id: 'shared.footer.navLabel',
    defaultMessage: 'Global footer',
    description: 'Accessible label for nav element in footer',
  },
  finePrint: {
    id: 'shared.footer.finePrint',
    defaultMessage:
      '© {currentYear} Starbucks Coffee Company. All rights reserved.',
    description: 'Footer fine print with copyright and all rights reserved.',
  },
});
