// state changers re-used among both reducers
export const getInitialState = () => ({
  data: null,
  loading: false,
  lastFetch: null,
  lastError: null,
});

export const startState = (item) =>
  Object.assign(getInitialState(), item, {
    loading: true,
    permanentFail: false,
  });

export const successState = (item, data) => {
  return Object.assign({}, item, {
    loading: false,
    stale: false,
    lastFetch: Date.now(),
    lastError: null,
    mutationError: null,
    data,
  });
};

export const errorState = (item, error) => {
  return Object.assign({}, item, {
    loading: false,
    lastError: Date.now(),
    permanentFail: error?.permanentFail,
    error,
  });
};

export const mutationErrorState = (item, error) => {
  return Object.assign({}, item, {
    loading: false,
    mutationError: error,
  });
};

export const staleState = (item) => {
  return Object.assign({}, item, {
    stale: true,
  });
};
