import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import {
  doMfaCodeChallenge,
  verifyMfaAccess,
} from 'shared/app/shell/state/action-creators/mfa-challenge.js';

const useMfa = ({ routeOptions }) => {
  const [contentLoading, setContentLoading] = useState(false);
  const { openModal } = useModalContext();
  const dispatch = useDispatch();

  const handleVerifyMfaAccess = () => {
    setContentLoading(true);
    dispatch(verifyMfaAccess())
      .then(({ requiresMfaChallenge }) => {
        if (requiresMfaChallenge) {
          dispatch(doMfaCodeChallenge({ openModal }));
        }
      })
      .finally(() => {
        setContentLoading(false);
      });
  };

  const { mfaAuthRequired } = routeOptions || {};

  useEffect(() => {
    if (mfaAuthRequired) {
      handleVerifyMfaAccess();
    }
  }, [mfaAuthRequired]);

  return { contentLoading };
};

export default useMfa;
