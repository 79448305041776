import { useContext, useEffect } from 'react';
import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';
import { useDispatch, useSelector } from 'react-redux';
import { setPlaceName } from 'store-locator/app/state/actions/location';
import { ModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import OrderingUnavailableDialog, {
  ORDERING_UNAVAILABLE_DIALOG_ID,
} from 'shared/app/components/ordering-unavailable-dialog/index.js';
import { debounce } from 'lodash';
import {
  shouldDisplayNotAvailableStoreDialogSelector,
  shouldFetchMapsOrderingLocationsSelector,
} from '../state/selectors';

export const useBetaMapsOrdering = () => {
  const selectedStore = useSelector(selectedStoreSelector) ?? {};
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);
  const shouldFetchMapsOrderingLocations = useSelector(
    shouldFetchMapsOrderingLocationsSelector
  );
  const shouldDisplayNotAvailableStoreDialog = useSelector(
    shouldDisplayNotAvailableStoreDialogSelector
  );
  const { store } = selectedStore;
  const isStoreOpen = store?.open;

  useEffect(() => {
    if (shouldFetchMapsOrderingLocations) {
      const postalCode = store?.address?.postalCode;
      const setZipInStoreLocator = () => {
        dispatch(setPlaceName(postalCode));
      };
      const debouncedGoToLocation = debounce(setZipInStoreLocator, 200);
      debouncedGoToLocation(postalCode);
    }
  }, [shouldFetchMapsOrderingLocations]);

  useEffect(() => {
    if (shouldDisplayNotAvailableStoreDialog) {
      openModal({
        component: OrderingUnavailableDialog,
        ariaLabelledBy: ORDERING_UNAVAILABLE_DIALOG_ID,
        componentProps: {
          rendersOnMenu: true,
          selectedStore,
          isStoreOpen,
          showAccountRequiredMessage: false, // not scoped for GCO - July '24
        },
      });
    }
  }, [shouldDisplayNotAvailableStoreDialog]);
};
