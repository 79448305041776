import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import Field from '@starbucks-web/pattern-library/lib/components/field';

import { svcMessages } from 'shared/app/messages';
import InputSvcImage from '../stored-value-cards-select/input-svc-image';

export const StaticSvcField = ({ svcCard, className, inputProps }) => {
  const { formatMessage, formatNumber, formats } = useIntl();

  const { nickname, cardImageUrl, balance } = svcCard ?? {};

  // replace any spaces in name for use as id attribute
  const nicknameId = nickname.replace(/\s+/g, '');

  return (
    <div className={`flex ${className ?? ''}`}>
      <InputSvcImage imageUrl={cardImageUrl} />
      <div className="flex-grow">
        <Field
          id={`static-svc-field-${nicknameId}`}
          readOnly
          value={`${formatNumber(balance?.amount, formats.number.money)}`}
          {...inputProps}
        >
          {formatMessage(svcMessages.starbucksCard)}
        </Field>
      </div>
    </div>
  );
};

StaticSvcField.propTypes = {
  svcCard: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default StaticSvcField;
