import React from 'react';
import { injectIntl, FormattedNumber } from 'react-intl';

const FormattedMoney = ({ intl: { formats }, value, ...otherProps }) => (
  <span {...otherProps}>
    <FormattedNumber {...formats.number.money} value={value} />
  </span>
);

export default injectIntl(FormattedMoney);
