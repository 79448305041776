import React from 'react';
import { FormattedMessage } from 'react-intl';

import { commonMessages } from 'shared/app/messages';

export const starbucksRewardsTrademarkMessage =
  commonMessages.starbucksRewardsTrademark;

const StarbucksRewardsTrademark = () => (
  <FormattedMessage {...commonMessages.starbucksRewardsTrademark} />
);

export default StarbucksRewardsTrademark;
