'use strict';

const IN_STORE_FEATURE_CODE = '16';
const DRIVE_THRU_FEATURE_CODE = 'DT';
const CURBSIDE_FEATURE_CODE = 'CX';
const OUTDOOR_FEATURE_CODE = '17';

module.exports = {
  IN_STORE_FEATURE_CODE,
  DRIVE_THRU_FEATURE_CODE,
  OUTDOOR_FEATURE_CODE,
  CURBSIDE_FEATURE_CODE,
};
