import PropTypes from 'prop-types';
import React from 'react';
import { prefixErrorMessage } from 'shared/app/components/a11y-error-message-prefix';
import { useIntl } from 'react-intl';
import Field from '@starbucks-web/pattern-library/lib/components/field';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';

import messages from './messages';
import { formMessages } from 'shared/app/messages';

const CityField = ({ city, ...otherProps }) => {
  const { formatMessage } = useIntl();
  const cityErrorMessage = prefixErrorMessage(
    city.errorMessage || messages.cityError
  );

  return (
    <Field
      {...city.input}
      autoComplete="address-level2"
      className="mb2"
      data-e2e="city"
      error={shouldDisplayError(city)}
      errorMessage={cityErrorMessage}
      maxLength={60}
      maxLengthAriaMessage={formatMessage(formMessages.maxLengthAriaMessage)}
      {...otherProps}
    >
      {formatMessage(messages.city)}
    </Field>
  );
};

CityField.propTypes = {
  city: PropTypes.object,
};

CityField.defaultProps = {
  city: {
    input: {
      id: 'city',
      value: '',
    },
  },
};

export default CityField;
