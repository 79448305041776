import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import config from 'config';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import SpilledCoffeeCanvas from '@starbucks-web/pattern-library/lib/components/spilled-coffee-canvas';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import LinkList from '@starbucks-web/pattern-library/lib/components/link-list';

import Footer from '../footer';
import H1 from 'shared/app/components/h1';
import MainElement from '../main-element';
import { isHolidaySeason } from 'shared/app/utils/is-seasonal';
import messages from './messages';

const PageNotFound = () => {
  const { formatMessage } = useIntl();
  const drinkMap = config.get('fourOhFourDrinkMap');
  const [randomDrink] = useState(() => {
    const index = Math.floor(Math.random() * drinkMap.length);
    return drinkMap[index];
  });

  const { name, color, hiddenContent } = randomDrink;

  return (
    <MainElement className="lg-my9 items-center">
      <Helmet title={formatMessage(messages.title)} />
      <ContentColumn className="bg-white py3 m4 mb9" size="lg">
        <H1 className="lg-mt5 text-bold" size="lg">
          <FormattedMessage {...messages.heading} />
        </H1>
        <p className="my3 text-sm">
          <FormattedMessage {...messages.instructions} values={{ name }} />{' '}
          <FormattedMessage {...messages.imageInstructions} />
        </p>
        <div className="lg-flex justify-between items-center">
          <SpilledCoffeeCanvas
            className="size12of12 mx-auto lg-size8of12"
            eraseDiameter={25}
            hiddenContent={hiddenContent}
            holidayCup={isHolidaySeason()}
            imageDescription={formatMessage(messages.imageDescription, {
              name,
            })}
            instructions={formatMessage(messages.imageInstructions)}
            spillColor={color}
          />
          <div className="lg-size4of12 lg-ml6">
            <Heading className="mt7 lg-mt0 mb3" size="md" tagName="h2">
              <FormattedMessage {...messages.linkListHeading} />
            </Heading>
            <LinkList
              className="ml3"
              linkClassName="color-greenAccent text-semibold text-noUnderline mb3"
              links={[
                {
                  text: formatMessage(messages.linkListCta1),
                  href: '/menu',
                  tagName: 'a',
                },
                {
                  text: formatMessage(messages.linkListCta2),
                  href: '/gift',
                  tagName: 'a',
                },
                {
                  text: formatMessage(messages.linkListCta3),
                  href: '/',
                  tagName: 'a',
                },
              ]}
              paddingBetweenInlineLinks={2}
            />
          </div>
        </div>
      </ContentColumn>
      <Footer />
    </MainElement>
  );
};

export default PageNotFound;
