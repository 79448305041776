import config from 'config';
import { setFlag, getFlag } from './local-storage-flags';

const TERMS_PRIVACY_FLAG = 'termsAcknowledgement';

export const setTermsPrivacyFlag = (version = null) => {
  const termsPrivacy = config.get('universal.termsPrivacy');
  if (termsPrivacy?.enableTermsPrivacyOverlay) {
    setFlag(TERMS_PRIVACY_FLAG, version || termsPrivacy?.version);
  }
};

export const getTermsPrivacyFlag = () => getFlag(TERMS_PRIVACY_FLAG);
