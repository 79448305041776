import config from 'config';
import { createSelector } from 'reselect';
import { signedInSelector } from 'shared/app/bundles/user';
import { getTermsPrivacyFlag } from 'shared/app/utils/local-storage-privacy-flag';

const enableTermsPrivacyOverlay = config.get(
  'universal.termsPrivacy.enableTermsPrivacyOverlay'
);

const termsPrivacyVersion = config.get('universal.termsPrivacy.version');

export const rawTermsPrivacy = (state) => state.termsPrivacy;

export const hasTermsAcknowledgementFlag =
  getTermsPrivacyFlag() === termsPrivacyVersion;

export const termsPrivacyDataSelector = createSelector(
  rawTermsPrivacy,
  (termsPrivacy) => {
    return termsPrivacy?.data;
  }
);

export const termsPrivacyDataFetchedSelector = createSelector(
  rawTermsPrivacy,
  (termsPrivacy) => {
    return termsPrivacy?.data && !termsPrivacy?.loading;
  }
);

export const shouldCheckTermsPrivacyStatusSelector = createSelector(
  signedInSelector,
  (signedIn) => enableTermsPrivacyOverlay && signedIn
);

export const termsPrivacyAcknowledgedSelector = createSelector(
  termsPrivacyDataSelector,
  (termsPrivacyData) =>
    termsPrivacyData?.acknowledged &&
    termsPrivacyData?.version === termsPrivacyVersion
);

export const shouldShowTermsPrivacyOverlaySelector = createSelector(
  shouldCheckTermsPrivacyStatusSelector,
  termsPrivacyAcknowledgedSelector,
  termsPrivacyDataFetchedSelector,
  (
    shouldCheckTermsPrivacyStatus,
    termsPrivacyAcknowledged,
    termsPrivacyDataFetched
  ) =>
    shouldCheckTermsPrivacyStatus &&
    !hasTermsAcknowledgementFlag &&
    termsPrivacyDataFetched &&
    !termsPrivacyAcknowledged
);
