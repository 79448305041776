import React from 'react';
import { useIntl } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading/';
import DialogWithMessages from 'shared/app/components/dialog-with-messages';

const noop = () => {};

export const NOTIFICATION_ERROR_DIALOG_ID = 'NotificationErrorDialogId';

const NotificationErrorDialog = ({
  title,
  translatedTitle,
  body,
  translatedBody,
  confirmText,
  cancelText,
  onCancel = noop,
  onConfirm = noop,
}) => {
  const { formatMessage } = useIntl();

  const cancelTextProp = cancelText
    ? { cancelText: formatMessage(cancelText) }
    : {};
  const confirmTextProp = confirmText
    ? { confirmText: formatMessage(confirmText) }
    : {};

  return (
    <DialogWithMessages
      {...cancelTextProp}
      {...confirmTextProp}
      confirmButtonOnly={!cancelText}
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()}
    >
      {title || translatedTitle ? (
        <Heading
          className="text-semibold mr4"
          id={NOTIFICATION_ERROR_DIALOG_ID}
          size="md"
          tagName="h2"
        >
          {translatedTitle || formatMessage(title)}
        </Heading>
      ) : null}

      {body || translatedBody ? (
        <p className="pt3 mr4">{translatedBody || formatMessage(body)}</p>
      ) : null}
    </DialogWithMessages>
  );
};

export default NotificationErrorDialog;
