import React from 'react';
import { MoveFocusInside } from 'react-focus-on';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import styles from './deli-ticket.cssm';

const DeliTicket = React.forwardRef(
  (
    {
      ariaLabel,
      body,
      callback = () => {},
      confirmText,
      onClose = () => {},
      title,
      ...otherProps
    },
    ref
  ) => {
    const confirmCallback = () => {
      callback();
      onClose();
    };

    return (
      <div
        aria-label={ariaLabel}
        className={`fixed flex justify-center sb-global-gutters ${styles.deliTicket}`}
        data-e2e="deliTicket"
        ref={ref}
        role="dialog"
        tabIndex="-1"
        {...otherProps}
      >
        <div
          className={`relative size12of12 flex flex-column py3 bg-neutralCool sb-global-gutters ${styles.content}`}
        >
          <div className="text-right order-2">
            <MoveFocusInside>
              <Button onClick={confirmCallback} visualStyle="textOnly">
                {confirmText}
              </Button>
            </MoveFocusInside>
          </div>
          <div className="flex flex-column flex-grow mb3 size12of12 order-1">
            {title && (
              <Heading className="mb3" size="md" tagName="h2">
                {title}
              </Heading>
            )}
            <p>{body}</p>
          </div>
        </div>
      </div>
    );
  }
);

export default DeliTicket;
