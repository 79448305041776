import { defineMessages } from 'react-intl';
import postalCodeMessages from 'shared/app/components/postal-code-field/messages';

export const messages = defineMessages({
  firstNameTooLongError: {
    id: 'shared.firstName.tooLongError',
    defaultMessage: 'First name must be 50 characters or less',
    description:
      'Error displayed when first name is too long. Must be 50 characters or less.',
  },
  lastNameTooLongError: {
    id: 'shared.lastName.tooLongError',
    defaultMessage: 'Last name must be 50 characters or less',
    description:
      'Error displayed when last name is too long, should be 50 characters or less.',
  },
  nameProfaneError: {
    id: 'shared.name.nameProfaneError',
    defaultMessage: 'Your name did not pass our filter. Modify and try again.',
    description:
      'Error displayed when first or last name fails profanity check.',
  },
  addressInvalidCharactersError: {
    id: 'shared.addressFields.invalidCharactersError',
    defaultMessage: 'Remove special characters',
    description:
      'Error displayed when invalid characters are specified for address.',
  },
  cityInvalidCharactersError: {
    id: 'shared.city.invalidCharactersError',
    defaultMessage: 'City name cannot have special characters',
    description:
      'Error displayed when invalid characters are specified for city.',
  },
  nameInvalidCharactersError: {
    id: 'shared.firstLastName.invalidCharactersError',
    defaultMessage:
      'First/Last name cannot have special characters or numerals',
    description:
      'Error displayed when invalid characters are specified for first/last name.',
  },
  invalidFirstNameError: {
    id: 'shared.firstName.invalidError',
    defaultMessage: 'Please enter a valid first name',
    description: 'Error displayed when an invalid first name is provided',
  },
});

const FIRSTNAME_FIELD = 'firstName';
const LASTNAME_FIELD = 'lastName';
const ADDRESS_LINE1_FIELD = 'addressLine1';
const CITY_FIELD = 'city';
const POSTALCODE_FIELD = 'postalCode';

export const errorCodeMap = {
  111025: {
    field: POSTALCODE_FIELD,
    message: postalCodeMessages.postalCodeError,
  },
  111036: {
    field: FIRSTNAME_FIELD,
    message: messages.nameInvalidCharactersError,
  },
  111046: {
    field: FIRSTNAME_FIELD,
    messageId: messages.invalidFirstNameError,
  },
  111062: {
    field: FIRSTNAME_FIELD,
    message: messages.firstNameTooLongError,
  },
  111064: {
    field: LASTNAME_FIELD,
    message: messages.lastNameTooLongError,
  },
  111362: {
    field: CITY_FIELD,
    message: messages.cityInvalidCharactersError,
  },
  111363: {
    field: ADDRESS_LINE1_FIELD,
    message: messages.addressInvalidCharactersError,
  },
  111090: {
    field: FIRSTNAME_FIELD,
    message: messages.nameProfaneError,
  },
  111091: {
    field: LASTNAME_FIELD,
    message: messages.nameProfaneError,
  },
};

export const getErrorDataByCode = (code) => {
  return errorCodeMap[code] ? errorCodeMap[code] : null;
};
