import { createSelector } from 'reselect';
import { cloneDeep, flow, isNumber } from 'lodash';

import {
  rewardLevelsSelector,
  starBalanceSelector,
} from '../../bundles/user/state/selectors';

export const TRACKER_INSET_PERCENTAGE = 6;

// export for testing only
export const setGoalPercentComplete = (trackerGoals, trackerStarProgress) => {
  // What percent of each goal on the tracker is completed by the user?
  // This will be needed to calulate the percent progress of the overall tracker.
  return trackerGoals.map((goal, idx) => {
    const previousGoalTotal =
      idx === 0 ? 0 : trackerGoals[idx - 1].totalStarsToEarn;
    const numberOfStarsInGoal = goal.totalStarsToEarn - previousGoalTotal;
    const userIsInThisRewardSegment =
      trackerStarProgress > previousGoalTotal &&
      trackerStarProgress <= goal.totalStarsToEarn;
    // the rewards schema tells us if a reward is available at this level,
    // the user may have a reward available, but their progress
    // toward the goal in the tracker is not complete yet, so we need a
    // 'complete' property in addition to 'available'
    const goalComplete = trackerStarProgress >= goal.totalStarsToEarn;
    let starsEarnedInGoal = 0;
    if (goalComplete) {
      starsEarnedInGoal = numberOfStarsInGoal;
    } else if (userIsInThisRewardSegment) {
      starsEarnedInGoal = trackerStarProgress - previousGoalTotal;
    }
    const percentProgressInGoal =
      Math.round((starsEarnedInGoal / numberOfStarsInGoal) * 1000) / 10;

    return Object.assign(
      goal,
      { complete: goalComplete },
      { percentProgressInGoal }
    );
  });
};

// export for testing only
export const setGoalPercentWidthAndLeft = (trackerGoals) => {
  // ---------------------------------------
  // First, calculate how much room on the tracker each segment gets.
  // This will flex depending on how many goals appear on the tracker.
  // ---------------------------------------

  // percent margin of the first and last goal marker away from ends of tracker
  const percentInset = TRACKER_INSET_PERCENTAGE;
  // If only one goal marker, it's at the right end of the tracker, less inset.
  // If multiple markers, they are spread out inside of the inset margin equidistantly.
  const percentOfTrackerUsedForGoalMarkers =
    trackerGoals.length > 1 ? 100 - percentInset * 2 : 100 - percentInset;
  const segmentsBeyondFirst = trackerGoals.length - 1;
  const percentPerSegment =
    trackerGoals.length === 1
      ? percentOfTrackerUsedForGoalMarkers
      : percentOfTrackerUsedForGoalMarkers / segmentsBeyondFirst;

  // ---------------------------------------
  // Then, for each goal, calculate where to position it on the tracker, and
  // the percentage width each goal is of the overall tracker
  // ---------------------------------------
  return trackerGoals.map((trackerGoal, idx) => {
    // we'll decorate each goal object with a few properties used in the UI
    const goal = Object.assign({}, { ...trackerGoal });

    // what percent of the tracker's length this goal segment takes up individually
    const segmentPercentWidth =
      idx === 0 && trackerGoals.length > 1 ? percentInset : percentPerSegment;

    // calculate how far along the track each segment's marker is located
    let markerPercentLeft;
    if (trackerGoals.length === 1) {
      markerPercentLeft = percentPerSegment;
    } else if (idx === 0) {
      markerPercentLeft = percentInset;
    } else {
      markerPercentLeft = percentInset + idx * percentPerSegment;
    }

    return Object.assign(goal, { markerPercentLeft }, { segmentPercentWidth });
  });
};

/* eslint-disable max-params */
export const trackerGoalsWithUiPropertiesSelector = createSelector(
  rewardLevelsSelector,
  starBalanceSelector,
  (rewardsLevels, starBalance) => {
    // In Starbucks Rewards program, there's only one major goal with sub-goals along the way
    // so the user's progress is just the user's starBalance
    const trackerStarProgress = starBalance;

    if (
      !isNumber(trackerStarProgress) ||
      !rewardsLevels ||
      rewardsLevels.length === 0
    ) {
      return null;
    }
    const goals = cloneDeep(rewardsLevels);
    const setGoalUiProperties = flow(
      setGoalPercentComplete,
      setGoalPercentWidthAndLeft
    );
    return setGoalUiProperties(goals, trackerStarProgress);
  }
);
/* eslint-enable max-params */

export const trackerPercentProgressSelector = createSelector(
  trackerGoalsWithUiPropertiesSelector,
  (trackerGoals) => {
    if (!trackerGoals) {
      return 0;
    }
    // ---------------------------------------
    // Calculate how much the progress within each goal segment of the tracker
    // contributes to the overall tracker progress, then add those together to get
    // the user's overall progress on the tracker. Because each goal can represent
    // a variable amount of stars, the overall progress is not necessarily a straight percentage
    // of the highest goal.
    //
    // Rough formula: If segment is 20% the width of the track and user has completed 50% of this reward level,
    // progress contributed by this segment to the overall tracker is 20 * 50% or 10%
    // ---------------------------------------
    return trackerGoals.reduce((runningTotal, thisGoal) => {
      const thisGoalContributesToOverallPercent =
        Math.round(
          thisGoal.segmentPercentWidth * thisGoal.percentProgressInGoal
        ) / 100;
      return runningTotal + thisGoalContributesToOverallPercent;
    }, 0);
  }
);

export const isTrackerCompletedSelector = createSelector(
  trackerGoalsWithUiPropertiesSelector,
  (trackerGoals) => {
    if (!trackerGoals || !trackerGoals.length) {
      return false;
    }
    return trackerGoals.every((goal) => goal && goal.complete === true);
  }
);
