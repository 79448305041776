import React from 'react';
import { connect } from 'react-redux';
import BottomSheet from '@starbucks-web/pattern-library/lib/components/sheets/bottom-sheet';
import PreventFocus from '@starbucks-web/pattern-library/lib/components/prevent-focus';

import { bottomSheetSelector } from 'shared/app/shell/state/selectors/app-ui';
import { hideBottomSheet as hideBottomSheetAction } from 'shared/app/shell/state/action-creators/app-lifecycle';

const GlobalBottomSheet = ({ bottomSheet, hideBottomSheet }) => {
  const {
    containerProps,
    contentProps,
    props,
    content: BottomSheetContent,
  } = bottomSheet || {};
  const hasBottomSheetContent = Boolean(BottomSheetContent);

  return (
    <PreventFocus enabled={!hasBottomSheetContent}>
      <BottomSheet
        closeProps={{
          'data-e2e': 'closeBottomSheet',
        }}
        containerProps={{
          'data-e2e': 'bottomSheetContainer',
          ...containerProps,
        }}
        crateWidth
        isOpen={hasBottomSheetContent}
        onCloseClick={hideBottomSheet}
        {...props}
      >
        {hasBottomSheetContent && <BottomSheetContent {...contentProps} />}
      </BottomSheet>
    </PreventFocus>
  );
};

const select = (state) => ({
  bottomSheet: bottomSheetSelector(state),
});

const actions = {
  hideBottomSheet: hideBottomSheetAction,
};

export default connect(select, actions)(GlobalBottomSheet);
