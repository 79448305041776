import { getAccertifyUab } from 'shared/app/utils/accertify';
import {
  IOVATION_MAX_DELAY,
  initIovation,
  bbComplete,
  bbValue,
} from './init-iovation';

export const getDeviceFingerprint = () => {
  return initIovation()
    .then(
      () =>
        new Promise((resolve) => {
          // Wait for the blackbox value to be generated by Iovation.
          const checkBlackBox = setInterval(() => {
            if (bbComplete) {
              clearInterval(checkBlackBox);
              resolve(bbValue);
            }
          }, 200);

          setTimeout(() => {
            clearInterval(checkBlackBox);
            resolve(bbValue);
          }, IOVATION_MAX_DELAY);
        })
    )
    .catch(() => bbValue);
};

const getCurrentCountryCode = () =>
  document.documentElement.lang?.split('-')?.[1] || 'US';

const getWebAppInfo = () => ({
  ccAgentName: 'WebApp',
  platform: 'Web',
  market: getCurrentCountryCode(),
});

// https://scm.starbucks.com/dpapi/account/blob/master/Account.WebApi/Models/Reputation.cs
export const getFraudReputation = async () => {
  const deviceFingerprint = await getDeviceFingerprint();
  return {
    ...getWebAppInfo(),
    deviceFingerprint,
    // ipAddress: will be set from `set-iovation-client-ip` middleware
  };
};

// https://scm.starbucks.com/starbucks-web/graphql/blob/main/src/schema/typeDefs/RiskObjectInput.graphql
export const getFraudRisk = async (includeUab = false) => {
  const uab = includeUab ? getAccertifyUab() : {};
  const deviceFingerprint = await getDeviceFingerprint();
  return {
    ...getWebAppInfo(),
    deviceFingerprint,
    reputation: {
      deviceFingerprint,
      ...uab,
      // iPAddress: will be set from `set-iovation-client-ip` middleware
    },
  };
};
