import {
  FETCH_ONBOARDING_FLAGS,
  UPDATE_ONBOARDING_FLAG_SUCCESS,
} from '../../actions';
import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

export default createSingleResourceReducer({
  startAction: FETCH_ONBOARDING_FLAGS,
  markStaleWhen: [UPDATE_ONBOARDING_FLAG_SUCCESS],
}).reducer;
