import React from 'react';
import { FormattedMessage } from 'react-intl';

const A11yErrorMessagePrefix = () => (
  <span className="hiddenVisually">
    <FormattedMessage
      defaultMessage="Error:"
      description="Field validation error message prefix for screen readers"
      id="shared.a11yErrorMessagePrefix"
    />
  </span>
);

export const prefixErrorMessage = (message) =>
  message && (
    <span>
      <A11yErrorMessagePrefix />
      {message.id ? <FormattedMessage {...message} /> : message}
    </span>
  );

export default A11yErrorMessagePrefix;
