import React, { useState } from 'react';
import './styles.css';
import FloatLabel from '@starbucks-web/pattern-library/lib/components/float-label';
import Ripple from '@starbucks-web/pattern-library/lib/components/ripple';
import { useIntl } from 'react-intl';
import messages from './messages';
import MessageCounter from './message-counter';

const TextArea = ({
  className,
  floatLabelText,
  id,
  maxLength,
  messageCounterId,
  onBlur,
  onChange,
  onFocus,
  rows = 3,
  value = '',
  ...otherProps
}) => {
  const { formatMessage } = useIntl();
  const [textAreaInput, setTextAreaInput] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }

    setTextAreaInput(event.target.value);
  };

  const handleFocus = (event) => {
    if (onFocus) {
      onFocus(event);
    }
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
    setIsFocused(false);
  };

  const getA11yMessage = () => {
    const current = textAreaInput.length;
    if (current === maxLength) {
      return formatMessage(messages.noCharactersRemaining);
    } else if (current / maxLength === 0.9) {
      return formatMessage(messages.numberCharactersRemaining, {
        remainingCharacterCount: maxLength - current,
      });
    }
    return '';
  };

  return (
    <Ripple
      backgroundClassName="bg-greenLight"
      className="sb-fieldBase__ripple"
    >
      {({ ripple, eventHandlers }) => {
        return (
          <div className={`sb-textArea overflow-hidden ${className || ''}`}>
            <div className="sb-textArea__childWrapper" {...eventHandlers}>
              {floatLabelText ? (
                <FloatLabel
                  active={Boolean(textAreaInput) || isFocused}
                  className="textArea__floatLabel"
                  htmlFor={id}
                  id="textArea-floatlabel"
                >
                  {floatLabelText}
                  <span
                    className="hiddenVisually"
                    htmlFor="textArea-floatlabel"
                  >
                    {formatMessage(messages.characterLimitLabel, {
                      maxCharacterCount: maxLength,
                    })}
                  </span>
                </FloatLabel>
              ) : (
                ''
              )}

              <textarea
                aria-describedby={messageCounterId}
                className="sb-textAreaInput"
                id={id}
                maxLength={`${maxLength}`}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                rows={rows}
                value={textAreaInput}
                {...otherProps}
              />
              {ripple}
            </div>
            <div className="flex justify-end mt2" id={messageCounterId}>
              <MessageCounter current={textAreaInput.length} max={maxLength} />
            </div>
            <span aria-live="polite" className="hiddenVisually">
              {getA11yMessage()}
            </span>
          </div>
        );
      }}
    </Ripple>
  );
};

export default TextArea;
