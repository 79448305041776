import React from 'react';
import { connect } from 'react-redux';
import {
  filteredCouponsSelector,
  allCouponsSelector,
} from 'shared/app/bundles/user/state/selectors/profile';
import CouponCard from './coupon-card';

export const Coupons = ({
  allCoupons,
  cardProps,
  className = '',
  filteredCoupons,
  canApplyToCart = false,
  onClick,
}) => {
  const couponCards = canApplyToCart ? filteredCoupons : allCoupons;
  if (!couponCards.length) {
    return null;
  }

  return couponCards.map((coupon, i) => (
    <CouponCard
      canApplyToCart={canApplyToCart}
      cardProps={cardProps}
      className={className}
      coupon={coupon}
      couponId={`coupon${i}`}
      key={`coupon${i}`}
      onClick={onClick}
    />
  ));
};

const select = (state) => ({
  allCoupons: allCouponsSelector(state),
  filteredCoupons: filteredCouponsSelector(state),
});

export default connect(select)(Coupons);
