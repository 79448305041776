import { get } from 'lodash';
import { createSelector } from 'reselect';
import shouldUpdate from 'shared/app/stale-reducers/should-update';
import { signedInSelector, userStateSelector } from './profile';

export const onboardingFlagsSelector = createSelector(
  userStateSelector,
  (user) => get(user, 'onboardingFlags')
);

export const onboardingFlagsDataSelector = createSelector(
  onboardingFlagsSelector,
  (onboardingFlags) => get(onboardingFlags, 'data')
);

export const hasFetchedOnboardingFlagsSelector = createSelector(
  onboardingFlagsSelector,
  (onboardingFlags) => Boolean(get(onboardingFlags, 'lastFetch'))
);

export const shouldFetchOnboardingFlagsSelector = createSelector(
  signedInSelector,
  onboardingFlagsSelector,
  (signedIn, onboardingFlags) => {
    return signedIn && shouldUpdate(onboardingFlags);
  }
);
