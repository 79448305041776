import readCookie from 'shared/app/utils/read-cookie';

import { DO_LOGOUT, SESSION_EXPIRED, UPDATE_SESSION_INFO } from '../../actions';
import { VERIFY_MFA_ACCESS_SUCCESS } from 'shared/app/shell/state/actions';
import {
  GUEST_SIGN_IN,
  GUEST_SIGN_IN_SUCCESS,
  GUEST_SIGN_IN_ERROR,
} from 'shared/app/state/action-creators/types';

import {
  reducer as profileReducer,
  initialState as profileInitialState,
} from './profile';
import onboardingFlagsReducer from './onboarding-flags';

import {
  reducer as mfaFactorsReducer,
  initialState as mfaFactorsInitialState,
} from './mfa';

import privacyPermissionsReducer from './privacy-permissions';

const getSessionHintValue = () => {
  const value = readCookie('s_check');
  try {
    const parsed = JSON.parse(value);
    return parsed;
  } catch (e) {
    return null;
  }
};

const initialState = {
  sessionExpired: false,
  loggedOut: false,
  reAuthEligible: null,
  profile: profileInitialState,
  mfaFactors: mfaFactorsInitialState,
  sessionMeta: getSessionHintValue(),
  privacyPermissions: null,
};

export default (state = initialState, action) => {
  state = {
    ...state,
    accountProfile: profileReducer(state.accountProfile, action),
    onboardingFlags: onboardingFlagsReducer(state.onboardingFlags, action),
    mfaFactors: mfaFactorsReducer(state.mfaFactors, action),
    privacyPermissions: privacyPermissionsReducer(
      state.privacyPermissions,
      action
    ),
  };
  switch (action.type) {
    case SESSION_EXPIRED: {
      const reAuthEligible = action?.payload?.reAuthEligible ?? null;
      return Object.assign({}, state, {
        sessionExpired: true,
        reAuthEligible,
        sessionMeta: getSessionHintValue(),
      });
    }

    case DO_LOGOUT: {
      return Object.assign({}, state, { loggedOut: true });
    }

    case UPDATE_SESSION_INFO: {
      return Object.assign({}, state, {
        sessionMeta: action.payload,
      });
    }

    case GUEST_SIGN_IN: {
      return { ...state, guestLoading: true };
    }

    case GUEST_SIGN_IN_SUCCESS: {
      return {
        ...state,
        sessionMeta: getSessionHintValue(),
        guestLoading: false,
      };
    }

    case GUEST_SIGN_IN_ERROR: {
      return { ...state, guestLoading: false };
    }

    case VERIFY_MFA_ACCESS_SUCCESS: {
      return Object.assign({}, state, {
        requiresMfaChallenge: action.payload.requiresMfaChallenge,
      });
    }

    default:
      return state;
  }
};
