import React from 'react';
import classnames from 'classnames';
import { MoveFocusInside } from 'react-focus-on';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import checkPath from '@starbucks-web/pattern-library/lib/icons/check';
import {
  ThemeContext,
  themes,
} from '@starbucks-web/pattern-library/lib/components/theme';

import CloseButton from 'shared/app/components/close-button-with-messages';

import styles from './toast.cssm';

const Title = ({ title }) =>
  title && (
    <Heading className="mb1" size="md" tagName="h2">
      {title}
    </Heading>
  );

const Success = ({ body, title, ...otherProps }) => (
  <div {...otherProps}>
    <Title title={title} />
    <p>
      <Icon className="mr3" path={checkPath} />
      {body}
    </p>
  </div>
);

const Confirm = ({
  body,
  callback,
  cancelText,
  confirmText,
  onClose,
  title,
  ...otherProps
}) => {
  return (
    <div className="size12of12" {...otherProps}>
      <Title title={title} />

      <div className="flex flex-wrap justify-between items-center">
        <span className="flex-shrink-none text-right order-2">
          <Button onClick={onClose}>{cancelText}</Button>
          <MoveFocusInside className="inline">
            <Button
              className="ml3"
              onClick={() => {
                callback();
                onClose();
              }}
              visualStyle="positive"
            >
              {confirmText}
            </Button>
          </MoveFocusInside>
        </span>
        <p className="py2 mr3 order-1">{body}</p>
      </div>
    </div>
  );
};

const DefaultMessage = ({ body, title, ...otherProps }) => (
  <div {...otherProps}>
    <Title title={title} />
    <p>{body}</p>
  </div>
);

const Message = (props) => {
  switch (props.type) {
    case 'success':
      return <Success {...props} />;
    case 'confirm':
      return <Confirm {...props} />;
    default:
      return <DefaultMessage {...props} />;
  }
};

const Toast = React.forwardRef(
  (
    {
      ariaLabel,
      body,
      callback = () => {},
      cancelText,
      confirmText,
      onClose = () => {},
      title,
      type,
    },
    ref
  ) => {
    const classes = classnames(
      styles.toast,
      'sb-global-gutters py3 lg-py4 flex items-center'
    );

    const dismissButton = (
      <MoveFocusInside>
        <CloseButton
          alignment="right"
          data-e2e="closeToastButton"
          onClick={onClose}
        />
      </MoveFocusInside>
    );

    return (
      <ThemeContext.Provider value={themes.dark}>
        <div
          aria-label={ariaLabel}
          className={classes}
          data-e2e="toast"
          ref={ref}
          tabIndex="-1"
        >
          {type !== 'confirm' ? dismissButton : null}
          <Message
            body={body}
            callback={callback}
            cancelText={cancelText}
            confirmText={confirmText}
            onClose={onClose}
            role="alert"
            title={title}
            type={type}
          />
        </div>
      </ThemeContext.Provider>
    );
  }
);

export default Toast;
