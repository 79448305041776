import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ExternalLink from '@starbucks-web/pattern-library/lib/components/external-link';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import sparkles from '@starbucks-web/pattern-library/lib/icons/sparkles';

import { giftFaqsUrlSelector } from 'shared/app/state/selectors/config';

import AdditionalHelp from './additional-help';

import messages from './messages';

const GiftReceiptFooter = ({ ReceiptFooterDetails, orderNumber }) => {
  const giftFaqsUrl = useSelector(giftFaqsUrlSelector);
  const { formatMessage } = useIntl();

  return (
    <div className="bg-neutralCool p4 lg-pt6">
      <ContentColumn size="lg">
        {ReceiptFooterDetails ? <ReceiptFooterDetails /> : null}
        <div className="lg-flex">
          <div className="lg-size6of12">
            <div className="mb4">
              <Heading className="text-semibold mb2" size="sm" tagName="h2">
                {formatMessage(messages.redemptionHeading)}
              </Heading>
              <div className="flex">
                <Icon className="color-gold" path={sparkles} />
                <p className="ml2 color-textBlackSoft text-xxs">
                  {formatMessage(messages.redemptionBody)}
                </p>
              </div>
            </div>

            <div className="mb4">
              <Heading className="text-semibold mb2" size="sm" tagName="h2">
                {formatMessage(messages.viewOrdersHeading)}
              </Heading>
              <div className="flex mb3">
                <Icon className="color-gold" path={sparkles} />
                <p className="ml2 color-textBlackSoft text-xxs">
                  {formatMessage(messages.viewOrdersBody1)}
                </p>
              </div>
              <div className="flex mt1">
                <Icon className="color-gold" path={sparkles} />
                <p className="ml2 color-textBlackSoft text-xxs">
                  {formatMessage(messages.viewOrdersBody2)}
                </p>
              </div>
            </div>

            <div className="mb4 lg-mb0">
              <Heading className="text-semibold mb2" size="sm" tagName="h2">
                {formatMessage(messages.questionsHeading)}
              </Heading>
              <p className="color-textBlackSoft text-xxs mb3">
                {formatMessage(messages.questionsBody)}
              </p>
              <ExternalLink
                className="mt1"
                href={giftFaqsUrl}
                visualStyle="button"
              >
                {formatMessage(messages.questionsCTA)}
              </ExternalLink>
            </div>
          </div>

          <div className="mb4 lg-size6of12 lg-ml6">
            <AdditionalHelp orderNumber={orderNumber} />
          </div>
        </div>
      </ContentColumn>
    </div>
  );
};

export default GiftReceiptFooter;
