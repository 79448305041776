import { findIndex } from 'lodash';
import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

import { REMOVE_PAYMENT_INSTRUMENT_SUCCESS } from 'shared/app/bundles/wallet';

import {
  ADD_SVC_CARD,
  ADD_SVC_CARD_SUCCESS,
  ADD_SVC_CARD_ERROR,
  CLEAR_RELOAD_AMOUNT_SELECTED,
  CLEAR_REPORT_LOST_STOLEN,
  FETCH_SVC_CARDS,
  FETCH_SVC_CARD_BALANCE,
  FETCH_SVC_CARD_BALANCE_SUCCESS,
  FETCH_SVC_CARD_BALANCE_ERROR,
  RELOAD_SVC_CARD_BALANCE,
  RELOAD_SVC_CARD_BALANCE_SUCCESS,
  RELOAD_SVC_CARD_BALANCE_ERROR,
  REMOVE_SVC_CARD,
  REMOVE_SVC_CARD_ERROR,
  REPORT_LOST_SVC,
  REPORT_LOST_SVC_SUCCESS,
  REPORT_LOST_SVC_INCOMPLETE,
  SET_CARD_IN_MANAGEMENT,
  SET_PRIMARY_SVC_CARD,
  SET_PRIMARY_SVC_CARD_ERROR,
  SET_RELOAD_AMOUNT_SELECTED,
  TRANSFER_SVC_CARD_BALANCE,
  TRANSFER_SVC_CARD_BALANCE_SUCCESS,
  TRANSFER_SVC_CARD_BALANCE_ERROR,
  UPDATE_AUTO_RELOAD,
  UPDATE_AUTO_RELOAD_SUCCESS,
  UPDATE_AUTO_RELOAD_ERROR,
  UPDATE_NICKNAME_SUCCESS,
} from '../actions';

import getUpdatedCardsState from './utils/get-updated-cards-state';

const { reducer, initialState } = createSingleResourceReducer({
  startAction: FETCH_SVC_CARDS,
  markStaleWhen: [REMOVE_PAYMENT_INSTRUMENT_SUCCESS],
});

const defaultState = {
  cardInManagement: null,
  expectingAddCardResponse: false,
  expectingReloadResponse: false,
  expectingAutoReloadResponse: false,
  expectingTransferResponse: false,
  expectingBalanceResponse: false,
  lostStolenSvcId: null,
  lostStolenComplete: null,
  lostStolenReplacementSvc: null,
  ...initialState,
};
// eslint-disable-next-line max-statements, complexity
export default (state = defaultState, action) => {
  state = reducer(state, action);

  switch (action.type) {
    case SET_CARD_IN_MANAGEMENT:
      return Object.assign({}, state, { cardInManagement: action.payload });
    case CLEAR_RELOAD_AMOUNT_SELECTED:
      return Object.assign({}, state, {
        reloadAmountSelected: null,
        autoReloadTriggerAmountSelected: null,
      });
    case SET_RELOAD_AMOUNT_SELECTED:
      return Object.assign({}, state, {
        reloadAmountSelected: action.payload.amount,
        autoReloadTriggerAmountSelected: action.payload.triggerAmount,
      });
    // this is the start of an async action to
    // mark a new card as "primary".
    // We optimistically update the card marked
    // as primary so it's reflected immediately
    // in the UI.
    case SET_PRIMARY_SVC_CARD: {
      const data =
        state.data &&
        state.data.map((card) =>
          Object.assign({}, card, {
            isPrimary: action.payload.cardId === card.cardId,
          })
        );
      return Object.assign({}, state, { data });
    }
    // On error we still get a `payload` attribute
    // in addition to the error that is the previous
    // primary card ID. We can use that to revert the
    // optimistic update.
    case SET_PRIMARY_SVC_CARD_ERROR: {
      const data =
        state.data &&
        state.data.map((card) =>
          Object.assign({}, card, {
            isPrimary: action.payload.cardId === card.cardId,
          })
        );
      return Object.assign({}, state, { data });
    }

    case ADD_SVC_CARD: {
      return Object.assign({}, state, { expectingAddCardResponse: true });
    }

    case ADD_SVC_CARD_SUCCESS: {
      const data = state.data || [];
      return Object.assign({}, state, {
        data: data.concat(action.payload),
        expectingAddCardResponse: false,
      });
    }

    case ADD_SVC_CARD_ERROR: {
      return Object.assign({}, state, { expectingAddCardResponse: false });
    }

    // optimistic card removal
    case REMOVE_SVC_CARD: {
      const index = findIndex(
        state.data,
        (card) => card?.cardId === action?.payload?.cardId
      );

      if (index > -1) {
        return Object.assign({}, state, {
          data: [...state.data.slice(0, index), ...state.data.slice(index + 1)],
        });
      }
      return state;
    }

    // put card back if failed
    case REMOVE_SVC_CARD_ERROR: {
      return Object.assign({}, state, {
        data: [...state.data, action.payload],
      });
    }

    case FETCH_SVC_CARD_BALANCE: {
      return Object.assign({}, state, { expectingBalanceResponse: true });
    }

    case FETCH_SVC_CARD_BALANCE_SUCCESS: {
      const data = getUpdatedCardsState(state, action.payload);
      return Object.assign(
        {},
        state,
        { data },
        { expectingBalanceResponse: false }
      );
    }

    case FETCH_SVC_CARD_BALANCE_ERROR: {
      return Object.assign({}, state, { expectingBalanceResponse: false });
    }

    case RELOAD_SVC_CARD_BALANCE: {
      return Object.assign({}, state, { expectingReloadResponse: true });
    }

    case RELOAD_SVC_CARD_BALANCE_SUCCESS: {
      const data = getUpdatedCardsState(state, action.payload);
      return Object.assign({}, state, {
        data,
        lastReloadResponse: Date.now(),
        expectingReloadResponse: false,
      });
    }

    case RELOAD_SVC_CARD_BALANCE_ERROR: {
      return Object.assign({}, state, { expectingReloadResponse: false });
    }

    case TRANSFER_SVC_CARD_BALANCE: {
      return Object.assign({}, state, { expectingTransferResponse: true });
    }

    case TRANSFER_SVC_CARD_BALANCE_SUCCESS: {
      const data = getUpdatedCardsState(state, action.payload);

      return Object.assign({}, state, {
        data,
        expectingTransferResponse: false,
      });
    }

    case TRANSFER_SVC_CARD_BALANCE_ERROR: {
      return Object.assign({}, state, { expectingTransferResponse: false });
    }

    case UPDATE_AUTO_RELOAD: {
      return Object.assign({}, state, { expectingAutoReloadResponse: true });
    }

    case UPDATE_AUTO_RELOAD_SUCCESS: {
      const data = getUpdatedCardsState(state, action.payload);
      return Object.assign({}, state, {
        data,
        expectingAutoReloadResponse: false,
      });
    }

    case UPDATE_AUTO_RELOAD_ERROR: {
      return Object.assign({}, state, { expectingAutoReloadResponse: false });
    }

    case UPDATE_NICKNAME_SUCCESS: {
      const data = getUpdatedCardsState(state, action.payload);
      return Object.assign({}, state, { data });
    }

    case CLEAR_REPORT_LOST_STOLEN: {
      return Object.assign({}, state, {
        lostStolenSvcId: null,
        lostStolenComplete: null,
        lostStolenReplacementSvc: null,
      });
    }

    case REPORT_LOST_SVC: {
      return { ...state, ...action.payload };
    }

    case REPORT_LOST_SVC_SUCCESS: {
      return { ...state, ...action.payload };
    }

    case REPORT_LOST_SVC_INCOMPLETE: {
      return { ...state, ...action.payload };
    }
  }
  return state;
};
