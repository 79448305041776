import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Animator from '@starbucks-web/pattern-library/lib/components/animator';

import ToastSlider from '../toast-slider';

import styles from './styles.cssm';

const FrapContainer = ({
  addSpacingForMultipleChildren = true,
  animated = true,
  children,
  className,
  inHeaderCrate,
  relativeAbove,
  ...otherProps
}) => {
  // If we're animating, delay render of FrapContainer to allow
  // for entrance after page transitions. If we're not animating,
  // render immediately.
  const [ready, setReady] = useState(!animated);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!ready) {
      setTimer(
        setTimeout(() => {
          setReady(true);
        }, 200)
      );
    }
    return clearTimeout(timer);
  }, [ready]);

  if (!ready) {
    return null;
  }

  const wrapChildren = (child, i) => (
    <div
      className={classNames('visible', {
        'mt3': i > 0 && addSpacingForMultipleChildren,
      })}
      key={i}
    >
      {child}
    </div>
  );

  const getMediaClass = (relativeAboveMedia) => {
    return (
      {
        md: styles.md,
        lg: styles.lg,
        xl: styles.xl,
        alwaysRelative: styles.alwaysRelative,
      }[relativeAboveMedia] ?? ''
    );
  };

  const classList = classNames(
    styles.base,
    'invisible sb-global-gutters py3 lg-py5',
    getMediaClass(relativeAbove),
    {
      [styles.left]: inHeaderCrate,
    },
    className
  );
  const ContainerComponent = animated ? Animator : 'div';

  return (
    <div className={classList} {...otherProps}>
      <ToastSlider mobileOnly={inHeaderCrate}>
        <div className="flex flex-column items-end">
          <ContainerComponent>
            {React.Children.map(children, wrapChildren)}
          </ContainerComponent>
        </div>
      </ToastSlider>
    </div>
  );
};

FrapContainer.propTypes = {
  addSpacingForMultipleChildren: PropTypes.bool,
  children: PropTypes.any,
  // animated: Flag enabling Frap entrance/exit animations.
  animated: PropTypes.bool,
  // inHeaderCrate: Aligns the `FrapContainer` with the `HeaderCrate`,
  // rather than the `ContentCrate`.
  inHeaderCrate: PropTypes.bool,
  // relativeAbove: changes styling from `position=fixed` to `position=relative`
  // for the given breakpoints or always (used for bottomsheets)
  relativeAbove: PropTypes.oneOf(['md', 'lg', 'xl', 'alwaysRelative']),
};

export default FrapContainer;
