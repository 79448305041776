import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import ExternalLink from 'shared/app/components/external-link';

import { customerServiceUrlSelector } from 'shared/app/state/selectors/privacy-and-terms';
import { commonMessages } from 'shared/app/messages';

const CustomerServiceLink = ({ className }) => {
  const { formatMessage } = useIntl();
  const customerServiceUrl = useSelector(customerServiceUrlSelector);
  return (
    <ExternalLink className={className} href={customerServiceUrl}>
      {formatMessage(commonMessages.needHelp)}
    </ExternalLink>
  );
};

export default CustomerServiceLink;
