import { defineMessages } from 'react-intl';

export const accountsMessages = defineMessages({
  password: {
    id: 'shared.accounts.password',
    defaultMessage: 'Password',
    description: 'Label for password field',
  },
  forgotYourPassword: {
    id: 'shared.accounts.forgotYourPassword',
    defaultMessage: 'Forgot your password?',
    description: 'Link for users who forgot their password',
  },
  termsOfUse: {
    id: 'shared.accounts.termsOfUse',
    defaultMessage: 'Terms of Use',
    description: 'Label for new terms of use link',
  },
  account: {
    id: 'shared.accounts.account',
    defaultMessage: 'Account',
    description: 'Label for user account content',
  },
  doNotShareMyPersonalInformation: {
    id: 'shared.accounts.doNotShareMyPersonalInformation',
    defaultMessage: 'Do Not Share My Personal Information',
    description: 'Label for do Not share my personal information link',
  },
});
