import React from 'react';
import classNames from 'classnames';

import SvcImage from '../svc-image';
import styles from './index.cssm';

export const InputSvcImage = ({ imageUrl }) => (
  <SvcImage
    altText={''}
    className={styles.fallbackImage}
    fallbackClassName={classNames(styles.fallbackImage, styles.svcImage)}
    flexEmbedWrapperClassName={classNames('mr3 self-top', styles.svcFlex)}
    imageClassName={styles.svcImage}
    lazyLoad={{ enabled: false }}
    mini
    src={imageUrl}
  />
);

export default InputSvcImage;
