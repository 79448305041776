import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';

import GlobalNav from '@starbucks-web/pattern-library/lib/components/global-nav';

import {
  doLogin as doLoginAction,
  doLogoutAndForget as doLogoutAction,
  hamburgerNavIsOpenSelector,
  setHamburgerNavIsOpen as setHamburgerNavIsOpenAction,
} from 'shared/app/shell';
import {
  coreAppBaseUrlSelector,
  isCoreAppUrlSelector,
  currentBaseUrlSelector,
} from 'shared/app/state/selectors/locales';
import {
  routeResultSelector,
  routeSelector,
} from 'shared/app/state/selectors/routes';

import { signedInSelector } from 'shared/app/bundles/user';
import {
  accountsMessages,
  commonMessages,
  sharedCallsToAction,
} from 'shared/app/messages';
import { globalNavAccountNavSelector } from 'shared/app/state/selectors/global-nav';

import getButtonProps from './get-button-props';
import messages from './messages';
import { navItemsFormatter } from './nav-item-formatters';
import styles from './styles.cssm';

export const SharedGlobalNav = ({
  accountNavConfig,
  coreAppBaseUrl,
  doLogin,
  doLogout,
  hamburgerNavIsOpen,
  headerProps,
  isCoreAppUrl,
  isUserAuthenticated,
  logoOnly = false,
  mainNavLinksConfig,
  route,
  routeResult,
  setHamburgerNavIsOpen,
  useFixedDesktopNav,
  useMaxWidthLayout = false,
}) => {
  const { formatMessage } = useIntl();
  const currentBaseUrl = useSelector(currentBaseUrlSelector);

  const isCrateLayout = Boolean(
    routeResult && (routeResult.headerCrate || routeResult.usesCrateLayout)
  );

  const hideMobileBottomShadow =
    isCrateLayout && !routeResult?.showGlobalNavMobileBottomShadow;

  const navItems = navItemsFormatter({
    logoOnly,
    navItems: mainNavLinksConfig,
    currentRoute: route,
    isCoreAppUrl,
  });

  const getAccountNavItems = () => {
    if (logoOnly) {
      return null;
    }
    const { subNavItems } = accountNavConfig;

    let standAloneAccountSubNavItems;
    if (!isCoreAppUrl) {
      standAloneAccountSubNavItems = subNavItems.map(
        ({ url, ...subNavItem }) => ({
          ...subNavItem,
          url: `${coreAppBaseUrl}${url}`,
        })
      );
    }
    return {
      children: formatMessage(accountsMessages.account),
      'data-e2e': 'accountHamburgerNavPushViewBtn',
      subNavItems: navItemsFormatter({
        currentRoute: route,
        isCoreAppUrl,
        navItems: standAloneAccountSubNavItems || subNavItems,
      }),
    };
  };

  const logoProps = {
    href: currentBaseUrl,
    tagName: 'a',
    ariaLabel: formatMessage(commonMessages.logoLabel),
  };

  const className = classNames(styles.globalNav, {
    [styles.globalNavOpen]: hamburgerNavIsOpen,
    [styles.globalNavFixed]: useFixedDesktopNav,
  });

  const { findAStorePin, joinNowButton, signInButton, signOutButton } = logoOnly
    ? {}
    : getButtonProps({
        doLogin,
        doLogout,
        formatMessage,
        isCoreAppUrl,
        route,
      });

  return (
    <GlobalNav
      accountNav={getAccountNavItems()}
      className={className}
      findAStorePin={findAStorePin}
      fixedWhenHamburgerNavOpen={false}
      headerProps={{
        'data-e2e': 'navigationBar',
        ...headerProps,
      }}
      hideMobileBottomShadow={hideMobileBottomShadow}
      isUserAuthenticated={isUserAuthenticated}
      joinNowButton={joinNowButton}
      logoProps={logoProps}
      menuButtonProps={{
        labelWhenClosed: formatMessage(sharedCallsToAction.openMenuButton),
        labelWhenOpen: formatMessage(sharedCallsToAction.closeMenuButton),
        'data-e2e': 'navMenuButton',
      }}
      navAriaLabel={formatMessage(messages.navAriaLabel)}
      navItems={navItems}
      onHamburgerNavIsOpenChange={(isOpen) => setHamburgerNavIsOpen(isOpen)}
      pushViewButtonLabels={{
        pushViewButtonLabelLeft: formatMessage(
          messages.pushViewButtonLabelLeft
        ),
        pushViewButtonLabelRightFormatter: (navItemText) =>
          formatMessage(messages.pushViewButtonLabelRight, {
            navItemText,
          }),
      }}
      signInButton={signInButton}
      signOutButton={signOutButton}
      useCrateLayout={isCrateLayout}
      useHamburgerMenuButton={logoOnly ? false : true}
      useMaxWidthLayout={useMaxWidthLayout}
    />
  );
};

SharedGlobalNav.propTypes = {
  headerProps: PropTypes.object,
  intl: PropTypes.object,
  accountNavConfig: PropTypes.shape({
    subNavItems: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  mainNavLinksConfig: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
      subNavItems: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    })
  ),
  useMaxWidthLayout: PropTypes.bool,
};

const select = (state) => ({
  accountNavConfig: globalNavAccountNavSelector(state),
  coreAppBaseUrl: coreAppBaseUrlSelector(state),
  hamburgerNavIsOpen: hamburgerNavIsOpenSelector(state),
  isCoreAppUrl: isCoreAppUrlSelector(state),
  route: routeSelector(state),
  routeResult: routeResultSelector(state),
  isUserAuthenticated: signedInSelector(state),
});

const actions = {
  doLogin: doLoginAction,
  doLogout: doLogoutAction,
  setHamburgerNavIsOpen: setHamburgerNavIsOpenAction,
};

export default connect(select, actions)(SharedGlobalNav);
