import reducer from './state/reducer';
import { shouldFetchSvcCardsSelector } from './state/selectors';
import {
  FETCH_SVC_CARDS,
  FETCH_SVC_CARDS_SUCCESS,
  SET_PRIMARY_SVC_CARD,
  SET_PRIMARY_SVC_CARD_SUCCESS,
  ADD_SVC_CARD_SUCCESS,
  REMOVE_SVC_CARD,
  REMOVE_SVC_CARD_SUCCESS,
  RELOAD_SVC_CARD_BALANCE,
  RELOAD_SVC_CARD_BALANCE_SUCCESS,
  UPDATE_AUTO_RELOAD,
  UPDATE_AUTO_RELOAD_SUCCESS,
  TRANSFER_SVC_CARD_BALANCE,
  TRANSFER_SVC_CARD_BALANCE_SUCCESS,
  FETCH_SVC_CARD_BALANCE_SUCCESS,
  UPDATE_NICKNAME,
  UPDATE_NICKNAME_SUCCESS,
} from './actions';
import { fetchSvcCards } from './state/action-creators';

export default {
  name: 'svcCards',
  reducer,
  effects: [
    {
      selector: shouldFetchSvcCardsSelector,
      actionCreator: fetchSvcCards,
    },
  ],
  asyncActions: [
    FETCH_SVC_CARDS,
    SET_PRIMARY_SVC_CARD,
    REMOVE_SVC_CARD,
    UPDATE_AUTO_RELOAD,
    RELOAD_SVC_CARD_BALANCE,
    TRANSFER_SVC_CARD_BALANCE,
    UPDATE_NICKNAME,
  ],
  persistAfter: [
    FETCH_SVC_CARDS_SUCCESS,
    SET_PRIMARY_SVC_CARD_SUCCESS,
    ADD_SVC_CARD_SUCCESS,
    REMOVE_SVC_CARD_SUCCESS,
    UPDATE_AUTO_RELOAD_SUCCESS,
    RELOAD_SVC_CARD_BALANCE_SUCCESS,
    TRANSFER_SVC_CARD_BALANCE_SUCCESS,
    FETCH_SVC_CARD_BALANCE_SUCCESS,
    UPDATE_NICKNAME_SUCCESS,
  ],
};

export * from './state/selectors';
export * from './state/action-creators';
