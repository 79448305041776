import { defineMessages } from 'react-intl';

export default defineMessages({
  termsPrivacyTitle: {
    id: 'shared.termsPrivacy.title',
    description: 'Terms Privacy title',
    defaultMessage: 'Our Privacy Notice has changed',
  },
  termsPrivacyText: {
    id: 'shared.termsPrivacy.text',
    description: 'Terms Privacy text',
    defaultMessage:
      'Our Privacy Notice has been updated. The updates are in the sections related to how we collect and use your personal information, and your choices on how to manage your personal information, including state-specific rights.',
  },
  termsPrivacyAgreeContinueText: {
    id: 'shared.termsPrivacy.agreeContinuetext',
    description: 'Terms Privacy Agree Continue text',
    defaultMessage:
      'By clicking "Agree & Continue" you accept and agree to these terms.',
  },
});
