const passwordRegexes = [
  {
    name: 'stringLength',
    regex: /^.{8,25}$/,
  },
  {
    name: 'number',
    regex: /\d/,
  },
  {
    name: 'uppercase',
    regex: /[A-Z]/,
  },
  {
    name: 'lowercase',
    regex: /[a-z]/,
  },
  {
    name: 'special',
    regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
  },
];

export const validatePassword = ({ value }) => {
  let error = false;

  const errors = passwordRegexes.reduce((accumulator, validation) => {
    const result = !validation.regex.test(value);

    if (result) {
      error = true;
    }

    accumulator[validation.name] = result;

    return accumulator;
  }, {});

  return {
    error,
    errorDetails: errors,
  };
};
