import { defineMessages } from 'react-intl';

export default defineMessages({
  iframeTitle: {
    defaultMessage: 'Credit or debit card details',
    description: 'Accessible name for SPF iframe',
    id: 'shared.securePaymentFormIframe.iframeTitle',
  },
  creditCardError: {
    defaultMessage: 'Please check your credit card information!',
    description: 'Credit card error occurred',
    id: 'shared.securePaymentFormIframe.creditCardError',
  },
  UnknownSystemError: {
    defaultMessage: 'Something went wrong, please try again later.',
    description: 'An unknown or system error occurred',
    id: 'shared.securePaymentFormIframe.unknownSystemError',
  },
});
