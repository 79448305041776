import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import {
  modalToOpenByHashSelector,
  modalToBeClosedSelector,
} from 'shared/app/shell/state/selectors/app-ui';
import { setModalByHashClosed } from 'shared/app/shell/state/action-creators/app-lifecycle';

const useModalsOpenedByUrlHash = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const modalToOpenByHash = useSelector(modalToOpenByHashSelector);
  const modalToBeClosed = useSelector(modalToBeClosedSelector);

  const { closeModal, openModal } = useModalContext();

  useEffect(() => {
    if (modalToOpenByHash) {
      openModal({
        ariaLabel: formatMessage(modalToOpenByHash.ariaLabelMessage),
        component: modalToOpenByHash.component,
        id: modalToOpenByHash.hash,
        onClose: () => {
          dispatch(setModalByHashClosed(modalToOpenByHash.hash));
        },
      });
    }

    if (modalToBeClosed) {
      closeModal(modalToBeClosed.hash);
    }
  }, [modalToOpenByHash, modalToBeClosed]);
};

export default useModalsOpenedByUrlHash;
