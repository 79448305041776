import React from 'react';
import { connect } from 'react-redux';
import { successNotificationSelector } from 'shared/app/shell/state/selectors/notification';
import Notification from 'shared/app/components/notification';

const SuccessNotification = ({ successNotification }) => (
  <Notification notification={successNotification} />
);

const select = (state) => ({
  successNotification: successNotificationSelector(state),
});

export default connect(select)(SuccessNotification);
