import React from 'react';
import { FormattedMessage } from 'react-intl';

import Dialog from '@starbucks-web/pattern-library/lib/components/dialog';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

const DialogWithMessages = ({
  children,
  contentContainerProps,
  dialogContainerProps,
  rootContainerProps,
  ...otherProps
}) => {
  return (
    <Dialog
      cancelText={<FormattedMessage {...sharedCallsToAction.cancel} />}
      confirmText={<FormattedMessage {...sharedCallsToAction.ok} />}
      contentContainerProps={{
        ...contentContainerProps,
        'data-e2e': 'dialogButtonContainer',
      }}
      dialogContainerProps={{
        ...dialogContainerProps,
        'data-e2e': 'dialogContent',
      }}
      rootContainerProps={{
        ...rootContainerProps,
        'data-e2e': 'dialogContainer',
      }}
      {...otherProps}
    >
      {children}
    </Dialog>
  );
};

export default DialogWithMessages;
