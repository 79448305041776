import { createSelector } from 'reselect';

export const configSelector = (state) => state.config;

export const phoneCountryCodeSelector = createSelector(
  configSelector,
  (config) => config?.phoneNumber?.countryCode ?? null
);

export const countryPhoneLengthSelector = createSelector(
  configSelector,
  (config) =>
    config?.phoneNumber?.length ? parseInt(config.phoneNumber.length) : null
);
