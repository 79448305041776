import { Children } from 'react';
import { connect } from 'react-redux';

import { hasShortSessionSelector } from 'shared/app/bundles/user';
import { doLogin } from 'shared/app/shell/state/action-creators/app-lifecycle';

const SessionCheck = (props) => {
  if (!props.hasShortSession) {
    props.doLogin();
    return null;
  }

  return props.children ? Children.only(props.children) : null;
};

const select = (state) => ({
  hasShortSession: hasShortSessionSelector(state),
});

export default connect(select, { doLogin })(SessionCheck);
