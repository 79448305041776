import React from 'react';

export default () => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="26" fill="#D4E9E2" r="22" />
    <path
      d="M12.6514 6.78722L12.7333 7.62239L14.246 6.63086L12.6514 6.78722Z"
      fill="#1E3932"
    />
    <path
      d="M13.5363 15.8505L15.8929 14.3067L15.8097 13.4629L13.4531 15.0066L13.5363 15.8505Z"
      fill="#1E3932"
    />
    <path
      d="M13.3348 13.7941L15.6914 12.2504L15.6083 11.4053L13.2529 12.949L13.3348 13.7941Z"
      fill="#1E3932"
    />
    <path
      d="M13.0527 10.8924L13.1346 11.7375L15.4912 10.1937L15.4093 9.34863L13.0527 10.8924Z"
      fill="#1E3932"
    />
    <path
      d="M15.2082 7.29102L12.8516 8.83477L12.9347 9.67987L15.2901 8.13611L15.2082 7.29102Z"
      fill="#1E3932"
    />
    <path
      d="M15.1347 6.54492L14.2461 6.63055L12.7334 7.62208L12.8513 8.8345L15.2079 7.29074L15.1347 6.54492Z"
      fill="#00754A"
    />
    <path
      d="M15.2899 8.13574L12.9346 9.6795L13.0525 10.8919L15.4091 9.34816L15.2899 8.13574Z"
      fill="#00754A"
    />
    <path
      d="M13.2529 12.9495L15.6083 11.4058L15.4904 10.1934L13.1338 11.7371L13.2529 12.9495Z"
      fill="#00754A"
    />
    <path
      d="M13.4529 15.0072L15.8094 13.4634L15.6915 12.251L13.335 13.7947L13.4529 15.0072Z"
      fill="#00754A"
    />
    <path
      d="M13.654 17.0641L16.0106 15.5203L15.8927 14.3066L13.5361 15.8504L13.654 17.0641Z"
      fill="#006241"
    />
    <path
      d="M12.4454 5.83981C12.3132 5.73577 12.2053 5.60414 12.1293 5.45411C12.0532 5.30408 12.0107 5.13926 12.0049 4.97114C12.006 4.71675 12.0649 4.46594 12.1774 4.23774C12.3945 3.7637 12.7284 3.36907 13.0076 2.92605C13.2868 2.48303 13.5176 1.98417 13.4804 1.45801C14.0612 2.47807 14.9348 3.37528 15.1172 4.5455C15.1768 4.93391 15.1333 5.3819 14.8479 5.63257C14.5017 5.9366 13.9544 5.88076 13.5362 5.96763C13.1466 6.04705 12.8016 6.11034 12.4454 5.83981Z"
      fill="#CBA258"
    />
    <mask
      height="44"
      id="mask0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="44"
      x="2"
      y="4"
    >
      <circle cx="24" cy="26" fill="#D4E9E2" r="22" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M24.1277 22.4512H9.56738L13.4913 59.0398C13.4913 59.0398 17.6213 62.2402 24.1277 62.2402C30.6341 62.2402 34.7628 59.0398 34.7628 59.0398L38.6867 22.4512H24.1277Z"
        fill="#F9F9F9"
      />
    </g>
    <path
      d="M37.9734 19.7134C37.6644 19.4218 37.776 18.8596 37.776 18.8596L37.5278 18.0096C37.5278 18.0096 37.4757 17.6658 37.235 16.2375C36.9942 14.8091 33.6362 13.6042 32.9499 13.2257C32.7601 13.1345 32.6062 12.9825 32.5127 12.7939C32.4192 12.6052 32.3914 12.3908 32.4337 12.1845V11.8494C32.2786 11.0912 31.0054 10.0079 31.0054 10.0079C30.54 9.23349 29.7483 8.78551 28.5607 8.06327C27.3731 7.34103 28.0643 6.22168 28.0643 6.22168C23.2072 6.61755 20.9201 9.80806 20.9201 9.80806L20.3815 10.1282C20.3815 10.1282 19.6072 9.43949 19.1257 10.8852C18.6442 12.3309 17.4218 11.8494 17.3089 12.1733C17.196 12.4972 15.4599 12.8497 14.9635 12.9154C14.4671 12.9812 14.0514 13.3982 13.792 14.5287C13.5327 15.6592 13.2472 15.55 13.2472 15.55C13.2472 15.55 12.5349 15.7138 12.1403 16.6458C11.7457 17.5777 10.3335 18.7628 9.64473 19.4342C8.956 20.1056 9.14339 21.6605 9.14339 21.6605C9.14339 23.8942 15.857 25.6998 24.1379 25.6998C32.4188 25.6998 39.1324 23.8942 39.1324 21.6605C39.1734 20.7608 38.2836 20.0063 37.9734 19.7134Z"
      fill="white"
    />
    <path
      d="M13.2486 15.5557C13.2486 15.5557 12.7906 17.5747 14.3505 17.942C15.9104 18.3094 17.242 17.942 18.1591 19.3642C19.0761 20.7863 20.1756 20.8794 21.6474 20.6957C23.1192 20.5121 23.757 19.9152 24.9521 21.4304C26.1471 22.9456 26.512 22.7607 27.614 22.577C28.7159 22.3934 29.3575 21.2008 31.698 22.2048C34.0384 23.2087 35.8292 22.618 36.6544 22.4802C37.4796 22.3425 38.6623 22.4145 38.7082 22.6192C38.7541 22.824 38.3359 24.4075 37.2513 24.0873C36.4831 23.8602 35.9197 24.0426 35.3241 24.1344C34.7284 24.2263 32.5245 24.271 31.2388 23.5363C29.9532 22.8017 27.0481 24.5551 25.9312 24.1841C24.8143 23.813 24.3093 22.0211 22.7047 21.9752C21.1001 21.9293 20.1806 22.4802 19.3541 22.1588C18.5276 21.8374 17.6565 20.2428 16.2331 19.8928C14.8097 19.5429 12.9743 19.9561 12.4705 19.039C11.9666 18.122 11.1377 15.6475 13.2486 15.5557Z"
      fill="#CBA258"
    />
    <path
      d="M17.4253 11.8496C17.4253 11.8496 16.3233 14.7746 17.4253 14.7038C18.5273 14.6331 19.4208 14.2906 20.2473 16.1148C21.0738 17.939 21.2797 18.5248 22.141 18.4205C23.0022 18.3163 25.9272 16.6658 26.7871 17.1125C27.6471 17.5593 28.5431 19.453 29.3348 19.3463C30.1266 19.2395 32.2598 17.97 33.121 18.3138C33.9823 18.6575 35.5297 20.5475 36.2532 20.2758C36.9767 20.004 37.9732 18.5893 37.9732 18.5893C37.9732 18.5893 37.8355 18.1078 37.5265 18.0035C37.5265 18.0035 36.2185 18.9963 35.7705 18.6923C35.3225 18.3882 34.1188 17.0058 33.1893 17.1088C32.2598 17.2118 30.3351 18.1413 29.4726 17.7975C28.6101 17.4538 28.0269 15.606 27.0279 15.5489C26.0289 15.4918 23.3795 16.7998 22.347 16.2488C21.3145 15.6978 20.833 13.4703 19.525 13.3288C18.217 13.1874 17.2876 13.2953 17.4253 11.8496Z"
      fill="#CBA258"
    />
    <path
      d="M20.0068 9.81892C20.0068 9.81892 20.4548 12.1259 22.0371 11.8504C23.6193 11.5749 24.519 10.8576 25.9957 12.1259C27.4725 13.3941 28.2295 13.2961 29.6789 12.4001C31.1284 11.5042 32.7093 12.2971 32.7093 12.2971C32.7093 12.2971 32.4686 10.6454 30.9881 9.8152C30.9881 9.8152 28.9914 11.3304 27.7182 11.0884C26.445 10.8464 25.5118 9.3759 24.1033 9.81892C22.6948 10.2619 21.6933 10.8862 21.1771 10.1639L20.6608 9.44043L20.0068 9.81892Z"
      fill="#CBA258"
    />
    <path
      d="M24.1278 47.2684C28.2866 47.2684 31.658 43.897 31.658 39.7382C31.658 35.5794 28.2866 32.208 24.1278 32.208C19.969 32.208 16.5977 35.5794 16.5977 39.7382C16.5977 43.897 19.969 47.2684 24.1278 47.2684Z"
      fill="#006241"
    />
    <path
      clipRule="evenodd"
      d="M11.444 25.403C11.4788 25.3402 11.5579 25.3175 11.6207 25.3522L11.5577 25.4659C11.6207 25.3522 11.6206 25.3522 11.6206 25.3522L11.6205 25.3521C11.6205 25.3521 11.6205 25.3521 11.6206 25.3522L11.6215 25.3526C11.6224 25.3532 11.6241 25.354 11.6265 25.3553C11.6312 25.3578 11.6388 25.3617 11.6494 25.367C11.6706 25.3775 11.7039 25.3934 11.7501 25.4139C11.8425 25.4549 11.987 25.5143 12.1917 25.586C12.6011 25.7295 13.2511 25.9222 14.2069 26.1154C16.1186 26.5017 19.2522 26.8897 24.1275 26.8897C24.1993 26.8897 24.2575 26.9478 24.2575 27.0196C24.2575 27.0914 24.1993 27.1496 24.1275 27.1496C19.2389 27.1496 16.0876 26.7607 14.1554 26.3702C13.1892 26.1749 12.5276 25.9792 12.1057 25.8314C11.8948 25.7575 11.7438 25.6955 11.6447 25.6515C11.5951 25.6296 11.5585 25.6121 11.5339 25.5998C11.5215 25.5937 11.5122 25.5889 11.5057 25.5855C11.5025 25.5838 11.5 25.5825 11.4982 25.5815L11.4959 25.5803L11.4952 25.5799L11.495 25.5798C11.4949 25.5797 11.4948 25.5797 11.5577 25.4659L11.4948 25.5797C11.432 25.5449 11.4093 25.4658 11.444 25.403Z"
      fill="#1E3932"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M36.6337 25.3522C36.6965 25.3175 36.7755 25.3402 36.8102 25.403C36.845 25.4658 36.8223 25.5449 36.7595 25.5797L36.6965 25.4659C36.7595 25.5797 36.7594 25.5797 36.7593 25.5798L36.759 25.5799L36.7583 25.5803L36.7561 25.5815C36.7543 25.5825 36.7518 25.5838 36.7485 25.5855C36.7421 25.5889 36.7327 25.5937 36.7204 25.5998C36.6958 25.6121 36.6592 25.6296 36.6096 25.6515C36.5105 25.6955 36.3595 25.7575 36.1486 25.8314C35.7268 25.9792 35.0651 26.1749 34.099 26.3702C32.167 26.7607 29.016 27.1496 24.128 27.1496C24.0562 27.1496 23.998 27.0914 23.998 27.0196C23.998 26.9478 24.0562 26.8897 24.128 26.8897C29.0027 26.8897 32.136 26.5017 34.0475 26.1154C35.0032 25.9222 35.6532 25.7295 36.0626 25.586C36.2672 25.5143 36.4117 25.4549 36.5042 25.4139C36.5504 25.3934 36.5836 25.3775 36.6048 25.367C36.6155 25.3617 36.6231 25.3578 36.6278 25.3553C36.6302 25.354 36.6318 25.3532 36.6328 25.3526L36.6335 25.3522L36.6337 25.3522Z"
      fill="#1E3932"
      fillRule="evenodd"
    />
    <path
      d="M39.9675 1.59404L41.9601 1.91917L42.8864 0.125235L43.1917 2.12189L45.1833 2.44754L43.3793 3.35473L43.6846 5.35138L42.2654 3.91582L40.4613 4.823L41.3876 3.02907L39.9675 1.59404Z"
      fill="#CBA258"
    />
    <path
      clipRule="evenodd"
      d="M38.7189 4.10044L36.0037 5.75954L33.5859 3.89169L34.273 6.94226L31.7881 8.83655L34.9031 9.03991L35.8864 12.0026L37.0919 9.11991L40.2171 9.01444L37.7993 7.14666L38.7189 4.10044Z"
      fill="#CBA258"
      fillRule="evenodd"
    />
    <path
      d="M37.5168 0.977914L36.8145 1.38619L37.6 1.38123L37.7328 2.1953L38.0244 1.43459L38.8285 1.56117L38.1907 1.03996L38.5853 0.359913L37.8817 0.76695L37.3059 0.192383L37.5168 0.977914Z"
      fill="#CBA258"
    />
  </svg>
);
