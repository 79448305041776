import React from 'react';
import styles from './styles.cssm';
import classNames from 'classnames';

const ContainerWithGutter = ({
  children,
  className,
  hideDefault = false,
  hideMedium = false,
  hideLarge = false,
  ...otherProps
}) => {
  const classList = classNames({
    [styles.defaultContainer]: !hideDefault,
    [styles.mediumContainer]: !hideMedium,
    [styles.largeContainer]: !hideLarge,
  });

  return (
    <div className={`${classList} ${className}`} {...otherProps}>
      {children}
    </div>
  );
};

export default ContainerWithGutter;
