import React from 'react';
import { connect } from 'react-redux';

import { svcPlaceholderImageUrlSelector } from '../../state/selectors/config';
import PLSvcImage from '@starbucks-web/pattern-library/lib/components/svc-image';

const SvcImage = ({
  // eslint-disable-next-line no-unused-vars
  dispatch, // factoring this out so it doesn't get passed into the image via props
  placeholderUrl,
  ...props
}) => (
  <PLSvcImage placeholderAlt="" placeholderUrl={placeholderUrl} {...props} />
);

const select = (state) => ({
  placeholderUrl: svcPlaceholderImageUrlSelector(state),
});

export default connect(select)(SvcImage);
