import React from 'react';
import { FormattedMessage } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import SvcImage from 'shared/app/components/svc-image';

import messages from '../messages';

const SummarySuccess = ({
  numberOfRecipients = 1,
  message,
  largeImageUrl,
  altText,
}) => {
  return (
    <div className="md-mx5">
      <div className="mt2 mb3">
        <SvcImage altText={altText} src={largeImageUrl} />
      </div>
      {message ? (
        <React.Fragment>
          <Heading
            className="text-bold text-center text-xs text-upper mb2"
            tagName="h3"
          >
            <FormattedMessage
              {...messages.personalNote}
              values={{ numberOfRecipients }}
            />
          </Heading>
          <p className="text-center">{message}</p>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default SummarySuccess;
