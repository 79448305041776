import { FETCH_USER_MFA_FACTORS } from '../../actions';

import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';
import {
  ADD_MFA_PHONE_SUCCESS,
  EDIT_MFA_PHONE_SUCCESS,
  DELETE_MFA_PHONE_SUCCESS,
} from 'account-settings/app/actions';
import { VERIFY_MFA_CODE_SUCCESS } from 'shared/app/bundles/mfa/actions';

const { reducer: mfaFactorsReducer, initialState: mfaFactorsInitialState } =
  createSingleResourceReducer({
    startAction: FETCH_USER_MFA_FACTORS,
    markStaleWhen: [
      ADD_MFA_PHONE_SUCCESS,
      VERIFY_MFA_CODE_SUCCESS,
      EDIT_MFA_PHONE_SUCCESS,
      DELETE_MFA_PHONE_SUCCESS,
    ],
  });

export const reducer = (state = mfaFactorsInitialState, action) => {
  state = mfaFactorsReducer(state, action);

  return state;
};

export const initialState = mfaFactorsInitialState;
