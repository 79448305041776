import { union } from 'lodash';

import { extractByAppName } from './sub-app-extractors';

// Merges cached data with bootstrap data from the server. By default,
// server data "wins," overriding cached data. Sub-apps can change how
// their state key merges data by providing a `mergeCachedAndBootstrapData` function.
export default (cachedData, bootstrapData, apps = []) => {
  const mergeFns = extractByAppName(apps, 'mergeCachedAndBootstrapData');
  return union(Object.keys(cachedData), Object.keys(bootstrapData)).reduce(
    (acc, key) => {
      const cachedSlice = cachedData[key];
      const bootstrapSlice = bootstrapData[key];
      const mergeFn = mergeFns[key];

      // If a sub-app wants to redefine the merge rules, respect that here
      if (mergeFn) {
        return { ...acc, [key]: mergeFn(cachedSlice, bootstrapSlice) };
      }

      // By default, the server keys override the cached ones...
      // ...but only if they're defined!
      return {
        ...acc,
        [key]: bootstrapSlice !== undefined ? bootstrapSlice : cachedSlice,
      };
    },
    {}
  );
};
