import { push } from 'redux-first-history';
import {
  selectedStoreSelector,
  storeNeedsConfirmationSelector,
} from 'shared/app/state/selectors/ordering';

import ConfirmStoreDialog, {
  confirmStoreDialogHeadingId,
} from 'shared/app/components/confirm-store-dialog';
import SelectStoreDialog from 'shared/app/components/select-store-dialog';
import messages from 'shared/app/components/select-store-dialog/messages';

export const selectOrConfirmStoreForCart =
  ({ formatMessage, openModal }) =>
  (dispatch, getState) => {
    const state = getState();
    const selectedStore = selectedStoreSelector(state)?.store;
    const storeNeedsConfirmation = storeNeedsConfirmationSelector(state);

    const goToCart = () => dispatch(push('/menu/cart'));
    const goToStoreLocator = () => {
      dispatch(push('/menu/store-locator?toCart=true'));
    };

    if (selectedStore?.id && !storeNeedsConfirmation) {
      return goToCart();
    }

    if (selectedStore?.id) {
      return openModal({
        component: ConfirmStoreDialog,
        componentProps: {
          goToCart,
          goToStoreLocator,
          selectedStore,
        },
        ariaLabelledBy: confirmStoreDialogHeadingId,
      });
    }

    return openModal({
      ariaLabel: formatMessage(messages.dialogAriaLabel),
      component: SelectStoreDialog,
      componentProps: {
        goToStoreLocator,
      },
    });
  };
