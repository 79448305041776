import React from 'react';
import classNames from 'classnames';
import styles from './display-ripple.cssm';

class DisplayRipple extends React.Component {
  constructor(props) {
    super(props);
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.startAnimation = this.startAnimation.bind(this);
    this.state = {
      active: false,
    };
  }

  startAnimation() {
    this.setState({ active: true });
    setTimeout(() => {
      this.setState({ active: false });
    }, 2000);
  }

  handleAnimationEnd() {
    this.setState({ active: false });
  }

  render() {
    return (
      <div
        className={classNames('absolute', styles.ripple, {
          'sb-ripple': Boolean(this.state.active),
          hidden: Boolean(!this.state.active),
        })}
        onAnimationEnd={this.handleAnimationEnd}
      />
    );
  }
}

export default DisplayRipple;
