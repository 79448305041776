import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import Field from '@starbucks-web/pattern-library/lib/components/field';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';

import { prefixErrorMessage } from 'shared/app/components/a11y-error-message-prefix';

import { formMessages } from 'shared/app/messages';
import errorMessages from './error-messages';
import messages from './messages';

const getErrorMessage = (fieldName) => {
  const messageDictionary = {
    existence: {
      firstName: errorMessages.firstNameErrorExistence,
      lastName: errorMessages.lastNameErrorExistence,
    },
    characters: {
      firstName: errorMessages.firstNameErrorCharacters,
      lastName: errorMessages.lastNameErrorCharacters,
    },
    profanity: {
      firstName: errorMessages.firstNameErrorProfanity,
      lastName: errorMessages.lastNameErrorProfanity,
    },
  };
  const { errorDetails, errorMessage } = fieldName;
  const clientError =
    errorDetails && Object.keys(errorDetails).find((key) => errorDetails[key]);
  const fieldMessages =
    clientError && messageDictionary[clientError][fieldName.input.id];

  return fieldMessages || errorMessage;
};

const FirstLastNameFields = ({ fieldProps = {}, firstName, lastName }) => {
  const { formatMessage } = useIntl();

  const firstNameMessage =
    getErrorMessage(firstName) || errorMessages.firstNameErrorExistence;
  const lastNameMessage =
    getErrorMessage(lastName) || errorMessages.lastNameErrorExistence;

  return (
    <div data-e2e="first-last-name-fields">
      <Field
        autoComplete="given-name"
        className="mb2"
        data-e2e="firstName"
        maxLength={50}
        maxLengthAriaMessage={formatMessage(formMessages.maxLengthAriaMessage)}
        {...firstName.input}
        error={shouldDisplayError(firstName)}
        errorMessage={prefixErrorMessage(firstNameMessage)}
        {...fieldProps.firstName}
      >
        {formatMessage(messages.firstName)}
      </Field>
      <Field
        autoComplete="family-name"
        className="mb2"
        data-e2e="lastName"
        maxLength={50}
        maxLengthAriaMessage={formatMessage(formMessages.maxLengthAriaMessage)}
        {...lastName.input}
        error={shouldDisplayError(lastName)}
        errorMessage={prefixErrorMessage(lastNameMessage)}
        {...fieldProps.lastName}
      >
        {formatMessage(messages.lastName)}
      </Field>
    </div>
  );
};

FirstLastNameFields.propTypes = {
  fieldProps: PropTypes.object,
  firstName: PropTypes.object,
  lastName: PropTypes.object,
};

export default FirstLastNameFields;
