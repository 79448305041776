import { defineMessages } from 'react-intl';

export default defineMessages({
  usernameHint: {
    id: 'shared.emailField.usernameHint',
    defaultMessage: 'This will be your username',
    description:
      'Label informing user that email address will be used as username',
  },
  existenceError: {
    id: 'shared.emailField.errorExistence',
    defaultMessage: 'Please enter your email address.',
    description: 'Error message displayed when email address is missing',
  },
  invalidError: {
    id: 'shared.emailField.invalidError',
    defaultMessage: 'Please enter a valid email address.',
    description: 'Error message displayed when email address is invalid',
  },
  emailFieldLabel: {
    id: 'shared.emailField.label',
    defaultMessage: 'Email address',
    description: 'Label for email address input',
  },
});
