// Access bootstrap data in DOM.
//
// Notes:
// - Calls are cached, assuming the DOM bootstrap data never changes.
// - Using this file directly should only happen in `browser-root.js
//   and where strictly needed in utilities without access to a Redux
//   store. Components should use a redux store to access `state` which
//   is inflated from this data.
// - You can pass the result of this to redux selectors as the `state`
//   param, which is what ends up being inflated there anyways.

// Actual accessor.
//
// No try/catch as we _want_ this to fail if we can't access the bootstrap.
const _getBootstrapData = () => window.__BOOTSTRAP;

// Cache.
let bootstrapData = null;

export const getBootstrapData = () => {
  if (!bootstrapData) {
    bootstrapData = _getBootstrapData();
  }

  return bootstrapData;
};
