import { createSelector } from 'reselect';

export const routesSelector = (state) => state.routes;

export const currentRouteSelector = createSelector(
  routesSelector,
  (routes) => routes.current
);

export const previousRouteSelector = createSelector(
  routesSelector,
  (routes) => routes.previous
);

// Named urlSelector in shell
export const pathnameSelector = createSelector(
  currentRouteSelector,
  (currentRoute) => currentRoute?.pathname
);

export const routeSelector = createSelector(
  currentRouteSelector,
  (currentRoute) => currentRoute.route
);

export const is404Selector = createSelector(
  routeSelector,
  (route) => route === null
);

export const queryParamsSelector = createSelector(
  currentRouteSelector,
  (currentRoute) => currentRoute.query
);

export const currentRouteSearchSelector = createSelector(
  currentRouteSelector,
  (currentRoute) => currentRoute.search || ''
);

export const hashSelector = createSelector(
  currentRouteSelector,
  (currentRoute) => currentRoute.hash
);

export const hashValueSelector = createSelector(
  // a convenience method to get the hash without the `#` character
  hashSelector,
  (hash) => hash.slice(1)
);

export const routeResultSelector = createSelector(
  currentRouteSelector,
  (currentRoute) => currentRoute.result
);

export const routeParamsSelector = createSelector(
  currentRouteSelector,
  (currentRoute) => currentRoute.params
);

export const urlAndQuerySelector = createSelector(
  currentRouteSelector,
  (route) => `${route.pathname}${route.search}`
);

export const isMenuRouteSelector = createSelector(
  pathnameSelector,
  (pathname) => Boolean(pathname && pathname.indexOf('/menu') !== -1)
);

export const isGiftHistoryRouteSelector = createSelector(
  pathnameSelector,
  (pathname) => pathname === '/account/history/egift'
);
