import { get } from 'lodash';
import {
  BOTTOM_SHEET_RETURN_URL,
  setSessionStorageItem,
  getSessionStorageItem,
  removeSessionStorageItem,
} from './session-storage';

export const setSessionBottomSheetReturnUrl = (bottomSheetUrl) => {
  setSessionStorageItem(BOTTOM_SHEET_RETURN_URL, {
    bottomSheetReturnUrl: bottomSheetUrl,
  });
};

export const popBottomsheetUrlFromStorage = () => {
  const bottomSheetReturnUrl = get(
    getSessionStorageItem(BOTTOM_SHEET_RETURN_URL),
    'bottomSheetReturnUrl',
    null
  );
  removeSessionStorageItem(BOTTOM_SHEET_RETURN_URL);
  return bottomSheetReturnUrl;
};

export const clearSessionBottomSheetReturnUrl = () => {
  removeSessionStorageItem(BOTTOM_SHEET_RETURN_URL);
};
