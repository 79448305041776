import classNames from 'classnames';
import React from 'react';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import heartSolid from '@starbucks-web/pattern-library/lib/icons/heart-solid';
import styles from './styles.cssm';

export const heartsAnimationDuration = 1200; // in milliseconds

const HeartsAnimation = ({ heartsCount = 4, showHeartsAnimation = false }) => {
  return (
    <div className={styles.heartsContainer}>
      {Array.from({ length: heartsCount }, (_, key) => (
        <div
          className={classNames(styles[`heart-${key + 1}`], {
            [styles[`heart-active-${key + 1}`]]: showHeartsAnimation,
          })}
          key={`heart-${key}`}
          style={{
            '--rotateDirection': key % 2 ? 1 : -1,
            '--heartsAnimationDuration': `${heartsAnimationDuration}ms`,
          }}
        >
          <Icon
            className="color-greenStarbucks"
            path={heartSolid}
            size="100px"
          />
        </div>
      ))}
    </div>
  );
};

export default HeartsAnimation;
