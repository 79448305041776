import createActionTypes from 'shared/app/state/create-action-types';

export const SET_MFA_CODE_DELIVERY_METHOD = 'SET_MFA_CODE_DELIVERY_METHOD';

export const {
  SEND_MFA_CODE,
  SEND_MFA_CODE_SUCCESS,
  SEND_MFA_CODE_ERROR,

  VERIFY_MFA_CODE_SECURE_SESSION,
  VERIFY_MFA_CODE_SECURE_SESSION_SUCCESS,
  VERIFY_MFA_CODE_SECURE_SESSION_ERROR,

  VERIFY_MFA_CODE_FOR_LOGIN,
  VERIFY_MFA_CODE_FOR_LOGIN_SUCCESS,
  VERIFY_MFA_CODE_FOR_LOGIN_ERROR,

  VERIFY_MFA_CODE,
  VERIFY_MFA_CODE_SUCCESS,
  VERIFY_MFA_CODE_ERROR,
} = createActionTypes([
  'SEND_MFA_CODE',
  'VERIFY_MFA_CODE_SECURE_SESSION',
  'VERIFY_MFA_CODE_FOR_LOGIN',
  'VERIFY_MFA_CODE',
]);
