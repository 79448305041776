const isScriptAdded = (scriptUrl) => {
  return Boolean(document.querySelector(`script[src="${scriptUrl}"]`));
};

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLScriptElement
const loadScript = async (scriptUrl) => {
  return new Promise((resolve, reject) => {
    if (isScriptAdded(scriptUrl)) {
      return resolve();
    }

    const el = document.createElement('script');
    el.async = true;
    el.onerror = reject;
    el.onload = resolve;
    document.head.appendChild(el);
    el.src = scriptUrl;
  });
};

export default loadScript;
