/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export const headers = defineMessages({
  checkCardInformation: {
    id: 'shared.cardErrorHeader.checkCardInformation',
    defaultMessage: 'That card number or security code doesn’t look right.',
    description: 'Error message header displayed on invalid card number or pin',
  },
  cardInactive: {
    id: 'shared.cardErrorHeader.cardInactive',
    defaultMessage:
      'This card isn’t active. Check your card information or try a different card.',
    description:
      'Error message header displayed when the user could not add card',
  },
  lostStolenCard: {
    id: 'shared.cardErrorHeader.lostStolenCard',
    defaultMessage: 'This card has been marked as lost or stolen',
    description:
      'Error message header displayed when the card was lost or stolen',
  },
  cardClosed: {
    id: 'shared.cardErrorHeader.cardClosed',
    defaultMessage: 'This Starbucks Card is closed.',
    description: 'Error message header displayed when the card is closed',
  },
  cardRegistered: {
    id: 'shared.cardErrorHeader.cardRegistered',
    defaultMessage: 'This card has already been added',
    description: 'Error message header displayed when the card was registered',
  },
});

export const messages = defineMessages({
  checkCardInformation: {
    id: 'shared.cardErrorBody.checkCardInformation',
    defaultMessage:
      'Check your card information and try again. (You can also get started with a digital Starbucks Card already added to your account.)',
    description:
      'Error message body displayed when the user need to check card information',
  },
  tryDifferentCard: {
    id: 'shared.cardErrorBody.tryDifferentCard',
    defaultMessage:
      'Try a different card. (You can also get started with a digital Starbucks Card already added to your account.)',
    description:
      'Error message body displayed when user need to try a different card',
  },
  startDigitalCard: {
    id: 'shared.cardErrorBody.startDigitalCard',
    defaultMessage:
      '(You can also get started with a digital Starbucks Card already added to your account.)',
    description:
      'Error message body instructs user to start with a digital card',
  },
  internalServerError: {
    id: 'shared.cardErrorBody.internalServerError',
    defaultMessage:
      'There was an error adding your card, please try again later',
    description: 'Error message body displayed on unexpected errors',
  },
});
