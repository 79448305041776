import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { tenuredGoldMedalYearSelector } from 'shared/app/bundles/user/state/selectors';
import { configImagesUrlSelector } from 'shared/app/shell';

import messages from './messages';
import styles from './styles.cssm';

export const TenuredGoldMedal = ({
  intl,
  tenuredGoldMedalYear,
  imagesUrl,
  width,
  fontSize,
}) => (
  <div className="relative inline-block" data-e2e="tenured-gold-medal">
    <img
      alt={intl.formatMessage(messages.imageAlt)}
      src={`${imagesUrl}/tenured-gold-medal.png`}
      width={width}
    />
    <div className={styles.year} style={{ fontSize }}>
      {tenuredGoldMedalYear}
    </div>
  </div>
);

TenuredGoldMedal.propTypes = {
  width: PropTypes.string,
  // please include unit with fontsize: '22px'
  fontSize: PropTypes.string,
};

const select = (state) => ({
  tenuredGoldMedalYear: tenuredGoldMedalYearSelector(state),
  imagesUrl: configImagesUrlSelector(state),
});

export default injectIntl(connect(select)(TenuredGoldMedal));
