import React from 'react';
import PreventFocus from '@starbucks-web/pattern-library/lib/components/prevent-focus';

const GlobalNav = ({
  hamburgerNavIsOpen,
  nav,
  preventFocusTargets,
  routeOptions,
}) => {
  const navProps = nav?.globalNav?.props;
  const isCrateLayout =
    routeOptions?.headerCrate || routeOptions?.usesCrateLayout;
  const getFixedNavSetting = () => {
    if (isCrateLayout) {
      return true;
    }
    return routeOptions?.useFixedDesktopNav ?? false;
  };

  return (
    <PreventFocus enabled={preventFocusTargets.header && !hamburgerNavIsOpen}>
      <nav.globalNav.component
        useFixedDesktopNav={getFixedNavSetting()}
        {...navProps}
      />
    </PreventFocus>
  );
};

export default GlobalNav;
