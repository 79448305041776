import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from 'shared/app/messages';

const AccessibleStar = ({ className, starCount }) => (
  <span className={className} data-e2e="accessible-star">
    <span aria-hidden="true">★</span>
    <span className="hiddenVisually">
      {starCount === 1 ? (
        <FormattedMessage {...commonMessages.star} />
      ) : (
        <FormattedMessage {...commonMessages.stars} />
      )}
    </span>
  </span>
);

AccessibleStar.propTypes = {
  className: PropTypes.string,
  starCount: PropTypes.number,
};

export default AccessibleStar;
