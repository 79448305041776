import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Card from '@starbucks-web/pattern-library/lib/components/card';

import StarbucksRewardsTrademark from 'shared/app/components/starbucks-rewards-trademark';
import { configImagesUrlSelector } from 'shared/app/shell';

import messages from './messages';

// eslint-disable-next-line no-unused-vars
export const RewardsEmptyState = ({ imagesUrl, dispatch, ...otherProps }) => (
  // dispatch pulled out of props to avoid being spread onto the Card
  <Card
    containerClasses="mb4"
    contentClasses="pt3 pb8 lg-pb5 text-center"
    imageClasses="pt6 px3 mx-auto"
    imagePosition="top"
    imageUrl={`${imagesUrl}/rewards/rewards-empty-state.jpg`}
    {...otherProps}
  >
    <p>
      <FormattedMessage
        {...messages.rewardsEmptyStateMessage}
        values={{
          starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
        }}
      />
    </p>
    <p>
      <FormattedMessage {...messages.checkBackSoon} />
    </p>
  </Card>
);

const select = (state) => ({
  imagesUrl: configImagesUrlSelector(state),
});

export default connect(select)(RewardsEmptyState);
