import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';
import Select from '@starbucks-web/pattern-library/lib/components/select';

const messages = defineMessages({
  countrySubdivisionLabel: {
    id: 'shared.countrySubdivisionField.label',
    defaultMessage: 'State',
    description:
      'Label for country subdivision (state, province, region) field',
  },
  countrySubdivisionError: {
    id: 'shared.countrySubdivisionField.error',
    defaultMessage: 'Please select state from the dropdown.',
    description: 'Error message requesting to select state from the drop down',
  },
});

export const CountrySubdivisionSelect = (props) => {
  const renderOptions = (countrySubdivisions) =>
    Object.keys(countrySubdivisions).map((key) => {
      return (
        <option key={key} value={key}>
          {countrySubdivisions[key]}
        </option>
      );
    });

  const {
    countrySubdivision,
    countrySubdivisions,
    // pull error out of props so not passed to Select as is
    // eslint-disable-next-line no-unused-vars
    error,
    intl: { formatMessage },
    ...rest
  } = props;

  const errorMessage = formatMessage(
    countrySubdivision.errorMessage || messages.countrySubdivisionError
  );
  return (
    <Select
      label={formatMessage(messages.countrySubdivisionLabel)}
      {...countrySubdivision.input}
      autoComplete="address-level1"
      className="mb2"
      data-e2e="countrySubdivision"
      {...rest}
      error={shouldDisplayError(countrySubdivision)}
      errorMessage={errorMessage}
    >
      {renderOptions(countrySubdivisions)}
    </Select>
  );
};

CountrySubdivisionSelect.propTypes = {
  countrySubdivision: PropTypes.object,
  countrySubdivisions: PropTypes.object,
};

CountrySubdivisionSelect.defaultProps = {
  countrySubdivision: {
    input: {
      id: 'countrySubdivision',
      value: '',
    },
  },
};

export default injectIntl(CountrySubdivisionSelect);
