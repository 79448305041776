import { defineMessages } from 'react-intl';

export const redeemMessages = defineMessages({
  redeemOrderModePrompt: {
    id: 'shared.redeem.options.orderModePrompt',
    defaultMessage: 'How are you ordering today?',
    description: 'Text guiding user to various redemption options',
  },
  redeemOrderInPersonPrompt: {
    id: 'shared.redeem.options.orderInPersonPrompt',
    defaultMessage: 'In person, at Starbucks',
    description: 'Link Text offering user in-store redemption option',
  },
  redeemOrderInPersonDetail: {
    id: 'shared.redeem.options.orderInPersonDetail',
    defaultMessage: "Let the barista know you'd like to redeem a Reward",
    description: 'Text describing user in-store redemption option',
  },
  redeemOrderAheadPrompt: {
    id: 'shared.redeem.options.orderAheadPrompt',
    defaultMessage: 'Order ahead for pickup',
    description: 'Link Text offering user mobile ordering redemption option',
  },
  redeemOrderAheadDetail: {
    id: 'shared.redeem.options.orderAheadDetail',
    defaultMessage:
      "You'll have a chance to redeem a Reward after you choose what you'd like",
    description: 'Text describing user mobile ordering redemption option',
  },
  redeemOrderAheadToRedeemPrompt: {
    id: 'shared.redeem.options.overlay.orderAheadPrompt',
    defaultMessage: 'Order ahead to redeem a Reward',
    description: 'Text offering user mobile ordering redemption option',
  },
  redeemOrderInPersonCaveat: {
    id: 'shared.redeem.options.overlay.orderAheadDetail',
    defaultMessage:
      "If you'd like to redeem in person at the store, it works best on a phone or tablet.",
    description: 'Text recommending mobile device for in person ordering',
  },
});
