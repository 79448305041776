import React from 'react';
import ExternalLinkPL from '@starbucks-web/pattern-library/lib/components/external-link';
import { injectIntl } from 'react-intl';

import messages from './messages';

const ExternalLink = ({ intl: { formatMessage }, ...props }) => (
  <ExternalLinkPL
    {...props}
    ariaDescription={formatMessage(messages.externalLinkAria)}
  />
);

export default injectIntl(ExternalLink);
