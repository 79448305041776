import { get } from 'lodash';
import loadExternalScript from 'shared/app/utils/load-script';
import { createSelector } from 'reselect';
import { configSelector } from 'shared/app/shell';
import { payPalScriptUrlSelector } from 'shared/app/state/selectors/pay-pal';

import { routeSelector } from 'shared/app/state/selectors/routes';

export const SCRIPT_IS_LOADING = 'SCRIPT_IS_LOADING';
export const SCRIPT_DID_LOAD = 'SCRIPT_DID_LOAD';
export const SCRIPT_DID_FAIL = 'SCRIPT_DID_FAIL';

export const loadScript =
  (url, load = loadExternalScript) =>
  (dispatch) => {
    dispatch({ type: SCRIPT_IS_LOADING, payload: url });

    return load(url)
      .then(() =>
        dispatch({
          type: SCRIPT_DID_LOAD,
          payload: url,
        })
      )
      .catch(() =>
        dispatch({
          type: SCRIPT_DID_FAIL,
          payload: url,
        })
      );
  };

export const scriptsSelector = (state) => state.scripts;

export const googleMapsAPIIsReadySelector = createSelector(
  scriptsSelector,
  configSelector,
  (scripts, config) => Boolean(get(scripts[config.googleMapsUrl], 'loaded'))
);

export const payPalScriptLoadedSelector = createSelector(
  scriptsSelector,
  payPalScriptUrlSelector,
  (scripts, payPalScriptUrl) => Boolean(scripts?.[payPalScriptUrl]?.loaded)
);

// https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons_using_javascript
// https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history
export const applePayScriptUrl =
  'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js';

export const applePayScriptLoadedSelector = createSelector(
  scriptsSelector,
  (scripts) => Boolean(scripts?.[applePayScriptUrl]?.loaded)
);

export const shouldLoadGoogleMapsAPISelector = createSelector(
  scriptsSelector,
  configSelector,
  routeSelector,
  (scripts, config, route) => {
    const script = scripts[config.googleMapsUrl];

    const wantsScript =
      Boolean(route) &&
      (route.indexOf('/menu') !== -1 || route.indexOf('/store-locator') !== -1);

    const hasScript = script && (script.loaded || script.loading);

    return wantsScript && !hasScript && config.googleMapsUrl;
  }
);

export const reducer = (state = {}, { type, payload } = {}) => {
  switch (type) {
    case SCRIPT_IS_LOADING:
      return {
        ...state,
        [payload]: {
          loading: true,
        },
      };
    case SCRIPT_DID_LOAD:
      return {
        ...state,
        [payload]: {
          loaded: true,
          loading: false,
          error: false,
        },
      };
    case SCRIPT_DID_FAIL:
      return {
        ...state,
        [payload]: {
          error: true,
          loading: false,
        },
      };
  }

  return state;
};

export default {
  name: 'scripts',
  reducer,
  effects: [
    {
      selector: shouldLoadGoogleMapsAPISelector,
      actionCreator: loadScript,
    },
  ],
};
