import React from 'react';
import { useIntl } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import DialogWithMessages from 'shared/app/components/dialog-with-messages';

import {
  sharedCallsToAction,
  sharedNotificationMessages,
} from 'shared/app/messages';

export const TERMS_PRIVACY_ERROR_DIALOG_ID = 'termsPrivacyErrorDialogId';

const TermsPrivacyErrorDialog = () => {
  const { formatMessage } = useIntl();
  return (
    <DialogWithMessages
      confirmButtonOnly
      confirmText={formatMessage(sharedCallsToAction.gotIt)}
    >
      <Heading
        className="text-semibold mb3"
        id={TERMS_PRIVACY_ERROR_DIALOG_ID}
        size="lg"
        tagName="h2"
      >
        {formatMessage(sharedNotificationMessages.somethingWentWrong)}
      </Heading>
      <p>{formatMessage(sharedNotificationMessages.tryAgainLater)}</p>
    </DialogWithMessages>
  );
};

export default TermsPrivacyErrorDialog;
