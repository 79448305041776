import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Breadcrumb from './breadcrumb';
import styles from './styles.cssm';
import messages from './messages';

const Breadcrumbs = ({
  breadcrumbs = [],
  className = '',
  TagName = 'nav',
  crumbSeparator,
  breadcrumbBarStyling,
  ariaLabel,
}) => {
  const { formatMessage } = useIntl();
  const navAriaLabel = ariaLabel || formatMessage(messages.navAriaLabel);
  if (!breadcrumbs?.length) return;
  return (
    <TagName
      aria-label={navAriaLabel}
      className={`${styles.breadcrumbs} ${
        breadcrumbBarStyling ? styles.breadcrumbBar : ''
      } ${className}`}
      data-e2e="breadcrumbs"
    >
      <ul className="flex flex-wrap">
        {breadcrumbs?.map(({ link, text, ...otherProps }, i) => {
          if (!text) return;
          return (
            <Breadcrumb
              crumbSeparator={crumbSeparator}
              isLastCrumb={
                i === breadcrumbs.length - 1 && breadcrumbs.length > 1
              }
              key={text?.props?.id ?? text} // if its a react-intl string, check for message id
              link={link}
              text={text}
              {...otherProps}
            />
          );
        })}
      </ul>
    </TagName>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbBarStyling: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    })
  ),
  className: PropTypes.string,
  TagName: PropTypes.string,
  crumbSeparator: PropTypes.string,
};

export default Breadcrumbs;
