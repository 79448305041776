import React from 'react';
import { FormattedMessage } from 'react-intl';
import SkipLink from '@starbucks-web/pattern-library/lib/components/skip-link';
import skipLinkMap from '../../utils/skip-link-map';

const ShellSkipLink = () => (
  <SkipLink
    href={`#${skipLinkMap.mainContent}`}
    target={skipLinkMap.mainContent}
  >
    <FormattedMessage
      defaultMessage="Skip to main content"
      description="Accessible link for screenreaders to skip to main page content"
      id="shared.header.skipLink"
    />
  </SkipLink>
);

export default ShellSkipLink;
