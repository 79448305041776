import { LOCATION_CHANGE } from 'redux-first-history';

import { queryParamsSelector } from 'shared/app/state/selectors/routes';

import { uoAvailable, trackPageView } from '../../../utils/uo-tracking';

export default (store) => (next) => (action) => {
  if (uoAvailable() && action.type === LOCATION_CHANGE) {
    const queryParams = queryParamsSelector(store?.getState());
    trackPageView(action.payload.location.pathname, {
      title: document.title,
      browserUserAgent: navigator.userAgent,
      pageReferrer: document.referrer,
      requestUri: location.href,
      refSource: queryParams?.ref_source ?? null,
      refMedium: queryParams?.ref_medium ?? null,
      refCampaign: queryParams?.ref_campaign ?? null,
    });
  }

  return next(action);
};
