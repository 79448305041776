import { defineMessages } from 'react-intl';

export default defineMessages({
  somethingWentWrong: {
    id: 'errorBoundary.somethingWentWrong',
    defaultMessage: 'Whoops, something went wrong 😢',
    description: 'Heading for when there was an app-crashing error',
  },
  couldNotRecover: {
    id: 'errorBoundary.couldNotRecover',
    defaultMessage: `The app had an error it couldn't recover from, please try refreshing the page, or clicking below.`,
    description: 'Heading when there was an error locating a specific store.',
  },
  refresh: {
    id: 'errorBoundary.refresh',
    defaultMessage: 'Refresh',
    description: 'Call to action text for when the app crashes',
  },
});
