import { defineMessages } from 'react-intl';

const errorMessages = defineMessages({
  firstNameErrorExistence: {
    id: 'shared.firstNameFields.existenceError',
    defaultMessage: 'Enter your first name',
    description: 'Error message displayed when first name is missing',
  },
  firstNameErrorCharacters: {
    id: 'shared.firstNameFields.charactersErrors',
    defaultMessage: 'First name cannot have special characters or numerals',
    description: 'Error message displayed when first name is invalid',
  },
  firstNameErrorProfanity: {
    id: 'shared.firstNameFields.profanityErrors',
    defaultMessage:
      'Your first name does not pass our filter. Modify and try again.',
    description: 'Error message displayed when first name has profanity',
  },
  lastNameErrorExistence: {
    id: 'shared.lastNameFields.existenceError',
    defaultMessage: 'Enter your last name',
    description: 'Error message displayed when last name is missing',
  },
  lastNameErrorCharacters: {
    id: 'shared.lastNameFields.charactersError',
    defaultMessage: 'Last name cannot have special characters or numerals',
    description: 'Error message displayed when last name is invalid',
  },
  lastNameErrorProfanity: {
    id: 'shared.lastNameFields.profanityErrors',
    defaultMessage:
      'Your last name does not pass our filter. Modify and try again.',
    description: 'Error message displayed when last name has profanity',
  },
});

export default errorMessages;
