import {
  GET_PRIVACY_PERMISSIONS_STATUS,
  SET_PRIVACY_PERMISSIONS_STATUS_SUCCESS,
} from 'shared/app/state/action-creators/types';

import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

export default createSingleResourceReducer({
  startAction: GET_PRIVACY_PERMISSIONS_STATUS,
  alternativeSuccessActions: [SET_PRIVACY_PERMISSIONS_STATUS_SUCCESS],
}).reducer;
