import { throttle } from 'lodash';

import IS_BROWSER from '../../utils/is-browser';
import readCookie from '../../../utils/read-cookie';

let lastValue;

const getChangedSessionHintValue = () => {
  const value = readCookie('s_check');
  if (value !== lastValue) {
    lastValue = value;
    try {
      const parsed = JSON.parse(value);
      return parsed;
    } catch (e) {
      return null;
    }
  }
};

export const checkSession = (store) => {
  let timeoutId;

  return throttle(
    () => {
      const payload = getChangedSessionHintValue();
      if (payload) {
        store?.dispatch({ type: 'UPDATE_SESSION_INFO', payload });
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        checkSession(store);
      }, 60000);
    },
    500,
    { leading: true }
  );
};

export const addSessionChecker = (store) => {
  if (IS_BROWSER) {
    store.checkSession = checkSession(store);
  }
};

export default addSessionChecker;
