import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import { configImagesUrlSelector } from 'shared/app/state/selectors/config';
import DialogWithMessages from 'shared/app/components/dialog-with-messages';

import ReAuthFormContainer from './re-auth-form-container';
import { firstNameSelector } from '../state/selectors';
import messages from './messages';

export const REAUTH_DIALOG_ID = 'ReAuthDialogId';

const ReAuthDialog = (props) => {
  const {
    imagesUrl,
    name,
    onSuccess,
    onCancel,
    reAuthenticate,
    useReauthorizeMessage,
  } = props;

  const { closeModal } = useModalContext();

  return (
    <DialogWithMessages data-e2e="reauthDialog" renderButtons={false}>
      <div className="text-center">
        <img alt="" height="88px" src={`${imagesUrl}/key.svg`} width="88px" />
      </div>
      <Heading className="pb2" id={REAUTH_DIALOG_ID} size="lg" tagName="h2">
        <FormattedMessage {...messages.reAuthDialogTitle} />
      </Heading>
      <p>
        <FormattedMessage
          {...messages[
            useReauthorizeMessage ? 'reAuthorizeBody' : 'reAuthDialogBody'
          ]}
          values={{ name }}
        />
      </p>
      <div className="pt2">
        <ReAuthFormContainer
          onCancel={() => {
            onCancel?.();
            closeModal();
          }}
          onSuccess={() => {
            onSuccess?.();
            closeModal();
          }}
          reAuthenticate={reAuthenticate}
        />
      </div>
    </DialogWithMessages>
  );
};

ReAuthDialog.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  reAuthenticate: PropTypes.func.isRequired,
  useReauthorizeMessage: PropTypes.bool,
};

ReAuthDialog.displayName = 'ReAuthDialog';

const select = (state) => ({
  name: firstNameSelector(state),
  imagesUrl: configImagesUrlSelector(state),
});

export default connect(select)(ReAuthDialog);
