import { defineMessages } from 'react-intl';

export const welcomeOverlayMessages = defineMessages({
  orderWelcomeOverlayTitle: {
    id: 'shared.overlay.orderWelcomeOverlayTitle',
    defaultMessage: 'Welcome!',
    description:
      'Title for overlay prompting user to pay in store or order ahead',
  },
  orderWelcomeOverlayBody: {
    id: 'shared.overlay.orderWelcomeOverlayBody',
    defaultMessage:
      'You’re ready to order ahead and pay with your phone. Pay with your card or app to order ahead, earn free food and drinks, and get free refills on brewed coffee and tea.',
    description:
      'Message content for the YoureInOverlay that pops up when a new user registers with a physical card.',
  },
  loadCardWelcomeOverlayTitle: {
    id: 'shared.overlay.loadCardWelcomeOverlayTitle',
    defaultMessage: 'You’re in!',
    description:
      'Title for overlay prompting user to load their Starbucks Card',
  },
  loadCardWelcomeOverlayBody: {
    id: 'shared.overlay.loadCardWelcomeOverlayBody',
    defaultMessage:
      'We’ve created your account – the next thing to do is load your card.',
    description:
      'Body for overlay dialog prompting user to load their Starbucks Card',
  },
  orderAheadButton: {
    id: 'shared.overlay.orderAheadButton',
    defaultMessage: 'Order ahead',
    description: 'Button to navigate to /menu',
  },
  loadCardButton: {
    id: 'shared.overlay.loadCardButton',
    defaultMessage: 'Load your card',
    description: 'Button to navigate to /cards#add-money',
  },
  payButton: {
    id: 'shared.overlay.payButton',
    defaultMessage: 'Pay in store',
    description: 'Button to pay with primary card',
  },
});
