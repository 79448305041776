import React from 'react';
import { connect } from 'react-redux';
import Animator from '@starbucks-web/pattern-library/lib/components/animator';
import ContentCrate from '@starbucks-web/pattern-library/lib/components/content-crate';
import PreventFocus from '@starbucks-web/pattern-library/lib/components/prevent-focus';
import {
  ThemeContext,
  themes,
} from '@starbucks-web/pattern-library/lib/components/theme';

import ContentCrateLoading from 'shared/app/components/content-crate-loading';
import ErrorBoundary from 'shared/app/components/error-boundary';
import { pathnameSelector } from 'shared/app/state/selectors/routes';

import GlobalFooter from './global-footer';
import styles from './styles.cssm';

const GlobalContentCrate = ({
  authSatisfied = true,
  contentLoading = false,
  nav,
  pathname,
  preventFocusTargets = {},
  routeOptions,
}) => {
  if (!routeOptions?.contentCrate) {
    return null;
  }
  const contentTheme = routeOptions?.contentTheme || themes.default;
  const ContentCrateContent = routeOptions?.contentCrate;

  return (
    <ThemeContext.Provider value={contentTheme}>
      <PreventFocus enabled={preventFocusTargets.content} wrapWithDiv={false}>
        <ContentCrate className={styles.passOnFullHeight}>
          <ErrorBoundary>
            <Animator animation="fade">
              {authSatisfied && contentLoading && (
                <ContentCrateLoading key="loading" />
              )}
              {authSatisfied && !contentLoading && (
                <div className={styles.pushFooterOutOfView} key={pathname}>
                  <ContentCrateContent key={pathname} />
                </div>
              )}
              <GlobalFooter
                alwaysUseMobileLayout
                key={`footer-${pathname}`}
                nav={nav}
                routeOptions={routeOptions}
                useCrateLayout
              />
            </Animator>
          </ErrorBoundary>
        </ContentCrate>
      </PreventFocus>
    </ThemeContext.Provider>
  );
};

const select = (state) => ({
  pathname: pathnameSelector(state),
});

export default connect(select)(GlobalContentCrate);
