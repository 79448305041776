import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import PLFooter from '@starbucks-web/pattern-library/lib/components/footer';
import { altLangSelector } from '../../state/selectors/locales';
import { footerContentSelector } from '../../state/selectors/config';
import { isMenuRouteSelector } from '../../state/selectors/routes';
import { signedInSelector } from 'shared/app/bundles/user';
import { switchLanguage as switchLanguageAction } from 'shared/app/shell/state/action-creators/app-lifecycle';
import {
  isCoreAppUrlSelector,
  coreAppBaseUrlSelector,
} from 'shared/app/state/selectors/locales';
import { trustArcEnabledSelector } from 'shared/app/state/selectors/trust-arc';

import customizeSecondaryLinks from './customize-secondary-links';
import messages from './messages';
import styles from './styles.cssm';

import getCurrentYear from '../../utils/get-current-year';

const Footer = ({
  altLocale,
  alwaysUseMobileLayout,
  className,
  coreAppBaseUrl,
  isCoreAppUrl,
  isMenuRoute,
  localeFooter,
  signedIn,
  switchLanguage,
  trustArcEnabled,
  useCrateLayout,
  useMaxWidthLayout,
}) => {
  if (!localeFooter) {
    return null;
  }
  const { formatMessage } = useIntl();
  const {
    languageSelector,
    primaryLinkLists,
    socialMediaIcons,
    secondaryLinkList,
  } = localeFooter;
  const primaryLinkListsHeadingProps = {
    size: 'sm',
    tagName: 'h2',
  };

  const customSecondaryLinkList = customizeSecondaryLinks({
    coreAppBaseUrl,
    isCoreAppUrl,
    formatMessage,
    links: secondaryLinkList,
    signedIn,
    trustArcEnabled,
  });

  const finePrintText = formatMessage(messages.finePrint, {
    currentYear: getCurrentYear,
  });

  const getLanguageProps = () => {
    if (!altLocale || !languageSelector || isMenuRoute) {
      return {};
    }

    return {
      text: languageSelector.text,
      href: isCoreAppUrl ? undefined : languageSelector.href,
      onClick: isCoreAppUrl
        ? (event) => {
            event.preventDefault();
            switchLanguage(altLocale);
          }
        : undefined,
      'data-e2e': 'footer-switchLanguage',
      lang: altLocale,
    };
  };

  return (
    <PLFooter
      alwaysUseMobileLayout={alwaysUseMobileLayout}
      className={`bg-white lg-pt7 ${styles.footer} ${className || ''}`}
      finePrintText={finePrintText}
      languageSelector={getLanguageProps()}
      navAriaLabel={formatMessage(messages.navLabel)}
      primaryLinkLists={primaryLinkLists}
      primaryLinkListsHeadingProps={primaryLinkListsHeadingProps}
      secondaryLinkList={customSecondaryLinkList}
      socialMediaIcons={socialMediaIcons}
      useCrateLayout={useCrateLayout}
      useMaxWidthLayout={useMaxWidthLayout}
    />
  );
};

const select = (state) => ({
  altLocale: altLangSelector(state),
  coreAppBaseUrl: coreAppBaseUrlSelector(state),
  isCoreAppUrl: isCoreAppUrlSelector(state),
  isMenuRoute: isMenuRouteSelector(state),
  localeFooter: footerContentSelector(state),
  signedIn: signedInSelector(state),
  trustArcEnabled: trustArcEnabledSelector(state),
});

const actions = {
  switchLanguage: switchLanguageAction,
};

Footer.propTypes = {
  alwaysUseMobileLayout: PropTypes.bool,
  className: PropTypes.string,
  localeFooter: PropTypes.object,
  useCrateLayout: PropTypes.bool,
  trustArcEnabled: PropTypes.bool,
};

export default connect(select, actions)(Footer);
