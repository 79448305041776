import React from 'react';
import { connect } from 'react-redux';
import Dialog from 'shared/app/components/dialog-with-messages';
import { dialogSelector } from 'shared/app/shell/state/selectors/app-ui';

const GlobalDialog = ({ dialog }) => {
  const { props, Content, contentProps } = dialog || {};
  return (
    <Dialog useLegacyVersion {...props}>
      {Content ? <Content {...contentProps} /> : null}
    </Dialog>
  );
};

const select = (state) => ({
  dialog: dialogSelector(state),
});

export default connect(select)(GlobalDialog);
