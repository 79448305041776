import React, { Component } from 'react';
import shouldPureComponentUpdate from 'react-pure-render/function';

export default ({ ignoreErrors = true } = {}) =>
  (ComposedComponent) => {
    class ErrorTrap extends Component {
      render() {
        if (!ignoreErrors) {
          Object.keys(this.props).forEach((key) => {
            const prop = this.props[key];

            if (prop?.error) {
              throw prop.payload;
            }
          });
        }

        return <ComposedComponent {...this.props} />;
      }
    }

    ErrorTrap.shouldComponentUpdate = shouldPureComponentUpdate;

    return ErrorTrap;
  };
