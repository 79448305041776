import React from 'react';
import PropTypes from 'prop-types';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import Provider from './provider';

const Feature = ({ children, name }) => {
  return (
    <Provider>
      <OptimizelyFeature autoUpdate feature={name}>
        {(isEnabled, variables) => children(isEnabled, variables)}
      </OptimizelyFeature>
    </Provider>
  );
};

Feature.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default Feature;
