import { isUndefined } from 'lodash';

// A lightweight wrapper for `addEventListener` and `removeEventListener`.
// Allows us to provide fallbacks for browsers like IE8 and to stub out
// event listener methods more easily in tests (working around issues with
// stubbing and spying on window event listener methods in PhantomJS).
/* eslint-disable max-params */
export const addEventListener = function (target, event, callback, useCapture) {
  useCapture = isUndefined(useCapture) ? false : useCapture;
  target.addEventListener(event, callback, useCapture);
};

export const removeEventListener = function (
  target,
  event,
  callback,
  useCapture
) {
  useCapture = isUndefined(useCapture) ? false : useCapture;
  target.removeEventListener(event, callback, useCapture);
};
