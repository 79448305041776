import { merge } from 'lodash';
import { getFraudRisk } from 'shared/app/utils/iovation';
import { parseResponseBody } from './fetch-utils';

const firstCatchStub = (err) => {
  throw err;
};

const createApiFetcher = (firstCatch = firstCatchStub, firstThen) => {
  return async (url, opts = {}) => {
    const {
      includeResponse,
      includeBlob,
      includeRisk = false,
      includeUab = false,
      body,
      ...fetchOpts
    } = opts;

    const bodyParams = body ?? {};

    if (includeRisk) {
      bodyParams.risk = await getFraudRisk(includeUab);
    }

    const combinedFetchOpts = merge(
      {
        method: 'get',
        headers: {
          accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
        credentials: 'same-origin',
      },
      fetchOpts
    );

    if (Object.keys(bodyParams)?.length > 0) {
      combinedFetchOpts.body = JSON.stringify(bodyParams);
      combinedFetchOpts.headers['content-type'] = 'application/json';
    }

    // simplifies testing
    const fetch = opts.fetch || window.fetch;

    return fetch(url, combinedFetchOpts)
      .then(parseResponseBody)
      .then(({ response, body: responseBody }) => {
        const responseOk = response.status >= 200 && response.status < 300;
        if (!responseOk) {
          const error = new Error(response.statusText);
          error.httpStatus = response.status;
          error.data = responseBody;
          throw error;
        }
        const bodyOrBlob = includeBlob
          ? { data: responseBody, blob: response.blob() }
          : responseBody;

        return includeResponse
          ? { response, data: responseBody, ...bodyOrBlob }
          : bodyOrBlob;
      })
      .catch((err) => firstCatch(err, url))
      .then((result) => {
        if (firstThen) {
          return firstThen(result);
        }
        return result;
      });
  };
};

export default createApiFetcher;
