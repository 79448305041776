const geolocation = (() => {
  const navigator = typeof window !== 'undefined' && window.navigator;
  if (!navigator) {
    return null;
  }
  return navigator.geolocation;
})();

export const geolocationSupported = Boolean(geolocation);

export const getGeolocation = (opts = {}) =>
  new Promise((resolve, reject) => {
    if (!geolocation) {
      // if not supported at all we return a `0` code.
      // this follows pattern set by the spec of returning
      // a code of 1, 2, 3 reference here:
      // https://developer.mozilla.org/en-US/docs/Web/API/PositionError
      const err = new Error('GeolocationNotSupported');
      err.code = 0;
      return reject(err);
    }
    geolocation.getCurrentPosition(resolve, reject, opts);
  });
