import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import storeManager from './store-manager/store-manager';
import browserRender from './browser-root';
import Layout from '../components';
import IS_BROWSER from '../utils/is-browser';

const composeStore =
  ({ apps }) =>
  ({ data, env }) =>
    storeManager.createRootStore({ data, apps, env });

const removeWrappingSpan = (props) => props.children;

const wrapWithProviders =
  (Root) =>
  ({ store, routes, history, locale, messages, formats }) => {
    return (
      <IntlProvider
        defaultFormats={formats}
        formats={formats}
        locale={locale}
        messages={messages}
        textComponent={removeWrappingSpan}
      >
        <Provider store={store}>
          <Router
            location={history.location}
            navigationType={history.action}
            navigator={history}
          >
            <Root routes={routes} />
          </Router>
        </Provider>
      </IntlProvider>
    );
  };

const defaultLayoutComponent = (layoutProps) => (props) => {
  // for convenience of setting a breakpoint on this line in the debugger, leave this return statement
  return <Layout {...layoutProps} {...props} />;
};

export default ({ rootComponent, apps, nav }) => {
  const getStore = composeStore({
    apps,
  });

  const Root = wrapWithProviders(
    rootComponent ||
      defaultLayoutComponent({
        nav,
      })
  );

  // just auto-render if browser so we
  // can use this as webpack entry point
  if (IS_BROWSER) {
    browserRender(Root, getStore, apps);
  }

  return { Root, getStore };
};
