import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import shouldUpdate from 'shared/app/stale-reducers/should-update';

import {
  hasShortSessionSelector,
  requiresMfaChallengeSelector,
} from './profile';

const userMfaFactorsResourceSelector = (state) => state.user?.mfaFactors;
const userMfaFactorsDataSelector = (state) =>
  state.user?.mfaFactors?.data || {};

export const shouldFetchUserMfaFactorsSelector = createSelector(
  userMfaFactorsResourceSelector,
  hasShortSessionSelector,
  requiresMfaChallengeSelector,
  (userMfaFactors, hasShortSession, requiresMfaChallenge) =>
    (hasShortSession || requiresMfaChallenge) &&
    shouldUpdate(userMfaFactors, {
      // passing null means cache indefinitely
      staleTime: null,
    })
);

export const hasFetchedMfaFactorsSelector = createSelector(
  userMfaFactorsResourceSelector,
  (mfaResource) => !mfaResource.isLoading && mfaResource.lastFetch !== null
);

export const userMfaVerifiedPhoneSelector = createSelector(
  userMfaFactorsDataSelector,
  (userMfaFactors) => userMfaFactors.verifiedPhone || {}
);

export const userMfaUnverifiedPhoneSelector = createSelector(
  userMfaFactorsDataSelector,
  (userMfaFactors) => userMfaFactors.unverifiedPhone || {}
);

export const userMfaPhoneSelector = createSelector(
  userMfaVerifiedPhoneSelector,
  userMfaUnverifiedPhoneSelector,
  (verifiedPhone, unverifiedPhone) =>
    (!isEmpty(verifiedPhone) && verifiedPhone) ||
    (!isEmpty(unverifiedPhone) && unverifiedPhone) ||
    {}
);

export const hasMfaPhoneSelector = createSelector(
  userMfaPhoneSelector,
  (phone) => !isEmpty(phone)
);

export const hasVerifiedPhoneSelector = createSelector(
  userMfaVerifiedPhoneSelector,
  (phone) => Boolean(phone.phoneNumber && phone.isVerified)
);

export const hasUnverifiedPhoneSelector = createSelector(
  userMfaUnverifiedPhoneSelector,
  (phone) => Boolean(phone.phoneNumber && !phone.isVerified)
);
