import React from 'react';
import classnames from 'classnames';
import prefix from 'prefix';

const prefixTransform = prefix('transform');
const prefixTransition = prefix('transition');

import styles from './styles.cssm';

const RADIUS_MIN_PX = 400;
const RADIUS_RANGE_PX = 800;
const ANIMATION_TIME_MIN_MS = 1000;
const ANIMATION_TIME_RANGE_MS = 1000;
const STAR_START_DELAY_RANGE_MS = 2000;
export const ANIMATION_TIME_MAX_MS =
  STAR_START_DELAY_RANGE_MS + ANIMATION_TIME_RANGE_MS * 1.2;

const circleCoord = (radius, deg) => {
  const rad = (deg * Math.PI) / 180;
  const x = radius * Math.cos(rad);
  const y = radius * Math.sin(rad);
  return { x, y };
};

const StarCore = ({ delay, isFilled, isAnimating }) => {
  const classes = classnames(styles.starCore, {
    [styles.starCoreFilled]: isFilled,
    [styles.starAnimateCore]: isAnimating,
  });
  /* eslint-disable max-len */
  const coreStyles = {
    [prefixTransition]: `opacity ${Math.round(
      ANIMATION_TIME_MIN_MS / 1.75
    )}ms ${Math.round(ANIMATION_TIME_MIN_MS / 1.75) + delay}ms`,
  };
  /* eslint-ensable max-len */
  return <div className={classes} style={coreStyles} />;
};

export const Star = ({
  x,
  y,
  delay,
  srcDeg,
  destDeg,
  isAnimating,
  ...otherProps
}) => {
  const classes = classnames(styles.star, {
    [styles.starAnimate0]: isAnimating && destDeg === 0,
    [styles.starAnimate90]: isAnimating && destDeg === 90,
    [styles.starAnimate180]: isAnimating && destDeg === 180,
    [styles.starAnimate270]: isAnimating && destDeg === 270,
  });

  const scale = 0.5 + Math.random() / 2; // 0.5 - 1.0
  const scaleStyle = {
    [prefixTransform]: `scale(${scale}, ${scale})`,
  };

  const wrapperStyles = {
    [prefixTransform]: `translate(${x}px, ${y}px) rotate(${srcDeg}deg)`,
    [prefixTransition]: `transform ${ANIMATION_TIME_MIN_MS}ms ${delay}ms, opacity 500ms ${delay}ms`,
  };

  return (
    <div className={classes} style={wrapperStyles}>
      <div style={scaleStyle}>
        <StarCore delay={delay} isAnimating={isAnimating} {...otherProps} />
      </div>
    </div>
  );
};

export const generateStarInfo = (isMobileViewport) => {
  const isSmall = Math.random() > 0.5;
  const radius = Math.round(RADIUS_MIN_PX + Math.random() * RADIUS_RANGE_PX);
  const coord = circleCoord(
    radius * (isSmall ? 2 : 1),
    (Math.random() - 0.5) * 360
  );

  if (isMobileViewport) {
    if (coord.x < -500) {
      coord.x /= 2;
    }
    if (coord.y < -500) {
      coord.y /= 2;
    }
  }

  return {
    x: Math.round(coord.x),
    y: Math.round(coord.y),
    delay: Math.round(Math.random() * STAR_START_DELAY_RANGE_MS),
    srcDeg: Math.round(
      360 * (2 + Math.random()) * (Math.random() > 0.5 ? -1 : 1)
    ),
    destDeg: 90 * Math.round(Math.random() * 3),
    animTimeMs: Math.round(
      ANIMATION_TIME_MIN_MS + Math.random() * ANIMATION_TIME_RANGE_MS
    ),
    isSmall,
    isFilled: Math.random() > 0.5,
  };
};

export default Star;
