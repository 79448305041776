import { defineMessages } from 'react-intl';

export const rewardsMessages = defineMessages({
  earningsPerDollar: {
    id: 'shared.rewards.earningsPerDollar',
    description: 'Amount earned per dollar spent',
    defaultMessage: 'Earns {numOfStars}{StarIcon} per $1',
  },
  rewardsPartnershipsMarriott: {
    id: 'shared.rewards.rewardsPartnershipsMarriott',
    defaultMessage:
      'Rewards Partnership with Starbucks® Rewards and Marriott Bonvoy',
    description:
      'en-us only multi-use heading for partnership with Marriott Bonvoy',
  },
  joinMarriottRewards: {
    id: 'shared.rewards.joinMarriottRewards',
    defaultMessage:
      'Not a Marriott Bonvoy® member? <cta>Join Marriott Bonvoy®</cta> first and return here to finish up linking.',
    description:
      'en-us only multi-use heading for partnership with Marriott Bonvoy',
  },
  termsAndConditions: {
    id: 'shared.rewards.termsAndConditions',
    defaultMessage: 'Terms and Conditions',
    description: 'Terms and Conditions shared message',
  },
});
