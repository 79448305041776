import { defineMessages } from 'react-intl';

export default defineMessages({
  body: {
    defaultMessage: 'Please select a store before continuing to the cart.',
    description:
      'Tells the user they need to select a store before continuing to their cart.',
    id: 'shared.selectStoreDialog.title',
  },
  cta: {
    defaultMessage: 'Select Store',
    description: 'Indicates that the user wants to select a store.',
    id: 'shared.selectStoreDialog.confirm',
  },
  dialogAriaLabel: {
    defaultMessage: 'Select a store',
    description: 'Accessible name for select store dialog',
    id: 'shared.selectStoreDialog.dialogAriaLabel',
  },
});
