import React from 'react';
import LogoWithName from 'shared/app/components/logo-with-a11y-name';
import HeaderCrate from '@starbucks-web/pattern-library/lib/components/header-crate';

import styles from './styles.cssm';

export default ({ children }) => (
  <div className={`lg-flex flex-column ${styles.base}`}>
    <div className="sb-global-gutters my3 lg-my4">
      <LogoWithName className={`${styles.siren} block`} size="100%" />
    </div>
    <HeaderCrate.Inner>{children}</HeaderCrate.Inner>
  </div>
);
