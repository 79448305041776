import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFraudReputation } from 'shared/app/utils/iovation';
import { createGuestSession } from 'shared/app/state/action-creators/guest';
import {
  signedInSelector,
  hasGuestSessionSelector,
  isCreatingGuestSessionSelector,
} from 'shared/app/bundles/user';

const useCreateGuestSession = () => {
  const isSignedIn = useSelector(signedInSelector);
  const hasGuestSession = useSelector(hasGuestSessionSelector);
  const isCreatingGuestSession = useSelector(isCreatingGuestSessionSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isSignedIn && !hasGuestSession && !isCreatingGuestSession) {
      (async () => {
        const reputation = await getFraudReputation();
        dispatch(createGuestSession({ reputation }));
      })();
    }
  }, [isSignedIn, hasGuestSession]);
};

export default useCreateGuestSession;
