import { createSelector } from 'reselect';
import { configSelector } from 'shared/app/shell';
import { currencyCodeSelector } from 'shared/app/state/selectors/locales';

export const payPalScriptUrlSelector = createSelector(
  configSelector,
  currencyCodeSelector,
  (config, currencyCode) => {
    const payPalUrl = config?.universal?.payPal?.url;
    return payPalUrl ? `${payPalUrl}&currency=${currencyCode}` : null;
  }
);
