import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';

import styles from './styles.cssm';

/* eslint-disable valid-jsdoc */
/**
 * This PR introduces a new component **_LegacyPaddedContentColumn_**.
 * It is intended to support legacy uses of the Pattern Library ContentColumn component.
 *
 * The original ContentColumn component included opinionated top and bottom padding,
 * as it was geared toward use within the ContentCrate system. The updated version of
 * the ContentColumn only controls gutters and column width, leaving consumers to
 * apply top and bottom spacing as needed.
 *
 * Given the expectation of top and bottom spacing being handled by ContentColumn,
 * this wrapper allows existing uses of the ContentColumn to be visually unchanged.
 *
 * >>> Please do not use the LegacyPaddedContentColumn component for future work.
 *
 * New uses for ContentColumn should use the straight Pattern Library version,
 * adding top and bottom spacing as needed to suit their needs.
 */
const LegacyPaddedContentColumn = ({
  children,
  size,
  className,
  innerClassName,
  ...otherProps
}) => {
  return (
    <ContentColumn
      children={children}
      className={classNames(styles.contentContainerPadding, `${className}`)}
      innerClassName={innerClassName}
      size={size}
      {...otherProps}
    />
  );
};

LegacyPaddedContentColumn.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
};

export default LegacyPaddedContentColumn;
