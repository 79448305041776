import React from 'react';

import styles from './styles.cssm';

const RadioToggle = ({ radioOptions, onChange, radioName, radioLegend }) => {
  return (
    <div className="px9 pt2 lg-px6 lg-pt3">
      <fieldset
        className={`flex justify-center color-greenAccent overflow-hidden ${styles.sbRadioToggle}`}
      >
        <legend className="hiddenVisually">{radioLegend}</legend>
        {radioOptions.map(({ value, displayName, defaultOption }) => {
          return (
            <label
              className={`flex-auto text-xs text-semibold text-center cursor-pointer ${styles.sbRadioToggleSlider}`}
              key={displayName}
            >
              <input
                className="hiddenVisually"
                defaultChecked={defaultOption}
                name={radioName}
                onChange={onChange}
                type="radio"
                value={value}
              />
              {displayName}
            </label>
          );
        })}
      </fieldset>
    </div>
  );
};

export default RadioToggle;
