import { defineMessages } from 'react-intl';

export const svcMessages = defineMessages({
  transferBalanceSuccess: {
    id: 'shared.svcMessages.notifications.transferBalanceSuccess',
    defaultMessage: '{amount} transferred to card ({lastFourDigits})',
    description:
      'Message displayed when a user successfully transfers a card balance',
  },
  autoReloadLabel: {
    id: 'shared.svcMessages.autoReloadLabel',
    defaultMessage: 'Auto reload',
    description: 'label for card auto reload',
  },
  transferCardBalance: {
    defaultMessage: 'Transfer balance',
    id: 'shared.svcMessages.transferCardBalance',
    description: 'CTA that brings you to transfer balance between cards',
  },
  scanInStore: {
    id: 'shared.svcMessages.scanInStore',
    defaultMessage: 'Scan in store',
    description: 'Text used for buttons to scan with primary card',
  },
  starbucksCard: {
    id: 'shared.svcMessages.starbucksCard',
    defaultMessage: 'Starbucks Card',
    description: 'Label for svc select input',
  },
  cardNicknameManageLink: {
    id: 'shared.svcMessages.cardNicknameManageLink',
    defaultMessage: 'Manage Link of card with nickname {nickname}',
    description: 'Text for the manage link of svc card with nickname',
  },
  cardNicknameBalance: {
    id: 'shared.svcMessages.cardNicknameBalance',
    defaultMessage: 'Balance of card with nickname {nickname} is {balance}',
    description: 'Text for the balance of svc card with nickname',
  },
  cardTermsAndConditionsLink: {
    id: 'shared.svcMessages.cardTermsAndConditionsLink',
    defaultMessage: 'Card Terms & Conditions',
    description: 'Text of a link to the Card terms page',
  },
  giftAltContext: {
    id: 'shared.svcMessages.giftAltContext',
    description: 'Context provider for gift card alt text',
    defaultMessage: 'Gift card image: ',
  },
});
